import React from 'react';
import { Prompt } from 'react-router'
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withAuthorization, withEmailVerification } from '../../session';

import Lottie from 'lottie-react-web'
import loading from '../../Lottiefiles/loading.json';
import payments from '../../Lottiefiles/payments.json';
import paymentSuccess from '../../Lottiefiles/payment-successful.json';
import paymentFailed from '../../Lottiefiles/payment-failed.json';

import * as ROUTES from '../../../constants/routes';
import { LinkContainer } from "react-router-bootstrap";

import "./style.css";

import Button from 'react-bootstrap/Button'

class OrderBase extends React.Component {
constructor(props) {
  super(props);

  this.state = {
    loading: false,
    bestelling: [],
    ...props.location.state,
  };
}

componentDidMount() {
  window.scrollTo(0, 0);

  this.setState({ loading: true });

  this.props.firebase
    .bestelling(this.props.match.params.id)
    .on('value', snapshot => {
      this.setState({
        bestelling: snapshot.val(),
        loading: false,
      });
    });
}

componentWillUnmount() {
  this.props.firebase.bestelling(this.props.match.params.id).off();
}

  render() {
    var {bestelling} = this.state
    var pending = true;
    var knopBlokkeren = true;

    if (bestelling.status !== "Niet betaald") {
      pending = false;
      knopBlokkeren = false;
    }

    var betalingOk = false;

    if (bestelling.status === "Betaald") {
      betalingOk = true;
    }


  return (
      <div id="orderDisplay">
        <Prompt
          when={knopBlokkeren}
          message='Ben je zeker dat je de pagina wilt verlaten? De betaling wordt nog nagekeken'
        />
        <div id="orderDisplay-block">
          <div className="orderDisplay-left">
            <Lottie
              options={{
                animationData: payments,
              }}
              width={"80%"}
              heigth={"auto"}
            />
          </div>
            {!!pending && (
              <div className="orderDisplay-right">
                <div>
                  <h2>Betaling nakijken</h2>
                  <h1>€ {bestelling.bedrag}</h1>
                  <br/>
                  <h5><b>Omschrijving:</b></h5>
                  <p>{bestelling.omschrijving}</p>
                </div>
                <Lottie
                  options={{
                    animationData: loading,
                  }}
                  width={"60%"}
                  heigth={"60%"}
                />
              <p>Dit kan wel even duren...</p>
              </div>
            )}
            {!pending && (
              <div className="orderDisplay-right">
                <div>
                  <h2>Betaling: {bestelling.status}</h2>
                  <h1>€ {bestelling.bedrag}</h1>
                  <br/>
                  <h5><b>Omschrijving:</b></h5>
                  <p>{bestelling.omschrijving}</p>
                </div>
                {!!betalingOk ? (
                  <Lottie
                    options={{
                      animationData: paymentSuccess,
                      loop: false,
                    }}
                    width={"80%"}
                    heigth={"auto"}
                  />
                ) : (
                  <Lottie
                    options={{
                      animationData: paymentFailed,
                      loop: false,
                    }}
                    width={"80%"}
                    heigth={"auto"}
                  />
                )}

              </div>
            )}
            <LinkContainer to={ROUTES.LEDENPORTAAL}>
              <Button disabled={knopBlokkeren} variant="primary" size="lg">
                Terug naar het ledenportaal
              </Button>
            </LinkContainer>
          </div>

        </div>

  );
    }
  }

  const condition = authUser =>
    authUser;

const Order = withFirebase(OrderBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase,
)(Order);
