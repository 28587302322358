import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification} from '../../session';
import { withFirebase } from '../../Firebase';

import * as ROLES from '../../../constants/roles';

class AanwezighedenBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

  };

  componentWillUnmount() {

  }

  onChangeText = event => {
   this.setState({ [event.target.name]: event.target.value });
  };

  render(){

  return (
    <div id="vraagstellen">
      <div>
        <div>
          <h1>Aanwezigheden trainingen</h1>
        </div>
        <div>
          <h3>Trainingen nog in te vullen</h3>
        </div>
        <div>
          <h3>Vorige ingevulde trainingen</h3>
        </div>
      </div>
    </div>
  );
}}

const condition = authUser =>
  authUser && (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.TRAINER]);

const Aanwezigheden = withFirebase(AanwezighedenBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(Aanwezigheden);
