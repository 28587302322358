import React from 'react';
import { Table } from 'react-bootstrap';
import './Home.css';
import { withFirebase } from '../Firebase';
import Button from 'react-bootstrap/Button'

class VolgendWeekendBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    loading: true,
    huidigSeizoen: "",
    ploegen: [],
    wedstrijden: [],
    kwbdames: [],
    kwbheren: [],
    kwbdamesKalender: [],
    kwbherenKalender: [],
    weeknummer: 0,
    jaar: 2022,
    weeknummerVast: 0
  }
}

componentDidMount() {

  var weeknummer = this.getWeekNumber(new Date());
  var currentdate = new Date();

  this.setState({
    weeknummer: weeknummer,
    weeknummerVast: weeknummer,
    jaar: currentdate.getFullYear()
  })

  this.props.firebase.volleyscoresWedstrijden().on('value', snapshot => {
    const usersObject = snapshot.val();
    if (usersObject) {

      const wedstrijdenList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));

      this.setState({
        wedstrijden: wedstrijdenList,
        loading: false,
      });
    }
  })

  this.props.firebase.kwbdames().on('value', snapshot => {
    const usersObject = snapshot.val();
    if (usersObject) {
      this.setState({
        kwbdames: usersObject,
        loading: false,
      });
    }else {
      this.setState({
        kwbdames: [],
        loading: false
      })
    }
  })

  this.props.firebase.kwbheren().on('value', snapshot => {
    const usersObject = snapshot.val();
    if (usersObject) {
      this.setState({
        kwbheren: usersObject,
        loading: false,
      });
    } else {
      this.setState({
        kwbheren: [],
        loading: false
      })
    }
  })

  var huidigSeizoen = "2023-2024"
  this.props.firebase.huidigSeizoen().on('value', snapshot => {
    huidigSeizoen = snapshot.val()
    this.setState({
      huidigSeizoen
    })
  })

  this.props.firebase.seizoen(huidigSeizoen).on('value', snapshot => {
    var seizoenObject = snapshot.val();
    if (seizoenObject) {
      this.setState({
        ploegen: seizoenObject.ploegen
      })
    }
  })

  this.props.firebase.kwbherenKalender().on('value', snapshot => {
    const objectValue = snapshot.val();
    if(objectValue) {
      const wedstrijdList = Object.keys(objectValue).map(key => ({
        ...objectValue[key],
        uid: key,
      }));

      this.setState({
        kwbherenKalender: wedstrijdList
      })
    }
  })

  this.props.firebase.kwbdamesKalender().on('value', snapshot => {
    const objectValue = snapshot.val();
    if(objectValue) {
      const wedstrijdList = Object.keys(objectValue).map(key => ({
        ...objectValue[key],
        uid: key,
      }));

      this.setState({
        kwbdamesKalender: wedstrijdList
      })
    }
  })

}

vorigeWeek() {
  if(this.state.weeknummer > 1){
    this.setState({
      weeknummer: this.state.weeknummer-1
    })
  } else {
    this.setState({
      weeknummer: 52,
      jaar: this.state.jaar - 1
    })
  }
}

volgendeWeek() {
  if(this.state.weeknummer < 52){
    this.setState({
      weeknummer: this.state.weeknummer+1
    })
  } else {
    this.setState({
      weeknummer: 1,
      jaar: this.state.jaar + 1
    })
  }
}

dezeWeek() {
  this.setState({
    weeknummer: this.state.weeknummerVast,
    jaar: new Date().getFullYear()
  })
}

getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    // Return array of year and week number
    return weekNo;
}


  render() {
    var { ploegen, wedstrijden, kwbdames, kwbheren, kwbdamesKalender, kwbherenKalender, weeknummer, jaar } = this.state

    var volgendeWedstrijden = []

    console.log(ploegen)

    ploegen.forEach((ploeg, i) => {
      var lijstWedstrijden = []
      wedstrijden.forEach((wedstrijdPerPloeg, y) => {
        if(ploeg.code.includes(wedstrijdPerPloeg.uid)) {
          var lijstWedstrijdenPerPloegCodeEen= []
          wedstrijdPerPloeg.tabel.forEach((game, z) => {

            const dag = game.datum.substr(0,2)
            const maand = game.datum.substr(3,2)
            const jaar = game.datum.substr(6,4)

            var currentdate = new Date(jaar, maand-1, dag);
            var weeknummerMatch = this.getWeekNumber(currentdate)

            if(weeknummerMatch === weeknummer && Number(jaar) === this.state.jaar) {
              game.ploeg = ploeg.code
              lijstWedstrijdenPerPloegCodeEen.push(game)
            }
          });
          if(lijstWedstrijdenPerPloegCodeEen.length > 0) {
            lijstWedstrijdenPerPloegCodeEen.forEach((wed, i) => {
              wed.ploeg = ploeg.code
              lijstWedstrijden.push(wed)
            });

          }
        }
        if(ploeg.codeTwee.includes(wedstrijdPerPloeg.uid)) {
          var lijstWedstrijdenPerPloegCodeTwee= []
          wedstrijdPerPloeg.tabel.forEach((game, z) => {

            const dag = game.datum.substr(0,2)
            const maand = game.datum.substr(3,2) - 1
            const jaar = game.datum.substr(6,4)

            var currentdate = new Date(jaar, maand, dag);
            var weeknummerMatch = this.getWeekNumber(currentdate)

            if(weeknummerMatch === weeknummer && Number(jaar) === this.state.jaar) {
              game.ploeg = ploeg.code
              lijstWedstrijdenPerPloegCodeTwee.push(game)
            }
          });
          if(lijstWedstrijdenPerPloegCodeTwee.length > 0) {
            lijstWedstrijdenPerPloegCodeTwee.forEach((wed, i) => {
              wed.ploeg = ploeg.code
              lijstWedstrijden.push(wed)
            });

          }
        }
      });
      if(lijstWedstrijden.length > 0) {

        var alleWedstrijdenGefiltert = lijstWedstrijden.sort(function (a, b) {
             var datumEen = new Date(a.datum.substring(6,10), a.datum.substring(3,5)-1, a.datum.substring(0,2), a.uur.substring(0,2))
             var datumTwee = new Date(b.datum.substring(6,10), b.datum.substring(3,5)-1, b.datum.substring(0,2), b.uur.substring(0,2))
             return new Date(datumEen) - new Date(datumTwee)
         })

        alleWedstrijdenGefiltert.forEach((wed, i) => {
          volgendeWedstrijden.push(wed)
        });
      } else if(ploeg.code !== "") {
        volgendeWedstrijden.push({
          ploeg: ploeg.code,
          datum: "",
          uur: "",
          thuis: "",
          bezoeker: "",
          plaats: ""
        })
      }
    });

    kwbherenKalender.forEach((wedKal, i) => {
      kwbheren.forEach((wedUitslag, y) => {
        if(wedUitslag.datum === wedKal.datum) {
          kwbherenKalender[i] = wedUitslag
        }
      });
    });

    kwbdamesKalender.forEach((wedKal, i) => {
      kwbdames.forEach((wedUitslag, y) => {
        if(wedUitslag.datum === wedKal.datum) {
          kwbdamesKalender[i] = wedUitslag
        }
      });
    });

    var lijstWedstrijdenKWBHeren = []
    kwbherenKalender.forEach((wed, i) => {
      const dag = wed.datum.substr(0,2)
      const maand = wed.datum.substr(3,2) - 1
      const jaar = wed.datum.substr(6,4)

      var currentdate = new Date(jaar, maand, dag);
      var weeknummerMatch = this.getWeekNumber(currentdate)

      if(weeknummerMatch === weeknummer && Number(jaar) === this.state.jaar) {
        wed.ploeg = "KWB Heren"
        lijstWedstrijdenKWBHeren.push(wed)
      }
    });
    if(lijstWedstrijdenKWBHeren[0] === undefined) {
      volgendeWedstrijden.push({
        ploeg: "KWB Heren",
        datum: "",
        uur: "",
        thuis: "",
        bezoeker: "",
        plaats: ""
      })
    } else {
      volgendeWedstrijden.push(lijstWedstrijdenKWBHeren[0])
    }

    var lijstWedstrijdenKWBDames = []
    kwbdamesKalender.forEach((wed, i) => {
      const dag = wed.datum.substr(0,2)
      const maand = wed.datum.substr(3,2) - 1
      const jaar = wed.datum.substr(6,4)

      var currentdate = new Date(jaar, maand, dag);
      var weeknummerMatch = this.getWeekNumber(currentdate)

      if(weeknummerMatch === weeknummer && Number(jaar) === this.state.jaar) {
        wed.ploeg = "KWB Dames"
        lijstWedstrijdenKWBDames.push(wed)
      }
    });
    if(lijstWedstrijdenKWBDames[0] === undefined) {
      volgendeWedstrijden.push({
        ploeg: "KWB Dames",
        datum: "",
        uur: "",
        thuis: "",
        bezoeker: "",
        plaats: ""
      })
    } else {
      volgendeWedstrijden.push(lijstWedstrijdenKWBDames[0])
    }

    var w2date = function(year, wn, dayNb){
      var j10 = new Date( year,0,10,12,0,0),
          j4 = new Date( year,0,4,12,0,0),
          mon1 = j4.getTime() - j10.getDay() * 86400000;
      return new Date(mon1 + ((wn - 1)  * 7  + dayNb) * 86400000);
  };

  const vrijdag = w2date(jaar, weeknummer, 4)
  var vrijdagDag = vrijdag.getDate()
  var vrijdagMaand = vrijdag.getMonth()+1
  const vrijdagJaar = vrijdag.getFullYear()
  if(vrijdagDag < 10) {
    vrijdagDag = "0"+vrijdagDag
  }
  if(vrijdagMaand < 10) {
    vrijdagMaand = "0"+vrijdagMaand
  }

  const zondag = w2date(jaar, weeknummer, 6)
  var zondagDag = zondag.getDate()
  var zondagMaand = zondag.getMonth()+1
  const zondagJaar = zondag.getFullYear()
  if(zondagDag < 10) {
    zondagDag = "0"+zondagDag
  }
  if(zondagMaand < 10) {
    zondagMaand = "0"+zondagMaand
  }

  var displayButton = false;

  if(this.state.weeknummerVast !== weeknummer) {
    displayButton = true;
  }

    return (
      <div className="kalendertabel">
        <div className="kalender-weekpicker">
          <i className="fas fa-arrow-circle-left" onClick={() => this.vorigeWeek()}></i>
          <p>Week {weeknummer}: VRIJ ({vrijdagDag}/{vrijdagMaand}/{vrijdagJaar}) - ZON ({zondagDag}/{zondagMaand}/{zondagJaar}) </p>
          <i className="fas fa-arrow-circle-right" onClick={() => this.volgendeWeek()}></i>
          {!!displayButton && (
            <Button onClick={() => this.dezeWeek()}>Terug naar huidige week</Button>
          )}
        </div>
        <Table striped bordered size="sm" className="text-black" responsive>
          <thead>
            <tr>
              <th>Ploeg</th>
              <th>Datum</th>
              <th>Uur</th>
              <th>Thuis</th>
              <th>Bezoekers</th>
              <th>Sporthal</th>
            </tr>
          </thead>
          <tbody>
            {volgendeWedstrijden.map(wed => {
              var vetThuis = ""
              var vetUit = ""

              if(wed.thuis.toLowerCase().includes("schriek")) {
                vetThuis = "bold"
              }
              if(wed.bezoeker.toLowerCase().includes("schriek")) {
                vetUit = "bold"
              }

              return(
              <tr key={wed.ploeg + wed.uur}>
                <td>{wed.ploeg}</td>
                <td>{wed.datum}</td>
                <td>{wed.uur}</td>
                <td style={{fontWeight: vetThuis}}>{wed.thuis}</td>
                <td style={{fontWeight: vetUit}}>{wed.bezoeker}</td>
                <td>{wed.plaats}</td>
              </tr>
            )})}
          </tbody>
        </Table>
      </div>
    )
  }
}
export const VolgendWeekend = withFirebase(VolgendWeekendBase);
