import React from 'react';
import "./style.css";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withEmailVerification } from '../../session';


class AddTodo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      omschrijving: "",
      naam: "",
      datum: "",
    };
  }

  onChangeText = event => {
   this.setState({ omschrijving: event.target.value });
  };

  onChangeBestuur = event => {
   this.setState({ naam: event.target.value });
  };

  onChangeDatum = event => {
   this.setState({ datum: event.target.value });
  };

  onCreateTodo = () => {
    var omschrijving = this.state.omschrijving;
    var naam = this.state.naam;
    var datum = this.state.datum;

    if(omschrijving !== "" && naam !== "" && datum !== "") {
      this.setState({
        omschrijving: "",
        naam: "",
        datum: "",
      })

      this.props.firebase.todo().push({
        omschrijving,
        naam,
        deadline: datum,
        createdAt: this.props.firebase.serverValue.TIMESTAMP,
      });
      this.setState({
        omschrijving: "",
        naam: "",
        datum: "",
      })
      alert("Opgeslagen!");
      this.props.close()
    } else {
      alert("Zo te zien werd niet alles ingevuld!"+omschrijving+naam+datum)
    }

  }

  render() {
    var bestuur = this.props.bestuur;

    return (
      <div id="AddTodoSection" style={{display:`${this.props.displayAdd}`}}>
        <div id="AddTodoSection-background">
        </div>
        <div id="AddTodoSection-front">
          <div>
            <h2>Todo toevoegen</h2>
            <br/>
              <Form>
                <Form.Group controlId="formGroupText">
                  <Form.Label>Omschrijvings:</Form.Label>
                  <Form.Control type="text" value={this.state.omschrijving} placeholder="Geef een taakomschrijving" onChange={this.onChangeText}/>
                </Form.Group>
                <Form.Group controlId="formGridState">
                 <Form.Label>Verantwoordelijke</Form.Label>
                 <Form.Control as="select" value={this.state.naam} style={{width: "220px", margin:"auto"}} onChange={this.onChangeBestuur}>
                   <option value="Kies bestuurslid">Kies bestuurslid</option>
                   {bestuur.map(lid => (
                     <option key={lid.username} value={lid.username}>{lid.username}</option>
                   ))}
                 </Form.Control>
               </Form.Group>
               <Form.Group controlId="dob">
                  <Form.Label>Deadline:</Form.Label>
                  <Form.Control type="date" name="datum" value={this.state.datum} placeholder="Deadline" style={{width: "220px", margin:"auto"}} onChange={this.onChangeDatum}/>
              </Form.Group>
              </Form>
            <Button variant="success" onClick={() => this.onCreateTodo()}>Toevoegen</Button>
            <Button variant="danger" onClick={this.props.close}>Annuleren</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withEmailVerification,
  withFirebase,
)(AddTodo);
