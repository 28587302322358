import React from 'react';
import { Nieuws } from './Nieuws';
import './Nieuws.css';
import { withFirebase } from '../Firebase';


class NieuwsoverzichtBase extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: false,
      nieuws: [],
    }
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.nieuws().on('value', snapshot => {
      const usersObject = snapshot.val();

      try {
        const usersList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));

        usersList.reverse()
        var usersListVier = usersList.slice(0, 3);


        this.setState({
          nieuws: usersListVier,
          loading: false,
        });
      } catch(error) {
        var nieuws = [{
          titel: "Er is momenteel geen nieuws",
          meerinfo: "",
        }]

          this.setState({
            nieuws: nieuws,
            loading: false,
          })
        }
    });
  }

  render() {
    const { nieuws, loading } = this.state

    return (
      <div className="Nieuwsoverzicht">
        {loading && <div>Loading ...</div>}
        {
          nieuws.map(obj => {
            return (
              <Nieuws titel={obj.titel} tekst={obj.text} datum={obj.datum} img={obj.urlfoto} link={obj.meerinfo} key={obj.uid}/>
            )
          })
        }
      </div>
    )
  }
}

export const Nieuwsoverzicht = withFirebase(NieuwsoverzichtBase);
