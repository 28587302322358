import React from 'react';
import './Sponsors.css'

import Sponsor1 from './img/avalon.jpg';
import Sponsor2 from './img/bartdehaes.jpg';
import Sponsor3 from './img/Bierpot.jpg';
import Sponsor4 from './img/bosmans.jpg';
import Sponsor5 from './img/broodplank.jpg';
import Sponsor6 from './img/claeskarl.jpg';
import Sponsor7 from './img/denuyl.jpg';
import Sponsor8 from './img/dorps broodje.jpg';
import Sponsor9 from './img/drafhandel.jpg';
import Sponsor10 from './img/fidentax.jpg';
import Sponsor11 from './img/fintro schriek.jpg';
import Sponsor12 from './img/gvl.jpg';
import Sponsor13 from './img/hoefzorg.jpg';
import Sponsor14 from './img/hoeve vd boer.jpg';
import Sponsor15 from './img/lapeconera.jpg';
import Sponsor16 from './img/mattijs.jpg';
import Sponsor17 from './img/restoatlas.jpg';
import Sponsor18 from './img/schriecks friethuys.jpg';
import Sponsor19 from './img/sintjanshoeve.jpg';
import Sponsor20 from './img/terbos.jpg';
import Sponsor21 from './img/vandenwijngaert.jpg';
import Sponsor22 from './img/vanmossel.jpg';
import Sponsor23 from './img/vanoosterwijck.jpg';
import Sponsor24 from './img/vatelier.jpg';
import Sponsor25 from './img/Verwimp.jpg';
import Sponsor26 from './img/whatssoup.jpg';
import Sponsor27 from './img/wijnhuis verlinden.jpg';


export class Sponsors extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="sponsoroverzicht one-edge-shadow">
        <div className="backgroundSponsors"></div>
        <div className="white-bg"></div>
        <h1 className="activiteit-titel">Sponsors</h1>
        <div className="onderlijn"></div>
        <div className="">
            <img src={Sponsor1}   alt="sponsor1" className="sponsor rounded"></img>
            <img src={Sponsor2}   alt="sponsor2" className="sponsor rounded"></img>
            <img src={Sponsor3}   alt="sponsor3" className="sponsor rounded"></img>
            <img src={Sponsor4}   alt="sponsor4" className="sponsor rounded"></img>
            <img src={Sponsor5}   alt="sponsor5" className="sponsor rounded"></img>
            <img src={Sponsor6}   alt="sponsor6" className="sponsor rounded"></img>
            <img src={Sponsor7}   alt="sponsor7" className="sponsor rounded"></img>
            <img src={Sponsor8}   alt="sponsor8" className="sponsor rounded"></img>
            <img src={Sponsor9}   alt="sponsor9" className="sponsor rounded"></img>
            <img src={Sponsor10}   alt="sponsor10" className="sponsor rounded"></img>
            <img src={Sponsor11}   alt="sponsor11" className="sponsor rounded"></img>
            <img src={Sponsor12}   alt="sponsor12" className="sponsor rounded"></img>
            <img src={Sponsor13}   alt="sponsor13" className="sponsor rounded"></img>
            <img src={Sponsor14}   alt="sponsor14" className="sponsor rounded"></img>
            <img src={Sponsor15}   alt="sponsor15" className="sponsor rounded"></img>
            <img src={Sponsor16}   alt="sponsor16" className="sponsor rounded"></img>
            <img src={Sponsor17}   alt="sponsor17" className="sponsor rounded"></img>
            <img src={Sponsor18}   alt="sponsor18" className="sponsor rounded"></img>
            <img src={Sponsor19}   alt="sponsor19" className="sponsor rounded"></img>
            <img src={Sponsor20}   alt="sponsor20" className="sponsor rounded"></img>
            <img src={Sponsor21}   alt="sponsor21" className="sponsor rounded"></img>
            <img src={Sponsor22}   alt="sponsor22" className="sponsor rounded"></img>
            <img src={Sponsor23}   alt="sponsor23" className="sponsor rounded"></img>
            <img src={Sponsor24}   alt="sponsor24" className="sponsor rounded"></img>
            <img src={Sponsor25}   alt="sponsor25" className="sponsor rounded"></img>
            <img src={Sponsor26}   alt="sponsor26" className="sponsor rounded"></img>
            <img src={Sponsor27}   alt="sponsor27" className="sponsor rounded"></img>
          </div>
      </div>
    )
  }
}
