import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification} from '../../session';
import Button from 'react-bootstrap/Button';
import { Switch, Route, Link } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import { withFirebase } from '../../Firebase';
import Pagination from 'react-bootstrap/Pagination'
import {datumFirebaseString} from '../../../functions/DatumFirebase'

const Ledennieuws = () => (
    <div style={{overflow: "auto", height: "100%"}}>
      <Switch>
        <Route exact path={ROUTES.LEDENNIEUWS_DETAILS} component={LedennieuwsItem} />
        <Route exact path={ROUTES.LEDENNIEUWS} component={LedennieuwsLijst} />
      </Switch>
    </div>
)

class LedennieuwsBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      ledennieuws: [],
      laatsteNieuwsItem: [],
      leeg: false,
      active: 1,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({ loading: true });

    this.props.firebase.ledennieuws().on('value', snapshot => {
      const usersObject = snapshot.val();

      try {
        const nieuwsList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));

        nieuwsList.reverse()

        var laatsteNieuwsItem = nieuwsList.shift()
        var laatsteNieuws = []
        if(laatsteNieuwsItem === undefined) {
          laatsteNieuws[0] = {
            uid: "leeg",
            titel: "geen nieuws",
            datum:"01-01-2021",
            meerinfo:"",
          }
        } else {
          laatsteNieuws[0] = laatsteNieuwsItem
        }
        console.log(nieuwsList)

        this.setState({
          ledennieuws: nieuwsList,
          laatsteNieuwsItem: laatsteNieuws,
          loading: false,
        });
      } catch(error) {
        var nieuws = []

          this.setState({
            ledennieuws: nieuws,
            loading: false,
            leeg: true,
          })
        }
      })
  }

  changePage(number) {
    this.setState({
      active: number
    })
  }

  render(){
    var { laatsteNieuwsItem, ledennieuws, active,  loading, leeg } = this.state;

    var ledenNieuwsArray = []
    var active1 = false;
    var active2 = false;
    var active3 = false;
    var displayPagination1 = true;
    var displayPagination2 = true;
    var displayPagination3 = true;

    if(ledennieuws.length < 6) {
      displayPagination1 = true;
      displayPagination2 = false;
      displayPagination3 = false;
    } else if (ledennieuws.length < 11) {
      displayPagination1 = true;
      displayPagination2 = true;
      displayPagination3 = false;
    } else {
      displayPagination1 = true;
      displayPagination2 = true;
      displayPagination3 = true;
    }

    if(ledennieuws.length > 0 && active === 1) {
      ledenNieuwsArray = []
      if(ledennieuws[0] !== undefined){
        ledenNieuwsArray.push(ledennieuws[0])
      }
      if(ledennieuws[1] !== undefined){
        ledenNieuwsArray.push(ledennieuws[1])
      }
      if(ledennieuws[2] !== undefined){
        ledenNieuwsArray.push(ledennieuws[2])
      }
      if(ledennieuws[3] !== undefined){
        ledenNieuwsArray.push(ledennieuws[3])
      }
      if(ledennieuws[4] !== undefined){
        ledenNieuwsArray.push(ledennieuws[4])
      }
      active1 = true;
    }
    if(ledennieuws.length > 5 && active === 2) {
      ledenNieuwsArray = []
      if(ledennieuws[5] !== undefined){
        ledenNieuwsArray.push(ledennieuws[5])
      }
      if(ledennieuws[6] !== undefined){
        ledenNieuwsArray.push(ledennieuws[6])
      }
      if(ledennieuws[7] !== undefined){
        ledenNieuwsArray.push(ledennieuws[7])
      }
      if(ledennieuws[8] !== undefined){
        ledenNieuwsArray.push(ledennieuws[8])
      }
      if(ledennieuws[9] !== undefined){
        ledenNieuwsArray.push(ledennieuws[9])
      }
      active2 = true;
    }
    if(ledennieuws.length > 10 && active === 3) {
      ledenNieuwsArray = []
      if(ledennieuws[10] !== undefined){
        ledenNieuwsArray.push(ledennieuws[10])
      }
      if(ledennieuws[11] !== undefined){
        ledenNieuwsArray.push(ledennieuws[11])
      }
      if(ledennieuws[12] !== undefined){
        ledenNieuwsArray.push(ledennieuws[12])
      }
      if(ledennieuws[13] !== undefined){
        ledenNieuwsArray.push(ledennieuws[13])
      }
      if(ledennieuws[14] !== undefined){
        ledenNieuwsArray.push(ledennieuws[14])
      }
      active3 = true;
    }


  return (
    <div id="nieuwspagina">
      <div>
        <div><h1>Clubnieuws</h1></div>
        <div className="onderlijn"></div>
      </div>
      <div>
        {loading && <div>Loading ...</div>}
        {leeg && <div className="nieuwspagina-geennieuws"><div>Er is momenteel geen nieuws.</div></div>}
        <div id="nieuwspagina-overzicht">
          <div>
            {laatsteNieuwsItem.map(function(nieuws){
              var text = nieuws.text
              if(nieuws.text.length > 500) {
                text = nieuws.text.substr(0, 500) + " (...)"
              }
              return (
                <Link to={{ state: {nieuws}, pathname: `${ROUTES.LEDENNIEUWS}/${nieuws.uid}` }} key={nieuws.uid}>
                  <div className="nieuws-item-big" >
                    <div className="nieuws-foto-big" style={{backgroundImage: `url('${nieuws.urlfoto}')`}}>
                    </div>
                    <div className="nieuws-tekst-big">
                      <h6>{nieuws.titel}</h6>
                      <p className="nieuws-datum-big">{datumFirebaseString(nieuws.datum)}</p>
                      <div>{text}</div>
                      <br/>
                      <Button style={{width: "200px", margin: "auto"}}>Meer info</Button>
                    </div>
                    <br/>
                  </div>
                </Link>
              )
            })}
          </div>
          <div className="nieuws-lijst">
              {ledenNieuwsArray.map(function(nieuws){
                var titel = nieuws.titel
                var datum = datumFirebaseString(nieuws.datum)
                return (
                  <Link to={{ state: { nieuws }, pathname: `${ROUTES.LEDENNIEUWS}/${nieuws.uid}` }} key={nieuws.uid}>
                    <div className="nieuws-item" >
                      <div className="nieuws-foto" style={{backgroundImage: `url('${nieuws.urlfoto}')`}}>
                      </div>
                      <div className="nieuws-tekst">
                        <h6>{titel}</h6>
                        <p className="nieuws-datum">{datum}</p>
                      </div>
                    </div>
                  </Link>
              )
              })}
          </div>
          </div>
      </div>
    </div>
  );
}}

class LedennieuwsItemBase extends React.Component {
constructor(props) {
  super(props);

  this.state = {
    loading: false,
    nieuws: null,
    ...props.location.state,
  };
}

componentDidMount() {
  window.scrollTo(0, 0);

  this.setState({ loading: true });

  this.props.firebase
    .ledennieuws(this.props.match.params.id)
    .on('value', snapshot => {
      this.setState({
        user: snapshot.val(),
        loading: false,
      });
    });
}

componentWillUnmount() {
  this.props.firebase.ledennieuws(this.props.match.params.id).off();
}

rerout() {
  window.location.replace(`${ROUTES.LEDENNIEUWS}`)
}

  render() {
    const { nieuws } = this.state;
    var displayInfo = true
    if(nieuws === null) {
      this.rerout()
    }
    if(nieuws.meerinfo === "") {
      displayInfo = false
    }
    var datum = datumFirebaseString(nieuws.datum)
  return (
    <div className="nieuwsCard">
      <br/>
      <Link variant="primary" to={ROUTES.LEDENNIEUWS}>Terug naar clubnieuws</Link>
      <br/><br/>
      <div className="nieuwsCard-img" style={{backgroundImage: `url('${nieuws.urlfoto}')`}}>
      </div>
      <div className="nieuwsCard-text">
        <h3>{nieuws.titel}</h3>
        <div>{datum}</div>
        <br/>
        <div className="divider2 div-transparent2"></div>
        <p>{nieuws.text}</p>
        <br/>
        {displayInfo && (
          <Button variant="primary" href={nieuws.meerinfo}>Meer info</Button>
        )}
      </div>
    </div>
  );
    }
  }

const condition = authUser => !!authUser;

const LedennieuwsLijst = withFirebase(LedennieuwsBase);
const LedennieuwsItem = withFirebase(LedennieuwsItemBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(Ledennieuws);
