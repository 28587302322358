import React from 'react';
import "./style.css";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import wakado from '../../afbeeldingen/wakado.jpg';
import ProgressBar from 'react-bootstrap/ProgressBar'

import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withEmailVerification } from '../../session';


class GegevensAanpassen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      ploeg: "",
      voornaam: "",
      achternaam: "",
      telefoon: "",
      email: "",
      straat: "",
      postcode: "",
      gemeente: "",
      geboortedatum: "",
      rijksregister: "",
      mutualiteit: "",
      extragegevens: "",
      geslacht: "",
      isOuder: "",
      isSpelend: "",
      ingeschreven: "",
      foto: "",
      image1:"",
      progress1: 0,
      fotoEdit: false,
      ploegen: [],
    };
  }

  componentDidMount() {

    this.props.firebase.user(this.props.user.uid).on('value', snapshot => {
      const userObject = snapshot.val();
      var datum = userObject.geboortedatum;
      var datumJuist = datum.substring(6,10)+datum.substring(2, 6)+datum.substring(0, 2);

      var rijksregister = userObject.rijksregister;
      rijksregister = rijksregister.replace(/[&/\\#,+()$~%.'":*?<>{}]/g,'');
      rijksregister = rijksregister.replace(/-/g, '');
      rijksregister = rijksregister.substring(0,2)+"."+rijksregister.substring(2,4)+"."+rijksregister.substring(4,6)+"-"+rijksregister.substring(6,9)+"-"+rijksregister.substring(9,12)

      if (userObject.telefoon === undefined) {
        userObject.telefoon = "";
      }
      if (userObject.straatnr === undefined) {
        userObject.straatnr = "";
      }
      if (userObject.postcode === undefined) {
        userObject.postcode = "";
      }
      if (userObject.gemeente === undefined) {
        userObject.gemeente = "";
      }
      if (userObject.foto === undefined) {
        userObject.foto = "";
      }

      var ingeschreven = userObject.ingeschreven;
      if (ingeschreven === true) {
        ingeschreven = "true"
      } else {
        ingeschreven = "false"
      }

      var ouder = userObject.isOuder;
      if (ouder === true) {
        ouder = "true"
      } else {
        ouder = "false"
      }

      var spelend = userObject.isSpelend;
      if (spelend === true) {
        spelend = "true"
      } else {
        spelend = "false"
      }

        this.setState({
          user: userObject,
          voornaam: userObject.voornaam,
          achternaam: userObject.achternaam,
          geboortedatum: datumJuist,
          rijksregister: rijksregister,
          telefoon: userObject.telefoon,
          mutualiteit: userObject.mutualiteit,
          email: userObject.email,
          straat: userObject.straatnr,
          postcode: userObject.postcode,
          gemeente: userObject.gemeente,
          extragegevens: userObject.extragegevens,
          geslacht: userObject.geslacht,
          ploeg: userObject.ploeg,
          foto: userObject.foto,
          isOuder: ouder,
          isSpelend: spelend,
          ingeschreven: ingeschreven,
          loading: false
        });
      }
    )

  this.props.firebase.seizoen("2020-2021").on('value', snapshot => {
    const seizoenObject = snapshot.val();
    if (seizoenObject) {
      var ploegen = [];
      ploegen.push(seizoenObject.ploeg1)
      ploegen.push(seizoenObject.ploeg2)
      ploegen.push(seizoenObject.ploeg3)
      ploegen.push(seizoenObject.ploeg4)
      ploegen.push(seizoenObject.ploeg5)
      ploegen.push(seizoenObject.ploeg6)
      ploegen.push(seizoenObject.ploeg7)
      ploegen.push(seizoenObject.ploeg8)
      if(seizoenObject.ploeg9 !== "") {
        ploegen.push(seizoenObject.ploeg9)
      }
      if(seizoenObject.ploeg10 !== "") {
        ploegen.push(seizoenObject.ploeg10)
      }
      if(seizoenObject.ploeg11 !== "") {
        ploegen.push(seizoenObject.ploeg11)
      }
      if(seizoenObject.ploeg12 !== "") {
        ploegen.push(seizoenObject.ploeg12)
      }
      ploegen.push("Niet Speler")
      ploegen.push("Ouder")
      this.setState({
        ploegen
      })
  } else {
    this.setState({ loading: false });
  }
})

}

  onChangeText = event => {
   this.setState({ [event.target.name]: event.target.value });
  };

  onChangeRijksregister = event => {
    var nummer = event.target.value;
    nummer = nummer.replace(/[&/\\#,+()$~%.'":*?<>{}]/g,'')
    nummer = nummer.replace(/-/g, '');
    if(nummer.length > 8){
      nummer = nummer.substring(0,2)+"."+nummer.substring(2,4)+"."+nummer.substring(4,6)+"-"+nummer.substring(6,9)+"-"+nummer.substring(9,11)
    } else if (nummer.length > 5) {
      nummer = nummer.substring(0,2)+"."+nummer.substring(2,4)+"."+nummer.substring(4,6)+"-"+nummer.substring(6,9)
    } else if (nummer.length > 3) {
      nummer = nummer.substring(0,2)+"."+nummer.substring(2,4)+"."+nummer.substring(4,6)
    } else if (nummer.length > 1) {
      nummer = nummer.substring(0,2)+"."+nummer.substring(2,4)
    } else {
      nummer = nummer.substring(0,2)
    }
    this.setState({ rijksregister: nummer });
  };

  handleChange1 = e => {
   if (e.target.files[0]) {
     const image1 = e.target.files[0];
     this.setState(() => ({ image1 }));
   }
  };

  handleUpload1 = () => {
   const { image1 } = this.state;
   var urlWindow = window.location.pathname;
   const imagename = urlWindow.substring(urlWindow.lastIndexOf('/') + 1);
   const uploadTask = this.props.firebase.storage.ref(`leden/${imagename}`).put(image1);
   uploadTask.on(
     "state_changed",
     snapshot => {
       // progress function ...
       const progress1 = Math.round(
         (snapshot.bytesTransferred / snapshot.totalBytes) * 100
       );
       this.setState({ progress1 });
     },
     error => {
       // Error function ...
       console.log(error);
     },
     () => {
       // complete function ...
       this.props.firebase.storage
         .ref("leden")
         .child(imagename)
         .getDownloadURL()
         .then(url => {
           this.setState({ foto: url });

            const { ...userSnapshot } = this.state.user;
            this.props.firebase.user(this.state.userid).set({
              ...userSnapshot,
              foto: url,
            });

            this.setState({
              foto: url,
            })

            this.props.firebase.clubchat().on('value', snapshot => {
              const clubchatObject = snapshot.val();
              if (clubchatObject) {
                const clubchatList = Object.keys(clubchatObject).map(key => ({
                  ...clubchatObject[key],
                  uid: key,
                }));

                clubchatList.forEach((item, i) => {
                  if (item.username === this.state.user.username && item.foto !== url) {
                    this.props.firebase.clubchatItem(item.uid).set({
                      bericht: item.bericht,
                      username: item.username,
                      foto: url,
                      createdAt: item.createdAt,
                    });
                  }
                });


              } else {
                this.setState({ clubchat: [], loading: false });
              }
            })
         });
     }
   );
   this.setState({
     fotoEdit: true,
   })
  };

  onEdit = () => {
    const { ...userSnapshot } = this.state.user;
    const username = this.state.achternaam + " " + this.state.voornaam


    var datum = this.state.geboortedatum;
    var datumOmgekeerd = datum.substring(8,10)+datum.substring(4, 8)+datum.substring(0, 4)

    var urlWindow = window.location.pathname;
    var id = urlWindow.substring(urlWindow.lastIndexOf('/') + 1);

    var ingeschreven = this.state.ingeschreven;
    if (ingeschreven === "true") {
      ingeschreven = true
    } else {
      ingeschreven = false
    }

    var ouder = this.state.isOuder;
    if (ouder === "true") {
      ouder = true
    } else {
      ouder = false
    }

    var spelend = this.state.isSpelend;
    if (spelend === "true") {
      spelend = true
    } else {
      spelend = false
    }
    this.props.firebase.user(id).set({
      ...userSnapshot,
      username: username,
      voornaam: this.state.voornaam,
      achternaam: this.state.achternaam,
      geboortedatum: datumOmgekeerd,
      rijksregister: this.state.rijksregister,
      telefoon: this.state.telefoon,
      mutualiteit: this.state.mutualiteit,
      straatnr: this.state.straat,
      postcode: this.state.postcode,
      gemeente: this.state.gemeente,
      extragegevens: this.state.extragegevens,
      ploeg: this.state.ploeg,
      geslacht: this.state.geslacht,
      isOuder: ouder,
      isSpelend: spelend,
      ingeschreven: ingeschreven,
      foto: this.state.foto,
    });
    alert('Gegevens opgeslagen!')
    this.props.close()
  };

  render() {
    var {user , foto} = this.state;

    var userfoto = "url('"+foto+"')"
    if(foto === undefined || foto === ""){
      userfoto = "url('"+wakado+"')"
    }


    return (
      <div id="AddTodoSection" style={{display:`${this.props.display}`}}>
        <div id="AddTodoSection-background">
        </div>
        <div id="GegevensAanpassenSection-front">
          <h2>Gegevens {user.voornaam} {user.achternaam} aanpassen</h2>
          <br/>

            <div className="accountFoto" style={{backgroundImage: userfoto}}></div>
              <br/>
              <input type="file" id="myfile" name="myfile" onChange={this.handleChange1} />
              <br />

              <Button
                onClick={this.handleUpload1}
                className="uploadknop"
              >
                Upload foto
              </Button>
              <div className="row">
                <ProgressBar now={this.state.progress1} label={`${this.state.progress1}%`} max="100" style={{width: "100%"}}/>
              </div>
            <Form>
              <Form.Group controlId="formGroupText">
              <Form.Label>Ploeg:</Form.Label>
              <Form.Control as="select" value={this.state.ploeg} name="ploeg" style={{width: "220px", margin:"0"}} onChange={this.onChangeText}>
                <option value="">Kies ploeg</option>
                {this.state.ploegen.map(ploeg => {
                    return(
                      <option value={ploeg}>{ploeg}</option>
                    )
                  }
                )}
              </Form.Control>
              </Form.Group>

              <Form.Group controlId="formGroupText">
              <Form.Label>Ingeschreven voor nieuw seizoen?:</Form.Label>
              <Form.Control as="select" value={this.state.ingeschreven} name="ingeschreven" style={{width: "220px", margin:"0"}} onChange={this.onChangeText}>
                <option value="true">Ja</option>
                <option value="false">Nee</option>
              </Form.Control>
              </Form.Group>

              <Form.Group controlId="formGroupText">
                <Form.Label>Voornaam:</Form.Label>
                <Form.Control type="text" value={this.state.voornaam} name="voornaam" placeholder="Voornaam" onChange={this.onChangeText}/>
              </Form.Group>

              <Form.Group controlId="formGroupText">
                <Form.Label>Achternaam:</Form.Label>
                <Form.Control type="text" value={this.state.achternaam} name="achternaam" placeholder="Achternaam" onChange={this.onChangeText}/>
              </Form.Group>

              <Form.Group controlId="formGroupText">
                <Form.Label>Telefoon:</Form.Label>
                <Form.Control type="text" value={this.state.telefoon} name="telefoon" placeholder="Telefoonnummer" onChange={this.onChangeText}/>
              </Form.Group>

              <Form.Group controlId="formGroupText">
                <Form.Label>Email:</Form.Label>
                <Form.Control type="text" value={this.state.email} name="email" placeholder="Emailadres" onChange={this.onChangeText}/>
              </Form.Group>

              <Form.Group controlId="formGroupText">
                <Form.Label>Straat + nr:</Form.Label>
                <Form.Control type="text" value={this.state.straat} name="straat" placeholder="Straat + nr" onChange={this.onChangeText}/>
              </Form.Group>

              <Form.Group controlId="formGroupText">
                <Form.Label>Postcode:</Form.Label>
                <Form.Control type="number" value={this.state.postcode} name="postcode" placeholder="postcode" onChange={this.onChangeText}/>
              </Form.Group>

              <Form.Group controlId="formGroupText">
                <Form.Label>Gemeente:</Form.Label>
                <Form.Control type="text" value={this.state.gemeente} name="gemeente" placeholder="Gemeente" onChange={this.onChangeText}/>
              </Form.Group>

              <Form.Group controlId="formGroupText">
                <Form.Label>Geboortedatum:</Form.Label>
                <Form.Control type="date" value={this.state.geboortedatum} name="geboortedatum" placeholder="01-01-2020" onChange={this.onChangeText}/>
              </Form.Group>

              <Form.Group controlId="formGroupText">
                <Form.Label>Rijksregisternr:</Form.Label>
                <Form.Control type="text" value={this.state.rijksregister} name="rijksregister" placeholder="00.00.00-000-00" onChange={this.onChangeRijksregister}/>
              </Form.Group>

              <Form.Group controlId="formGroupText">
                <Form.Label>Mutualiteit:</Form.Label>
                <Form.Control type="text" value={this.state.mutualiteit} name="mutualiteit" placeholder="Mutualiteit" onChange={this.onChangeText}/>
              </Form.Group>

              <Form.Group controlId="formGroupText">
                <Form.Label>Extra Gegevens:</Form.Label>
                <Form.Control type="text" value={this.state.extragegevens} name="extragegevens" placeholder="..." onChange={this.onChangeText}/>
              </Form.Group>

              <Form.Group controlId="formGroupText">
              <Form.Label>Geslacht:</Form.Label>
              <Form.Control as="select" value={this.state.geslacht} name="geslacht" style={{width: "220px", margin:"0"}} onChange={this.onChangeText}>
                <option value="Kies geslacht">Kies geslacht</option>
                <option value="Man">Man</option>
                <option value="Vrouw">Vrouw</option>
                <option value="Onzijdig">Onzijdig</option>
              </Form.Control>
              </Form.Group>

              <Form.Group controlId="formGroupText">
              <Form.Label>Ouder-account:</Form.Label>
              <Form.Control as="select" value={this.state.isOuder} name="isOuder" style={{width: "220px", margin:"0"}} onChange={this.onChangeText}>
                <option value="true">Ja</option>
                <option value="false">Nee</option>
              </Form.Control>
              </Form.Group>

              <Form.Group controlId="formGroupText">
              <Form.Label>Spelend?:</Form.Label>
              <Form.Control as="select" value={this.state.isSpelend} name="isSpelend" style={{width: "220px", margin:"0"}} onChange={this.onChangeText}>
                <option value="true">Ja</option>
                <option value="false">Nee</option>
              </Form.Control>
              </Form.Group>
              <br/><br/>
            </Form>
          <Button variant="success" onClick={() => this.onEdit()}>Aanpassen</Button>
          <Button variant="danger" onClick={this.props.close}>Annuleren</Button>
        </div>
      </div>
    );
  }
}

export default compose(
  withEmailVerification,
  withFirebase,
)(GegevensAanpassen);
