import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import Header from './components/header/Header';
import { Home } from './components/home/Home';
import { Bestuur } from './components/info/bestuur/Bestuur';
import { Contact } from './components/info/contact/Contact';
import { Api } from './components/info/API/Api';
import { Lidworden } from './components/info/lidworden/Lidworden';
import { Jeugd } from './components/jeugd/Jeugd';
import { Sporthal } from './components/info/sporthal/Sporthal';
import { Heren1 } from './components/ploegen/Heren1';
import { Dames1 } from './components/ploegen/Dames1';
import { JongensU17 } from './components/ploegen/JongensU17';
import { JongensU15 } from './components/ploegen/JongensU15';
import { Dames2 } from './components/ploegen/Dames2';
import { MeisjesU17 } from './components/ploegen/MeisjesU17';
import { MeisjesU15 } from './components/ploegen/MeisjesU15';
import { MeisjesU13 } from './components/ploegen/MeisjesU13';
import { JongensU13 } from './components/ploegen/JongensU13';
import { U11Gemengd } from './components/ploegen/U11Gemengd';
import { Bewegingsschool } from './components/ploegen/Bewegingsschool';
import { KWBHeren } from './components/ploegen/KWBHeren';
import { KWBDames } from './components/ploegen/KWBDames';
import Jeugdweekend from './components/activiteiten/Jeugdweekend';
import Spaghettidag from './components/activiteiten/Spaghettidag';
import Quiz from './components/activiteiten/Quiz';
import Brunch from './components/activiteiten/Brunch';
import { Sponsors } from './components/sponsors/Sponsors';
import SignInPage from './components/login/Login';
import Inschrijven from './components/inschrijven/Inschrijven';
import Lidaanmaken from './components/lidaanmaken/Lidaanmaken';
import Gegevensaanpassen from './components/ledenPortaal/account/Gegevensaanpassen';
import GegevensaanpassenGezin from './components/ledenPortaal/account/GegevensaanpassenGezin';
import Herinschrijven from './components/ledenPortaal/account/Herinschrijven';
import HerinschrijvenGezin from './components/ledenPortaal/account/Herinschrijvengezin';
import HerinschrijvenGezinOpnieuw from './components/ledenPortaal/account/HerinschrijvengezinOpnieuw';
import LedenPortaal from './components/ledenPortaal/LedenPortaal';
import BestuurDashboard from './components/bestuurPortaal/BestuurDashboard';
import PasswordForgetPage from './components/paswoordvergeten/password';
import Privacy from './components/privacy/Privacy';
import { Footer } from './components/footer/Footer';
import { Error } from './components/error/Error';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as ROUTES from './constants/routes';
import { withAuthentication } from './components/session';

class App extends React.Component {
  render() {
    return (
      <div className="App" id="centraal">
        <Header />
        <Switch>
          <Route path={ROUTES.HOME} component={Home} exact/>
          <Route path={ROUTES.SPORTHAL} component={Sporthal} />
          <Route path={ROUTES.BESTUUR} component={Bestuur} />
          <Route path={ROUTES.CONTACT} component={Contact} />
          <Route path={ROUTES.APIS} component={Api} />
          <Route path={ROUTES.LIDWORDEN} component={Lidworden} />
          <Route path={ROUTES.JEUGD} component={Jeugd} />
          <Route path={ROUTES.HEREN1} component={Heren1} exact/>
          <Route path={ROUTES.DAMES1} component={Dames1} exact/>
          <Route path={ROUTES.JONGENSU17} component={JongensU17} exact/>
          <Route path={ROUTES.JONGENSU15} component={JongensU15} exact/>
          <Route path={ROUTES.DAMES2} component={Dames2} exact/>
          <Route path={ROUTES.MEISJESU17} component={MeisjesU17} exact/>
          <Route path={ROUTES.MEISJESU15} component={MeisjesU15} exact/>
          <Route path={ROUTES.MEISJESU13} component={MeisjesU13} exact/>
          <Route path={ROUTES.JONGENSU13} component={JongensU13} exact/>
          <Route path={ROUTES.U11GEMENGD} component={U11Gemengd} exact/>
          <Route path={ROUTES.BEWEGINGSSCHOOL} component={Bewegingsschool} exact/>
          <Route path={ROUTES.KWBHEREN} component={KWBHeren} exact/>
          <Route path={ROUTES.KWBDAMES} component={KWBDames} exact/>
          <Route path={ROUTES.JEUGDWEEKEND} component={Jeugdweekend} />
          <Route path={ROUTES.SPAGHETTIDAG} component={Spaghettidag} />
          <Route path={ROUTES.QUIZ} component={Quiz} />
          <Route path={ROUTES.BRUNCH} component={Brunch} />
          <Route path={ROUTES.SPONSERS} component={Sponsors} />
          <Route path={ROUTES.INSCHRIJVEN} component={Inschrijven} />
          <Route path={ROUTES.LIDAANMAKEN} component={Lidaanmaken} />
          <Route path={ROUTES.LOGIN} component={SignInPage} />
          <Route exact path={ROUTES.GEGEVENSAANPASSEN} component={Gegevensaanpassen} />
          <Route exact path={ROUTES.GEGEVENSAANPASSENGEZIN} component={GegevensaanpassenGezin} />
          <Route path={ROUTES.HERINSCHRIJVEN} component={Herinschrijven} />
          <Route path={ROUTES.HERINSCHRIJVENFAMILIELID} component={HerinschrijvenGezin} />
          <Route path={ROUTES.HERINSCHRIJVENFAMILIELIDOPNIEUW} component={HerinschrijvenGezinOpnieuw} />
          <Route path={ROUTES.PASSWORD_VERGETEN} component={PasswordForgetPage} />
          <Route path={ROUTES.PRIVACY} component={Privacy} />
          <Route path={ROUTES.LEDENPORTAAL} component={LedenPortaal} />
          <Route path={ROUTES.BESTUURDASHBOARD} component={BestuurDashboard} />
          <Route component={Error} />
        </Switch>
        <Footer />
      </div>
)}
};

export default withAuthentication(App);