import React from 'react';
import { withFirebase } from '../Firebase';
import './mercisponsor.css';
import { LinkContainer } from "react-router-bootstrap";
import * as ROUTES from '../../constants/routes';
var lijstSponsors = []


class MerciSponsorBase extends React.Component {

  constructor() {
    super()
    this.state = {
      nummer: []
    }
  }

  importAll(r) {
        return r.keys().map(r);
    }

  componentWillMount() {
    lijstSponsors = this.importAll(require.context('../sponsors/img', false, /\.(png|jpe?g|svg)$/));
    const nummer = Math.floor((Math.random() * lijstSponsors.length) + 1);
    this.setState({
      nummer: nummer
    })
  }

  render() {

    return (
      <div>
        <div>
          <img src={lijstSponsors[this.state.nummer]} className="imageSponsor" alt="sponsor"/>
          <p>En natuurlijk aan <LinkContainer to={ROUTES.SPONSERS}><div className="sponsorslink">alle andere sponsors!</div></LinkContainer></p>
        </div>
      </div>
    )
  }
}

export const MerciSponsor = withFirebase(MerciSponsorBase);
