import React from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withAuthorization, withEmailVerification } from '../../session';
import * as ROUTES from '../../../constants/routes';
import * as ROLES from '../../../constants/roles';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table'

import './style.css';

class nietingeschrevenBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: this.props.users,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

      if(this.state.users.length === 0) {
        window.location.replace(`${ROUTES.BESTUURDASHBOARD}`)
      }
    }

  componentWillUnmount() {

  }

  SendEmail(adres) {
    window.location = "mailto:" + adres
  }

  render(){
    const { users } = this.state;

    var users_Niet_Ingeschreven = users.filter(d => {var ingeschreven = d.ingeschreven; var welkom = d.welkom;
                             return (ingeschreven === false && welkom === false);
                            });

    var users_Nooit_Ingelogd = users.filter(d => {var welkom = d.welkom;
                             return (welkom === true );
                            });

    var users_Geen_Uid = users.filter(d => {var uid = d.uid;
                             return (uid !== undefined);
                            });

    return (
      <div id="adminpagina">
        <br />
        <h2>Leden met toegang tot portaal maar niet ingeschreven</h2>
        <br/>
        <Table striped bordered hover className="text-black" responsive style={{textAlign: "left", maxWidth: "500px", margin:"auto"}}>
          <thead>
            <tr>
              <th>Naam</th>
              <th>Contact</th>
            </tr>
          </thead>
          <tbody>
            {users_Niet_Ingeschreven.map(user => (
                <tr key={user.username}>
                  <td>{user.username}</td>
                  <td>
                    <Button variant="success" onClick={() => {this.SendEmail(user.email)}}>
                      Contacteren
                    </Button>
                  </td>
                </tr>
            ))}
          </tbody>
        </Table>
        <br/><br/>
        <h2>Leden met account maar nog nooit ingelogd</h2>
        <p>(Welkom venster nog nooit weggeklikt)</p>
        <Table responsive style={{width: "40%", margin: "auto", minWidth: "300px"}}>
          <thead>
            <tr>
              <th>Naam</th>
              <th>Contact</th>
            </tr>
          </thead>
          <tbody>
            {users_Nooit_Ingelogd.map(user => (

                <tr key={user.username}>
                  <td>{user.username}</td>
                  <td>
                    <Button variant="success" onClick={() => {this.SendEmail(user.email)}}>
                        Contacteren
                    </Button>
                  </td>
                </tr>

            ))}
          </tbody>
        </Table>
        <Table responsive style={{width: "40%", margin: "auto", minWidth: "300px"}}>
          <thead>
            <tr>
              <th>Naam</th>
              <th>UID</th>
            </tr>
          </thead>
          <tbody>
            {users_Geen_Uid.map(user => (

                <tr key={user.username}>
                  <td>{user.username}</td>
                  <td>{user.uid}</td>
                </tr>

            ))}
          </tbody>
        </Table>
        <br/><br/>
      </div>
    )
  }
}

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

  const nietIngeschreven = withFirebase(nietingeschrevenBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase,
)(nietIngeschreven);
