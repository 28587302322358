import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as ROUTES from '../../../constants/routes';
import { LinkContainer } from "react-router-bootstrap";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button';
import PasswordChangeForm from '../../PasswordChange';
import EmailVeranderenForm from '../../emailveranderen';

import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../session';

class Gegevensaanpassen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      aangepast: false,
      user: "",
      userid: "",
      achternaam:"",
      geboortedatum:"",
      gemeente:"",
      ploeg:"",
      postcode:"",
      rijksregister:"",
      straatnr:"",
      telefoon:"",
      voornaam:"",
      roles:[],
      username:"",
      email:"",
      geslacht:"",
      mutualiteit:"",
      extragegevens:"",
      foto: "",
      image1:"",
      progress1: 0,
      fotoEdit: false,
    };

  }
  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({ loading: true });
    var user = JSON.parse(localStorage.getItem('authUser'))
    this.setState({userid: user.uid})

    this.props.firebase.user(user.uid).on('value', snapshot => {
      const userObject = snapshot.val();
      if (userObject) {
        this.setState({
          user: userObject,
          voornaam: userObject.voornaam,
          achternaam: userObject.achternaam,
          geboortedatum: userObject.geboortedatum,
          rijksregister: userObject.rijksregister,
          telefoon: userObject.telefoon,
          mutualiteit: userObject.mutualiteit,
          straatnr: userObject.straatnr,
          postcode: userObject.postcode,
          gemeente: userObject.gemeente,
          extragegevens: userObject.extragegevens,
          geslacht: userObject.geslacht,
          ploeg: userObject.ploeg,
          foto: userObject.foto,
          loading: false
        });
      } else {
        this.setState({
          user: null,
          voornaam: null,
          achternaam: null,
          geboortedatum: null,
          rijksregister: null,
          telefoon: null,
          mutualiteit: null,
          straatnr: null,
          postcode: null,
          gemeente: null,
          extragegevens: null,
          ploeg: null,
          geslacht: null,
          loading: false });
      }
    });
  }

  onChangeVoornaam = event => {
    this.setState({ voornaam: event.target.value });
  };

  onChangeAchternaam = event => {
    this.setState({ achternaam: event.target.value });
  };

  onChangeStraatnr = event => {
    this.setState({ straatnr: event.target.value });
  };

  onChangePostcode = event => {
    this.setState({ postcode: event.target.value });
  };

  onChangeGemeente = event => {
    this.setState({ gemeente: event.target.value });
  };

  onChangeTelefoonnr = event => {
    this.setState({ telefoon: event.target.value });
  };

  onChangeRijksregister = event => {
    this.setState({ rijksregister: event.target.value });
  };

  onChangeGeboortedatum = event => {
    this.setState({ geboortedatum: event.target.value });
  };

  onChangePloeg = event => {
    this.setState({ ploeg: event.target.value });
  };

  onChangeGeslacht = event => {
    this.setState({ geslacht: event.target.value });
  };

  onChangeMutualiteit = event => {
    this.setState({ mutualiteit: event.target.value });
  };

  onChangeExtragegevens = event => {
    this.setState({ extragegevens: event.target.value });
  };

  handleChange1 = e => {
   if (e.target.files[0]) {
     const image1 = e.target.files[0];
     this.setState(() => ({ image1 }));
   }
  };

  handleUpload1 = () => {
   const { image1 } = this.state;
   const imagename = this.state.userid;
   const uploadTask = this.props.firebase.storage.ref(`leden/${imagename}`).put(image1);
   uploadTask.on(
     "state_changed",
     snapshot => {
       // progress function ...
       const progress1 = Math.round(
         (snapshot.bytesTransferred / snapshot.totalBytes) * 100
       );
       this.setState({ progress1 });
     },
     error => {
       // Error function ...
       console.log(error);
     },
     () => {
       // complete function ...
       this.props.firebase.storage
         .ref("leden")
         .child(imagename)
         .getDownloadURL()
         .then(url => {
           this.setState({ foto: url });
         });
     }
   );
   this.setState({
     fotoEdit: true,
   })
  };

  onEditGegevens = (user, uid, voornaam, achternaam, geboortedatum, rijksregister, telefoon, mutualiteit, straatnr, postcode, gemeente, extragegevens, ploeg, geslacht) => {
    const { ...userSnapshot } = user;
    const username = achternaam + " " + voornaam

    var titelFoto = this.state.userid;
    var url = this.state.foto;
    var naam = titelFoto.replace(/ /g, "%20");
    var newNaam = naam + "_500x500";
    var newUrl = "";
    if(this.state.fotoEdit === true) {
      newUrl = url.replace(naam,newNaam);
    } else {
      newUrl = url;
    }

    this.props.firebase.user(uid).set({
      ...userSnapshot,
      username: username,
      voornaam,
      achternaam,
      geboortedatum,
      rijksregister,
      telefoon,
      mutualiteit,
      straatnr,
      postcode,
      gemeente,
      extragegevens,
      ploeg,
      geslacht,
      foto: newUrl,
    });
    alert('Gegevens opgeslagen!')
    window.location.replace("./Account")
  };

  render() {
    const { isOuder } = this.state;

    return(
    <div id="herinschrijven">
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="grid-herinschrijven formulier-gegevensaanpassen">
            <div className="grid-container">
              <h3>Gegevens aanpassen</h3>
              <Form.Row>
                <Form.Label column lg={4}>
                  Voornaam: *
                </Form.Label>
                <Col>
                  <Form.Control type="text" placeholder="Voornaam"  value={this.state.voornaam} onChange={this.onChangeVoornaam}/>
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={4}>
                  Achternaam: *
                </Form.Label>
                <Col>
                  <Form.Control type="text" placeholder="Achternaam" value={this.state.achternaam}  onChange={this.onChangeAchternaam}/>
                </Col>
              </Form.Row>
              {!isOuder &&
                <Form.Row>
                  <Form.Label column lg={4} >Geslacht: *</Form.Label>
                  <Col>
                    <Form.Control as="select" value={this.state.geslacht}  onChange={this.onChangeGeslacht}>
                      <option value="">Kies...</option>
                      <option value="Man">Man</option>
                      <option value="Vrouw">Vrouw</option>
                      <option value="Onzijdig">Genderneutraal</option>
                    </Form.Control>
                  </Col>
                </Form.Row>
              }
              <Form.Row>
                <Form.Label column lg={4}>
                  Telefoonnr: *
                </Form.Label>
                <Col>
                  <Form.Control type="text" placeholder="0499/99.99.99" value={this.state.telefoon}  onChange={this.onChangeTelefoonnr}/>
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={4}>
                  Straat + nr: *
                </Form.Label>
                <Col>
                  <Form.Control type="text" placeholder="Straat + nr" value={this.state.straatnr}  onChange={this.onChangeStraatnr}/>
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={4}>
                  Postcode: *
                </Form.Label>
                <Col>
                  <Form.Control type="number" placeholder="Postcode"  value={this.state.postcode}  onChange={this.onChangePostcode}/>
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={4}>
                  Gemeente: *
                </Form.Label>
                <Col>
                  <Form.Control type="text" placeholder="Gemeente"  value={this.state.gemeente}  onChange={this.onChangeGemeente}/>
                </Col>
              </Form.Row>
              {!isOuder &&
                <Form.Row>
                  <Form.Label column lg={4}>
                    Mutualiteit:
                  </Form.Label>
                  <Col>
                    <Form.Control type="text" placeholder="Mutualiteit" value={this.state.mutualiteit}  onChange={this.onChangeMutualiteit}/>
                  </Col>
                </Form.Row>
               }
               <br/><br/>
              <Button variant="primary" size="lg" active onClick={() => {this.onEditGegevens(this.state.user, this.state.userid, this.state.voornaam, this.state.achternaam, this.state.geboortedatum, this.state.rijksregister, this.state.telefoon, this.state.mutualiteit, this.state.straatnr, this.state.postcode, this.state.gemeente, this.state.extragegevens, this.state.ploeg, this.state.geslacht)}} value="Submit">Gegevens aanpassen</Button>{' '}
              <br/><br/>
              <LinkContainer to={ROUTES.ACCOUNT}>
                <Button variant="secondary" size="lg" active>
                  Ga terug
                </Button>
              </LinkContainer>
              </div>

            <EmailVeranderenForm email={this.state.email}/>
            <PasswordChangeForm />
        </div>
        )}
      </AuthUserContext.Consumer>
    </div>
    )
  }
}

const condition = authUser => authUser ;

const Gegevens = withFirebase(Gegevensaanpassen);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase,
)(Gegevens);