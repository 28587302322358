import React from 'react';
import './style.css'

export class Error extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div id="errorpage">
        <div>
          <h1>Oeps, er is iets foutgelopen</h1>
          <a href="http://www.vocschriek.be">Ga terug naar de homepage</a>
          <br/>
          <br />
          <div>
            <p>
              Blijft het probleem aanhouden?
                <br/>
              Stuur een mailtje naar onze webmaster: webmaster@vocschriek.be
            </p>
          </div>
        </div>
      </div>
    )
  }
}
