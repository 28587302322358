import React from 'react';


export class Bestuurslid extends React.Component {
  render() {
    const imgBestuurslid = this.props.img
    const foto = "url("+imgBestuurslid+")"


    return (
      <div className="bestuurslidDisplay">
        <div className="bestuurFoto" style={{backgroundImage: foto}}>
        </div>
        <div className="bestuurslidText">
          <div className="bestuurslidText-top">
            <h4>{this.props.naam}</h4>
            <h5>{this.props.functie}</h5>
          </div>
          <div>
            <div><i className="fas fa-phone-alt"></i>{this.props.gsm}</div>
            <div><i className="fas fa-envelope"></i>{this.props.email}</div>
          </div>
        </div>
      </div>
    )
  }
}
