import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Switch,
  Route,
  Link
} from "react-router-dom";
import Lottie from 'lottie-react-web'
import animation from '../Lottiefiles/loading.json';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown'
import * as ROUTES from '../../constants/routes';

import Todo from './Todo/Todo';
import VragenLeden from './vragen/VragenLeden';

import Ledenlijst from './ledenlijst/Ledenlijst';
import Statistieken from './ledenstatistieken/Statistieken';
import LedenlijstInschrijven from './ledenIemandInschrijven';
import NietIngeschreven from './ledenNietIngeschreven/NietIngeschreven';
import Verjaardagen from './ledenVerjaardagen/Verjaardagen';
import Emailsturen from './berichten/Berichtsturen';
import Financieel from './financieel/Financieel';
import Helpers from './helpers/helpers';
import Nieuwsredactie from './nieuwsredactie/Nieuwsredactie';
import Website from './website/website';
import Dienst from './website/dienst';
import {Agenda} from './website/agenda';
import OverzichtAanwezigheden from './overzichtaanwezigheden/OverzichtAanwezigheden';
import { KalenderKWB } from './website/kalenderKWB/KalenderKWB';
import { SeizoenInstellingen } from './website/seizoeninstellingen/SeizoenInstellingen';

import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../session';


class BestuurDashboardBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      leden: [],
      agenda: [],
      huidigSeizoen: "",
      seizoen: [],
      todo: [],
      vragen: [],
      menuAan: true,
      displayMenu: "grid",
      menuWidth: "100",
      ploegen: 0
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ loading: true });

    if(window.innerWidth < 700) {
      this.setState({
        menuAan: false,
        displayMenu: "none",
        menuWidth: "0",
      });
    }

    window.addEventListener('resize', this.menuGSM);

    this.props.firebase.users().on('value', snapshot => {
      const ledenObject = snapshot.val();

      const ledenArray = Object.keys(ledenObject).map(key => ({
        ...ledenObject[key],
        uid: key,
      }));

      this.setState({
        leden: ledenArray,
        loading: false,
      });
    });

    this.props.firebase.todo().on('value', snapshot => {
      var todoObject = snapshot.val();
      if (todoObject === undefined || todoObject === null) {
        todoObject = [];
      }
      const todoArray = Object.keys(todoObject).map(key => ({
        ...todoObject[key],
        uid: key,
      }));

      this.setState({
        todo: todoArray,
        loading: false,
      });
    });

    this.props.firebase.vragenLeden().on('value', snapshot => {
      var todoObject = snapshot.val();
      if (todoObject === undefined || todoObject === null) {
        todoObject = [];
      }
      const todoArray = Object.keys(todoObject).map(key => ({
        ...todoObject[key],
        uid: key,
      }));

      this.setState({
        vragen: todoArray,
        loading: false,
      });
    });

    this.props.firebase.algemeneagenda().on('value', snapshot => {
      const agendaObject = snapshot.val();

      const agendaArray = Object.keys(agendaObject).map(key => ({
        ...agendaObject[key],
        uid: key,
      }));

      this.setState({
        agenda: agendaArray,
        loading: false,
      });
    });

    var huidigSeizoen = ""
    this.props.firebase.huidigSeizoen().on('value', snapshot => {
      huidigSeizoen = snapshot.val();
      this.setState({
        huidigSeizoen: snapshot.val(),
      })

      this.props.firebase.seizoen(huidigSeizoen).on('value', snapshot => {
        if(snapshot.val() !== undefined && snapshot.val() !== null) {
          var aantalPloegen = snapshot.val().ploegen.length
          this.setState({
            seizoen: snapshot.val(),
            ploegen: aantalPloegen,
            loading: false,
          });
        } else {
          console.log("Error: get ploegen")
          this.setState({
            seizoen: snapshot.val(),
            ploegen: 0,
            loading: false,
          });
        }
      });
    })



  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.menuGSM);
  }

  menu() {
    if(this.state.menuAan && window.innerWidth < 700) {
      this.setState({
        menuAan: false,
        displayMenu: "none",
        menuWidth: "0",
      });
    } else {
      this.setState({
        menuAan: true,
        displayMenu: "grid",
        menuWidth: "100",
      });
    }

  }

  menuGSM = () => {
    if(window.innerWidth < 700) {
      this.setState({
        menuAan: false,
        displayMenu: "none",
        menuWidth: "0",
      });
    } else {
      this.setState({
        menuAan: true,
        displayMenu: "grid",
        menuWidth: "100",
      });
    }
  }

  render() {
    var { loading, leden, agenda, huidigSeizoen, menuWidth, displayMenu, todo, vragen, ploegen } = this.state
//Aantal ingeschreven leden
    var ingeschrevenLeden = [];
    leden.forEach((item, i) => {
      var seizoenIngeschreven = false;
      if(item.seizoenen !== undefined) {
        if(item.seizoenen[huidigSeizoen] !== undefined) {
          if(item.seizoenen[huidigSeizoen].ingeschreven) {
            seizoenIngeschreven = true;
          }
        }
      }

      if(seizoenIngeschreven === true && item.isSpelend === true && item.ploeg !== "Niet Speler" && item.geslacht !== "") {
        ingeschrevenLeden.push(item)
      }
    });
    var aantalIngeschrevenLeden = ingeschrevenLeden.length

//Agenda
  var agendaItemsToekomst = [];
  agenda.forEach((item, i) => {
    var datum = item.datum;
    // eslint-disable-next-line
    var datumZonderTekens = datum.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'')
    var dag = datumZonderTekens.substring(0,2)
    var maand = datumZonderTekens.substring(2,4)
    var jaar = datumZonderTekens.substring(4,8)
    if(jaar.length < 3) {
      jaar = "20"+jaar;
    }
    var nieuweDatum = new Date(maand+"/"+dag+"/"+jaar)
    var vandaag = new Date();
    if(nieuweDatum > vandaag && (item.extern || item.intern)){
      agendaItemsToekomst.push(item)
    }
  });
  var agendaItemOmschrijving = ""
  if(agendaItemsToekomst.length > 0) {
    agendaItemOmschrijving = agendaItemsToekomst[0].omschrijving
  }

  //Todo
    var aantalTodos = todo.length;

  //bestuursleden
      var bestuursleden = [];
      leden.forEach((item, i) => {
        if(item["roles"] !== undefined && item.achternaam !== "Schilders") {
          if(item.roles["ADMIN"] === "ADMIN") {
            bestuursleden.push(item)
          }
        }
      });

    return (
      <div id="BestuurDashboard">
        <div id="BestuurDashboard-menuIcon" onClick={()=> this.menu()}><i className="fas fa-bars"></i></div>
        <div id="BestuurDashboard-menu" style={{width:`${ menuWidth }%`, display:`${displayMenu}`, transition:"width 2s linear"}}>
          <h3>Bestuur<br/>portaal</h3>

              <Nav className="flex-column" >
                <Link to={ROUTES.BESTUURDASHBOARD} onClick={()=> this.menu()}>
                  <div id="icon-menu">
                    <i className="fas fa-tachometer-alt"></i>
                    <Nav.Item><div className="nav-link">Dashboard</div></Nav.Item>
                  </div>
                </Link>
                <div id="icon-menu">
                  <i className="fas fa-address-book"></i>
                  <NavDropdown title="Ledenlijst" id="nav-dropdown">
                    <Link to={ROUTES.LEDENLIJST} onClick={()=> this.menu()} id="dropdown-itemColor"><div className="dropdown-item"><div>Ledenlijst</div></div></Link>
                    <Link to={ROUTES.LEDENLIJST_INSCHRIJVEN} onClick={()=> this.menu()} id="dropdown-itemColor"><div className="dropdown-item"><div>Iemand inschrijven</div></div></Link>
                    <Link to={ROUTES.LEDENLIJST_VERJAARDAGEN} onClick={()=> this.menu()} id="dropdown-itemColor"><div className="dropdown-item" ><div>Verjaardagen</div></div></Link>
                    <Link to={ROUTES.LEDENLIJST_STAT} onClick={()=> this.menu()} id="dropdown-itemColor"><div className="dropdown-item" ><div>Statistieken (work in progress)</div></div></Link>
                  </NavDropdown>
                </div>
                <Link  to={ROUTES.HELPERS} onClick={()=> this.menu()}>
                  <div id="icon-menu">
                    <i className="fas fa-people-roof"></i>
                    <Nav.Item><div className="nav-link">Helpers activiteiten</div></Nav.Item>
                  </div>
                </Link>
                <Link  to={ROUTES.EMAILSTUREN} onClick={()=> this.menu()}>
                  <div id="icon-menu">
                    <i className="fas fa-envelope"></i>
                    <Nav.Item><div className="nav-link">Email sturen</div></Nav.Item>
                  </div>
                </Link>
                <Link  to={ROUTES.FINANCIEEL} onClick={()=> this.menu()}>
                  <div id="icon-menu">
                    <i className="fas fa-euro-sign"></i>
                    <Nav.Item><div className="nav-link">Voc Financiën</div></Nav.Item>
                  </div>
                </Link>
                {/*
                <Link  to={ROUTES.HELPERS} onClick={()=> this.menu()}>
                  <div id="icon-menu">
                    <i className="fas fa-people-carry"></i>
                    <Nav.Item><div className="nav-link">Helperslijsten</div></Nav.Item>
                  </div>
                </Link>
                */}
                <Link  to={ROUTES.NIEUWSREDACTIE} onClick={()=> this.menu()}>
                  <div id="icon-menu">
                    <i className="far fa-newspaper"></i>
                    <Nav.Item><div className="nav-link">Nieuwsredactie</div></Nav.Item>
                  </div>
                </Link>
                <div id="icon-menu">
                  <i className="fas fa-desktop"></i>
                  <NavDropdown title="Website" id="nav-dropdown">
                    <Link to={ROUTES.SEIZOENINSTELLINGEN} onClick={()=> this.menu()} id="dropdown-itemColor"><div className="dropdown-item" ><div>Seizoen-instellingen</div></div></Link>
                    <Link to={ROUTES.KALENDERKWB} onClick={()=> this.menu()} id="dropdown-itemColor"><div className="dropdown-item" ><div>Kalender KWB</div></div></Link>
                    <Link to={ROUTES.AGENDA} onClick={()=> this.menu()} id="dropdown-itemColor"><div className="dropdown-item" ><div>Agenda</div></div></Link>
                    <Link to={ROUTES.DIENST} onClick={()=> this.menu()} id="dropdown-itemColor"><div className="dropdown-item" ><div>Ploegen Van Dienst</div></div></Link>
                    <Link to={ROUTES.WEBSITE} onClick={()=> this.menu()} id="dropdown-itemColor"><div className="dropdown-item" ><div>Activiteiten</div></div></Link>
                    <Link to={ROUTES.WEBSITE} onClick={()=> this.menu()} id="dropdown-itemColor"><div className="dropdown-item" ><div>Het Bestuur</div></div></Link>
                    <Link to={ROUTES.WEBSITE} onClick={()=> this.menu()} id="dropdown-itemColor"><div className="dropdown-item" ><div>Sponsors</div></div></Link>
                  </NavDropdown>
                </div>
              </Nav>


        </div>
        <div  id="BestuurDashboard-info">
        {!!loading ? (
          <Lottie
            options={{
              animationData: animation,
            }}
            width={"250px"}
            heigth={"250px"}
          />
        ) : (
          <Switch>
            <Route exact path={ROUTES.BESTUURDASHBOARD}>
              <h2 className="BestuurDashboard-hoofdtitel">Bestuur Dashboard</h2>
              <div className="BestuurDashboard-gegevens">
                <div className="BestuurDashboard-Button" id="SpelendeLeden">
                  <div className="BestuurDashboard-Button-Text">
                    <div className="BestuurDashboard-Button-Text-info"><h6>Ingeschreven Leden<br/>(Seizoen {huidigSeizoen})</h6><h2>{aantalIngeschrevenLeden}</h2></div>
                    <i className="fas fa-volleyball-ball"></i>
                  </div>
                </div>
                <div className="BestuurDashboard-Button"id="AantalPloegen">
                  <div className="BestuurDashboard-Button-Text">
                    <div className="BestuurDashboard-Button-Text-info"><h6>Ploegen <br/>(Seizoen {huidigSeizoen})</h6><h2>{ploegen}</h2></div>
                    <i className="fas fa-users"></i>
                  </div>
                </div>
                <div className="BestuurDashboard-Button" id="Todo">
                  <div className="BestuurDashboard-Button-Text">
                    <div className="BestuurDashboard-Button-Text-info"><h6>Todo's</h6><h2>{aantalTodos}</h2></div>
                    <i className="fas fa-clipboard-list"></i>
                  </div>
                </div>
                <div className="BestuurDashboard-Button" id="VolgendeActiviteit">
                  <div className="BestuurDashboard-Button-Text">
                    <div className="BestuurDashboard-Button-Text-info"><h6>Next Up</h6><h5>{agendaItemOmschrijving}</h5></div>
                    <i className="fas fa-calendar-alt"></i>
                  </div>
                </div>
                <div className="module" id="VolgendWeekend">
                  <h3>Openstaande vragen van leden</h3>
                  <div className="module-info">
                    <VragenLeden vragen={vragen} />
                  </div>
                </div>

                <div className="module" id="Todo-module">
                  <h3>Todo's Bestuur</h3>
                  <div className="module-info">
                    <Todo todo={this.state.todo} bestuur={bestuursleden}/>
                  </div>
                </div>

              </div>
            </Route>
            <Route path={ROUTES.LEDENLIJST}>
              <Ledenlijst leden={this.state.leden} />
            </Route>
            <Route exact path={ROUTES.LEDENLIJST_VERJAARDAGEN} >
              <Verjaardagen users={this.state.leden} />
            </Route>
            <Route path={ROUTES.LEDENLIJST_STAT}>
              <Statistieken />
            </Route>
            <Route exact path={ROUTES.LEDENLIJST_INSCHRIJVEN} >
              <LedenlijstInschrijven />
            </Route>
            <Route exact path={ROUTES.LEDENLIJST_NIET} >
              <NietIngeschreven users={this.state.leden} />
            </Route>
            <Route exact path={ROUTES.EMAILSTUREN} >
              <Emailsturen users={this.state.leden}/>
            </Route>
            <Route exact path={ROUTES.FINANCIEEL} >
              <Financieel />
            </Route>
            <Route path={ROUTES.HELPERS}>
              <Helpers users={this.state.leden} seizoen={this.state.seizoen} huidigSeizoen={huidigSeizoen}/>
            </Route>
            <Route path={ROUTES.NIEUWSREDACTIE}>
              <Nieuwsredactie />
            </Route>
            <Route path={ROUTES.WEBSITE}>
              <Website />
            </Route>
            <Route path={ROUTES.SEIZOENINSTELLINGEN}>
              <SeizoenInstellingen />
            </Route>
            <Route path={ROUTES.KALENDERKWB}>
              <KalenderKWB />
            </Route>
            <Route path={ROUTES.DIENST}>
              <Dienst />
            </Route>
            <Route path={ROUTES.OVERZICHTENAANWEZIGHEDEN}>
              <OverzichtAanwezigheden />
            </Route>
            <Route path={ROUTES.AGENDA}>
              <Agenda />
            </Route>
          </Switch>
        )}
        </div>
      </div>
    );
  }
  }

const condition = authUser =>
  authUser;

const BestuurDashboard = withFirebase(BestuurDashboardBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase,
)(BestuurDashboard);
