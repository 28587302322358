import React from 'react';
import { Table } from 'react-bootstrap';
import { withFirebase } from '../Firebase';

class VorigeMatchenBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      huidigSeizoen: "",
      ploegen: [],
      wedstrijden: [],
      wedstrijdenTwee: [],
      kwbdames: [],
      kwbheren: [],
    }
  }

  componentDidMount() {
    this.props.firebase.volleyscoresWedstrijden().on('value', snapshot => {
      const usersObject = snapshot.val();
      if (usersObject) {

        const wedstrijdenList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));

        this.setState({
          wedstrijden: wedstrijdenList
        });
      }
    })

    this.props.firebase.kwbdamesKalender().on('value', snapshot => {
      const usersObject = snapshot.val();

      if(usersObject) {
        this.setState({
          kwbdames: usersObject
        });
      } else {
        this.setState({
          kwbdames: []
        });
      }
    })

  this.props.firebase.kwbherenKalender().on('value', snapshot => {
    const usersObject = snapshot.val();

    if(usersObject) {
      this.setState({
        kwbheren: usersObject,
        loading: false,
      });
    } else {
      this.setState({
        kwbheren: []
      });
    }
      
  })


  this.props.firebase.huidigSeizoen().on('value', snapshot => {
    var huidigSeizoen = snapshot.val()
    this.setState({
      huidigSeizoen
    })

    this.props.firebase.seizoen(huidigSeizoen).on('value', snapshot => {
      var seizoenObject = snapshot.val();
      if (seizoenObject) {
        this.setState({
          ploegen: seizoenObject.ploegen
        })
      }
    })
  })


}

    render() {
      var { ploegen, wedstrijden, kwbdames, kwbheren } = this.state

      var vorigeWedstrijden = []

      ploegen.forEach((ploeg, i) => {
        wedstrijden.forEach((wedstrijd, y) => {
          if(ploeg.code === wedstrijd.uid) {
            var lijstWedstrijden = []
            wedstrijd.tabel.forEach((game, z) => {
              if(game.uitslag !== "") {
                game.ploeg = ploeg.code
                lijstWedstrijden.push(game)
              }
            });
            if(lijstWedstrijden[0] === undefined) {
              vorigeWedstrijden.push({
                ploeg: ploeg.code,
                datum: "",
                uur: "",
                thuis: "",
                bezoeker: "",
                plaats: ""
              })
            } else {
              vorigeWedstrijden.push(lijstWedstrijden[lijstWedstrijden.length - 1])
            }

          }
        });
      });
      var lijstWedstrijdenKWBHeren = []
        kwbheren.forEach((wed, i) => {
          if(wed.uitslag !== "") {
            wed.ploeg = "KWB Heren"
            lijstWedstrijdenKWBHeren.push(wed)
          }
        })

        if(lijstWedstrijdenKWBHeren[0] === undefined) {
          vorigeWedstrijden.push({
            ploeg: "KWB Heren",
            datum: "",
            uur: "",
            thuis: "",
            bezoeker: "",
            plaats: ""
          })
        } else {
          vorigeWedstrijden.push(lijstWedstrijdenKWBHeren[lijstWedstrijdenKWBHeren.length - 1])
        }

        var lijstWedstrijdenKWBDames = []
          kwbdames.forEach((wed, i) => {
            if(wed.uitslag !== "") {
              wed.ploeg = "KWB Dames"
              lijstWedstrijdenKWBDames.push(wed)
            }
          })

          if(lijstWedstrijdenKWBDames[0] === undefined) {
            vorigeWedstrijden.push({
              ploeg: "KWB Dames",
              datum: "",
              uur: "",
              thuis: "",
              bezoeker: "",
              plaats: ""
            })
          } else {
            vorigeWedstrijden.push(lijstWedstrijdenKWBDames[lijstWedstrijdenKWBDames.length - 1])
          }

    return (
      <div className="kalendertabel">
      <Table bordered size="sm" className="text-black" responsive>
        <thead>
          <tr>
            <th>Ploeg</th>
            <th>Datum</th>
            <th>Thuis</th>
            <th>Bezoekers</th>
            <th>Uitslag</th>
          </tr>
        </thead>
        <tbody>
          {vorigeWedstrijden.map(wed => {
            var kleurUitslag = ""
            var color = ""
            var vetThuis = ""
            var vetUit = ""

            if(wed.thuis.toLowerCase().includes("schriek")) {
              vetThuis = "bold"
              if(wed.uitslag.charAt(0) === "3" || wed.uitslag.charAt(0) === "4") {
                kleurUitslag = "#27ae60"
                color = "white"
              } else {
                kleurUitslag = "#c0392b"
                color = "white"
              }
            }
            if(wed.bezoeker.toLowerCase().includes("schriek")) {
              vetUit = "bold"
              if(wed.uitslag.charAt(4) === "3" || wed.uitslag.charAt(4) === "4") {
                kleurUitslag = "#27ae60"
                color = "white"
              } else {
                kleurUitslag = "#c0392b"
                color = "white"
              }
            }

            return(
              <tr key={wed.ploeg}>
                <td>{wed.ploeg}</td>
                <td>{wed.datum}</td>
                <td style={{fontWeight: vetThuis}}>{wed.thuis}</td>
                <td style={{fontWeight: vetUit}}>{wed.bezoeker}</td>
                <td style={{backgroundColor: kleurUitslag, color: color, textAlign: "center"}}>{wed.uitslag}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
    )
  }
}

export const VorigeMatchen = withFirebase(VorigeMatchenBase);
