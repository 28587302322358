import React from 'react';
import { Nieuwsoverzicht } from './Nieuwsoverzicht';
import { Kalender } from './Kalender';
import { VorigeMatchen } from './VorigeMatchen';
import './Home.css';
import { VolgendWeekend } from './VolgendWeekend';
import { MerciSponsor } from './MerciSponsor';
import {ButtonOne} from '../../constants/button';
import { LinkContainer } from "react-router-bootstrap";
import * as ROUTES from '../../constants/routes';

export class Home extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {

    return (
      <div>
        <div id="jumbotron">
          <div id="jumbotron-overlay"></div>
          <div id="jumbotron-text">
            <h1>Je volleybal verhaal start bij ons!</h1>
            <br/>
            <h5>Klein of groot, jong of oud, ervaren of een groentje, voor ons maakt het allemaal niet uit.<br/>
            Als jij wilt volleyballen, willen wij je daar graag bij helpen.</h5>
            <br/>
            <LinkContainer to={ROUTES.LIDWORDEN}><div><ButtonOne variant="primary" text="Lid worden" light/></div></LinkContainer>
          </div>
        </div>
        <div className="white-bg"></div>
        <h1 className="titel-section">Nieuws</h1>
        <div className="onderlijn"></div>
        <div className="section" id="section1">
          <Nieuwsoverzicht />
        </div>
        <h1 className="titel-section">Merci aan deze top sponsor!</h1>
        <div className="onderlijn"></div>
        <div className="section" id="section1">
          <MerciSponsor />
        </div>
        <h1 className="titel-section">Algemene kalender</h1>
        <div className="onderlijn"></div>
        <div className="section" id="section2">
          <Kalender />
        </div>
        <h1 className="titel-section">Volgende matchen</h1>
        <div className="onderlijn"></div>
        <div className="section" id="section2">
          <VolgendWeekend/>
        </div>
        <h1 className="titel-section">Laatste resultaten</h1>
        <div className="onderlijn"></div>
        <div className="section" id="section2">
          <VorigeMatchen />
        </div>
      </div>
    )
  }
}
