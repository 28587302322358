import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { withFirebase } from '../../Firebase';


class ActiviteitDrieBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      activiteit: [],
      naam:"",
      datum: "",
      plaats: "",
      text:"",
      prijs:"",
      google:"",
      affiche: null,
      urlaffiche: "",
      progressAffiche: 0,
      image1: null,
      urlfoto1: "",
      progress1: 0,
      image2: null,
      urlfoto2: "",
      progress2: 0,
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    this.props.firebase.activiteiten().on('value', snapshot => {
      const activiteitObject = snapshot.val();
      if (activiteitObject) {
        const activiteitList = Object.keys(activiteitObject).map(key => ({
          ...activiteitObject[key],
          uid: key,
        }));
        this.setState({
          activiteit: activiteitList[2],
          naam: activiteitList[2].naam,
          datum: activiteitList[2].datum,
          plaats: activiteitList[2].plaats,
          text: activiteitList[2].text,
          prijs: activiteitList[2].prijs,
          google: activiteitList[2].google,
          urlfoto1: activiteitList[2].urlfoto1,
          urlfoto2: activiteitList[2].urlfoto2,
          urlaffiche: activiteitList[2].urlaffiche,
          loading: false
        });
      } else {
        this.setState({ activiteit: null, loading: false });
      }
    });
  }

  componentWillUnmount() {
    this.props.firebase.activiteiten().off();
  }

  onChangeDatum = event => {
    this.setState({ datum: event.target.value });
  };

  onChangePlaats = event => {
    this.setState({ plaats: event.target.value });
  };

  onChangeTekst = event => {
    this.setState({ text: event.target.value });
  };

  onChangePrijs = event => {
    this.setState({ prijs: event.target.value });
  };

  onChangeNaam = event => {
    this.setState({ naam: event.target.value });
  };

  onChangeGoogle = event => {
    this.setState({ google: event.target.value });
  };

  onEditMessage = (activiteit, text, datum, plaats, prijs, naam, google, urlfoto1, urlfoto2, urlaffiche) => {
    const { uid, ...activiteitSnapshot } = activiteit;
    this.props.firebase.activiteit(activiteit.uid).set({
      ...activiteitSnapshot,
      text,
      datum,
      plaats,
      prijs,
      naam,
      google,
      urlfoto1,
      urlfoto2,
      urlaffiche,
    });
  };

  handleChangeAffiche = e => {
   if (e.target.files[0]) {
     const affiche = e.target.files[0];
     this.setState(() => ({ affiche }));
   }
 };

  handleChange1 = e => {
   if (e.target.files[0]) {
     const image1 = e.target.files[0];
     this.setState(() => ({ image1 }));
   }
 };

   handleChange2 = e => {
    if (e.target.files[0]) {
      const image2 = e.target.files[0];
      this.setState(() => ({ image2 }));
    }
  };

  handleUploadAffiche = () => {
    const { affiche } = this.state;
    const imagename = "activiteit3affiche";
    const uploadTask = this.props.firebase.storage.ref(`activiteit3/${imagename}`).put(affiche);
    uploadTask.on(
      "state_changed",
      snapshot => {
        // progress function ...
        const progressAffiche = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        this.setState({ progressAffiche });
      },
      error => {
        // Error function ...
        console.log(error);
      },
      () => {
        // complete function ...
        this.props.firebase.storage
          .ref("activiteit3")
          .child(imagename)
          .getDownloadURL()
          .then(url => {
            this.setState({ urlaffiche: url });
          });
      }
    );
  };

   handleUpload1 = () => {
     const { image1 } = this.state;
     const imagename = "activiteit3foto1";
     const uploadTask = this.props.firebase.storage.ref(`activiteit3/${imagename}`).put(image1);
     uploadTask.on(
       "state_changed",
       snapshot => {
         // progress function ...
         const progress1 = Math.round(
           (snapshot.bytesTransferred / snapshot.totalBytes) * 100
         );
         this.setState({ progress1 });
       },
       error => {
         // Error function ...
         console.log(error);
       },
       () => {
         // complete function ...
         this.props.firebase.storage
           .ref("activiteit3")
           .child(imagename)
           .getDownloadURL()
           .then(url => {
             this.setState({ urlfoto1: url });
           });
       }
     );
   };

   handleUpload2 = () => {
     const { image2 } = this.state;
     const imagename = "activiteit3foto2";
     const uploadTask = this.props.firebase.storage.ref(`activiteit3/${imagename}`).put(image2);
     uploadTask.on(
       "state_changed",
       snapshot => {
         // progress function ...
         const progress2 = Math.round(
           (snapshot.bytesTransferred / snapshot.totalBytes) * 100
         );
         this.setState({ progress2 });
       },
       error => {
         // Error function ...
         console.log(error);
       },
       () => {
         // complete function ...
         this.props.firebase.storage
           .ref("activiteit3")
           .child(imagename)
           .getDownloadURL()
           .then(url => {
             this.setState({ urlfoto2: url });
           });
       }
     );
   };

  render() {
    const { activiteit, loading} = this.state;
    return (
      <div className="sectionAanpassenWebsite">
        {loading && <div>Loading ...</div>}
        <br/>
        <h3>Activiteit 3: {activiteit.naam}</h3>
        <br/>
          <div className="overzichtFotosActiviteit">
            <h5>Affiche toevoegen</h5>
            <img
               src={this.state.urlaffiche || "https://via.placeholder.com/400x300"}
               alt="Uploaded Images"
               height="300"
               width="400"
             />
               <br/>
              <input type="file" id="myfile" name="myfile" onChange={this.handleChangeAffiche} />
              <br />

              <button
                onClick={this.handleUploadAffiche}
              >
                Upload
              </button>
              <div className="row">
                <progress value={this.state.progressAffiche} max="100" className="progress" />
              </div>
              <br />
                 <br/>
                 <br/>
           <h5>Afbeelding 1 toevoegen</h5>
           <img
              src={this.state.urlfoto1 || "https://via.placeholder.com/400x300"}
              alt="Uploaded Images"
              height="300"
              width="400"
            />
              <br/>
             <input type="file" id="myfile" name="myfile" onChange={this.handleChange1} />
             <br />

             <button
               onClick={this.handleUpload1}
             >
               Upload
             </button>
             <div className="row">
               <progress value={this.state.progress1} max="100" className="progress" />
             </div>
             <br />
                <br/>
                <br/>
             <h5>Afbeelding 2 toevoegen</h5>
             <img
                src={this.state.urlfoto2 || "https://via.placeholder.com/400x300"}
                alt="Uploaded Images"
                height="300"
                width="400"
              />
              <br/>
               <input type="file" id="myfile" name="myfile" onChange={this.handleChange2} />
               <br />
               <button
                 onClick={this.handleUpload2}
               >
                 Upload
               </button>
               <div className="row">
                 <progress value={this.state.progress2} max="100" className="progress" />
               </div>
               <br />
               <h4 id="alert">NIET VERGETEN BENEDEN OP TE SLAGEN!!!</h4>
           </div>
         <br />
        <form className="formActiviteitAanpassen">
          <h5>Naam activiteit:</h5>
             <input
               type="text"
               value={this.state.naam}
               onChange={this.onChangeNaam}
             />
           <br />
          <h5>Datum:</h5>
             <input
               type="text"
               value={this.state.datum}
               onChange={this.onChangeDatum}
             />
           <br />
           <h5>Plaats:</h5>
             <input
               type="text"
               value={this.state.plaats}
               onChange={this.onChangePlaats}
             />
           <br />
           <h5>Tekst:</h5>
               <textarea
                 type="text"
                 value={this.state.text}
                 onChange={this.onChangeTekst}
                 className="inputText"
               />
             <br />
           <h5>Prijs:</h5>
             <input
               type="text"
               value={this.state.prijs}
               onChange={this.onChangePrijs}
             />
           <br />
           <h5>Google Spreadsheet-link:</h5>
               <input
                 type="text"
                 value={this.state.google}
                 onChange={this.onChangeGoogle}
               />
             <br />
             <br />
             <button type="submit" onClick={() => {this.onEditMessage(this.state.activiteit, this.state.text, this.state.datum, this.state.plaats, this.state.prijs, this.state.naam, this.state.google, this.state.urlfoto1, this.state.urlfoto2, this.state.urlaffiche)}}>Opslagen</button>
        </form>
      </div>
    );
  }
}

export const ActiviteitDrie = withFirebase(ActiviteitDrieBase);
