import React from 'react';
import { Ploeg } from './Ploeg';
import img from './img/Dames1.jpg';
import { withFirebase } from '../Firebase';

class Dames1Base extends React.Component {
  constructor() {
    super()
    this.state = {
      ploeg: 1,
    }
  }

  render() {

    return (
      <div>
        <Ploeg
          ploeg={this.state.ploeg}
          img={img}
        />
      </div>
    )
  }
}

export const Dames1 = withFirebase(Dames1Base);
