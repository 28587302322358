import React from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withAuthorization, withEmailVerification } from '../../session';
import * as ROUTES from '../../../constants/routes';
import * as ROLES from '../../../constants/roles';
import Table from 'react-bootstrap/Table'

import './style.css';

class verjaardagenBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: this.props.users,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

      if(this.state.users.length === 0) {
        window.location.replace(`${ROUTES.BESTUURDASHBOARD}`)
      }
    }

  componentWillUnmount() {

  }

  SendEmail(adres) {
    window.location = "mailto:" + adres
  }

  render(){
    const { users } = this.state;

    var users_Verjaardagen = users.filter(d => {var geboortedatum = d.geboortedatum; var ingeschreven = d.ingeschreven;
                             return (ingeschreven === true && geboortedatum !== "");
                            });

    users_Verjaardagen.sort(function(a, b) {

        var datum1 = "2021-"+a.geboortedatum.substring(3,5)+"-"+a.geboortedatum.substr(0,2)
        var datum2 = "2021-"+b.geboortedatum.substring(3,5)+"-"+b.geboortedatum.substr(0,2)
        var c = new Date(datum1);
        var d = new Date(datum2);
        return c-d;
    });
    return (
      <div id="adminpagina">
        <br />
        <h2>Verjaardagen van ingeschreven leden</h2>
        <br/>
        <Table striped bordered hover className="text-black" responsive style={{textAlign: "left", maxWidth: "500px", margin:"auto"}}>
          <thead>
            <tr>
              <th>Naam</th>
              <th>Verjaardag</th>
              <th>Wordt:</th>
            </tr>
          </thead>
          <tbody>
            {users_Verjaardagen.map(user => {

              let datum = user.geboortedatum.substr(0,5)
              let jaar = user.geboortedatum.substr(6,4)
              let huidigJaar = new Date().getFullYear()

              let hoeveelJaar = Number(huidigJaar) - Number(jaar)

              return(
                <tr key={user.username}>
                  <td>{user.username}</td>
                  <td>{datum}</td>
                  <td>{hoeveelJaar} jaar</td>
                </tr>
            )})}
          </tbody>
        </Table>
      </div>
    )
  }
}

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

  const verjaardagen = withFirebase(verjaardagenBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase,
)(verjaardagen);
