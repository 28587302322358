import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import * as ROLES from '../../constants/roles';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const Inschrijven = () => (
  <div id="inschrijven">
    <div id="inschrijvenFormulier">
      <h2>Inschrijven bij VOC Schriek</h2>
      <InschrijvenForm />
    </div>
  </div>
);

const INITIAL_STATE = {
  achternaam: '',
  voornaam: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  isOuder: false,
  isAkkoord: false,
  error: null,
};

class InschrijvenFormBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onSubmit = event => {
    const { voornaam, achternaam, email, passwordOne, isOuder } = this.state;
    const roles = {};
    const geboortedatum = "";
    const gemeente = "";
    const ploeg = "";
    const postcode = "";
    const rijksregister = "";
    const straatnr = "";
    const telefoon = "";
    const mutualiteit = "";
    const extragegevens = "";
    const geslacht = "";
    const ingeschreven = false;
    const welkom = true;
    const username = achternaam + " " + voornaam
    var isSpelend = true;
    if (isOuder) {
     isSpelend = false
     roles[ROLES.OUDER] = ROLES.OUDER;
    }

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        return this.props.firebase
          .user(authUser.user.uid)
          .set({
            username,
            email,
            roles,
            voornaam,
            achternaam,
            geboortedatum,
            gemeente,
            ploeg,
            postcode,
            rijksregister,
            straatnr,
            telefoon,
            mutualiteit,
            extragegevens,
            ingeschreven,
            isOuder,
            isSpelend,
            geslacht,
            welkom,
          });
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        axios.get('https://us-central1-ledenportaal-85bb5.cloudfunctions.net/emailNieuwLid', {params: {voornaam: voornaam,  achternaam: achternaam, email: email}})
      })
      .then(authUser => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.LEDENPORTAAL);
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    var {
     voornaam,
     achternaam,
     email,
     passwordOne,
     passwordTwo,
     isOuder,
     isAkkoord,
     error,
   } = this.state;

   const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      voornaam === '' ||
      achternaam === '' ||
      isAkkoord === false;

    var colorKnop = "#2980b9"

    if (isInvalid) {
      colorKnop = "#95a5a6"
    }

    var verkeerd = false;
    if (passwordOne !== passwordTwo && passwordTwo) {
      verkeerd = true;
    }

    if (email.slice(-1) === " ") {
      email = email.replace(" ", "")
    }
    return (
    <div>
      <form  className="inschrijfgegevensform" onSubmit={this.onSubmit}>
        <div>
          <input
            name="voornaam"
            value={voornaam}
            onChange={this.onChange}
            type="text"
            placeholder="Voornaam"
          />
          <input
            name="achternaam"
            value={achternaam}
            onChange={this.onChange}
            type="text"
            placeholder="Achternaam"
          />
          <input
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="E-mailadres"
          />
        </div>
        <div>
          <input
            name="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
            type="password"
            placeholder="Wachtwoord"
          />
          <input
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            type="password"
            placeholder="Bevestig wachtwoord"
          />
        </div>
        {verkeerd && <Alert variant="danger">Je wachtwoorden zijn niet gelijk aan elkaar!</Alert>}
        <Form>
          <Form.Row>
            <Form.Label>
              <strong>Ik wil enkel een gezinslid inschrijven: *</strong>
            </Form.Label>
            <input
              aria-label="option 1"
              name="isOuder"
              type="checkbox"
              checked={isOuder}
              onChange={this.onChangeCheckbox}
            />
          </Form.Row>
          <Form.Row>
            <Form.Label>
              <strong>Ik heb de <a target="_blank" rel="noopener noreferrer" href="http://www.vocschriek.be/Privacy">Privacy verklaring</a> gelezen <br/>en ga hiermee akkoord:</strong>
            </Form.Label>
            <input
              aria-label="option 1"
              name="isAkkoord"
              type="checkbox"
              checked={isAkkoord}
              onChange={this.onChangeCheckbox}
            />
          </Form.Row>
        </Form>
        <Button variant="primary" disabled={isInvalid} style={{backgroundColor: colorKnop}} type="submit">
          Inschrijven
        </Button>
        {error && <p>{error.message}</p>}
        <br/><br/>
        <p>* Je krijgt dan een gratis gezinshoofd account om alles te regelen voor de familie.</p>
      </form>
      <div className="stappenplan">
        <h3>Stappenplan inschrijven:</h3>
        <br/>
        <p><strong>Stap 1:</strong>bovenstaande formulier invullen</p>
        <p><strong>Stap 2:</strong> e-mailadres verifiëren <br/>(bekijk zeker de SPAM of ongewenste emails)</p>
        <p><strong>Stap 3:</strong> inloggen op het ledenportaal</p>
        <p><strong>Stap 4:</strong> inschrijven voor het nieuwe seizoen <br/>(via melding of via "Account")</p>
        <p><strong>Stap 5:</strong> het lidgeld betalen</p>
        <p><strong>Stap 6:</strong> veel plezier hebben!!!</p>
      </div>
    </div>
    );
  }
}

const InschrijvenLink = () => (
  <p>
    Nog geen account? <Link to={ROUTES.INSCHRIJVEN}>Inschrijven!</Link>
  </p>
);
const InschrijvenForm = compose(
  withRouter,
  withFirebase,
)(InschrijvenFormBase);

export { InschrijvenForm, InschrijvenLink };

export default compose(
  withFirebase,
)(Inschrijven);
