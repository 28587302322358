import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar'
import wakado from '../../../afbeeldingen/wakado.jpg';

import { compose } from 'recompose';
import { withFirebase } from '../../../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../../session';

class FotoaanpassenBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      aangepast: false,
      user: "",
      userid: "",
      achternaam:"",
      geboortedatum:"",
      gemeente:"",
      ploeg:"",
      postcode:"",
      rijksregister:"",
      straatnr:"",
      telefoon:"",
      voornaam:"",
      roles:[],
      username:"",
      email:"",
      geslacht:"",
      mutualiteit:"",
      extragegevens:"",
      foto: "",
      image1:"",
      progress1: 0,
      fotoEdit: false,
    };

  }
  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({ loading: true });
    var user = JSON.parse(localStorage.getItem('authUser'))
    this.setState({userid: user.uid})

    this.props.firebase.user(user.uid).on('value', snapshot => {
      const userObject = snapshot.val();
      if (userObject) {
        this.setState({
          user: userObject,
          voornaam: userObject.voornaam,
          achternaam: userObject.achternaam,
          geboortedatum: userObject.geboortedatum,
          rijksregister: userObject.rijksregister,
          telefoon: userObject.telefoon,
          mutualiteit: userObject.mutualiteit,
          straatnr: userObject.straatnr,
          postcode: userObject.postcode,
          gemeente: userObject.gemeente,
          extragegevens: userObject.extragegevens,
          geslacht: userObject.geslacht,
          ploeg: userObject.ploeg,
          foto: userObject.foto,
          loading: false
        });
      } else {
        this.setState({
          user: null,
          voornaam: null,
          achternaam: null,
          geboortedatum: null,
          rijksregister: null,
          telefoon: null,
          mutualiteit: null,
          straatnr: null,
          postcode: null,
          gemeente: null,
          extragegevens: null,
          ploeg: null,
          geslacht: null,
          loading: false });
      }
    });
  }

  onChangeVoornaam = event => {
    this.setState({ voornaam: event.target.value });
  };

  onChangeAchternaam = event => {
    this.setState({ achternaam: event.target.value });
  };

  onChangeStraatnr = event => {
    this.setState({ straatnr: event.target.value });
  };

  onChangePostcode = event => {
    this.setState({ postcode: event.target.value });
  };

  onChangeGemeente = event => {
    this.setState({ gemeente: event.target.value });
  };

  onChangeTelefoonnr = event => {
    this.setState({ telefoon: event.target.value });
  };

  onChangeRijksregister = event => {
    this.setState({ rijksregister: event.target.value });
  };

  onChangeGeboortedatum = event => {
    this.setState({ geboortedatum: event.target.value });
  };

  onChangePloeg = event => {
    this.setState({ ploeg: event.target.value });
  };

  onChangeGeslacht = event => {
    this.setState({ geslacht: event.target.value });
  };

  onChangeMutualiteit = event => {
    this.setState({ mutualiteit: event.target.value });
  };

  onChangeExtragegevens = event => {
    this.setState({ extragegevens: event.target.value });
  };

  handleChange1 = e => {
   if (e.target.files[0]) {
     const image1 = e.target.files[0];
     this.setState(() => ({ image1 }));
   }
  };

  handleUpload1 = () => {
   const { image1 } = this.state;
   const imagename = this.state.userid;
   const uploadTask = this.props.firebase.storage.ref(`leden/${imagename}`).put(image1);
   uploadTask.on(
     "state_changed",
     snapshot => {
       // progress function ...
       const progress1 = Math.round(
         (snapshot.bytesTransferred / snapshot.totalBytes) * 100
       );
       this.setState({ progress1 });
     },
     error => {
       // Error function ...
       console.log(error);
     },
     () => {

       this.props.firebase.storage
         .ref("leden")
         .child(imagename)
         .getDownloadURL()
         .then(url => {
           this.setState({ foto: url });

           const { ...userSnapshot } = this.state.user;

           this.props.firebase.user(this.state.userid).set({
             ...userSnapshot,
             foto: url,
           });

           this.props.firebase.clubchat().on('value', snapshot => {
             const clubchatObject = snapshot.val();
             if (clubchatObject) {
               const clubchatList = Object.keys(clubchatObject).map(key => ({
                 ...clubchatObject[key],
                 uid: key,
               }));

               clubchatList.forEach((item, i) => {
                 if (item.username === this.state.user.username && item.foto !== url) {
                   this.props.firebase.clubchatItem(item.uid).set({
                     bericht: item.bericht,
                     username: item.username,
                     foto: url,
                     createdAt: item.createdAt,
                   });
                 }
               });


             } else {
               this.setState({ clubchat: [], loading: false });
             }
           })
         });
     },
   );

   this.setState({
     fotoEdit: true,
   })

  };

  gaTerug() {
     window.location.replace("./Account")
  }

  render() {
    const { foto } = this.state;

    return(
    <div id="fotoAanpassenDisplay">
      <div>
      <br />
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="grid-herinschrijven">
            <h2>Foto aanpassen</h2>
            <div className="grid-container">

               <img
                  src={foto || wakado}
                  alt="Uploaded Images"
                  height="200"
                  width="auto"
                />
              <br/><br/>
                 <input type="file" id="myfile" name="myfile" onChange={this.handleChange1} />
                 <br />

                 <Button
                   onClick={this.handleUpload1}
                   className="uploadknop"
                   variant="primary"
                 >
                   Upload foto
                 </Button>
                 <div className="row">
                   <ProgressBar now={this.state.progress1} label={`${this.state.progress1}%`} max="100" style={{width: "100%"}}/>
                 </div>
            </div>
            <br />
              <Button variant="secondary" size="lg" active onClick={() => this.gaTerug()}>
                Ga terug
              </Button>
        </div>
        )}
      </AuthUserContext.Consumer>
      </div>
    </div>
    )
  }
}

const condition = authUser => authUser ;

const Fotoaanpassen = withFirebase(FotoaanpassenBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase,
)(Fotoaanpassen);
