import React from 'react';
import "./Sporthal.css"
import sporthal1 from './sporthal.JPG';
import sporthal2 from './sporthal2.jpg';
import sporthal3 from './sporthal3.jpg';
import waze from './icons8-waze.svg';
import googlemaps from './icons8-google-maps.svg';

export class Sporthal extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    var wazeLink = "https://www.waze.com/ul?place=ChIJR332GfJZwUcRuvrgwV48YXE&ll=51.06743230%2C4.73563310&navigate=yes"
    var googleLink = "https://www.google.com/maps/search/?api=1&query=De+Sporthal+Lostraat+Heist"
    return (
      <div>
        <div className="backgroundSporthal"></div>
        <div className="white-bg"></div>
          <h1 className="activiteit-titel">DE Sporthal</h1>
          <div className="onderlijn"></div>
          <div className="sporthaladres">
            <div>
              <h2>Adres:</h2><br/><h4>Lostraat 48A, <br></br>2220 Heist-op-den-Berg</h4>
              <br/><br/>
            </div>
            <div>
              <h2>Navigatie:</h2>
              <div className="icons">
                <a href={wazeLink} target="_blank" rel="noopener noreferrer"><img src={waze} alt="waze"></img></a>
                <a href={googleLink} target="_blank" rel="noopener noreferrer"><img src={googlemaps} alt="waze"></img></a>
              </div>
            </div>
        </div>

          <div className="overzichtSporthal">
            <img src={sporthal1} alt="sporthal1" className="sporthalFoto"></img>
            <img src={sporthal2} alt="sporthal2" className="sporthalFoto"></img>
            <img src={sporthal3} alt="sporthal3" className="sporthalFoto"></img>
          </div>
      </div>
    )
  }
}
