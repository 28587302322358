import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup'
import { withFirebase } from '../../Firebase';


class AgendaBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      agenda1: [],
      agenda1datum: "",
      agenda1omschr: "",
      agenda1startuur: "",
      agenda1plaats: "",
      agenda1link: "",
      agenda1intern: "",
      agenda1extern: "",
      agenda2: [],
      agenda2datum: "",
      agenda2omschr: "",
      agenda2startuur: "",
      agenda2plaats: "",
      agenda2link: "",
      agenda2intern: "",
      agenda2extern: "",
      agenda3: [],
      agenda3datum: "",
      agenda3omschr: "",
      agenda3startuur: "",
      agenda3plaats: "",
      agenda3link: "",
      agenda3intern: "",
      agenda3extern: "",
      agenda4: [],
      agenda4datum: "",
      agenda4omschr: "",
      agenda4startuur: "",
      agenda4plaats: "",
      agenda4link: "",
      agenda4intern: "",
      agenda4extern: "",
      agenda5: [],
      agenda5datum: "",
      agenda5omschr: "",
      agenda5startuur: "",
      agenda5plaats: "",
      agenda5link: "",
      agenda5intern: "",
      agenda5extern: "",
      agenda6: [],
      agenda6datum: "",
      agenda6omschr: "",
      agenda6startuur: "",
      agenda6plaats: "",
      agenda6link: "",
      agenda6intern: "",
      agenda6extern: "",
      agenda7: [],
      agenda7datum: "",
      agenda7omschr: "",
      agenda7startuur: "",
      agenda7plaats: "",
      agenda7link: "",
      agenda7intern: "",
      agenda7extern: "",
      agenda8: [],
      agenda8datum: "",
      agenda8omschr: "",
      agenda8startuur: "",
      agenda8plaats: "",
      agenda8link: "",
      agenda8intern: "",
      agenda8extern: "",
      agenda9: [],
      agenda9datum: "",
      agenda9omschr: "",
      agenda9startuur: "",
      agenda9plaats: "",
      agenda9link: "",
      agenda9intern: "",
      agenda9extern: "",
      agenda10: [],
      agenda10datum: "",
      agenda10omschr: "",
      agenda10startuur: "",
      agenda10plaats: "",
      agenda10link: "",
      agenda10intern: "",
      agenda10extern: "",
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    this.props.firebase.algemeneagenda().on('value', snapshot => {
      const agendaObject = snapshot.val();
      if (agendaObject) {
        const agendaList = Object.keys(agendaObject).map(key => ({
          ...agendaObject[key],
          uid: key,
        }));

      if(agendaList[0] === undefined) {
        agendaList[0].datum = ""
        agendaList[0].omschrijving = ""
        agendaList[0].startuur = ""
        agendaList[0].plaats = ""
        agendaList[0].link = ""
        agendaList[0].intern = ""
        agendaList[0].extern = ""
      }
      if(agendaList[1] === undefined) {
        agendaList[1].datum = ""
        agendaList[1].omschrijving = ""
        agendaList[1].startuur = ""
        agendaList[1].plaats = ""
        agendaList[1].link = ""
        agendaList[1].intern = ""
        agendaList[1].extern = ""
      }
      if(agendaList[2] === undefined) {
        agendaList[2].datum = ""
        agendaList[2].omschrijving = ""
        agendaList[2].startuur = ""
        agendaList[2].plaats = ""
        agendaList[2].link = ""
        agendaList[2].intern = ""
        agendaList[2].extern = ""
      }
      if(agendaList[3] === undefined) {
        agendaList[3].datum = ""
        agendaList[3].omschrijving = ""
        agendaList[3].startuur = ""
        agendaList[3].plaats = ""
        agendaList[3].link = ""
        agendaList[3].intern = ""
        agendaList[3].extern = ""
      }
      if(agendaList[4] === undefined) {
        agendaList[4].datum = ""
        agendaList[4].omschrijving = ""
        agendaList[4].startuur = ""
        agendaList[4].plaats = ""
        agendaList[4].link = ""
        agendaList[4].intern = ""
        agendaList[4].extern = ""
      }
      if(agendaList[5] === undefined) {
        agendaList[5].datum = ""
        agendaList[5].omschrijving = ""
        agendaList[5].startuur = ""
        agendaList[5].plaats = ""
        agendaList[5].link = ""
        agendaList[5].intern = ""
        agendaList[5].extern = ""
      }
      if(agendaList[6] === undefined) {
        agendaList[6] = []
        agendaList[6].datum = ""
        agendaList[6].omschrijving = ""
        agendaList[6].startuur = ""
        agendaList[6].plaats = ""
        agendaList[6].link = ""
        agendaList[6].intern = ""
        agendaList[6].extern = ""
      }
      if(agendaList[7] === undefined) {
        agendaList[7] = []
        agendaList[7].datum = ""
        agendaList[7].omschrijving = ""
        agendaList[7].startuur = ""
        agendaList[7].plaats = ""
        agendaList[7].link = ""
        agendaList[7].intern = ""
        agendaList[7].extern = ""
      }
      if(agendaList[8] === undefined) {
        agendaList[8] = []
        agendaList[8].datum = ""
        agendaList[8].omschrijving = ""
        agendaList[8].startuur = ""
        agendaList[8].plaats = ""
        agendaList[8].link = ""
        agendaList[8].intern = ""
        agendaList[8].extern = ""
      }
      if(agendaList[9] === undefined) {
        agendaList[9] = []
        agendaList[9].datum = ""
        agendaList[9].omschrijving = ""
        agendaList[9].startuur = ""
        agendaList[9].plaats = ""
        agendaList[9].link = ""
        agendaList[9].intern = ""
        agendaList[9].extern = ""
      }
        this.setState({
          agenda1: agendaList[0],
          agenda1datum: agendaList[0].datum,
          agenda1omschr: agendaList[0].omschrijving,
          agenda1startuur: agendaList[0].startuur,
          agenda1plaats: agendaList[0].plaats,
          agenda1link: agendaList[0].link,
          agenda1intern: agendaList[0].intern,
          agenda1extern: agendaList[0].extern,
          agenda2: agendaList[1],
          agenda2datum: agendaList[1].datum,
          agenda2omschr: agendaList[1].omschrijving,
          agenda2startuur: agendaList[1].startuur,
          agenda2plaats: agendaList[1].plaats,
          agenda2link: agendaList[1].link,
          agenda2intern: agendaList[1].intern,
          agenda2extern: agendaList[1].extern,
          agenda3: agendaList[2],
          agenda3datum: agendaList[2].datum,
          agenda3omschr: agendaList[2].omschrijving,
          agenda3startuur: agendaList[2].startuur,
          agenda3plaats: agendaList[2].plaats,
          agenda3link: agendaList[2].link,
          agenda3intern: agendaList[2].intern,
          agenda3extern: agendaList[2].extern,
          agenda4: agendaList[3],
          agenda4datum: agendaList[3].datum,
          agenda4omschr: agendaList[3].omschrijving,
          agenda4startuur: agendaList[3].startuur,
          agenda4plaats: agendaList[3].plaats,
          agenda4link: agendaList[3].link,
          agenda4intern: agendaList[3].intern,
          agenda4extern: agendaList[3].extern,
          agenda5: agendaList[4],
          agenda5datum: agendaList[4].datum,
          agenda5omschr: agendaList[4].omschrijving,
          agenda5startuur: agendaList[4].startuur,
          agenda5plaats: agendaList[4].plaats,
          agenda5link: agendaList[4].link,
          agenda5intern: agendaList[4].intern,
          agenda5extern: agendaList[4].extern,
          agenda6: agendaList[5],
          agenda6datum: agendaList[5].datum,
          agenda6omschr: agendaList[5].omschrijving,
          agenda6startuur: agendaList[5].startuur,
          agenda6plaats: agendaList[5].plaats,
          agenda6link: agendaList[5].link,
          agenda6intern: agendaList[5].intern,
          agenda6extern: agendaList[5].extern,
          agenda7: agendaList[6],
          agenda7datum: agendaList[6].datum,
          agenda7omschr: agendaList[6].omschrijving,
          agenda7startuur: agendaList[6].startuur,
          agenda7plaats: agendaList[6].plaats,
          agenda7link: agendaList[6].link,
          agenda7intern: agendaList[6].intern,
          agenda7extern: agendaList[6].extern,
          agenda8: agendaList[7],
          agenda8datum: agendaList[7].datum,
          agenda8omschr: agendaList[7].omschrijving,
          agenda8startuur: agendaList[7].startuur,
          agenda8plaats: agendaList[7].plaats,
          agenda8link: agendaList[7].link,
          agenda8intern: agendaList[7].intern,
          agenda8extern: agendaList[7].extern,
          agenda9: agendaList[8],
          agenda9datum: agendaList[8].datum,
          agenda9omschr: agendaList[8].omschrijving,
          agenda9startuur: agendaList[8].startuur,
          agenda9plaats: agendaList[8].plaats,
          agenda9link: agendaList[8].link,
          agenda9intern: agendaList[8].intern,
          agenda9extern: agendaList[8].extern,
          agenda10: agendaList[9],
          agenda10datum: agendaList[9].datum,
          agenda10omschr: agendaList[9].omschrijving,
          agenda10startuur: agendaList[9].startuur,
          agenda10plaats: agendaList[9].plaats,
          agenda10link: agendaList[9].link,
          agenda10intern: agendaList[9].intern,
          agenda10extern: agendaList[9].extern,
          loading: false
        });
      } else {
        this.setState({ activiteit: null, loading: false });
      }
    });
  }

  componentWillUnmount() {
    this.props.firebase.algemeneagenda().off();
  }

  onChangeDatum = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChange0mschr = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeStartuur = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangePlaats = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeLink = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  onEditAgenda = () => {
    var activiteiten = [{
    datum: this.state.agenda1datum,
    omschrijving: this.state.agenda1omschr,
    startuur: this.state.agenda1startuur,
    plaats: this.state.agenda1plaats,
    link: this.state.agenda1link,
    intern: this.state.agenda1intern,
    extern: this.state.agenda1extern
  },{
    datum: this.state.agenda2datum,
    omschrijving: this.state.agenda2omschr,
    startuur: this.state.agenda2startuur,
    plaats: this.state.agenda2plaats,
    link: this.state.agenda2link,
    intern: this.state.agenda2intern,
    extern: this.state.agenda2extern
  },{
    datum: this.state.agenda3datum,
    omschrijving: this.state.agenda3omschr,
    startuur: this.state.agenda3startuur,
    plaats: this.state.agenda3plaats,
    link: this.state.agenda3link,
    intern: this.state.agenda3intern,
    extern: this.state.agenda3extern
  },{
    datum: this.state.agenda4datum,
    omschrijving: this.state.agenda4omschr,
    startuur: this.state.agenda4startuur,
    plaats: this.state.agenda4plaats,
    link: this.state.agenda4link,
    intern: this.state.agenda4intern,
    extern: this.state.agenda4extern
  },{
    datum: this.state.agenda5datum,
    omschrijving: this.state.agenda5omschr,
    startuur: this.state.agenda5startuur,
    plaats: this.state.agenda5plaats,
    link: this.state.agenda5link,
    intern: this.state.agenda5intern,
    extern: this.state.agenda5extern
  },{
    datum: this.state.agenda6datum,
    omschrijving: this.state.agenda6omschr,
    startuur: this.state.agenda6startuur,
    plaats: this.state.agenda6plaats,
    link: this.state.agenda6link,
    intern: this.state.agenda6intern,
    extern: this.state.agenda6extern
  },{
    datum: this.state.agenda7datum,
    omschrijving: this.state.agenda7omschr,
    startuur: this.state.agenda7startuur,
    plaats: this.state.agenda7plaats,
    link: this.state.agenda7link,
    intern: this.state.agenda7intern,
    extern: this.state.agenda7extern
  },{
    datum: this.state.agenda8datum,
    omschrijving: this.state.agenda8omschr,
    startuur: this.state.agenda8startuur,
    plaats: this.state.agenda8plaats,
    link: this.state.agenda8link,
    intern: this.state.agenda8intern,
    extern: this.state.agenda8extern
  },{
    datum: this.state.agenda9datum,
    omschrijving: this.state.agenda9omschr,
    startuur: this.state.agenda9startuur,
    plaats: this.state.agenda9plaats,
    link: this.state.agenda9link,
    intern: this.state.agenda9intern,
    extern: this.state.agenda9extern
  },{
    datum: this.state.agenda10datum,
    omschrijving: this.state.agenda10omschr,
    startuur: this.state.agenda10startuur,
    plaats: this.state.agenda10plaats,
    link: this.state.agenda10link,
    intern: this.state.agenda10intern,
    extern: this.state.agenda10extern
  }]
    var activiteitenJSON = JSON.parse(JSON.stringify(activiteiten))
    this.props.firebase.algemeneagenda().set(activiteitenJSON);
    alert("Aangepast!")
  };

  render() {
    const {loading} = this.state;
    return (
      <div className="sectionAanpassenWebsite">
        {loading && <div>Loading ...</div>}
        <br/>
        <h3>Agenda</h3>
        <br/>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <td></td>
                <td>Datum</td>
                <td>Activiteit</td>
                <td>Startuur</td>
                <td>Plaats</td>
                <td>Link</td>
                <td>Club Intern</td>
                <td>Club Extern</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.</td>
                <td><input type="text" value={this.state.agenda1datum} name="agenda1datum" onChange={this.onChangeDatum}></input></td>
                <td><input type="text" value={this.state.agenda1omschr} name="agenda1omschr" onChange={this.onChange0mschr}></input></td>
                <td><input type="text" value={this.state.agenda1startuur} name="agenda1startuur" onChange={this.onChangeStartuur}></input></td>
                <td><input type="text" value={this.state.agenda1plaats} name="agenda1plaats" onChange={this.onChangePlaats}></input></td>
                <td><input type="text" value={this.state.agenda1link} name="agenda1link" onChange={this.onChangeLink}></input></td>
                <td><InputGroup.Checkbox name="agenda1intern" checked={this.state.agenda1intern} onChange={this.onChangeCheckbox}/></td>
                <td><InputGroup.Checkbox name="agenda1extern" checked={this.state.agenda1extern} onChange={this.onChangeCheckbox}/></td>
              </tr>
              <tr>
                <td>2.</td>
                <td><input type="text" value={this.state.agenda2datum} name="agenda2datum" onChange={this.onChangeDatum}></input></td>
                <td><input type="text" value={this.state.agenda2omschr} name="agenda2omschr" onChange={this.onChange0mschr}></input></td>
                <td><input type="text" value={this.state.agenda2startuur} name="agenda2startuur" onChange={this.onChangeStartuur}></input></td>
                <td><input type="text" value={this.state.agenda2plaats} name="agenda2plaats" onChange={this.onChangePlaats}></input></td>
                <td><input type="text" value={this.state.agenda2link} name="agenda2link" onChange={this.onChangeLink}></input></td>
                <td><InputGroup.Checkbox name="agenda2intern" checked={this.state.agenda2intern} onChange={this.onChangeCheckbox}/></td>
                <td><InputGroup.Checkbox name="agenda2extern" checked={this.state.agenda2extern} onChange={this.onChangeCheckbox}/></td>
              </tr>
              <tr>
                <td>3.</td>
                <td><input type="text" value={this.state.agenda3datum} name="agenda3datum" onChange={this.onChangeDatum}></input></td>
                <td><input type="text" value={this.state.agenda3omschr} name="agenda3omschr" onChange={this.onChange0mschr}></input></td>
                <td><input type="text" value={this.state.agenda3startuur} name="agenda3startuur" onChange={this.onChangeStartuur}></input></td>
                <td><input type="text" value={this.state.agenda3plaats} name="agenda3plaats" onChange={this.onChangePlaats} ></input></td>
                <td><input type="text" value={this.state.agenda3link} name="agenda3link" onChange={this.onChangeLink}></input></td>
                <td><InputGroup.Checkbox name="agenda3intern" checked={this.state.agenda3intern} onChange={this.onChangeCheckbox}/></td>
                <td><InputGroup.Checkbox name="agenda3extern" checked={this.state.agenda3extern} onChange={this.onChangeCheckbox}/></td>

              </tr>
              <tr>
                <td>4.</td>
                  <td><input type="text" value={this.state.agenda4datum} name="agenda4datum" onChange={this.onChangeDatum}></input></td>
                  <td><input type="text" value={this.state.agenda4omschr} name="agenda4omschr" onChange={this.onChange0mschr}></input></td>
                  <td><input type="text" value={this.state.agenda4startuur} name="agenda4startuur" onChange={this.onChangeStartuur}></input></td>
                  <td><input type="text" value={this.state.agenda4plaats} name="agenda4plaats" onChange={this.onChangePlaats}></input></td>
                  <td><input type="text" value={this.state.agenda4link} name="agenda4link" onChange={this.onChangeLink}></input></td>
                  <td><InputGroup.Checkbox name="agenda4intern" checked={this.state.agenda4intern} onChange={this.onChangeCheckbox}/></td>
                  <td><InputGroup.Checkbox name="agenda4extern" checked={this.state.agenda4extern} onChange={this.onChangeCheckbox}/></td>
              </tr>
              <tr>
                <td>5.</td>
                  <td><input type="text" value={this.state.agenda5datum} name="agenda5datum" onChange={this.onChangeDatum}></input></td>
                  <td><input type="text" value={this.state.agenda5omschr} name="agenda5omschr" onChange={this.onChange0mschr}></input></td>
                  <td><input type="text" value={this.state.agenda5startuur} name="agenda5startuur" onChange={this.onChangeStartuur}></input></td>
                  <td><input type="text" value={this.state.agenda5plaats} name="agenda5plaats" onChange={this.onChangePlaats}></input></td>
                  <td><input type="text" value={this.state.agenda5link} name="agenda5link" onChange={this.onChangeLink}></input></td>
                  <td><InputGroup.Checkbox name="agenda5intern" checked={this.state.agenda5intern} onChange={this.onChangeCheckbox}/></td>
                  <td><InputGroup.Checkbox name="agenda5extern" checked={this.state.agenda5extern} onChange={this.onChangeCheckbox}/></td>
              </tr>
              <tr>
                <td>6.</td>
                  <td><input type="text" value={this.state.agenda6datum} name="agenda6datum" onChange={this.onChangeDatum}></input></td>
                  <td><input type="text" value={this.state.agenda6omschr} name="agenda6omschr" onChange={this.onChange0mschr}></input></td>
                  <td><input type="text" value={this.state.agenda6startuur} name="agenda6startuur" onChange={this.onChangeStartuur}></input></td>
                  <td><input type="text" value={this.state.agenda6plaats} name="agenda6plaats" onChange={this.onChangePlaats}></input></td>
                  <td><input type="text" value={this.state.agenda6link} name="agenda6link" onChange={this.onChangeLink}></input></td>
                  <td><InputGroup.Checkbox name="agenda6intern" checked={this.state.agenda6intern} onChange={this.onChangeCheckbox}/></td>
                  <td><InputGroup.Checkbox name="agenda6extern" checked={this.state.agenda6extern} onChange={this.onChangeCheckbox}/></td>
              </tr>
              <tr>
                <td>7.</td>
                  <td><input type="text" value={this.state.agenda7datum} name="agenda7datum" onChange={this.onChangeDatum}></input></td>
                  <td><input type="text" value={this.state.agenda7omschr} name="agenda7omschr" onChange={this.onChange0mschr}></input></td>
                  <td><input type="text" value={this.state.agenda7startuur} name="agenda7startuur" onChange={this.onChangeStartuur}></input></td>
                  <td><input type="text" value={this.state.agenda7plaats} name="agenda7plaats" onChange={this.onChangePlaats}></input></td>
                  <td><input type="text" value={this.state.agenda7link} name="agenda7link" onChange={this.onChangeLink}></input></td>
                  <td><InputGroup.Checkbox name="agenda7intern" checked={this.state.agenda7intern} onChange={this.onChangeCheckbox}/></td>
                  <td><InputGroup.Checkbox name="agenda7extern" checked={this.state.agenda7extern} onChange={this.onChangeCheckbox}/></td>
              </tr>
              <tr>
                <td>8.</td>
                  <td><input type="text" value={this.state.agenda8datum} name="agenda8datum" onChange={this.onChangeDatum}></input></td>
                  <td><input type="text" value={this.state.agenda8omschr} name="agenda8omschr" onChange={this.onChange0mschr}></input></td>
                  <td><input type="text" value={this.state.agenda8startuur} name="agenda8startuur" onChange={this.onChangeStartuur}></input></td>
                  <td><input type="text" value={this.state.agenda8plaats} name="agenda8plaats" onChange={this.onChangePlaats}></input></td>
                  <td><input type="text" value={this.state.agenda8link} name="agenda8link" onChange={this.onChangeLink}></input></td>
                  <td><InputGroup.Checkbox name="agenda8intern" checked={this.state.agenda8intern} onChange={this.onChangeCheckbox}/></td>
                  <td><InputGroup.Checkbox name="agenda8extern" checked={this.state.agenda8extern} onChange={this.onChangeCheckbox}/></td>
              </tr>
              <tr>
                <td>9.</td>
                  <td><input type="text" value={this.state.agenda9datum} name="agenda9datum" onChange={this.onChangeDatum}></input></td>
                  <td><input type="text" value={this.state.agenda9omschr} name="agenda9omschr" onChange={this.onChange0mschr}></input></td>
                  <td><input type="text" value={this.state.agenda9startuur} name="agenda9startuur" onChange={this.onChangeStartuur}></input></td>
                  <td><input type="text" value={this.state.agenda9plaats} name="agenda9plaats" onChange={this.onChangePlaats}></input></td>
                  <td><input type="text" value={this.state.agenda9link} name="agenda9link" onChange={this.onChangeLink}></input></td>
                  <td><InputGroup.Checkbox name="agenda9intern" checked={this.state.agenda9intern} onChange={this.onChangeCheckbox}/></td>
                  <td><InputGroup.Checkbox name="agenda9extern" checked={this.state.agenda9extern} onChange={this.onChangeCheckbox}/></td>
              </tr>
              <tr>
                <td>10.</td>
                  <td><input type="text" value={this.state.agenda10datum} name="agenda10datum" onChange={this.onChangeDatum}></input></td>
                  <td><input type="text" value={this.state.agenda10omschr} name="agenda10omschr" onChange={this.onChange0mschr}></input></td>
                  <td><input type="text" value={this.state.agenda10startuur} name="agenda10startuur" onChange={this.onChangeStartuur}></input></td>
                  <td><input type="text" value={this.state.agenda10plaats} name="agenda10plaats" onChange={this.onChangePlaats}></input></td>
                  <td><input type="text" value={this.state.agenda10link} name="agenda10link" onChange={this.onChangeLink}></input></td>
                  <td><InputGroup.Checkbox name="agenda10intern" checked={this.state.agenda10intern} onChange={this.onChangeCheckbox}/></td>
                  <td><InputGroup.Checkbox name="agenda10extern" checked={this.state.agenda10extern} onChange={this.onChangeCheckbox}/></td>
              </tr>
            </tbody>
          </Table>
          <Button variant="primary" onClick={() => this.onEditAgenda()}>
            Doorsturen
          </Button>
          <br/>
          <br/>
      </div>
    );
  }
}

export const Agenda = withFirebase(AgendaBase);
