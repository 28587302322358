import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification} from '../../session';
import { withFirebase } from '../../Firebase';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table'

import * as ROLES from '../../../constants/roles';

class MollieBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      mollieBetalingen: [],
      mollieNr: 0
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    axios.get(`https://us-central1-ledenportaal-85bb5.cloudfunctions.net/mollieRetrieveListPayments`)
     .then(res => {
       const mollieBetalingen = res.data;
       this.setState({ mollieBetalingen });
     })
  };

  vorigeTien() {
    var cijfer = this.state.mollieNr

    if(cijfer >= 10) {
      var nieuwCijfer = cijfer - 10
      this.setState({
        mollieNr: nieuwCijfer
      })
    }
  }

  volgendeTien(betalingen) {
    var cijfer = this.state.mollieNr
    var length = betalingen.length

    if(cijfer < length - 10) {
      var nieuwCijfer = cijfer + 10
      this.setState({
        mollieNr: nieuwCijfer
      })
    }
  }

  render(){
    const {mollieBetalingen, mollieNr} = this.state;

    const tienMollies = mollieBetalingen.slice(mollieNr, mollieNr+10);

  return (
    <div>
      <h2>Mollie overzicht</h2>
      <p>Laatste 50 transacties</p>
      <Table striped bordered hover size="sm" responsive>
        <thead>
          <tr>
            <th>Mollie ID</th>
            <th>Omschrijving</th>
            <th>Aangemaakt op</th>
            <th>Betaald op</th>
            <th>Bedrag</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {tienMollies.map(betaling => {

            var status = ""
            var backgroundStatus = ""
            var betaaldOp = ""
            var textcolorStatus = "#ecf0f1"
            if (betaling.status === "paid") {
              status = "Betaald"
              backgroundStatus = "#27ae60"
            } else if (betaling.status === "expired") {
              status = "Vervallen"
              backgroundStatus = "#95a5a6"
            } else if (betaling.status === "failed") {
              status = "Mislukt"
              backgroundStatus = "#c0392b"
            } else if (betaling.status === "canceled") {
              status = "Geannuleerd"
              backgroundStatus = "#d35400"
            } else {
              status = betaling.status
              backgroundStatus = "#2c3e50"
            }

            if (betaling.paidAt !== undefined) {
              betaaldOp = betaling.paidAt
            }

            return(
            <tr key={betaling.id}>
              <td>{betaling.id}</td>
              <td>{betaling.description}</td>
              <td>{betaling.createdAt}</td>
              <td>{betaaldOp}</td>
              <td>{betaling.amount["value"]}</td>
              <td style={{backgroundColor: backgroundStatus, color: textcolorStatus}}>{status}</td>
            </tr>
          )
          })
          }
        </tbody>
      </Table>
      <br/>
      <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
        {mollieNr !== 0 ? (
          <Button onClick={() => this.vorigeTien()}>Vorige 10 </Button>
        ) : (
          <Button disabled>Vorige 10 </Button>
        )}
        <p style={{margin: "0 10px"}}> - {mollieNr} tot {mollieNr+10} - </p>
        {mollieNr < mollieBetalingen.length - 10 ? (
          <Button onClick={() => this.volgendeTien(mollieBetalingen)}>Volgende 10</Button>
        ) : (
          <Button disabled>Volgende 10</Button>
        )}
      </div>
      <br/><br/>
    </div>
  );
}}

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN]

const Mollie = withFirebase(MollieBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(Mollie);
