import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification} from '../../session';
import Table from 'react-bootstrap/Table';

import {datumFirebaseDate, datumFirebaseString} from '../../../functions/DatumFirebase'

class Ledenagenda extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      algemeneAgenda: [],
      ploegDienst: [],
      leeg: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({ loading: true });

    this.props.firebase.algemeneagenda().on('value', snapshot => {
      const agendaObject = snapshot.val();
      if (agendaObject) {
        this.setState({
          algemeneAgenda: agendaObject,
        });
      } else {
        this.setState({ algemeneAgenda: null, loading: false });
      }

      })

      this.props.firebase.ploegDienst().on('value', snapshot => {
        const ploegDienstObject = snapshot.val();
        if (ploegDienstObject) {
          this.setState({
            ploegDienst: ploegDienstObject,
          });
        } else {
          this.setState({ ploegDienst: null, loading: false });
        }

        })
    };

    componentWillUnmount() {
      this.props.firebase.algemeneagenda().off();
      this.props.firebase.ploegDienst().off();
    }

  render(){
    var {algemeneAgenda, ploegDienst} = this.state;
    var algemeneAgendaShow = [];

    algemeneAgenda.forEach((item, i) => {
      if(item.omschrijving !== "" && item.intern){
        algemeneAgendaShow.push(item)
      }
    });

    var ploegDienstShow = [];

    ploegDienst.forEach((item, i) => {
        var datumDienst = datumFirebaseDate(item.datum)
        var vandaag = new Date(new Date().getFullYear(),new Date().getMonth() , new Date().getDate())
        if(datumDienst >= vandaag) {
          ploegDienstShow.push(item)
        }
    });

    const vandaag = new Date()
    var agendaGefilterd = algemeneAgenda.filter(a => {var datum = a.datum; var omschrijving = a.omschrijving
      var arrayDatum = datum.split("/");

      const dagAgenda = arrayDatum[0]
      const maandAgenda = Number(arrayDatum[1])
      var jaarAgenda = arrayDatum[2]
      if(jaarAgenda < 2000) {
        jaarAgenda = "20"+String(jaarAgenda)
      }
  
      const dateString = jaarAgenda+"-"+maandAgenda+"-"+dagAgenda
  
      return (new Date(dateString) >= vandaag);
     });

  return (
    <div id="nieuwspagina">
      <div>
        <div><h1>Op de agenda</h1></div>
        <div className="onderlijn"></div>
      </div>
      <div className="niewspagina-overflow">
        <Table className="agendatabel">
          <thead>
            <tr>
              <th>Datum</th>
              <th>Omschrijving</th>
              <th>Startuur</th>
              <th id="hide-mobile">Plaats</th>
            </tr>
          </thead>
          <tbody>
            {agendaGefilterd.map(nieuws => {
              return(
              <tr key={nieuws.omschrijving}>
                <td>{nieuws.datum}</td>
                <td>{nieuws.omschrijving}</td>
                <td>{nieuws.startuur}</td>
                <td>{nieuws.plaats}</td>
              </tr>
            )})}
          </tbody>
        </Table>
        <br />
        {ploegDienstShow.length > 0 && (
        <Table className="agendatabel">
          <thead>
            <tr style={{textAlign: 'center'}}>
              <th colSpan="5"><h4>Ploeg van dienst</h4></th>
            </tr>
            <tr>
              <th>Datum</th>
              <th>Ploeg van Dienst</th>
              <th>Van</th>
              <th>Tot</th>
              <th>Terrein</th>
            </tr>
          </thead>
          <tbody>
              {ploegDienstShow.map(shift => {
                var datum = datumFirebaseString(shift.datum)
                return(
                <tr key={shift.ploeg+shift.startuur}>
                  <td>{datum}</td>
                  <td>{shift.ploeg}</td>
                  <td>{shift.startuur}</td>
                  <td>{shift.einduur}</td>
                  <td>{shift.terrein}</td>
                </tr>
              )})}

          </tbody>
        </Table>
        )}
       </div>
    </div>
  );
}}

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(Ledenagenda);
