import React from 'react';
import logo from './logo.svg';
import './Header.css';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import SignOutButton from '../SignOut';
import { AuthUserContext } from '../session';

const NAV_ITEMS = [
  { to: ROUTES.HOME, label: 'Home' },
  {
    label: 'Info', items: [
      { to: ROUTES.SPORTHAL, label: 'De Sporthal' },
      { to: ROUTES.BESTUUR, label: 'Het Bestuur' },
      { to: ROUTES.APIS, label: 'Aanspreekpunt integriteit (API)' },
      { to: ROUTES.CONTACT, label: 'Contact' },
      { isDivider: true },
      { to: ROUTES.LIDWORDEN, label: 'LID WORDEN!' },
    ]
  },
  { to: ROUTES.JEUGD, label: 'Jeugd' },
  {
    label: 'Ploegen', items: [
      { to: ROUTES.HEREN1, label: 'Heren Promo 2' },
      { to: ROUTES.DAMES1, label: 'Dames Promo 3' },
      { to: ROUTES.DAMES2, label: 'Dames 2de Gewest' },
      { to: ROUTES.JONGENSU17, label: 'Jongens U17' },
      { to: ROUTES.MEISJESU17, label: 'Meisjes U17' },
      { to: ROUTES.MEISJESU15, label: 'Meisjes U15' },
      { to: ROUTES.MEISJESU13, label: 'Meisjes U13' },
      { to: ROUTES.U11GEMENGD, label: 'U11 Gemengd' },
      { to: ROUTES.BEWEGINGSSCHOOL, label: 'U9 (Kids Volley)' },
      { to: ROUTES.KWBHEREN, label: 'KWB Heren' },
      { to: ROUTES.KWBDAMES, label: 'KWB Dames' },
    ]
  },
  {
    label: 'Activiteiten', items: [
      { to: ROUTES.JEUGDWEEKEND, label: 'Jeugdweekend' },
      { to: ROUTES.QUIZ, label: 'Quiz' },
      { href: 'http://brunch.vocschriek.be', label: 'Brunch', external: true },
      { href: 'http://www.baptistfeesten.be', label: 'Baptistfeesten', external: true },
    ]
  },
  { to: ROUTES.SPONSERS, label: 'Sponsors' },
];

const Header = () => (
  <div>
    <AuthUserContext.Consumer>
      {authUser => authUser ? <Navigation authUser={authUser} /> : <NavigationNonAuth />}
    </AuthUserContext.Consumer>
  </div>
);

const Navigation = ({ authUser }) => (
  <Navbar collapseOnSelect expand="lg" id="header">
    <LinkContainer to={ROUTES.HOME}>
      <Navbar.Brand><img src={logo} alt="logo" id="logo" /></Navbar.Brand>
    </LinkContainer>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="ml-auto mt-2 mr-2">
        {NAV_ITEMS.map(item => (
          item.items ? (
            <NavDropdown title={item.label} id="basic-nav-dropdown" key={item.label}>
              {item.items.map(subItem => (
                subItem.isDivider ? <NavDropdown.Divider key="divider" /> :
                  subItem.external ? (
                    <a key={subItem.label} className="dropdown-item" href={subItem.href} target="_blank" rel="noopener noreferrer">{subItem.label}</a>
                  ) : (
                    <LinkContainer to={subItem.to} key={subItem.label}>
                      <NavDropdown.Item>{subItem.label}</NavDropdown.Item>
                    </LinkContainer>
                  )
              ))}
            </NavDropdown>
          ) : (
            <LinkContainer to={item.to} key={item.label}>
              <Nav.Link>{item.label}</Nav.Link>
            </LinkContainer>
          )
        ))}
        <LinkContainer to={ROUTES.LEDENPORTAAL}><Nav.Link id="dropdown-itemColor">Ledenportaal</Nav.Link></LinkContainer>
        {authUser.roles[ROLES.ADMIN] && (
          <LinkContainer to={ROUTES.BESTUURDASHBOARD}>
            <Nav.Link>Bestuurportaal</Nav.Link>
          </LinkContainer>
        )}
        <SignOutButton />
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

const NavigationNonAuth = () => (
  <Navbar collapseOnSelect expand="lg" id="header">
    <LinkContainer to={ROUTES.HOME}>
      <Navbar.Brand><img src={logo} alt="logo" id="logo" /></Navbar.Brand>
    </LinkContainer>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="ml-auto mt-2 mr-2">
        {NAV_ITEMS.map(item => (
          item.items ? (
            <NavDropdown title={item.label} id="basic-nav-dropdown" key={item.label}>
              {item.items.map(subItem => (
                subItem.isDivider ? <NavDropdown.Divider key="divider" /> :
                  subItem.external ? (
                    <a key={subItem.label} className="dropdown-item" href={subItem.href} target="_blank" rel="noopener noreferrer">{subItem.label}</a>
                  ) : (
                    <LinkContainer to={subItem.to} key={subItem.label}>
                      <NavDropdown.Item>{subItem.label}</NavDropdown.Item>
                    </LinkContainer>
                  )
              ))}
            </NavDropdown>
          ) : (
            <LinkContainer to={item.to} key={item.label}>
              <Nav.Link>{item.label}</Nav.Link>
            </LinkContainer>
          )
        ))}
        <LinkContainer to={ROUTES.LOGIN}>
          <Nav.Link>Login ledenportaal</Nav.Link>
        </LinkContainer>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default Header;
