import React from 'react';
import './Footer.css';
import webdev from './ctrlspace.svg'

export class Footer extends React.Component {
  render() {
    return (
        <footer className="page-footer font-small blue" id="footer">
          <div className="d-flex justify-content-center">
            <p>VOC Schriek VZW --- Vaes-Oogstlaan 15 - 2220 Heist-op-den-Berg --- <br/>volleybalclubschriek@gmail.com --- BE0568.983.885 --- Clubnummer: AH-1736</p>
          </div>
          <div className="footer-copyright text-center py-3">
            <a href="mailto:info@controlspace.be" target="_blank" rel="noopener noreferrer"><img id="webdev" src={webdev} alt="controlspace"></img></a>
          </div>
        </footer>
    )
  }
}
