import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as ROUTES from '../../../constants/routes';
import axios from 'axios';
import { LinkContainer } from "react-router-bootstrap";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Lottie from 'lottie-react-web'
import animation from '../../Lottiefiles/loading.json';
import wakado from '../../afbeeldingen/wakado.jpg';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../session';

class InschrijvingVoltooid extends React.Component {
  render(){
    return(
      <div style={{display: this.props.voltooidDisplay}}>
        <div className="backgroundVoltooid"></div>
        <div className="componentVoltooid">
          <h3>Yes {this.props.voornaam}, je bent ingeschreven!</h3>
          <br/>
          <h6>Je bent ingeschreven voor volgende ploeg:</h6>
          <h4>{this.props.ploeg}</h4>
          <br/>
          <h6>Er is een e-mail onderweg met de nodige informatie i.v.m. het lidgeld  (bekijk zeker de SPAM folder!)</h6>
          <br/>
          <h6>Maar voor de zekerheid, hier al even de nodige info:</h6>
          <br/>
          <h6>Het lidgeld bedraagt € {this.props.lidgeld}</h6>
          <h6>Rekeningnummer: BE73 7332 3611 0760</h6>
          <h6>Mededeling: Lidmaatschap VOC Schriek {this.props.voornaam} {this.props.achternaam}</h6>
          <br/>
          <LinkContainer to={ROUTES.LEDENPORTAAL}>
            <Button variant="primary" size="lg" active>
              Komt in orde!
            </Button>
          </LinkContainer>
        </div>
      </div>
    )
  }
}

class HerinschrijvenBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      aangepast: false,
      huidigSeizoen: "",
      user: "",
      userid: "",
      achternaam:"",
      geboortedatum:"",
      gemeente:"",
      ploeg:"",
      postcode:"",
      rijksregister:"",
      straatnr:"",
      telefoon:"",
      voornaam:"",
      roles:[],
      username:"",
      email:"",
      geslacht:"",
      mutualiteit:"",
      extragegevens:"",
      isOuder: false,
      isSpelend: false,
      activiteit1:"",
      activiteit2:"",
      activiteit3:"",
      activiteit4:"",
      activiteit5:"",
      activiteit6:"",
      activiteit7:"",
      datumActiviteit1:"",
      datumActiviteit2:"",
      datumActiviteit3:"",
      datumActiviteit4:"",
      datumActiviteit5:"",
      datumActiviteit6:"",
      datumActiviteit7:"",
      helpenActiviteit1:false,
      helpenActiviteit2:false,
      helpenActiviteit3:false,
      helpenActiviteit4:false,
      helpenActiviteit5:false,
      helpenActiviteit6:false,
      helpenActiviteit7:false,
      lidgeldNietSpeler: "",
      lidgeld: "",
      bancontact: false,
      overschrijving: false,
      voltooidDisplay: "none",
      loadingBC: false,
      foto: "",
      image1:"",
      progress1: 0,
      fotoEdit: false,
    };

  }
  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({ loading: true });

    var user = JSON.parse(localStorage.getItem('authUser'))

    this.setState({userid: user.uid})

    this.props.firebase.user(user.uid).on('value', snapshot => {
      const userObject = snapshot.val();
      var datum = userObject.geboortedatum;
      if(datum === undefined) {
        datum = "2020-01-01"
      }
      var datumJuist = datum.substring(6,10)+datum.substring(2, 6)+datum.substring(0, 2);

      var rijksregister = userObject.rijksregister;
      if(rijksregister === undefined) {
        rijksregister = ""
      }
      rijksregister = rijksregister.replace(/[&/\\#,+()$~%.'": ?<>{}]/g,'');
      rijksregister = rijksregister.replace(/-/g, '');
      rijksregister = rijksregister.substring(0,2)+"."+rijksregister.substring(2,4)+"."+rijksregister.substring(4,6)+"-"+rijksregister.substring(6,9)+"-"+rijksregister.substring(9,12)

      var isOuder = userObject.isOuder
      if(isOuder === "" && userObject.familie !== undefined) {
        isOuder = true
      }

      var isSpelend = userObject.isSpelend
      if(isSpelend === "") {
        isSpelend = true
      }

      if (userObject) {
        this.setState({
          user: userObject,
          voornaam: userObject.voornaam,
          achternaam: userObject.achternaam,
          geboortedatum: datumJuist,
          rijksregister,
          geslacht: userObject.geslacht,
          loading: false,
          isOuder: isOuder,
          isSpelend: isSpelend,
          foto: userObject.foto
        });
      } else {
        this.setState({
          user: null,
          voornaam: null,
          achternaam: null,
          geboortedatum: null,
          rijksregister: null,
          telefoon: null,
          mutualiteit: null,
          straatnr: null,
          postcode: null,
          gemeente: null,
          extragegevens: null,
          ploeg: "",
          geslacht: "",
          loading: false,
          ploeg1: "",
          ploeg2: "",
          ploeg3: "",
          ploeg4: "",
          ploeg5: "",
          ploeg6: "",
          ploeg7: "",
          ploeg8: "",
          ploeg9: "",
          ploeg10: "",
          ploeg11: "",
          ploeg12: "",
          lidgeldPloeg1: "",
          lidgeldPloeg2: "",
          lidgeldPloeg3: "",
          lidgeldPloeg4: "",
          lidgeldPloeg5: "",
          lidgeldPloeg6: "",
          lidgeldPloeg7: "",
          lidgeldPloeg8: "",
          lidgeldPloeg9: "",
          lidgeldPloeg10: "",
          lidgeldPloeg11: "",
          lidgeldPloeg12: "",
          lidgeldNietSpeler: "",
          isOuder: false,
          isSpelend: false,
         });
      }
    });

    var huidigSeizoen = "2024-2025"
    this.props.firebase.huidigSeizoen().on('value', snapshot => {
      huidigSeizoen = snapshot.val()
      this.setState({
        huidigSeizoen
      })
      this.props.firebase.seizoen(huidigSeizoen).on('value', snapshot => {
        var seizoenObject = snapshot.val();
        if (seizoenObject) {
          this.setState({
            activiteit1: seizoenObject.activiteit1,
            activiteit2: seizoenObject.activiteit2,
            activiteit3: seizoenObject.activiteit3,
            activiteit4: seizoenObject.activiteit4,
            activiteit5: seizoenObject.activiteit5,
            activiteit6: seizoenObject.activiteit6,
            activiteit7: seizoenObject.activiteit7,
            datumActiviteit1:seizoenObject.datumActiviteit1,
            datumActiviteit2:seizoenObject.datumActiviteit2,
            datumActiviteit3:seizoenObject.datumActiviteit3,
            datumActiviteit4:seizoenObject.datumActiviteit4,
            datumActiviteit5:seizoenObject.datumActiviteit5,
            datumActiviteit6:seizoenObject.datumActiviteit6,
            datumActiviteit7:seizoenObject.datumActiviteit7,
            ploeg1: seizoenObject.ploeg1,
            ploeg2: seizoenObject.ploeg2,
            ploeg3: seizoenObject.ploeg3,
            ploeg4: seizoenObject.ploeg4,
            ploeg5: seizoenObject.ploeg5,
            ploeg6: seizoenObject.ploeg6,
            ploeg7: seizoenObject.ploeg7,
            ploeg8: seizoenObject.ploeg8,
            ploeg9: seizoenObject.ploeg9,
            ploeg10: seizoenObject.ploeg10,
            ploeg11: seizoenObject.ploeg11,
            ploeg12: seizoenObject.ploeg12,
            lidgeldNietSpeler: seizoenObject.lidgeldNietSpeler,
            lidgeldPloeg1: seizoenObject.lidgeldPloeg1,
            lidgeldPloeg2: seizoenObject.lidgeldPloeg2,
            lidgeldPloeg3: seizoenObject.lidgeldPloeg3,
            lidgeldPloeg4: seizoenObject.lidgeldPloeg4,
            lidgeldPloeg5: seizoenObject.lidgeldPloeg5,
            lidgeldPloeg6: seizoenObject.lidgeldPloeg6,
            lidgeldPloeg7: seizoenObject.lidgeldPloeg7,
            lidgeldPloeg8: seizoenObject.lidgeldPloeg8,
            lidgeldPloeg9: seizoenObject.lidgeldPloeg9,
            lidgeldPloeg10: seizoenObject.lidgeldPloeg10,
            lidgeldPloeg11: seizoenObject.lidgeldPloeg11,
            lidgeldPloeg12: seizoenObject.lidgeldPloeg12,
            loading: false,
          })
      } else {
        this.setState({ inschrijvingOpen: null, loading: false });
      }
    })
    })

      
  }

  onChangeVoornaam = event => {
    this.setState({ voornaam: event.target.value });
  };

  onChangeAchternaam = event => {
    this.setState({ achternaam: event.target.value });
  };

  onChangeStraatnr = event => {
    this.setState({ straatnr: event.target.value });
  };

  onChangePostcode = event => {
    this.setState({ postcode: event.target.value });
  };

  onChangeGemeente = event => {
    this.setState({ gemeente: event.target.value });
  };

  onChangeTelefoonnr = event => {
    this.setState({ telefoon: event.target.value });
  };

  onChangeRijksregister = event => {
    var nummer = event.target.value;
    nummer = nummer.replace(/[&/\\#,+()$~%.'": ?<>{}]/g,'')
    nummer = nummer.replace(/-/g, '');
    if(nummer.length > 8){
      nummer = nummer.substring(0,2)+"."+nummer.substring(2,4)+"."+nummer.substring(4,6)+"-"+nummer.substring(6,9)+"-"+nummer.substring(9,11)
    } else if (nummer.length > 5) {
      nummer = nummer.substring(0,2)+"."+nummer.substring(2,4)+"."+nummer.substring(4,6)+"-"+nummer.substring(6,9)
    } else if (nummer.length > 3) {
      nummer = nummer.substring(0,2)+"."+nummer.substring(2,4)+"."+nummer.substring(4,6)
    } else if (nummer.length > 1) {
      nummer = nummer.substring(0,2)+"."+nummer.substring(2,4)
    } else {
      nummer = nummer.substring(0,2)
    }
    this.setState({ rijksregister: nummer });
  };

  onChangeGeboortedatum = event => {
    this.setState({ geboortedatum: event.target.value });
  };

  onChangePloeg = event => {
    this.setState({ ploeg: event.target.value });
  };

  onChangeMutualiteit = event => {
    this.setState({ mutualiteit: event.target.value });
  };

  onChangeExtragegevens = event => {
    this.setState({ extragegevens: event.target.value });
  };

  onChangeGeslacht = event => {
    this.setState({ geslacht: event.target.value });
  };

  onChangeActiviteit1 = event => {
    var value = event.target.value;
    var bol = false
    if (value === "true") {
      bol = true
    }
    this.setState({ helpenActiviteit1: bol});
  };

  onChangeActiviteit2 = event => {
    var value = event.target.value;
    var bol = false
    if (value === "true") {
      bol = true
    }
    this.setState({ helpenActiviteit2: bol });
  };

  onChangeActiviteit3 = event => {
    var value = event.target.value;
    var bol = false
    if (value === "true") {
      bol = true
    }
    this.setState({ helpenActiviteit3: bol });
  };

  onChangeActiviteit4 = event => {
    var value = event.target.value;
    var bol = false
    if (value === "true") {
      bol = true
    }
    this.setState({ helpenActiviteit4: bol });
  };

  onChangeActiviteit5 = event => {
    var value = event.target.value;
    var bol = false
    if (value === "true") {
      bol = true
    }
    this.setState({ helpenActiviteit5: bol });
  };

  onChangeActiviteit6 = event => {
    var value = event.target.value;
    var bol = false
    if (value === "true") {
      bol = true
    }
    this.setState({ helpenActiviteit6: bol });
  };

  onChangeActiviteit7 = event => {
    var value = event.target.value;
    var bol = false
    if (value === "true") {
      bol = true
    }
    this.setState({ helpenActiviteit7: bol });
  };

  checkMinTwoTrue = () => {
    const { 
      helpenActiviteit1, 
      helpenActiviteit2, 
      helpenActiviteit3, 
      helpenActiviteit4, 
      helpenActiviteit5, 
      helpenActiviteit6, 
      helpenActiviteit7 
    } = this.state;
  
    const activities = [
      helpenActiviteit1, 
      helpenActiviteit2, 
      helpenActiviteit3, 
      helpenActiviteit4, 
      helpenActiviteit5, 
      helpenActiviteit6, 
      helpenActiviteit7
    ];
  
    const trueCount = activities.filter(activity => activity).length;
  
    return trueCount >= 2;
  };

  onChangeCheckbox = event => {

    var bool = ""
    if(event.target.value === "true") {
      bool = true
    } else {
      bool = false
    }

    this.setState({ isSpelend: bool });
  };

  onChangeCheckboxBancontact = event => {
    this.setState({
      overschrijving: false,
      bancontact: true
     });
  };

  onChangeCheckboxOverschrijving = event => {
    this.setState({
      overschrijving: true,
      bancontact: false
     });
  };

  onChangeCheckboxActiviteit1 = event => {
    if(this.state.helpenActiviteit1){
      this.setState({
        helpenActiviteit1: false,
      });
    } else {
      this.setState({
        helpenActiviteit1: true,
      });
    }
  };

  onChangeCheckboxActiviteit2 = event => {

    if(this.state.helpenActiviteit2){
      this.setState({
        helpenActiviteit2: false,
      });
    } else {
      this.setState({
        helpenActiviteit2: true,
      });
    }
  };

  onChangeCheckboxActiviteit3 = event => {
    if(this.state.helpenActiviteit3){
      this.setState({
        helpenActiviteit3: false,
      });
    } else {
      this.setState({
        helpenActiviteit3: true,
      });
    }
  };

  onChangeCheckboxActiviteit4 = event => {
    if(this.state.helpenActiviteit4){
      this.setState({
        helpenActiviteit4: false,
      });
    } else {
      this.setState({
        helpenActiviteit4: true,
      });
    }
  };

  onChangeCheckboxActiviteit5 = event => {

    if(this.state.helpenActiviteit5){
      this.setState({
        helpenActiviteit5: false,
      });
    } else {
      this.setState({
        helpenActiviteit5: true,
      });
    }
  };

  onChangeCheckboxActiviteit6 = event => {

    if(this.state.helpenActiviteit6){
      this.setState({
        helpenActiviteit6: false,
      });
    } else {
      this.setState({
        helpenActiviteit6: true,
      });
    }
  };

  onChangeCheckboxActiviteit7 = event => {

    if(this.state.helpenActiviteit7){
      this.setState({
        helpenActiviteit7: false,
      });
    } else {
      this.setState({
        helpenActiviteit7: true,
      });
    }
  };

  handleChange1 = e => {
   if (e.target.files[0]) {
     const image1 = e.target.files[0];
     this.setState(() => ({ image1 }));
   }
  };

  handleUpload1 = () => {
   const { image1 } = this.state;
   const liduid = this.state.userid

   const imagename = liduid
   const uploadTask = this.props.firebase.storage.ref(`leden/${imagename}`).put(image1);

   uploadTask.on(
     "state_changed",
     snapshot => {
       // progress function ...
       const progress1 = Math.round(
         (snapshot.bytesTransferred / snapshot.totalBytes) * 100
       );
       this.setState({ progress1 });
     },
     error => {
       // Error function ...
       console.log(error);
     },
     () => {

       this.props.firebase.storage
         .ref("leden")
         .child(imagename)
         .getDownloadURL()
         .then(url => {
           this.setState({ foto: url });
         });
     },
   );

   this.setState({
     fotoEdit: true,
   })

  };


  onEditGegevens = (user, uid, voornaam, achternaam, geboortedatum, rijksregister, telefoon, mutualiteit, straatnr, postcode, gemeente, extragegevens, ploeg, geslacht) => {


    if(voornaam === "" || achternaam === "" || telefoon === "" || straatnr === "" || postcode === "" || gemeente === "") {
      window.alert("Heb je alles ingevuld? Zo te zien is er nog iets niet ingevuld.")
    } else {

      if(!!this.state.isSpelend && (geslacht === "" || geboortedatum === "" || rijksregister === "" || ploeg === "")) {
        window.alert("Alles ingevuld?")
      } else if (!this.state.overschrijving && !this.state.bancontact && this.state.isSpelend) {
        window.alert("Gelieve nog een betaalwijze aan te duiden.")
      } else {

        var isSpelend = this.state.isSpelend
        var isOuder = this.state.isOuder

      this.setState({
        loadingBC: true,
      })

      try {
        const { ...userSnapshot } = user;
        const ingeschreven = true;
        var bedrag = "";

        if(this.state.ploeg1 === ploeg) {
          bedrag = this.state.lidgeldPloeg1
        }
        if(this.state.ploeg2 === ploeg) {
          bedrag = this.state.lidgeldPloeg2
        }
        if(this.state.ploeg3 === ploeg) {
          bedrag = this.state.lidgeldPloeg3
        }
        if(this.state.ploeg4 === ploeg) {
          bedrag = this.state.lidgeldPloeg4
        }
        if(this.state.ploeg5 === ploeg) {
          bedrag = this.state.lidgeldPloeg5
        }
        if(this.state.ploeg6 === ploeg) {
          bedrag = this.state.lidgeldPloeg6
        }
        if(this.state.ploeg7 === ploeg) {
          bedrag = this.state.lidgeldPloeg7
        }
        if(this.state.ploeg8 === ploeg) {
          bedrag = this.state.lidgeldPloeg8
        }
        if(this.state.ploeg9 === ploeg) {
          bedrag = this.state.lidgeldPloeg9
        }
        if(this.state.ploeg10 === ploeg) {
          bedrag = this.state.lidgeldPloeg10
        }
        if(this.state.ploeg11 === ploeg) {
          bedrag = this.state.lidgeldPloeg11
        }
        if(this.state.ploeg12 === ploeg) {
          bedrag = this.state.lidgeldPloeg12
        }
        if(ploeg === "Niet Speler") {
          bedrag = this.state.lidgeldNietSpeler
        }

        bedrag.toString();
        var bedragMollie = bedrag
        bedrag = bedrag.replace(/\./g,',')

        var datum = geboortedatum;
        var datumOmgekeerd = datum.substring(8,10)+datum.substring(4, 8)+datum.substring(0, 4)

        var betaalmethode = ""
        if (this.state.overschrijving) {
          betaalmethode = "Overschrijving"
        } else if (this.state.bancontact) {
          betaalmethode = "Mollie"
        } else {
          betaalmethode = "Andere manier???"
        }



        var seizoenen = user.seizoenen
        if(user.seizoenen === undefined) {
          seizoenen = []
        }

        const ditSeizoen = this.state.huidigSeizoen

        var betaalwijze = ""
        if(this.state.overschrijving) {
          betaalwijze = "Overschrijving"
        }
        if(this.state.bancontact) {
          betaalwijze = "Bancontact"
        }
        if(!!isOuder && !isSpelend) {
          ploeg = "Ouder"
          betaalwijze = "gratis"
        }

        var email = ""
        if(user.email !== undefined) {
          email = user.email
        }

        let seizoenInschrijving = {
            "ploeg": ploeg,
            "voornaam": voornaam,
            "achternaam": achternaam,
            "ingeschreven": ingeschreven,
            "isOuder": isOuder,
            "isSpelend": isSpelend,
            "lidgeld": bedrag,
            "betaald": false,
            "betaaldwijze": betaalwijze,
            "email": email
          }

        seizoenen[ditSeizoen] = seizoenInschrijving

        var foto = this.state.foto
        if(this.state.foto === "" || this.state.foto === undefined) {
          foto = ""
        }

        this.props.firebase.user(uid).set({
          ...userSnapshot,
          voornaam,
          achternaam,
          geslacht,
          geboortedatum: datumOmgekeerd,
          rijksregister,
          telefoon,
          straatnr,
          postcode,
          gemeente,
          mutualiteit,
          extragegevens,
          ploeg,
          ingeschreven,
          isOuder,
          isSpelend,
          seizoenen,
          uid,
          foto
        });

        this.props.firebase.activiteitenAanwezigheidPerUser(uid).set({
          voornaam: this.state.voornaam,
          achternaam: this.state.achternaam,
          email: user.email,
          ploeg: ploeg,
          activiteit1: this.state.helpenActiviteit1,
          activiteit2: this.state.helpenActiviteit2,
          activiteit3: this.state.helpenActiviteit3,
          activiteit4: this.state.helpenActiviteit4,
          activiteit5: this.state.helpenActiviteit5,
          activiteit6: this.state.helpenActiviteit6,
          activiteit7: this.state.helpenActiviteit7,
        })

        if(isSpelend === "" && rijksregister.length > 0) {
          isSpelend = true
        }

        if(!!isSpelend) {
          var key = this.props.firebase.bestellingen().push().getKey()
          var status = ""

          if(this.state.overschrijving) {
            status = "Over te schrijven"
          }

          if(this.state.bancontact) {
            status = "Niet betaald"
            this.setState({
              loadingBC: true,
            })
          }

            const bestelling = {
              naam: user.username,
              userUid: uid,
              seizoen: this.state.huidigSeizoen,
              bedrag,
              omschrijving: "Lidgeld "+this.state.huidigSeizoen+" - "+user.username+" - "+ploeg,
              betaalmethode,
              aanmaakdatum: this.props.firebase.serverValue.TIMESTAMP,
              status: status,
              betaaldOp: "",
              uid: key
            }
            this.props.firebase.userBestelling(uid, key).set(bestelling)

            this.props.firebase.bestelling(key).set(bestelling)

        }

        if(this.state.overschrijving) {
          this.setState({
            lidgeld: bedrag,
            voltooidDisplay: "block",
          })
          if(email !== "") {
            axios.get('https://us-central1-ledenportaal-85bb5.cloudfunctions.net/emailInschrijving', {params: {voornaam: voornaam,  naam: achternaam, email: email, ploeg: ploeg, bedrag: bedrag}})
          } else {
            axios.get('https://us-central1-ledenportaal-85bb5.cloudfunctions.net/emailInschrijving', {params: {voornaam: voornaam,  naam: achternaam, email: "laurens.schilders@gmail.com", ploeg: ploeg, bedrag: bedrag}})
          }

        } else if (this.state.bancontact) {
            (async()=> {
              try {
                const descriptionBetaling = achternaam+" "+voornaam+" lidgeld "+this.state.huidigSeizoen
                const redirectUrl = "http://www.vocschriek.be/Ledenportaal/orders/"+key
                const response = await axios.get('https://us-central1-ledenportaal-85bb5.cloudfunctions.net/molliePayment', {params: {bedrag: bedragMollie, description: descriptionBetaling, redirectUrl: redirectUrl, key: key, userid: uid, seizoen: this.state.huidigSeizoen, gezinslid: false}})
                window.location.replace(response.data)
            } catch(error){
              console.error("error" + error);
            }
          }
          )()
          }

        if(!!isOuder && !isSpelend) {
          alert('Je bent ingeschreven!')
          window.location.replace("./")
        }

      }
      catch(err) {
        axios.get('https://us-central1-ledenportaal-85bb5.cloudfunctions.net/emailUitsturenViaPortaal',{params: {titel: "Error :"+user.voornaam+" "+user.achternaam, message: err.message, emails: ["laurens.schilders@gmail.com"]}})
        alert("Error: de webmaster werd gecontacteerd en laat je zo snel mogelijk iets weten!")
      }

      }
    }
  };

  render() {
    var {loading, isOuder, isSpelend, huidigSeizoen, loadingBC } = this.state;

    var knopBlok = true;

    if(!!isSpelend) {
      var isSpelendTekst = "true"
    } else {
      var isSpelendTekst = "false"
    }

    var watMist = ""

    if(this.state.voornaam === "") {
      watMist = watMist + "voornaam, "
    }
    if(this.state.achternaam === "") {
      watMist = watMist + "achternaam, "
    }
    if(this.state.geboortedatum === "" && !!isSpelend) {
      watMist = watMist + "geboortedatum, "
    }
    if(this.state.geslacht === "" && !!isSpelend) {
      watMist = watMist + "genderidentiteit, "
    }
    if(this.state.rijksregister === "" && !!isSpelend) {
      watMist = watMist + "rijksregisternummer, "
    }
    if(this.state.telefoon === "") {
      watMist = watMist + "telefoonnummer, "
    }
    if(this.state.straatnr === "") {
      watMist = watMist + "straatnummer, "
    }
    if(this.state.postcode === "") {
      watMist = watMist + "postcode, "
    }
    if(this.state.gemeente === "") {
      watMist = watMist + "gemeente, "
    }
    if(this.state.ploeg === "" && !!isSpelend) {
      watMist = watMist + "ploeg, "
    }

    if(!this.checkMinTwoTrue()) {
      watMist = watMist + "minstens 2 activiteiten om te helpen, "
    }

    if(!this.state.bancontact && !this.state.overschrijving && !!isSpelend) {
      watMist = watMist + "betaalwijze."
    }

    if(watMist.length === 0) {
      knopBlok = false
    }

    return (
      <div id="herinschrijven">
        <AuthUserContext.Consumer>
          {authUser => (
            <div>
            {!!loading ? (
              <Lottie
                options={{
                  animationData: animation,
                }}
                width={"250px"}
                heigth={"250px"}
              />
            ) : (
            <div>
            <InschrijvingVoltooid
              voltooidDisplay={this.state.voltooidDisplay}
              voornaam={this.state.voornaam}
              achternaam={this.state.achternaam}
              ploeg={this.state.ploeg}
              closeInschrijving={this.closeInschrijving}
              lidgeld={this.state.lidgeld}
              />
              <br/>
            <div>
              {!!isSpelend ? (
                <h2>Inschrijven voor seizoen <br/>{huidigSeizoen}</h2>
              ) : (
                <h2>Inschrijven als ouder</h2>
              )}
              <div className="onderlijn"></div>
              <div className="grid-herinschrijven">
                <Form>
                  <Form.Group as={Row} style={{width: "80vw", maxWidth: "400px"}}>
                    <Form.Label>Type inschrijving:</Form.Label>
                    <Form.Control as="select" value={isSpelendTekst}  onChange={this.onChangeCheckbox} id="isSpelend">
                      <option value="">Kies...</option>
                      <option value="true">Als speler (Ik wil zelf spelen en evt gezinsleden inschrijven)</option>
                      <option value="false">Als ouder (Ik wil enkel gezinsleden inschrijven)</option>
                    </Form.Control>
                  </Form.Group >
                <div className="herinschrijven-formulier-flex">
                  <div>
                    <h3>Persoonlijke gegevens</h3>
                      <Row>
                        <Form.Group as={Col} controlId="formVoornaam">
                          <Form.Label>Naam: *</Form.Label>
                          <Form.Control type="text" placeholder="Voornaam"  value={this.state.voornaam} onChange={this.onChangeVoornaam}/>
                        </Form.Group>
                        <Form.Group as={Col}>
                          <Form.Label>Achternaam: *  </Form.Label>
                          <Form.Control type="text" placeholder="Achternaam" value={this.state.achternaam}  onChange={this.onChangeAchternaam}/>
                        </Form.Group>
                      </Row>


                      {!!isSpelend || !isOuder ? (
                        <div>
                          
                      <Row>
                        <Form.Group as={Col}  controlId="formDatum">
                          <Form.Label>Geboortedatum: *</Form.Label>
                          <Form.Control  type="date" placeholder="01-01-2020"  value={this.state.geboortedatum}  onChange={this.onChangeGeboortedatum}/>
                        </Form.Group>
                        
                        <Form.Group as={Col} controlId="formGeslacht">
                          <Form.Label>Genderidentiteit: *</Form.Label>
                          <Form.Control as="select" value={this.state.geslacht}  onChange={this.onChangeGeslacht}>
                            <option value="">Kies...</option>
                            <option value="Man">Man</option>
                            <option value="Vrouw">Vrouw</option>
                            <option value="Onzijdig">Anders</option>
                          </Form.Control>
                        </Form.Group>
                      </Row>
                          
                      <Row>
                        <Form.Group as={Col} controlId="formRijksnr">
                          <Form.Label>Rijksregisternummer: *</Form.Label>
                          <Form.Control type="text" placeholder="00.00.00-000-00" value={this.state.rijksregister}  onChange={this.onChangeRijksregister}/>
                        </Form.Group>
                        {!!isSpelend || !isOuder ? (
                          <Form.Group as={Col} controlId="formMut">
                            <Form.Label>Mutualiteit:</Form.Label>
                            <Form.Control type="text" placeholder="Mutualiteit" value={this.state.mutualiteit}  onChange={this.onChangeMutualiteit}/>
                          </Form.Group>
                          ) : (
                            <div></div>
                          )}
                      </Row>

                      </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <div>
                      <h3>Contactgegevens</h3>

                      <Row>
                        <Form.Group as={Col} controlId="formStraat">
                          <Form.Label>Straat + nr: *</Form.Label>
                          <Form.Control type="text" placeholder="Straat + nr" value={this.state.straatnr}  onChange={this.onChangeStraatnr}/>
                        </Form.Group>
                      </Row>

                      <Row>
                        <Form.Group as={Col} controlId="formPostcode">
                          <Form.Label>Postcode: *</Form.Label>
                          <Form.Control type="number" placeholder="Postcode"  value={this.state.postcode}  onChange={this.onChangePostcode}/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGemeente">
                          <Form.Label  sm="3">Gemeente: *</Form.Label>
                          <Form.Control type="text" placeholder="Gemeente"  value={this.state.gemeente}  onChange={this.onChangeGemeente}/>
                        </Form.Group>
                      </Row>

                      <Row>
                        <Form.Group as={Col} controlId="formTelefoon">
                            <Form.Label>Telefoonnr: *</Form.Label>
                            <Form.Control type="text" placeholder="0499/99.99.99" value={this.state.telefoon}  onChange={this.onChangeTelefoonnr}/>
                          </Form.Group>
                          
                        
                      </Row>
                    </div>

                    {!!isSpelend || !isOuder ? (
                        <div>
                          <h3>Inschrijving</h3>
                          <Form.Group as={Col} controlId="formPloeg">
                            <Form.Label>Ik schrijf in voor volgende ploeg: *</Form.Label>
                            <Form.Control as="select" value={this.state.ploeg} onChange={this.onChangePloeg}>
                              <option value="">Kies ploeg</option>
                              {!!this.state.ploeg1 && (
                                <option value={this.state.ploeg1}>{this.state.ploeg1} (€ {this.state.lidgeldPloeg1})</option>
                                )}
                              {!!this.state.ploeg2 && (
                                <option value={this.state.ploeg2}>{this.state.ploeg2} (€ {this.state.lidgeldPloeg2})</option>
                              )}
                              {!!this.state.ploeg3 && (
                                <option value={this.state.ploeg3}>{this.state.ploeg3} (€ {this.state.lidgeldPloeg3})</option>
                              )}
                              {!!this.state.ploeg4 && (
                                <option value={this.state.ploeg4}>{this.state.ploeg4} (€ {this.state.lidgeldPloeg4})</option>
                              )}
                              {!!this.state.ploeg5 && (
                                <option value={this.state.ploeg5}>{this.state.ploeg5} (€ {this.state.lidgeldPloeg5})</option>
                              )}
                              {!!this.state.ploeg6 && (
                                <option value={this.state.ploeg6}>{this.state.ploeg6} (€ {this.state.lidgeldPloeg6})</option>
                              )}
                              {!!this.state.ploeg7 && (
                                <option value={this.state.ploeg7}>{this.state.ploeg7} (€ {this.state.lidgeldPloeg7})</option>
                              )}
                              {!!this.state.ploeg8 && (
                                <option value={this.state.ploeg8}>{this.state.ploeg8} (€ {this.state.lidgeldPloeg8})</option>
                              )}
                              {!!this.state.ploeg9 && (
                                <option value={this.state.ploeg9}>{this.state.ploeg9} (€ {this.state.lidgeldPloeg9})</option>
                              )}
                              {!!this.state.ploeg10 && (
                                <option value={this.state.ploeg10}>{this.state.ploeg10} (€ {this.state.lidgeldPloeg10})</option>
                              )}
                              {!!this.state.ploeg11 && (
                                <option value={this.state.ploeg11}>{this.state.ploeg11} (€ {this.state.lidgeldPloeg11})</option>
                              )}
                              {!!this.state.ploeg12 && (
                                <option value={this.state.ploeg12}>{this.state.ploeg12} (€ {this.state.lidgeldPloeg12})</option>
                              )}
                                <option value="Niet Speler">Niet Speler (€ {this.state.lidgeldNietSpeler})</option>
                            </Form.Control>
                          </Form.Group>

                          <Form.Group as={Col} controlId="formGegevens">
                            <Form.Label>Extra gegevens:</Form.Label>
                            <Form.Control as="textarea" rows="4" placeholder="Extra gegevens" value={this.state.extragegevens}  onChange={this.onChangeExtragegevens}/>
                          </Form.Group>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      <div>
                        <h3>Helpen op activiteiten</h3>
                        <p>Om onze volleybalclub draaiende te houden en de lidgelden zo laag mogelijk te houden, zijn we afhankelijk van de inkomsten uit onze activiteiten. Zonder deze evenementen kunnen we niet voldoende middelen verzamelen om alles goed te laten functioneren. Daarom vragen wij aan ieder lid om minstens twee activiteiten aan te duiden waarop ze willen helpen. Jullie inzet is cruciaal voor het succes van de club en helpt ons om de kosten voor iedereen betaalbaar te houden.<br/><br/>Alvast hartelijk dank voor jullie steun en betrokkenheid!</p>

                        <div>
                          {/* ACTIVITEIT 1 */}
                          {!this.state.helpenActiviteit1 ? (
                            <div className="formGridCheckbox" style={{width: "100%", minWidth: "auto", height: "auto", minHeight: "100px"}} onClick={()=>{this.onChangeCheckboxActiviteit1()}}>
                              <h4>{this.state.activiteit1} ({this.state.datumActiviteit1})</h4>
                              <i className="far fa-circle"></i>
                            </div>
                          ) : (
                            <div className="formGridCheckbox" id="checked" style={{width: "100%", minWidth: "auto", height: "auto", minHeight: "100px"}}  onClick={()=>{this.onChangeCheckboxActiviteit1()}}>
                              <h4>{this.state.activiteit1} ({this.state.datumActiviteit1})</h4>
                              <i className="fas fa-check-circle"></i>
                            </div>
                          )}
                          {/* ACTIVITEIT 2 */}
                          {!this.state.helpenActiviteit2 ? (
                            <div className="formGridCheckbox" style={{width: "100%", minWidth: "auto", height: "auto", minHeight: "100px"}} onClick={()=>{this.onChangeCheckboxActiviteit2()}}>
                              <h4>{this.state.activiteit2} ({this.state.datumActiviteit2})</h4>
                              <i className="far fa-circle"></i>
                            </div>
                          ) : (
                            <div className="formGridCheckbox" id="checked" style={{width: "100%", minWidth: "auto", height: "auto", minHeight: "100px"}}  onClick={()=>{this.onChangeCheckboxActiviteit2()}}>
                              <h4>{this.state.activiteit2} ({this.state.datumActiviteit2})</h4>
                              <i className="fas fa-check-circle"></i>
                            </div>
                          )}
                          {/* ACTIVITEIT 3 */}
                          {!this.state.helpenActiviteit3 ? (
                            <div className="formGridCheckbox" style={{width: "100%", minWidth: "auto", height: "auto", minHeight: "100px"}} onClick={()=>{this.onChangeCheckboxActiviteit3()}}>
                              <h4>{this.state.activiteit3} ({this.state.datumActiviteit3})</h4>
                              <i className="far fa-circle"></i>
                            </div>
                          ) : (
                            <div className="formGridCheckbox" id="checked" style={{width: "100%", minWidth: "auto", height: "auto", minHeight: "100px"}}  onClick={()=>{this.onChangeCheckboxActiviteit3()}}>
                              <h4>{this.state.activiteit3} ({this.state.datumActiviteit3})</h4>
                              <i className="fas fa-check-circle"></i>
                            </div>
                          )}
                          {/* ACTIVITEIT 4 */}
                          {!this.state.helpenActiviteit4 ? (
                            <div className="formGridCheckbox" style={{width: "100%", minWidth: "auto", height: "auto", minHeight: "100px"}} onClick={()=>{this.onChangeCheckboxActiviteit4()}}>
                              <h4>{this.state.activiteit4} ({this.state.datumActiviteit4})</h4>
                              <i className="far fa-circle"></i>
                            </div>
                          ) : (
                            <div className="formGridCheckbox" id="checked" style={{width: "100%", minWidth: "auto", height: "auto", minHeight: "100px"}}  onClick={()=>{this.onChangeCheckboxActiviteit4()}}>
                              <h4>{this.state.activiteit4} ({this.state.datumActiviteit4})</h4>
                              <i className="fas fa-check-circle"></i>
                            </div>
                          )}
                          {/* ACTIVITEIT 5 */}
                          {!this.state.helpenActiviteit5 ? (
                            <div className="formGridCheckbox" style={{width: "100%", minWidth: "auto", height: "auto", minHeight: "100px"}} onClick={()=>{this.onChangeCheckboxActiviteit5()}}>
                              <h4>{this.state.activiteit5} ({this.state.datumActiviteit5})</h4>
                              <i className="far fa-circle"></i>
                            </div>
                          ) : (
                            <div className="formGridCheckbox" id="checked" style={{width: "100%", minWidth: "auto", height: "auto", minHeight: "100px"}}  onClick={()=>{this.onChangeCheckboxActiviteit5()}}>
                              <h4>{this.state.activiteit5} ({this.state.datumActiviteit5})</h4>
                              <i className="fas fa-check-circle"></i>
                            </div>
                          )}
                          {/* ACTIVITEIT 6 */}
                          {!this.state.helpenActiviteit6 ? (
                            <div className="formGridCheckbox" style={{width: "100%", minWidth: "auto", height: "auto", minHeight: "100px"}} onClick={()=>{this.onChangeCheckboxActiviteit6()}}>
                              <h4>{this.state.activiteit6} ({this.state.datumActiviteit6})</h4>
                              <i className="far fa-circle"></i>
                            </div>
                          ) : (
                            <div className="formGridCheckbox" id="checked" style={{width: "100%", minWidth: "auto", height: "auto", minHeight: "100px"}}  onClick={()=>{this.onChangeCheckboxActiviteit6()}}>
                              <h4>{this.state.activiteit6} ({this.state.datumActiviteit6})</h4>
                              <i className="fas fa-check-circle"></i>
                            </div>
                          )}
   
                          {!this.checkMinTwoTrue() && (
                            <p style={{color: "red", marginTop: "10px"}}>Gelieve minstens 2 activiteiten aan te duiden.</p>
                          )}
                          
                        </div>
                      </div>
                    

                    {!!isSpelend && (
                    <div className="herinschrijven-activiteiten" id="betaalmethode">
                      <h3>Betaalwijze lidgeld</h3>

                      <h4>Ik ga betalen via:</h4>
                      <br/>
                      <Form.Row>
                        {!this.state.bancontact ? (
                          <div className="formGridCheckbox" onClick={()=>{this.onChangeCheckboxBancontact()}}>
                            <h4>Bancontact</h4>
                            <i className="far fa-circle"></i>
                          </div>
                        ) : (
                          <div className="formGridCheckbox" id="checked" onClick={()=>{this.onChangeCheckboxBancontact()}}>
                            <h4>Bancontact</h4>
                            <i className="fas fa-check-circle"></i>
                          </div>
                        )}
                        {!this.state.overschrijving ? (
                          <div className="formGridCheckbox"onClick={()=>{this.onChangeCheckboxOverschrijving()}}>
                            <h4>Overschrijving</h4>
                            <i className="far fa-circle"></i>
                          </div>
                        ) : (
                          <div className="formGridCheckbox" id="checked"  onClick={()=>{this.onChangeCheckboxOverschrijving()}}>
                            <h4>Overschrijving</h4>
                            <i className="fas fa-check-circle"></i>
                          </div>
                        )}
                      </Form.Row>
                      <br/><br/>
                    </div>
                  )}



                  </div>

                  </Form>
                </div>
                
                
                {watMist.length > 0 && (
                  <p style={{color: "red"}}>Volgende gegevens moeten nog ingevuld worden: {watMist} <br/><br/></p>
                )}

                  <div className="buttons-grid">
                    <Button disabled={knopBlok} variant="primary" size="lg" onClick={() => {this.onEditGegevens(
                        this.state.user,
                        this.state.userid,
                        this.state.voornaam,
                        this.state.achternaam,
                        this.state.geboortedatum,
                        this.state.rijksregister,
                        this.state.telefoon,
                        this.state.mutualiteit,
                        this.state.straatnr,
                        this.state.postcode,
                        this.state.gemeente,
                        this.state.extragegevens,
                        this.state.ploeg,
                        this.state.geslacht,
                        this.state.helpenActiviteit1,
                        this.state.helpenActiviteit2,
                        this.state.helpenActiviteit3,
                        this.state.helpenActiviteit4,
                        this.state.helpenActiviteit5,
                        this.state.helpenActiviteit6,
                        this.state.helpenActiviteit7)}}>
                        <span>
                      Inschrijven
                      {!!loadingBC && (
                        <Lottie
                          options={{
                            animationData: animation,
                          }}
                          width={"100px"}
                          heigth={"100px"}
                        />
                      )}
                      </span>
                    </Button>{' '}
                    <LinkContainer to={ROUTES.LEDENPORTAAL}>
                      <Button variant="secondary" size="lg" active>
                        Annuleren
                      </Button>
                    </LinkContainer>
                    
                </div>
                
              </div>
            </div>
          )}
        </div>
          )}
        </AuthUserContext.Consumer>
      </div>
    );
}}

const condition = authUser => authUser

const Herinschrijven = withFirebase(HerinschrijvenBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase,
)(Herinschrijven);
