import React from 'react';
import {compose} from 'recompose';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import {LinkContainer} from "react-router-bootstrap";
import * as ROUTES from '../../../constants/routes';
import wakado from '../../afbeeldingen/wakado.jpg';
import {ButtonOne} from '../../../constants/button'
import {withFirebase} from '../../Firebase';
import {withAuthorization, withEmailVerification} from '../../session';
import './style.css'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'

import Fotoaanpassen from './fotoaanpassen/Fotoaanpassen';

import {DatumFromFirebaseServerToString} from "../../../functions/DatumFirebase"

class AccountPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      users: [],
      straatnr: "",
      user: "",
      familie: [],
      inschrijvingOpen: true,
      fotoAanpassenDisplay: false,
      bestellingen:[],
      huidigSeizoen: "",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({loading: true});

    var user = JSON.parse(localStorage.getItem('authUser'))
    this.props.firebase.user(user.uid).on('value', snapshot => {
      const userObject = snapshot.val();
      if (userObject) {
        if (userObject.bestellingen !== undefined){
          const bestellingenList = Object.keys(userObject.bestellingen).map(key => ({
            ...userObject.bestellingen[key],
            uid: key,
          }));
          this.setState({
            bestellingen: bestellingenList
          })
        }
        if (userObject.familie !== undefined){
          const familieList = Object.keys(userObject.familie).map(key => ({
            ...userObject.familie[key],
          }));
          this.setState({
            familie: familieList
          })
        }

        this.setState({users: userObject, straatnr: "", loading: false, user: user});
      } else {
        this.setState({users: null, loading: false});
      }

    });

    var huidigSeizoen = ""
    this.props.firebase.huidigSeizoen().on('value', snapshot => {
      huidigSeizoen = snapshot.val();
      this.setState({
        huidigSeizoen: snapshot.val(),
      })
    })


  }

  eerstInschrijven() {
    alert('Je moet je eerst inschrijven voor je gegevens kan aanpassen!')
  }

  eerstInschrijvenGezinsleden() {
    alert('Je moet je eerst inschrijven als gezinshoofd voor je gezinsleden kan inschrijven!')
  }

  fotoAanpassenAan() {
    this.setState({
      fotoAanpassenDisplay: true
    })
  }

  fotoAanpassenUit() {
    this.setState({
      fotoAanpassenDisplay: false
    })
  }

  alert(naam) {
    alert("De herinschrijving van "+naam+" werd al in orde gebracht")
  }

  render() {
    var {
      user,
      familie,
      bestellingen,
      fotoAanpassenDisplay,
      inschrijvingOpen,
      loading
    } = this.state;


    if (familie === undefined) {
      familie = [];
    }

    var nummer = user.rijksregister
    var userfoto = "url('" + user.foto + "')"
    if (!loading) {
      if (nummer === undefined || nummer.length < 2) {
        nummer = "00000000000"
      }
      nummer = nummer.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '')
      nummer = nummer.replace(/-/g, '');
      if (nummer.length > 8) {
        nummer = nummer.substring(0, 2) + "." + nummer.substring(2, 4) + "." + nummer.substring(4, 6) + "-" + nummer.substring(6, 9) + "-" + nummer.substring(9, 11)
      } else if (nummer.length > 5) {
        nummer = nummer.substring(0, 2) + "." + nummer.substring(2, 4) + "." + nummer.substring(4, 6) + "-" + nummer.substring(6, 9)
      } else if (nummer.length > 3) {
        nummer = nummer.substring(0, 2) + "." + nummer.substring(2, 4) + "." + nummer.substring(4, 6)
      } else if (nummer.length > 1) {
        nummer = nummer.substring(0, 2) + "." + nummer.substring(2, 4)
      } else {
        nummer = nummer.substring(0, 2)
      }
      if (user.foto === undefined || user.foto === "") {
        userfoto = "url('" + wakado + "')"
      }
      if (!!user.isOuder && user.ploeg === "") {
        user.ploeg = "Ouder"
      }
    }


    var ingeschreven = false;

    if(user.seizoenen !== undefined) {
      if(user.seizoenen[this.state.huidigSeizoen] !== undefined) {
        if(!!user.seizoenen[this.state.huidigSeizoen].ingeschreven) {

          ingeschreven = true
        }
      } else {
        ingeschreven = false
      }
    }

    var bestellingenGefiltert = bestellingen.sort((a, b) => {
      var c = new Date(a.aanmaakdatum)
      var d = new Date(b.aanmaakdatum)
      return d - c
    })

    bestellingenGefiltert = bestellingenGefiltert.slice(0, 5);

    return (
      <div id="account">
        {!!fotoAanpassenDisplay && (
          <Fotoaanpassen close={() => this.fotoAanpassenUit()}/>
        )}

        <div className="accountgegevenspagina">
          <div className="accountgegevensEnButtons">
            <div id="accountGegevens">
              <div>
                <div className="accountFoto" style={{
                    backgroundImage: userfoto
                  }}>
                </div>
                <div onClick={() => this.fotoAanpassenAan()}>
                  <ButtonOne  small text={"Foto Aanpassen"} />
                </div>
              </div>
              <div>
                <div>
                  <h4>{user.voornaam} {user.achternaam}</h4>
                  <h5>{user.ploeg}</h5>
                </div>
                <br/>
                <div>
                  <h6><i className="fas fa-map-marker-alt"></i>{user.straatnr} {user.postcode} {user.gemeente}</h6>
                  <h6><i className="fas fa-phone-alt"></i>{user.telefoon}</h6>
                  <h6><i className="fas fa-at"></i>{user.email}</h6>
                </div>
              </div>

            </div>
            {
              !user.ingeschreven
                ? (
                  <LinkContainer disabled to={ROUTES.ACCOUNT}  onClick={() => this.eerstInschrijven()} className="accountButton">
                  <div className="accountButton-grid">
                    <i className="fas fa-user-circle"></i>
                    <div className="accountButton-text">
                      <h5>Mijn gegevens aanpassen</h5>
                      <p>Adres / Mutualiteit / telefoonnr / ...</p>
                    </div>
                    <i className="fas fa-chevron-right"></i>
                  </div>
                </LinkContainer>)
                : (<LinkContainer to={ROUTES.GEGEVENSAANPASSEN} className="accountButton">
                  <div className="accountButton-grid">
                    <i className="fas fa-user-circle"></i>
                    <div className="accountButton-text">
                      <h5>Mijn gegevens aanpassen</h5>
                      <p>Adres / Mutualiteit / telefoonnr / ...</p>
                    </div>
                    <i className="fas fa-chevron-right"></i>
                  </div>
                </LinkContainer>)
            }

              
              <LinkContainer to={ROUTES.PRIVACY} className="accountButton">
                <div className="accountButton-grid">
                  <i className="fas fa-user-secret"></i>
                  <div className="accountButton-text">
                    <h5>Privacy verklaring VOC Schriek</h5>
                    <p>GDPR regels</p>
                  </div>
                  <i className="fas fa-chevron-right"></i>
                </div>
              </LinkContainer>
        </div>
          <div className="account-overzichten">
            <div id="accountOverzicht">
              <h4>Laatste 5 betalingen:</h4>
              <Card.Body>
                <Table id="lijstFamilieleden" striped hover size="sm" responsive>
                  <thead>
                    <tr>
                      <th> </th>
                      <th>Datum</th>
                      <th>Omschrijving</th>
                      <th>Methode</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bestellingenGefiltert.map(betaling => {
                        var bedrag = ""

                        if(betaling.bedrag !== undefined){
                          bedrag = "€"+betaling.bedrag
                        }
                        var datum = new Date(betaling.aanmaakdatum)

                        const datumText = DatumFromFirebaseServerToString(datum)

                        var status = betaling.status
                        return(
                          <tr key={betaling.omschrijving} className="familielid">
                            <td><i className="fas fa-euro-sign"></i></td>
                            <td>{datumText}</td>
                            <td>{betaling.omschrijving} ({bedrag})</td>
                            <td>{betaling.betaalmethode}</td>
                          </tr>
                          )
                    } )

                    }
                    </tbody>
                </Table>
              </Card.Body>
            </div>

          </div>
        </div>
      </div>
)
  }
}

const condition = authUser => !!authUser;

export default compose(withEmailVerification, withAuthorization(condition), withFirebase,)(AccountPage);
