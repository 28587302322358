import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { LinkContainer } from "react-router-bootstrap";
import wakado from '../../afbeeldingen/wakado.jpg';
import axios from 'axios';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withAuthorization, withEmailVerification } from '../../session';
import * as ROUTES from '../../../constants/routes';
import * as ROLES from '../../../constants/roles';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table'
import { CSVLink } from "react-csv";
import GegevensAanpassen from './GegevensAanpassen';

const Ledenlijst = () => (
    <div id="adminpagina">
      <div>
        <Switch>
          <Route exact path={ROUTES.LEDENLIJST_DETAILS} component={UserItem} />
          <Route exact path={ROUTES.LEDENLIJST} component={UserList} />
        </Switch>
      </div>
    </div>
)

class UserListBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
      huidigSeizoen: "",
      search: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({ loading: true });

    this.props.firebase.users().on('value', snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));

      this.setState({
        users: usersList,
        loading: false,
      });
    });

    var huidigSeizoen = "2024-2025"
      this.props.firebase.huidigSeizoen().on('value', snapshot => {
        huidigSeizoen = snapshot.val()

        this.setState({
          huidigSeizoen
        })
      })
  }

  componentWillUnmount() {

  }

  onChangeSearch = event => {
    this.setState({ search: event.target.value });
  };

  render() {
    const { users, loading } = this.state;
    var displayA = "block";
    var displayB = "block";
    var displayC = "block";
    var displayD = "block";
    var displayE = "block";
    var displayF = "block";
    var displayG = "block";
    var displayH = "block";
    var displayI = "block";
    var displayJ = "block";
    var displayK = "block";
    var displayL = "block";
    var displayM = "block";
    var displayN = "block";
    var displayO = "block";
    var displayP = "block";
    var displayQ = "block";
    var displayR = "block";
    var displayS = "block";
    var displayT = "block";
    var displayU = "block";
    var displayV = "block";
    var displayW = "block";
    var displayX = "block";
    var displayY = "block";
    var displayZ = "block";
    var displayRest = "block";

    var usersFilter = [];
    var searchFilter = this.state.search;
    searchFilter = searchFilter.toLowerCase();

    if (searchFilter === "") {
      usersFilter = users;
    } else {
      usersFilter = users.filter(d => {var achternaam = d.achternaam; var voornaam = d.voornaam; var ploeg = d.ploeg; var gezinshoofd = d.gezinshoofd; var email = d.email;
                                if (gezinshoofd === undefined) {
                                  gezinshoofd = ""
                                }
                                if (email === undefined) {
                                  email = ""
                                }
                               return (achternaam.toLowerCase().includes(searchFilter) || voornaam.toLowerCase().includes(searchFilter)|| ploeg.toLowerCase().includes(searchFilter) || gezinshoofd.toLowerCase().includes(searchFilter) || email.toLowerCase().includes(searchFilter));
                              });
    }

    var users_A = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "a" || achternaam.charAt(0) === "A" );
                            });
    if (users_A.length === 0) {
      displayA = "none";
    }
    users_A.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_B = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "b" || achternaam.charAt(0) === "B" );
                            });
    if (users_B.length === 0) {
      displayB = "none";
    }
    users_B.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_C = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "c" || achternaam.charAt(0) === "C" );
                            });
    if (users_C.length === 0) {
      displayC = "none";
    }
    users_C.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_D = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "d" || achternaam.charAt(0) === "D" );
                            });
    if (users_D.length === 0) {
      displayD = "none";
    }
    users_D.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_E = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "e" || achternaam.charAt(0) === "E" );
                            });
    if (users_E.length === 0) {
      displayE = "none";
    }
    users_E.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_F = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "f" || achternaam.charAt(0) === "F" );
                            });
    if (users_F.length === 0) {
      displayF = "none";
    }
    users_F.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_G = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "g" || achternaam.charAt(0) === "G" );
                            });
    if (users_G.length === 0) {
      displayG = "none";
    }
    users_G.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_H = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "h" || achternaam.charAt(0) === "H" );
                            });
    if (users_H.length === 0) {
      displayH = "none";
    }
    users_H.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_I = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "i" || achternaam.charAt(0) === "I" );
                            });
    if (users_I.length === 0) {
      displayI = "none";
    }
    users_I.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_J = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "j" || achternaam.charAt(0) === "J" );
                            });
    if (users_J.length === 0) {
      displayJ = "none";
    }
    users_J.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_K = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "k" || achternaam.charAt(0) === "K" );
                            });
    if (users_K.length === 0) {
      displayK = "none";
    }
    users_K.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_L = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "l" || achternaam.charAt(0) === "L" );
                            });
    if (users_L.length === 0) {
      displayL = "none";
    }
    users_L.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_M = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "m" || achternaam.charAt(0) === "M" );
                            });
    if (users_M.length === 0) {
      displayM = "none";
    }
    users_M.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_N = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "n" || achternaam.charAt(0) === "N" );
                            });
    if (users_N.length === 0) {
      displayN = "none";
    }
    users_N.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_O = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "o" || achternaam.charAt(0) === "O" );
                            });
    if (users_O.length === 0) {
      displayO = "none";
    }
    users_O.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_P = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "p" || achternaam.charAt(0) === "P" );
                            });
    if (users_P.length === 0) {
      displayP = "none";
    }
    users_P.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_Q = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "q" || achternaam.charAt(0) === "Q" );
                            });
    if (users_Q.length === 0) {
      displayQ = "none";
    }
    users_Q.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_R = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "r" || achternaam.charAt(0) === "R" );
                            });
    if (users_R.length === 0) {
      displayR = "none";
    }
    users_R.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_S = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "s" || achternaam.charAt(0) === "S" );
                            });
    if (users_S.length === 0) {
      displayS = "none";
    }
    users_S.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_T = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "t" || achternaam.charAt(0) === "T" );
                            });
    if (users_T.length === 0) {
      displayT = "none";
    }
    users_T.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_U = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "u" || achternaam.charAt(0) === "U" );
                            });
    if (users_U.length === 0) {
      displayU = "none";
    }
    users_U.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_V = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "v" || achternaam.charAt(0) === "V" );
                            });
    if (users_V.length === 0) {
      displayV = "none";
    }
    users_V.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_W = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "W" || achternaam.charAt(0) === "W" );
                            });
    if (users_W.length === 0) {
      displayW = "none";
    }
    users_W.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_X = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "X" || achternaam.charAt(0) === "X" );
                            });
    if (users_X.length === 0) {
      displayX = "none";
    }
    users_X.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_Y = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "Y" || achternaam.charAt(0) === "Y" );
                            });
    if (users_Y.length === 0) {
      displayY = "none";
    }
    users_Y.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_Z = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) === "Z" || achternaam.charAt(0) === "Z" );
                            });
    if (users_Z.length === 0) {
      displayZ = "none";
    }
    users_Z.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    var users_Rest = usersFilter.filter(d => {var achternaam = d.achternaam;
                             return (achternaam.charAt(0) !== "a" &&
                             achternaam.charAt(0) !== "A" &&
                             achternaam.charAt(0) !== "b" &&
                             achternaam.charAt(0) !== "B" &&
                             achternaam.charAt(0) !== "c" &&
                             achternaam.charAt(0) !== "C" &&
                             achternaam.charAt(0) !== "d" &&
                             achternaam.charAt(0) !== "D" &&
                             achternaam.charAt(0) !== "e" &&
                             achternaam.charAt(0) !== "E" &&
                             achternaam.charAt(0) !== "f" &&
                             achternaam.charAt(0) !== "F" &&
                             achternaam.charAt(0) !== "g" &&
                             achternaam.charAt(0) !== "G" &&
                             achternaam.charAt(0) !== "h" &&
                             achternaam.charAt(0) !== "H" &&
                             achternaam.charAt(0) !== "i" &&
                             achternaam.charAt(0) !== "J" &&
                             achternaam.charAt(0) !== "k" &&
                             achternaam.charAt(0) !== "K" &&
                             achternaam.charAt(0) !== "l" &&
                             achternaam.charAt(0) !== "L" &&
                             achternaam.charAt(0) !== "m" &&
                             achternaam.charAt(0) !== "M" &&
                             achternaam.charAt(0) !== "n" &&
                             achternaam.charAt(0) !== "N" &&
                             achternaam.charAt(0) !== "o" &&
                             achternaam.charAt(0) !== "O" &&
                             achternaam.charAt(0) !== "p" &&
                             achternaam.charAt(0) !== "P" &&
                             achternaam.charAt(0) !== "q" &&
                             achternaam.charAt(0) !== "Q" &&
                             achternaam.charAt(0) !== "r" &&
                             achternaam.charAt(0) !== "R" &&
                             achternaam.charAt(0) !== "s" &&
                             achternaam.charAt(0) !== "S" &&
                             achternaam.charAt(0) !== "t" &&
                             achternaam.charAt(0) !== "T" &&
                             achternaam.charAt(0) !== "u" &&
                             achternaam.charAt(0) !== "U" &&
                             achternaam.charAt(0) !== "v" &&
                             achternaam.charAt(0) !== "V" &&
                             achternaam.charAt(0) !== "w" &&
                             achternaam.charAt(0) !== "W" &&
                             achternaam.charAt(0) !== "x" &&
                             achternaam.charAt(0) !== "X" &&
                             achternaam.charAt(0) !== "y" &&
                             achternaam.charAt(0) !== "Y" &&
                             achternaam.charAt(0) !== "z" &&
                             achternaam.charAt(0) !== "Z")
                            });
    if (users_Rest.length === 0) {
      displayRest = "none";
    }
    users_Rest.sort((a, b) => a.achternaam.localeCompare(b.achternaam))

    const headers = [
      ["Achternaam"],["Voornaam"],["Ploeg"],["Ouder"],["Ingeschreven seizoen "+this.state.huidigSeizoen],["bedrag"],["Betaalwijze"],["e-mailadres"],["Rijksregisternr"],["Geboortedatum"],["Geslacht"],["Telefoon"],["Straat+nr"],["Postcode"],["Gemeente"],["Mutualiteit"],["Extra Gegevens"],["Familieleden"]
    ];

    const csvData = [];

    users.forEach((item, i) => {
      var familie = []
      if(item.familie !== undefined) {

      }
      var ouder = "Nee";
      if(item.isOuder === true) {
        ouder = "Ja"
      }
      var ingeschreven = "Nee";
      var bedrag = 0;
      var betaalwijze = ""
      if(item.ingeschreven === true) {
        if(item.seizoenen !== undefined && item.seizoenen[this.state.huidigSeizoen] !== undefined && item.seizoenen[this.state.huidigSeizoen].ingeschreven) {
          ingeschreven = "Ja"
          bedrag = item.seizoenen[this.state.huidigSeizoen].lidgeld
          betaalwijze = item.seizoenen[this.state.huidigSeizoen].betaaldwijze
        }
      }
      var ploeg = item.ploeg
      if(ploeg === "" && item.isOuder === true){
        ploeg = "Ouder"
      }
      var lid = [
        item.achternaam, item.voornaam, ploeg, ouder, ingeschreven, bedrag, betaalwijze, item.email, item.rijksregister, item.geboortedatum, item.geslacht,item.telefoon, item.straatnr, item.postcode, item.gemeente, item.mutualiteit, item.extragegevens, familie
      ]
      csvData.push(lid)
    });

    csvData.sort();

    return (
      <div>
        <br />
        <h2>Ledenlijst</h2>
        <br/>
        <div className="buttons-list">
          <CSVLink headers={headers} data={csvData}  separator={";"} filename={"Ledenlijst_VOC_Schriek.csv"}>
            <Button variant="success" active>Download Ledenlijst</Button>
          </CSVLink>
        </div>


        <div className="lijstUsers">
          <br />
          <div>
            <Form.Control placeholder="Lid / ouder / ploeg /... zoeken" value={this.state.search} onChange={this.onChangeSearch} />
          </div>
          {loading && <div>Loading ...</div>}
          <div style={{display: displayA}}>
            <h4 className="subtitel-ledenlijst">A</h4>
            {users_A.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayB}}>
            <h4 className="subtitel-ledenlijst">B</h4>
            {users_B.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayC}}>
            <h4 className="subtitel-ledenlijst">C</h4>
            {users_C.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayD}}>
            <h4 className="subtitel-ledenlijst">D</h4>
            {users_D.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayE}}>
            <h4 className="subtitel-ledenlijst">E</h4>
            {users_E.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayF}}>
            <h4 className="subtitel-ledenlijst">F</h4>
            {users_F.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayG}}>
            <h4 className="subtitel-ledenlijst">G</h4>
            {users_G.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayH}}>
            <h4 className="subtitel-ledenlijst">H</h4>
            {users_H.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayI}}>
            <h4 className="subtitel-ledenlijst">I</h4>
            {users_I.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayJ}}>
            <h4 className="subtitel-ledenlijst">J</h4>
            {users_J.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayK}}>
            <h4 className="subtitel-ledenlijst">K</h4>
            {users_K.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayL}}>
            <h4 className="subtitel-ledenlijst">L</h4>
            {users_L.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayM}}>
            <h4 className="subtitel-ledenlijst">M</h4>
            {users_M.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayN}}>
            <h4 className="subtitel-ledenlijst">N</h4>
            {users_N.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayO}}>
            <h4 className="subtitel-ledenlijst">O</h4>
            {users_O.map(user => (
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton">
                      <i className="fas fa-user"></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            ))}
          </div>
          <div style={{display: displayP}}>
            <h4 className="subtitel-ledenlijst">P</h4>
            {users_P.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayQ}}>
            <h4 className="subtitel-ledenlijst">Q</h4>
            {users_Q.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayR}}>
            <h4 className="subtitel-ledenlijst">R</h4>
            {users_R.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayS}}>
            <h4 className="subtitel-ledenlijst">S</h4>
            {users_S.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayT}}>
            <h4 className="subtitel-ledenlijst">T</h4>
            {users_T.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayU}}>
            <h4 className="subtitel-ledenlijst">U</h4>
            {users_U.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayV}}>
            <h4 className="subtitel-ledenlijst">V</h4>
            {users_V.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayW}}>
            <h4 className="subtitel-ledenlijst">W</h4>
            {users_W.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayX}}>
            <h4 className="subtitel-ledenlijst">X</h4>
            {users_X.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayY}}>
            <h4 className="subtitel-ledenlijst">Y</h4>
            {users_Y.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayZ}}>
            <h4 className="subtitel-ledenlijst">Z</h4>
            {users_Z.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
          <div style={{display: displayRest}}>
            <h4>Rest</h4>
            {users_Rest.map(user => {
              var icon = "fas fa-user";
              var backgroundColor = "#2c3e50";

              if (user.isOuder){
                icon = "fas fa-users";
                backgroundColor = "#2980b9";
              }
              return(
              <div key={user.uid}>
                  <Link to={{ state: { user }, pathname: `${ROUTES.LEDENLIJST}/${user.uid}` }}>
                    <div className="lidButton" style={{backgroundColor: backgroundColor}}>
                      <i className={icon}></i>
                      <p>{user.achternaam} {user.voornaam}</p>
                    </div>
                  </Link>
              </div>
            )})}
          </div>
        </div>
      </div>
    );
  }
  }

  class UserItemBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: null,
      displayAanpassen: "none",
      ...props.location.state,
      huidigSeizoen: "",
      familie: [],
      bestellingen: []
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({ loading: true });

    this.props.firebase
      .user(this.props.match.params.id)
      .on('value', snapshot => {
        this.setState({
          user: snapshot.val(),
          loading: false,
        });

        if (snapshot.val().familie !== undefined){
          const familieList = Object.keys(snapshot.val().familie).map(key => ({
            ...snapshot.val().familie[key],
            uid: key,
          }));
          this.setState({
            familie: familieList
          })
        }

        if (snapshot.val().bestellingen !== undefined){
          const bestellingenList = Object.keys(snapshot.val().bestellingen).map(key => ({
            ...snapshot.val().bestellingen[key],
            uid: key,
          }));
          this.setState({
            bestellingen: bestellingenList
          })
        }
      });

      var huidigSeizoen = "2024-2025"
      this.props.firebase.huidigSeizoen().on('value', snapshot => {
        huidigSeizoen = snapshot.val()

        this.setState({
          huidigSeizoen
        })
      })
  }

  componentWillUnmount() {
  }

  onSendPasswordResetEmail = () => {
  this.props.firebase.doPasswordReset(this.state.user.email);
  };

  bestuurMaken(user, username, uid) {
    if (window.confirm("Ben je zeker dat je "+username+" bestuurrechten wilt geven?")) {
      const { ...userSnapshot } = user;
      var roles = user.roles
      if(roles === undefined) {
        roles = []
      }
      roles[ROLES.ADMIN] = ROLES.ADMIN
      this.props.firebase.user(this.props.match.params.id).set({
        ...userSnapshot,
        roles: roles,
      });
    } else {
      console.log("niet gebeurd")
    }
  }

  bestuurVerwijderen(user, username, uid) {
    if (window.confirm("Ben je zeker dat je "+username+" bestuurrechten wilt ontnemen?")) {
      const { ...userSnapshot } = user;
      var roles = user.roles
      roles[ROLES.ADMIN] = ""
      this.props.firebase.user(this.props.match.params.id).set({
        ...userSnapshot,
        roles: roles,
      });
    } else {
      console.log("niet gebeurd")
    }
  }

  ouderMaken(user, username, uid) {
    if (window.confirm("Ben je zeker dat je het account van "+username+" wilt aanpassen naar ouder?")) {
      const { ...userSnapshot } = user;
      var roles = user.roles
      if(roles === undefined) {
        roles = []
      }
      roles[ROLES.OUDER] = ROLES.OUDER
      this.props.firebase.user(this.props.match.params.id).set({
        ...userSnapshot,
        roles: roles,
      });
    } else {
      console.log("niet gebeurd")
    }
  }

  ouderVerwijderen(user, username, uid) {
    if (window.confirm("Ben je zeker dat je het ouder account van "+username+" wilt aanpassen naar gewoon?")) {
      const { ...userSnapshot } = user;
      var roles = user.roles
      roles[ROLES.OUDER] = ""
      this.props.firebase.user(this.props.match.params.id).set({
        ...userSnapshot,
        roles: roles,
      });
    } else {
      console.log("niet gebeurd")
    }
  }

  trainerMaken(user, username, uid) {
    if (window.confirm("Ben je zeker dat je "+username+" trainer rechten wilt geven?")) {
      const { ...userSnapshot } = user;
      var roles = user.roles
      if(roles === undefined) {
        roles = []
      }
      roles[ROLES.TRAINER] = ROLES.TRAINER
      this.props.firebase.user(this.props.match.params.id).set({
        ...userSnapshot,
        roles: roles,
      });
    } else {
      console.log("niet gebeurd")
    }
  }

  trainerVerwijderen(user, username, uid) {
    if (window.confirm("Ben je zeker dat je "+username+" geen trainer rechten meer wilt geven?")) {
      const { ...userSnapshot } = user;
      var roles = user.roles
      roles[ROLES.TRAINER] = ""
      this.props.firebase.user(this.props.match.params.id).set({
        ...userSnapshot,
        roles: roles,
      });
    } else {
      console.log("niet gebeurd")
    }
  }

  deleteUser(username, uid) {
    if (window.confirm("Ben je zeker dat je "+username+" wilt verwijderen?")) {
      alert('momenteel nog niet mogelijk')
    } else {
      console.log("niet gebeurd")
    }
  }

  SendEmail(adres) {
    window.location = "mailto:" + adres
  }

  OpenAanpassen() {
    this.setState({
      displayAanpassen: "grid",
    });
  }

  CloseAanpassen() {
    this.setState({
      displayAanpassen: "none",
    });
    document.documentElement.scrollTop = 0;
  }

  emailVerifieren(uid) {
    axios.get('https://us-central1-ledenportaal-85bb5.cloudfunctions.net/handmatigeEmailVerificatie', {params: {uid: uid}})
    .then(alert("Verificatie is gebeurd"))
  }

    render() {
      const { user, familie, huidigSeizoen, bestellingen } = this.state;
      var ingeschreven = "NEE";

      if(user.seizoenen !== undefined){
        if (user.seizoenen[huidigSeizoen] !== undefined) {
          if(!!user.seizoenen[huidigSeizoen].ingeschreven){
            ingeschreven = "JA"
          }
        }
      }

      var ouderaccount = "";
      if (user.gezinshoofd) {
        ouderaccount = "Ja, " + user.gezinshoofd
      } else {
        ouderaccount = "NEE"
      }

      var ouder = false;
      try {
        if (user.roles.OUDER === "OUDER") {
          ouder = true;
        } else {
          ouder = false;
        }
        }
        catch(error) {
            ouder = false;
            }

      var trainer = false;
      try {
        if (user.roles.TRAINER === ROLES.TRAINER) {
          trainer = true;
        } else {
          trainer = false;
        }
        }
        catch(error) {
            ouder = false;
            }

      var bestuur = false;
      try {
        if (user.roles.ADMIN === "ADMIN") {
          bestuur = true;
        } else {
          bestuur = false;
        }
        }
        catch(error) {
            bestuur = false;
            }

      var userfoto = "url('"+user.foto+"')"
      if(user.foto === undefined || user.foto === ""){
        userfoto = "url('"+wakado+"')"
      }


    return (
      <div className="userCard">
        <LinkContainer to={ROUTES.LEDENLIJST}><i id="backAdmin" className="fas fa-arrow-left"></i></LinkContainer>
        {user && (
          <div className="userInfo">
            <div className="accountFoto" style={{backgroundImage: userfoto}}></div>
            <br />
            <div className="userInfoName">
              <i className="fas fa-user-circle"></i>
              <div className="userInfoNameText">
                <h4>{user.voornaam} {user.achternaam}</h4>
                <p>{user.ploeg}</p>
              </div>
            </div>

            <div>
              <div className="userInfoRest">
                <i className="fas fa-phone-alt"></i>
                <div className="userInfoRestText">
                  <p>Telefoon</p>
                  <h6>{user.telefoon}</h6>
                </div>
              </div>
              <div className="userInfoRest">
                <i className="fas fa-envelope"></i>
                <div className="userInfoRestText">
                  <p>Email</p>
                  <h6>{user.email}</h6>
                </div>
              </div>
              <div className="userInfoRest">
                <i className="fas fa-map-marker-alt"></i>
                <div className="userInfoRestText">
                  <p>Adres</p>
                  <h6>{user.straatnr}</h6>
                  <h6>{user.postcode} - {user.gemeente}</h6>
                </div>
              </div>
            </div>

            <div className="userInfoRest">
              <i className="fas fa-birthday-cake"></i>
              <div className="userInfoRestText">
                <p>Geboortedatum</p>
                <h6>{user.geboortedatum}</h6>
              </div>
            </div>
            <div className="userInfoRest">
              <i className="fas fa-address-card"></i>
              <div className="userInfoRestText">
                <p>Rijksregisternr</p>
                <h6>{user.rijksregister}</h6>
              </div>
            </div>
            <div className="userInfoRest">
              <i className="fas fa-hand-holding-medical"></i>
              <div className="userInfoRestText">
                <p>Mutualiteit</p>
                <h6>{user.mutualiteit}</h6>
              </div>
            </div>
            <div className="userInfoRest">
              <i className="fas fa-exclamation-triangle"></i>
              <div className="userInfoRestText">
                <p>Extra gegevens</p>
                <h6>{user.extragegevens}</h6>
              </div>
            </div>
            <div className="userInfoRest">
              <i className="fas fa-sign-in-alt"></i>
              <div className="userInfoRestText">
                <p>Ingeschreven voor seizoen {this.state.huidigSeizoen}</p>
                <h6>{ingeschreven}</h6>
              </div>
            </div>
              <div className="userInfoRest">
                <i className="fas fa-child"></i>
                <div className="userInfoRestText">
                  <p>Gelinkt aan ouder account?</p>
                  <h6>{ouderaccount}</h6>
                </div>
              </div>
              {!!user.familie && (
                <div className="userInfoRest">
                  <i className="fas fa-user-friends"></i>
                  <div className="userInfoRestText">
                    <p>Gezinsleden</p>
                    {familie.map(lid => (
                      <h6>{lid.achternaam} {lid.voornaam}</h6>
                    ))}
                  </div>
                </div>
              )}
              <div className="userInfoRest" style={{width: "100%"}}>
                <i className="fas fa-money-bill-alt"></i>
                <div className="userInfoRestText">
                  <p>Betalingen</p>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Datum</th>
                        <th>Naam</th>
                        <th>Omschrijving</th>
                        <th>Status</th>
                        <th>Betaalmethode</th>
                        <th>Betaald op</th>
                      </tr>
                    </thead>
                    <tbody>
                    {bestellingen.map(bestelling => {

                      return(
                        <tr key={bestelling.id}>
                          <td>{bestelling.aanmaakdatum}</td>
                          <td>{bestelling.naam}</td>
                          <td>{bestelling.omschrijving}</td>
                          <td>{bestelling.status}</td>
                          <td>{bestelling.betaalmethode}</td>
                          <td>{bestelling.betaaldOp}</td>
                        </tr>
                      )
                    })}
                    </tbody>
                  </Table>
                </div>
              </div>

            <div className="lijstKnopUserInfo">
              {!!user.ouderuid ? (
                <div className="knopUserInfo" onClick={() => {this.emailVerifieren(user.ouderuid)}}>
                  <div className="knopUserInfoText">
                    <i className="fa-solid fa-envelope"></i>
                    <p>Emailadres ouder verifiëren</p>
                  </div>
                </div>
              ) : (
                <div className="knopUserInfo" onClick={() => {this.emailVerifieren(user.uid)}}>
                  <div className="knopUserInfoText">
                    <i className="fa-solid fa-envelope"></i>
                    <p>Emailadres verifiëren</p>
                  </div>
                </div>
              )}
              <div className="knopUserInfo" onClick={() => {this.SendEmail(user.email)}}>
                <div className="knopUserInfoText">
                  <i className="far fa-paper-plane"></i>
                  <p>Email sturen</p>
                </div>
              </div>
                <div className="knopUserInfo" onClick={() => {this.OpenAanpassen()}}>
                  <div className="knopUserInfoText">
                    <i className="fas fa-user-edit"></i>
                    <p>Gegevens aanpassen</p>
                  </div>
                </div>
              {!ouder &&
                <div className="knopUserInfo" onClick={() => {this.ouderMaken(this.state.user,user.username, user.uid)}}>
                  <div className="knopUserInfoText">
                    <i className="fas fa-users"></i>
                    <p>Ouder maken</p>
                  </div>
                </div>
               }
               {!!ouder &&
                 <div className="knopUserInfo" onClick={() => {this.ouderVerwijderen(this.state.user,user.username, user.uid)}}>
                   <div className="knopUserInfoText">
                     <i className="fas fa-users-slash"></i>
                     <p>Geen ouder maken</p>
                   </div>
                 </div>
                }
                {!trainer &&
                  <div className="knopUserInfo" onClick={() => {this.trainerMaken(this.state.user,user.username, user.uid)}}>
                    <div className="knopUserInfoText">
                      <i className="fas fa-clipboard-list"></i>
                      <p>Trainer maken</p>
                    </div>
                  </div>
                 }
                 {!!trainer &&
                   <div className="knopUserInfo" onClick={() => {this.trainerVerwijderen(this.state.user,user.username, user.uid)}}>
                     <div className="knopUserInfoText">
                       <i className="fas fa-clipboard-list"></i>
                       <p>Geen trainer maken</p>
                     </div>
                   </div>
                  }
              {!bestuur &&
                <div className="knopUserInfo" onClick={() => {this.bestuurMaken(this.state.user,user.username, user.uid)}}>
                  <div className="knopUserInfoText">
                    <i className="fas fa-user-tie"></i>
                    <p>Bestuur maken</p>
                  </div>
                </div>
               }
               {!!bestuur &&
                 <div className="knopUserInfo" onClick={() => {this.bestuurVerwijderen(this.state.user,user.username, user.uid)}}>
                   <div className="knopUserInfoText">
                     <i className="fas fa-user-tie"></i>
                     <p>Verwijder bestuurfunctie</p>
                   </div>
                 </div>
                }
                <GegevensAanpassen user={this.state.user} close={() => this.CloseAanpassen()} display={this.state.displayAanpassen} aanpassen={() => this.aanpassen()}/>
            </div>
          </div>
        )}
      </div>
    );
      }
    }

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const UserList = withFirebase(UserListBase);
const UserItem = withFirebase(UserItemBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase,
)(Ledenlijst);
