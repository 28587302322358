import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import Card from 'react-bootstrap/Card';

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  onSubmit = event => {
    const { passwordOne } = this.state;
    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { passwordOne, passwordTwo, error } = this.state;
    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';
    return (
      <form className="formWachtwoordChange" onSubmit={this.onSubmit}>
          <Card border="light" id="accountCard" style={{width: "100%"}}>
            <br/>
            <h3>Wachtwoord aanpassen</h3>
            <Card.Body>
              <Form.Control
                name="passwordOne"
                value={passwordOne}
                onChange={this.onChange}
                type="password"
                placeholder="Nieuw wachtwoord"
              />
            <br />
              <Form.Control
                name="passwordTwo"
                value={passwordTwo}
                onChange={this.onChange}
                type="password"
                placeholder="Bevestig nieuw wachtwoord"
              />
            <div>
              <br />
              <Button variant="primary" disabled={isInvalid} type="submit">
                Wachtwoord aanpassen
              </Button>
            </div>
              {error && <p>{error.message}</p>}
            </Card.Body>
          </Card>
      </form>
    );
  }
}
export default withFirebase(PasswordChangeForm);
