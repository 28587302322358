import React from 'react';
import './dienst.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import { withFirebase } from '../../Firebase';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../../session';
import * as ROLES from '../../../constants/roles';

import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";


class DienstBase extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      loading: false,
      ploegDienst: [],
      file: "",
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.firebase.ploegDienst().on('value', snapshot => {
      const agendaObject = snapshot.val();
      if (agendaObject) {
        const agendaList = Object.keys(agendaObject).map(key => ({
          ...agendaObject[key],
          uid: key,
        }));
        this.setState({
          ploegDienst: agendaList,
          loading: false
        });
      } else {
        this.setState({ ploegDienst: null, loading: false });
      }
    });
  }

  componentWillUnmount() {
    this.props.firebase.ploegDienst().off();
  }

  handleClick(e) {
    this.refs.fileUploader.click();
  }

  filePathset(e) {
    e.stopPropagation();
    e.preventDefault();
    var file = e.target.files[0];
    console.log(file);
    this.setState({ file });

    console.log(this.state.file);
  }

  readFile() {
    if(this.state.file === "") {
      alert("Je moet eerst een bestand kiezen!")
    } else {
      var f = this.state.file;

      const reader = new FileReader();
      reader.onload = (evt) => {
        // evt = on_file_select event
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        /* Update state */
        var dataJson = this.convertToJson(data);
        var json = JSON.parse(dataJson)
        this.props.firebase.ploegDienst().set(json)
        console.log(json)
        alert("Aangepast!")
        this.setState({ file: ""});
      };
      reader.readAsBinaryString(f);
    }
  }

  convertToJson(csv) {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  }

  render() {
    const {ploegDienst} = this.state;
    const headers = [
      ["datum"],["ploeg"],["startuur"],["einduur"],["terrein"]
    ];

    const csvData = [];

    ploegDienst.forEach((item, i) => {
      var dienst = [
        item.datum, item.ploeg, item.startuur, item.einduur, item.terrein
      ]
      csvData.push(dienst)
    });
    return (
      <div className="sectionDienst">
        <div>
          <h2>Ploegen van Dienst</h2>
          <br/>
          <br/>
            <CSVLink headers={headers} data={csvData}  separator={";"} filename={"PloegenVanDienst.csv"}>
              <Button variant="danger" size="sm" active>Download huidige dienstregeling</Button>
              <br/><br/>
            </CSVLink>
            <br/>
            <p>Via bovenstaande knop kan je de huidige dienstregeling downloaden naar excel.</p>
            <p>Wanneer je de excel hebt aangepast, kan je deze terug uploaden via onderstaande knop.</p>
            <p style={{color: "red"}}>PAS OP: van de eerste rij afblijven!</p>
            <br/>
            <div style={{display: "grid", width:"500px", margin: "auto"}}>
              <input
                type="file"
                id="file"
                ref="fileUploader"
                onChange={this.filePathset.bind(this)}
              />
            <br/>
              <Button
                variant="success"
                onClick={() => {
                  this.readFile();
                }}
              >
                Uploaden naar server
              </Button>
            </div>
        </div>
      </div>
    );
  }
}

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN] && !!authUser.roles[ROLES.WEBSITE];

const Dienst = withFirebase(DienstBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase,
)(Dienst);
