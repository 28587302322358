import React from 'react';
import background from './img/Spagh_banner.png';
import { withFirebase } from '../Firebase';
import './Activiteit.css';

const Spaghettidag = () => (
      <div>
        <Activiteitpagina />
      </div>
)

class ActiviteitBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      activiteit: []
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ loading: true });
    this.props.firebase.activiteiten().on('value', snapshot => {
      const activiteitObject = snapshot.val();
      if (activiteitObject) {
        const activiteitList = Object.keys(activiteitObject).map(key => ({
          ...activiteitObject[key],
          uid: key,
        }));
        this.setState({
          activiteit: activiteitList[0],
          loading: false
        });
      } else {
        this.setState({ activiteit: null, loading: false });
      }
    });
  }
  componentWillUnmount() {
    this.props.firebase.activiteiten().off();
  }

  render() {
    const { activiteit, loading } = this.state;
    var backgroundImage = "url('"+background+"'"
    return (
      <div>
        <div className="activiteitpagina">
            <div className="bg-activiteit" style={{backgroundImage: backgroundImage}}></div>
            <div className="white-bg"></div>
            <div className="activiteit-text">
              {loading && <div>Laden ...</div>}
              <div key={activiteit.uid}>
                <h1 className="activiteit-titel">{activiteit.naam}</h1>
                <div className="onderlijn"></div>
                <h3 className="activiteit-datum">{activiteit.datum}</h3>
                <h5>{activiteit.plaats}</h5>
                <div className="activiteit-inputtekst">{activiteit.text}</div>
                {activiteit.prijs ? (
                  <h4>Prijs: {activiteit.prijs}</h4>
                ) : (
                  <div></div>
                )}
                <div className="divider2 div-transparent2"></div>
                {activiteit.urlaffiche ? (
                  <div>
                    <img className="activiteitaffiche" src={activiteit.urlaffiche} alt={activiteit.naam+"-affiche"}></img>
                    <div className="divider2 div-transparent2"></div>
                  </div>
              ) : (
                  <div></div>
                )}
                <br/>
                {activiteit.google ? (
                  <a id="googleformlink" href={activiteit.google} target="_black">Bestelling plaatsen</a>
                  ) : (
                      <div></div>
                    )}
              </div>
            </div>
        </div>
      </div>
    );
  }
}

const Activiteitpagina = withFirebase(ActiviteitBase);

export default Spaghettidag
