import React from 'react';
import './style.css';
import { withAuthorization, withEmailVerification} from '../../session';
import { compose } from 'recompose';

import Carousel from 'react-bootstrap/Carousel'

import {ButtonOne} from '../../../constants/button';

class Welkom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      welkom: false,
      uid:""
    };
  }
  componentDidMount() {
    var user = JSON.parse(localStorage.getItem('authUser'))
    this.props.firebase.user(user.uid).on('value', snapshot => {
      const userObject = snapshot.val();
      if (userObject) {
        this.setState({
          user: userObject,
          welkom: userObject.welkom,
          uid: user.uid,
        });
      } else {
        this.setState({ user: null, loading: false });
      }

      });
  }

  welkomWeg(user, uid) {
      const { ...userSnapshot } = user;
      this.props.firebase.user(uid).set({
        ...userSnapshot,
        welkom: false,
      });
  }

  welkomWegSkip(user, uid) {
    var confirm = window.confirm("Ben je zeker dat je de uitleg wilt skippen?")

    if(confirm){
      const { ...userSnapshot } = user;
      this.props.firebase.user(uid).set({
        ...userSnapshot,
        welkom: false,
      });
    }
  }

  render() {
    var { user, welkom } = this.state;
    var display = "none"
    if(welkom === true) {
      display="block"
    }

    return (
      <div style={{display: display}}>
        <div id="welkomkaart-bg" ></div>
        <div id="welkomkaart">
          <i className="fas fa-times-circle" id="skipWelkom" onClick={() => {this.welkomWegSkip(this.state.user, this.state.uid)}}></i>
          <Carousel  prevLabel={"Vorige"} nextLabel={"Volgende"} nextIcon={<i className="fas fa-caret-right carousel-control"></i>} prevIcon={<i className="fas fa-caret-left carousel-control"></i>}>
            <Carousel.Item>
              <div className="textCarousel">
                <div>
                  <h2>Hej {user.voornaam}, welkom bij VOC Schriek!</h2>
                  <br/>
                  <p>
                    Hierbij even wat uitleg over ons  ledenportaal.<br/>
                  </p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="textCarousel">
                <div>
                  <i className="fas fa-house-user"></i>
                  <h3>Dashboard</h3>
                  <p>Hier krijg je kort al een overzicht van volgende items:</p>
                  <ul>
                    <li>Je persoonlijke meldingen</li>
                    <li>Het laatste clubnieuws</li>
                    <li>De volgende clubactiviteit</li>
                    <li>De clubchat om contact te zoeken met andere leden</li>
                  </ul>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="textCarousel">
                <div>
                  <i className="fas fa-user-alt"></i>
                  <h3>Account</h3>
                  <p>Hier kan je al je gegevens nakijken en updaten.<br />Via deze pagina kan je ook uzelf en/of gezinsleden <strong>inschrijven</strong> voor het nieuwe seizoen.</p>
                </div>
              </div>
          </Carousel.Item>
            <Carousel.Item>
              <div className="textCarousel">
                <div>
                  <i className="fas fa-info"></i>
                  <h3>Clubinfo</h3>
                    <p>Hier kan je interne info vinden zoals bijvoorbeeld:</p>
                    <ul>
                      <li>Whatsapp groepen</li>
                      <li>Verzekeringspapieren bij blessure</li>
                      <li>Hoe moet je markeren</li>
                      <li>Wat dien je te doen als ploeg van dienst</li>
                    </ul>
                  </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="textCarousel">
                <div>
                  <i className="far fa-newspaper"></i>
                  <h3>Clubnieuws</h3>
                    <p>De pagina waar je al het intern clubnieuws kan vinden</p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="textCarousel">
                <div>
                  <i className="fas fa-calendar-alt"></i>
                  <h3>Clubagenda</h3>
                  <p>Op deze pagina kan vindt je zowel de algemene clubagenda, als de agenda i.v.m. ploegen van dienst</p>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="textCarousel">
                <div>
                  <i className="fas fa-question-circle"></i>
                  <h3>Vraag stellen</h3>
                  <p>Via deze pagina kan je een vraag stellen aan het bestuur</p>
                  <br/><br/>
                  <ButtonOne variant="primary" onClick={() => {this.welkomWeg(this.state.user, this.state.uid)}} text="Begrepen!" >Begrepen!</ButtonOne>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
      )
  }
}

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(Welkom);
