import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import Card from 'react-bootstrap/Card';

const INITIAL_STATE = {
  emaileen: '',
  emailtwee: '',
  error: null,
};

class EmailVeranderenForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  onSubmit = event => {
    const { emaileen } = this.state;
    this.props.firebase
      .doEmailUpdate(emaileen)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { emaileen, emailtwee, error } = this.state;
    const isInvalid =
      emaileen !== emailtwee || emaileen === '';
    return (
      <form className="formWachtwoordChange" onSubmit={this.onSubmit}>
          <Card border="light" id="accountCard" style={{width: "100%"}}>
            <br/>
            <h3>Emailadres aanpassen</h3>
            <br />
            <p>Huidig emailadres: {this.props.email}</p>
            <Card.Body>
              <Form.Control
                type="email"
                name="emaileen"
                value={emaileen}
                onChange={this.onChange}
                placeholder="Nieuw Emailadres"
              />
            <br />
              <Form.Control
                name="emailtwee"
                value={emailtwee}
                onChange={this.onChange}
                type="email"
                placeholder="Bevestig Nieuw Emailadres"
              />
            <div>
              <br/>
            <Button variant="primary" disabled={isInvalid} type="submit">
                Emailadres aanpassen
              </Button>
            </div>
              {error && <p>{error.message}</p>}
            </Card.Body>
          </Card>
      </form>
    );
  }
}
export default withFirebase(EmailVeranderenForm);
