import React from 'react';
import "./style.css";
import Button from 'react-bootstrap/Button';

import { Table } from 'react-bootstrap';

import AddTodo from './AddTodo.js';

import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withEmailVerification } from '../../session';

class Todo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      todo:this.props.todo,
      displayAdd: "none",
    };
  }

  open() {
    this.setState({
      displayAdd: "grid"
    });
  }

  close = () => {
    this.setState({
      displayAdd: "none"
    });
  }

  todoDone(todo) {
    var gedaan = window.confirm(`Ben je zeker dat deze todo is gebeurd? (${todo.omschrijving})`)
    if (gedaan) {
      this.props.firebase.todoGedaan().push({
        omschrijving: todo.omschrijving,
        naam: todo.naam,
        deadline: todo.deadline,
        gedaanOp: this.props.firebase.serverValue.TIMESTAMP,
      });
      this.props.firebase.todoItem(todo.uid).remove();
      alert("In orde!")
    }
  }

  render() {
    var {displayAdd} = this.state;
    var todo = this.props.todo
    if (todo === undefined) {
      todo = [];
    }
    todo.sort(function(a, b) {
        var c = new Date(a.deadline);
        var d = new Date(b.deadline);
        return c-d;
    });

    var geenTodoDisplay = false;
    if (todo.length === 0) {
      geenTodoDisplay = true;
    }

    return (
      <div>
        <Table responsive id="todoTable">
          <thead>
            <tr>
              <th>Omschrijving</th>
              <th>Naam</th>
              <th>Deadline</th>
              <th></th>
            </tr>
          </thead>

          {!!geenTodoDisplay ? (
            <tbody>
                <tr>
                  <td colSpan="4" style={{textAlign:"center", padding:"50px 0"}}>Momenteel geen todokes. Hopla!</td>
                </tr>
            </tbody>
          ) : (
          <tbody>
            {todo.map(item => {
              var jaar = item.deadline.substr(0,4);
              var maand = item.deadline.substr(5,2);
              var dag = item.deadline.substr(8,2);
              var datum = dag+"/"+maand+"/"+jaar;

              var vandaag = new Date()
              var toekomst = new Date(vandaag.setDate(vandaag.getDate() + 14))
              var deadline = new Date(item.deadline)
              var background = ""
              var color = ""
              var weight = ""
              if(deadline < toekomst) {
                background = "#e74c3c";
                color = "white";
                weight = "bold"
              }
              return(
              <tr key={item.omschrijving} className="todoke" style={{backgroundColor: background, color: color, fontWeight: weight}}>
                <td>{item.omschrijving}</td>
                <td>{item.naam}</td>
                <td>{datum}</td>
                <td className="done-button" onClick={()=> this.todoDone(item)}><i className="fas fa-check-circle"></i></td>
              </tr>
            )})}
          </tbody>
        )}
        </Table>
        <Button variant="succes" id="todo-button" onClick={()=>this.open()}><i className="fas fa-plus"></i></Button>
        <AddTodo displayAdd={displayAdd} close={() => this.close()} bestuur={this.props.bestuur}/>
      </div>
    );
  }
}


export default compose(
  withEmailVerification,
  withFirebase,
)(Todo);
