import React from 'react';
import { Ploeg } from './Ploeg';
import img from './img/MU13.JPG';

import { withFirebase } from '../Firebase';

export class MeisjesU13Base extends React.Component {
  constructor() {
    super()
    this.state = {
      ploeg: 6,
    }
  }


  render() {

    return (
      <div>
        <Ploeg
          ploeg={this.state.ploeg}
          img={img}
        />
      </div>
    )
  }
}

export const MeisjesU13 = withFirebase(MeisjesU13Base);
