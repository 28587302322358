import React from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withAuthorization, withEmailVerification } from '../../session';
import * as ROUTES from '../../../constants/routes';
import * as ROLES from '../../../constants/roles';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import axios from 'axios';
import './style.css';

import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

const INITIAL_STATE = {
  email: "",
  huidigSeizoen: "",
  gekozen: false,
  lidInschrijven: false,
  voornaam: "",
  achternaam: "",
  geslacht:"",
  geboortedatum: "",
  rijksregister: "",
  telefoon: "",
  straatnr: "",
  postcode: "",
  gemeente:"",
  mutualiteit: "",
  extragegevens: "",
  ploeg: "",
  voornaamKind: "",
  achternaamKind: "",
  geslachtKind:"",
  geboortedatumKind: "",
  rijksregisterKind: "",
  telefoonKind: "",
  straatnrKind: "",
  postcodeKind: "",
  gemeenteKind:"",
  mutualiteitKind: "",
  extragegevensKind: "",
  ploegKind: "",
};

class iemandInschrijvenBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      huidigSeizoen: "",
      gekozen: false,
      lidInschrijven: false,
      voornaam: "",
      achternaam: "",
      geslacht:"",
      geboortedatum: "",
      rijksregister: "",
      telefoon: "",
      straatnr: "",
      postcode: "",
      gemeente:"",
      mutualiteit: "",
      extragegevens: "",
      ploeg:"",
      voornaamKind: "",
      achternaamKind: "",
      geslachtKind:"",
      geboortedatumKind: "",
      rijksregisterKind: "",
      telefoonKind: "",
      straatnrKind: "",
      postcodeKind: "",
      gemeenteKind:"",
      mutualiteitKind: "",
      extragegevensKind: "",
      ploegKind: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    var huidigSeizoen = "2022-2023"
    this.props.firebase.huidigSeizoen().on('value', snapshot => {
      huidigSeizoen = snapshot.val()
      this.setState({
        huidigSeizoen
      })
      this.props.firebase.seizoen(huidigSeizoen).on('value', snapshot => {
        var seizoenObject = snapshot.val();
        if (seizoenObject) {
          this.setState({
            ploeg1: seizoenObject.ploeg1,
            ploeg2: seizoenObject.ploeg2,
            ploeg3: seizoenObject.ploeg3,
            ploeg4: seizoenObject.ploeg4,
            ploeg5: seizoenObject.ploeg5,
            ploeg6: seizoenObject.ploeg6,
            ploeg7: seizoenObject.ploeg7,
            ploeg8: seizoenObject.ploeg8,
            ploeg9: seizoenObject.ploeg9,
            ploeg10: seizoenObject.ploeg10,
            ploeg11: seizoenObject.ploeg11,
            ploeg12: seizoenObject.ploeg12,
            loading: false,
          })
      } else {
        this.setState({ inschrijvingOpen: null, loading: false });
      }
    })
    })



    }

  kiezenSpeler() {
    this.setState({
      gekozen: true,
      lidInschrijven: true
    })
  }

  kiezenOuder() {
    this.setState({
      gekozen: true,
      lidInschrijven: false
    })
  }

  annuleren() {
    this.setState({
      gekozen: false,
      lidInschrijven: false
    })
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  aanmaken() {
    var zeker = window.confirm("Zeker van het emailadres? ("+this.state.email+").")

    if(zeker) {
      const username = this.state.achternaam + " " + this.state.voornaam

      var password = this.makeid(10)

      axios.get('https://us-central1-ledenportaal-85bb5.cloudfunctions.net/NieuwLidToegevoegdviaBestuursportaal', {
        params: {
          username: username,
          voornaam: this.state.voornaam,
          achternaam: this.state.achternaam,
          email: this.state.email,
          password: password,
          geslacht: this.state.geslacht,
          geboortedatum: this.state.geboortedatum,
          rijksregister: this.state.rijksregister,
          telefoon: this.state.telefoon,
          straatnr: this.state.straatnr,
          postcode: this.state.postcode,
          gemeente: this.state.gemeente,
          mutualiteit: this.state.mutualiteit,
          extragegevens: this.state.extragegevens,
          ploeg: this.state.ploeg,
          ingeschreven: false,
          isOuder: false,
          isSpelend: true,
        }
      })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.log("Error: "+error)
      })

    }
  }

  aanmakenOuder() {
    var zeker = window.confirm("Zeker van het emailadres? ("+this.state.email+").")

    if(zeker) {
      const username = this.state.achternaam + " " + this.state.voornaam

      var password = this.makeid(10)

      axios.get('https://us-central1-ledenportaal-85bb5.cloudfunctions.net/NieuwOuderToegevoegdviaBestuursportaal', {
        params: {
          password: password,
          email: this.state.email,
          voornaam: this.state.voornaam,
          achternaam: this.state.achternaam,
          telefoon: this.state.telefoon,
          straatnr: this.state.straatnr,
          postcode: this.state.postcode,
          gemeente: this.state.gemeente,
          isOuder: true,
          isSpelend: false,
          voornaamKind: this.state.voornaamKind,
          achternaamKind: this.state.achternaamKind,
          geslachtKind: this.state.geslachtKind,
          geboortedatumKind: this.state.geboortedatumKind,
          rijksregisterKind: this.state.rijksregisterKind,
          telefoonKind: this.state.telefoonKind,
          straatnrKind: this.state.straatnrKind,
          postcodeKind: this.state.postcodeKind,
          gemeenteKind: this.state.gemeenteKind,
          mutualiteitKind: this.state.mutualiteitKind,
          extragegevensKind: this.state.extragegevensKind,
          ploegKind: this.state.ploegKind,
          ingeschreven: false,
        }
      })
      .then((response) => {
        alert(response)
      })
      .catch((error) => {
        console.log("Error: "+error)
      })

    }
  }


  render(){
    const {
      gekozen,
      lidInschrijven
     } = this.state;


    return (
      <div id="adminpagina">
        <br/>
        <h1>Iemand handmatig inschrijven (seizoen: {this.state.huidigSeizoen})</h1>
        <br/>
        {!gekozen ? (
          <div>
            <p>Speler of ouder inschrijven?</p>
            <Button onClick={() => this.kiezenSpeler()}>Speler</Button>
            <Button onClick={() => this.kiezenOuder()}>Ouder + 1 kind</Button>
          </div>
        ) : (
          <div>
            {!!lidInschrijven ? (
              <div>
                <br/><br/>
                <Button onClick={() => this.annuleren()}>Opnieuw beginnen</Button>
                <h2>Lid</h2>
                <br/>
                <Form>
                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formEmail">
                    <Form.Label column sm="3">Email: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="email" placeholder="Email@vocschriek.be"  value={this.state.email} name="email" onChange={this.onChange}/>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formVoornaam">
                    <Form.Label column sm="3">Voornaam: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="Voornaam"  value={this.state.voornaam} name="voornaam" onChange={this.onChange}/>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formNaam">
                    <Form.Label column sm="3">Achternaam: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="Achternaam"  value={this.state.achternaam} name="achternaam" onChange={this.onChange}/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formGeslacht">
                    <Form.Label column sm="3">Geslacht: *</Form.Label>
                    <Col sm="9">
                      <Form.Control as="select" value={this.state.geslacht} name="geslacht" onChange={this.onChange}>
                        <option value="">Kies...</option>
                        <option value="Man">Man</option>
                        <option value="Vrouw">Vrouw</option>
                        <option value="Onzijdig">Genderneutraal</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formDatum">
                    <Form.Label column sm="3">Geboortedatum: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="date" placeholder="01-01-2022"  value={this.state.geboortedatum} name="geboortedatum"  onChange={this.onChange}/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formRijksnr">
                    <Form.Label column sm="3">Rijksregisternr: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="00.00.00-000-00" value={this.state.rijksregister} name="rijksregister"  onChange={this.onChange}/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formTelefoon">
                    <Form.Label column sm="3">Telefoonnr: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="0499/99.99.99" value={this.state.telefoon} name="telefoon"  onChange={this.onChange}/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formStraat">
                    <Form.Label column sm="3">Straat + nr: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="Straat + nr" value={this.state.straatnr} name="straatnr" onChange={this.onChange}/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formPostcode">
                    <Form.Label column sm="3">Postcode: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="number" placeholder="Postcode"  value={this.state.postcode} name="postcode" onChange={this.onChange}/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formGemeente">
                    <Form.Label column sm="3">Gemeente: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="Gemeente"  value={this.state.gemeente} name="gemeente" onChange={this.onChange}/>
                    </Col>
                  </Form.Group>


                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formMut">
                    <Form.Label column sm="3">Mutualiteit:</Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="Mutualiteit" value={this.state.mutualiteit} name="mutualiteit" onChange={this.onChange}/>
                    </Col>
                  </Form.Group>


                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formGegevens">
                    <Form.Label column sm="3">Extra gegevens:</Form.Label>
                    <Col sm="9">
                      <Form.Control as="textarea" rows="5" placeholder="Extra gegevens" value={this.state.extragegevens} name="extragegevens" onChange={this.onChange}/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formPloeg">
                    <Form.Label column sm="3">Ik schrijf in voor: *</Form.Label>
                    <Col sm="9">
                      <Form.Control size="lg" as="select" value={this.state.ploeg} name="ploeg" onChange={this.onChange}>
                        <option value="">Kies ploeg</option>
                        {!!this.state.ploeg1 && (
                          <option value={this.state.ploeg1}>{this.state.ploeg1}</option>
                          )}
                        {!!this.state.ploeg2 && (
                          <option value={this.state.ploeg2}>{this.state.ploeg2}</option>
                        )}
                        {!!this.state.ploeg3 && (
                          <option value={this.state.ploeg3}>{this.state.ploeg3}</option>
                        )}
                        {!!this.state.ploeg4 && (
                          <option value={this.state.ploeg4}>{this.state.ploeg4}</option>
                        )}
                        {!!this.state.ploeg5 && (
                          <option value={this.state.ploeg5}>{this.state.ploeg5}</option>
                        )}
                        {!!this.state.ploeg6 && (
                          <option value={this.state.ploeg6}>{this.state.ploeg6}</option>
                        )}
                        {!!this.state.ploeg7 && (
                          <option value={this.state.ploeg7}>{this.state.ploeg7}</option>
                        )}
                        {!!this.state.ploeg8 && (
                          <option value={this.state.ploeg8}>{this.state.ploeg8}</option>
                        )}
                        {!!this.state.ploeg9 && (
                          <option value={this.state.ploeg9}>{this.state.ploeg9}</option>
                        )}
                        {!!this.state.ploeg10 && (
                          <option value={this.state.ploeg10}>{this.state.ploeg10}</option>
                        )}
                        {!!this.state.ploeg11 && (
                          <option value={this.state.ploeg11}>{this.state.ploeg11}</option>
                        )}
                        {!!this.state.ploeg12 && (
                          <option value={this.state.ploeg12}>{this.state.ploeg12}</option>
                        )}
                          <option value="Niet Speler">Niet Speler</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Button onClick={() => this.aanmaken()}>Aanmaken</Button>
                </Form>
              </div>
            ) : (
              <div>
                <Button onClick={() => this.annuleren()}>Opnieuw beginnen</Button>
                <br/><br/>
                <h2>Ouder</h2>
                <br/>
                <Form>
                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formEmail">
                    <Form.Label column sm="3">Email: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="email" placeholder="Email@vocschriek.be"  value={this.state.email} name="email" onChange={this.onChange}/>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formVoornaam">
                    <Form.Label column sm="3">Voornaam: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="Voornaam"  value={this.state.voornaam} name="voornaam" onChange={this.onChange}/>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formNaam">
                    <Form.Label column sm="3">Achternaam: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="Achternaam"  value={this.state.achternaam} name="achternaam" onChange={this.onChange}/>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formTelefoon">
                    <Form.Label column sm="3">Telefoonnr: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="0499/99.99.99" value={this.state.telefoon} name="telefoon"  onChange={this.onChange}/>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formStraat">
                    <Form.Label column sm="3">Straat + nr: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="Straat + nr" value={this.state.straatnr} name="straatnr" onChange={this.onChange}/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formPostcode">
                    <Form.Label column sm="3">Postcode: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="number" placeholder="Postcode"  value={this.state.postcode} name="postcode" onChange={this.onChange}/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formGemeente">
                    <Form.Label column sm="3">Gemeente: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="Gemeente"  value={this.state.gemeente} name="gemeente" onChange={this.onChange}/>
                    </Col>
                  </Form.Group>
                </Form>
                <br/><br/>
                <h2>Kind</h2>
                <br/>
                <Form>
                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formVoornaam">
                    <Form.Label column sm="3">Voornaam: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="Voornaam"  value={this.state.voornaamKind} name="voornaamKind" onChange={this.onChange}/>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formNaam">
                    <Form.Label column sm="3">Achternaam: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="Achternaam"  value={this.state.achternaamKind} name="achternaamKind" onChange={this.onChange}/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formGeslacht">
                    <Form.Label column sm="3">Geslacht: *</Form.Label>
                    <Col sm="9">
                      <Form.Control as="select" value={this.state.geslachtKind} name="geslachtKind" onChange={this.onChange}>
                        <option value="">Kies...</option>
                        <option value="Man">Man</option>
                        <option value="Vrouw">Vrouw</option>
                        <option value="Onzijdig">Genderneutraal</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formDatum">
                    <Form.Label column sm="3">Geboortedatum: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="date" placeholder="01-01-2022"  value={this.state.geboortedatumKind} name="geboortedatumKind"  onChange={this.onChange}/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formRijksnr">
                    <Form.Label column sm="3">Rijksregisternr: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="00.00.00-000-00" value={this.state.rijksregisterKind} name="rijksregisterKind"  onChange={this.onChange}/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formTelefoon">
                    <Form.Label column sm="3">Telefoonnr: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="0499/99.99.99" value={this.state.telefoonKind} name="telefoonKind"  onChange={this.onChange}/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formStraat">
                    <Form.Label column sm="3">Straat + nr: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="Straat + nr" value={this.state.straatnrKind} name="straatnrKind" onChange={this.onChange}/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formPostcode">
                    <Form.Label column sm="3">Postcode: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="number" placeholder="Postcode"  value={this.state.postcodeKind} name="postcodeKind" onChange={this.onChange}/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formGemeente">
                    <Form.Label column sm="3">Gemeente: *</Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="Gemeente"  value={this.state.gemeenteKind} name="gemeenteKind" onChange={this.onChange}/>
                    </Col>
                  </Form.Group>


                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formMut">
                    <Form.Label column sm="3">Mutualiteit:</Form.Label>
                    <Col sm="9">
                      <Form.Control type="text" placeholder="Mutualiteit" value={this.state.mutualiteitKind} name="mutualiteitKind" onChange={this.onChange}/>
                    </Col>
                  </Form.Group>


                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formGegevens">
                    <Form.Label column sm="3">Extra gegevens:</Form.Label>
                    <Col sm="9">
                      <Form.Control as="textarea" rows="5" placeholder="Extra gegevens" value={this.state.extragegevensKind} name="extragegevensKind" onChange={this.onChange}/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mx-3 mb-3" controlId="formPloeg">
                    <Form.Label column sm="3">Ik schrijf in voor: *</Form.Label>
                    <Col sm="9">
                      <Form.Control size="lg" as="select" value={this.state.ploegKind} name="ploegKind" onChange={this.onChange}>
                        <option value="">Kies ploeg</option>
                        {!!this.state.ploeg1 && (
                          <option value={this.state.ploeg1}>{this.state.ploeg1}</option>
                          )}
                        {!!this.state.ploeg2 && (
                          <option value={this.state.ploeg2}>{this.state.ploeg2}</option>
                        )}
                        {!!this.state.ploeg3 && (
                          <option value={this.state.ploeg3}>{this.state.ploeg3}</option>
                        )}
                        {!!this.state.ploeg4 && (
                          <option value={this.state.ploeg4}>{this.state.ploeg4}</option>
                        )}
                        {!!this.state.ploeg5 && (
                          <option value={this.state.ploeg5}>{this.state.ploeg5}</option>
                        )}
                        {!!this.state.ploeg6 && (
                          <option value={this.state.ploeg6}>{this.state.ploeg6}</option>
                        )}
                        {!!this.state.ploeg7 && (
                          <option value={this.state.ploeg7}>{this.state.ploeg7}</option>
                        )}
                        {!!this.state.ploeg8 && (
                          <option value={this.state.ploeg8}>{this.state.ploeg8}</option>
                        )}
                        {!!this.state.ploeg9 && (
                          <option value={this.state.ploeg9}>{this.state.ploeg9}</option>
                        )}
                        {!!this.state.ploeg10 && (
                          <option value={this.state.ploeg10}>{this.state.ploeg10}</option>
                        )}
                        {!!this.state.ploeg11 && (
                          <option value={this.state.ploeg11}>{this.state.ploeg11}</option>
                        )}
                        {!!this.state.ploeg12 && (
                          <option value={this.state.ploeg12}>{this.state.ploeg12}</option>
                        )}
                          <option value="Niet Speler">Niet Speler</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Button onClick={() => this.aanmakenOuder()}>Aanmaken</Button>
                  </Form>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase,
)(iemandInschrijvenBase);
