import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification} from '../../session';
import { withFirebase } from '../../Firebase';
import axios from 'axios';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

class VraagstellenBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ingediend: false,
      onderwerp: "",
      vraag: "",
      user: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.firebase.user(this.props.user.uid).on('value', snapshot => {
      const userObject = snapshot.val();
      if (userObject) {
        this.setState({
          user: userObject,
          ingediend: false,
          onderwerp: "",
          vraag: "",
        });
      } else {
        var user = JSON.parse(localStorage.getItem('authUser'))

        this.setState({
          user: user,
          ingediend: false,
          onderwerp: "",
          vraag: "",
        });
      }
    });
  };


  onChangeText = event => {
   this.setState({ [event.target.name]: event.target.value });
  };

  indienen() {
    if(this.state.onderwerp === "" || this.state.vraag === "") {
      alert("Gelieve alle velden in te vullen")
    } else {

      this.props.firebase.vragenLeden().push({
        onderwerp: this.state.onderwerp,
        vraag: this.state.vraag,
        user: this.state.user.username ?? "",
        email: this.state.user.email,
        datum: this.props.firebase.serverValue.TIMESTAMP,
      })
      this.setState({
        ingediend: true,
        onderwerp: "",
        vraag: "",
      })
      axios.get('https://us-central1-ledenportaal-85bb5.cloudfunctions.net/emailNieuwevraag', {params: {onderwerp: this.state.onderwerp,  user: this.state.user.username, email: this.state.user.email, vraag: this.state.vraag}})
    }
  }

  render(){
  var {ingediend} = this.state;

  return (
    <div id="vraagstellen">
    {!ingediend ? (
      <div>
        <div style={{width: "50%", margin: "auto", minWidth: "300px"}}><h1>Vraag stellen aan het bestuur</h1></div>
        <div className="onderlijn"></div>
        <Form>
          <br/>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Onderwerp:</Form.Label>
            <Form.Control type="text" placeholder="Onderwerp..." name="onderwerp" value={this.state.onderwerp} onChange={this.onChangeText}/>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Vraag:</Form.Label>
            <Form.Control as="textarea" rows="6" placeholder="Vraag..." name="vraag" value={this.state.vraag} onChange={this.onChangeText}/>
          </Form.Group>
          <Button onClick={() => this.indienen()}>Vraag doorsturen</Button>
        </Form>
      </div>
    ) : (
      <div style={{margin: "auto", textAlign: "center"}}>
        <h2>Vraag doorgestuurd</h2>
        <br/>
        <p>We contacteren je zo snel mogelijk!</p>
      </div>
      )}
    </div>
  );
}}

const condition = authUser => !!authUser;

const Vraagstellen = withFirebase(VraagstellenBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(Vraagstellen);
