import React from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withAuthorization, withEmailVerification } from '../../session';
import * as ROLES from '../../../constants/roles';

import './style.css';

import Paper from '@material-ui/core/Paper';
import {
  Chart,
  BarSeries,
  PieSeries,
  Legend,
  Title,
  ArgumentAxis,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';

import { Animation } from '@devexpress/dx-react-chart';

class StatistiekenBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      seizoen: [],
      huidigSeizoen: ""
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    var huidigSeizoen = ""
    this.props.firebase.huidigSeizoen().on('value', snapshot => {
      huidigSeizoen = snapshot.val();
      this.setState({
        huidigSeizoen: snapshot.val(),
      })
    })

    this.props.firebase.seizoen(huidigSeizoen).on('value', snapshot => {
      this.setState({
        seizoen: snapshot.val(),
        loading: false,
      });
    });

    this.props.firebase.users().on('value', snapshot => {
      const ledenObject = snapshot.val();

      const ledenArray = Object.keys(ledenObject).map(key => ({
        ...ledenObject[key],
        uid: key,
      }));

      this.setState({
        users: ledenArray,
        loading: false,
      });
    });
  }

  componentWillUnmount() {

  }

  render(){
    const { users, seizoen } = this.state;

    var aantalLeden = users.filter(d => {var spelend = d.isSpelend; var ingeschreven = d.ingeschreven;  var ploeg = d.ploeg; var geslacht = d.geslacht;
                              return (spelend && ingeschreven && ploeg !== 'Niet Speler' && geslacht !== "");
                            });

    var manUsers = aantalLeden.filter(d => {var geslacht = d.geslacht;
                             return (geslacht.toLowerCase() === "man");
                            });

    var vrouwUsers = aantalLeden.filter(d => {var geslacht = d.geslacht;
                             return (geslacht.toLowerCase() === "vrouw");
                          });
    var onzijdigUsers = aantalLeden.filter(d => {var geslacht = d.geslacht;
                             return (geslacht.toLowerCase() === "onzijdig");
                          });
    var foutUsers = aantalLeden.filter(d => {var geslacht = d.geslacht;
                             return (geslacht.toLowerCase() === "");
                          });

    var dataGeslacht = [
      { geslacht: `Man (${manUsers.length})`, aantal: manUsers.length},
      { geslacht: `Vrouw (${vrouwUsers.length})`, aantal: vrouwUsers.length },
      { geslacht: `Genderneutraal (${onzijdigUsers.length})`, aantal: onzijdigUsers.length},
    ];

    var vandaag = new Date();

    var UsersTien = aantalLeden.filter(d => {var datum = d.geboortedatum; var spelend = d.isSpelend;

                        var datumGeb = datum;
                        var datumJuist = datumGeb.substring(6,10)+datumGeb.substring(2, 6)+datumGeb.substring(0, 2);
                        var gebdatum = new Date(datumJuist);
                        if (datum) {
                          var leeftijd = vandaag.getFullYear() - gebdatum.getFullYear()
                          return (leeftijd <= 10 && spelend);
                        } else {
                          return false;
                        }
                        });

    var UsersTwintig = aantalLeden.filter(d => {var datum = d.geboortedatum; var spelend = d.isSpelend;

                        var datumGeb = datum;
                        var datumJuist = datumGeb.substring(6,10)+datumGeb.substring(2, 6)+datumGeb.substring(0, 2);
                        var gebdatum = new Date(datumJuist);
                        if (datum) {
                          var leeftijd = vandaag.getFullYear() - gebdatum.getFullYear()
                          return (leeftijd > 10 && leeftijd <= 20 && spelend);
                        } else {
                          return false;
                        }
                        });

    var UsersDertig = aantalLeden.filter(d => {var datum = d.geboortedatum; var spelend = d.isSpelend;
                        var datumGeb = datum;
                        var datumJuist = datumGeb.substring(6,10)+datumGeb.substring(2, 6)+datumGeb.substring(0, 2);
                        var gebdatum = new Date(datumJuist);
                        if (datum) {
                          var leeftijd = vandaag.getFullYear() - gebdatum.getFullYear()
                          return (leeftijd > 20 && leeftijd <= 30 && spelend);
                        } else {
                          return false;
                        }
                        });
    var UsersVeertig = aantalLeden.filter(d => {var datum = d.geboortedatum; var spelend = d.isSpelend;
                        var datumGeb = datum;
                        var datumJuist = datumGeb.substring(6,10)+datumGeb.substring(2, 6)+datumGeb.substring(0, 2);
                        var gebdatum = new Date(datumJuist);
                        if (datum) {
                          var leeftijd = vandaag.getFullYear() - gebdatum.getFullYear()
                          return (leeftijd > 30 && leeftijd <= 40 && spelend);
                        } else {
                          return false;
                        }
                        });
    var UsersVijftig = aantalLeden.filter(d => {var datum = d.geboortedatum; var spelend = d.isSpelend;
                        var datumGeb = datum;
                        var datumJuist = datumGeb.substring(6,10)+datumGeb.substring(2, 6)+datumGeb.substring(0, 2);
                        var gebdatum = new Date(datumJuist);
                        if (datum) {
                          var leeftijd = vandaag.getFullYear() - gebdatum.getFullYear()
                          return (leeftijd > 40 && leeftijd <= 50 && spelend);
                        } else {
                          return false;
                        }
                        });
    var UsersZestig = aantalLeden.filter(d => {var datum = d.geboortedatum; var spelend = d.isSpelend;
                        var datumGeb = datum;
                        var datumJuist = datumGeb.substring(6,10)+datumGeb.substring(2, 6)+datumGeb.substring(0, 2);
                        var gebdatum = new Date(datumJuist);
                        if (datum) {
                          var leeftijd = vandaag.getFullYear() - gebdatum.getFullYear()
                          return (leeftijd > 50 && leeftijd <= 60 && spelend);
                        } else {
                          return false;
                        }
                        });
    var UsersHonderd = aantalLeden.filter(d => {var datum = d.geboortedatum; var spelend = d.isSpelend;
                        var datumGeb = datum;
                        var datumJuist = datumGeb.substring(6,10)+datumGeb.substring(2, 6)+datumGeb.substring(0, 2);
                        var gebdatum = new Date(datumJuist);
                        if (datum) {
                          var leeftijd = vandaag.getFullYear() - gebdatum.getFullYear()
                          return (leeftijd > 60 && leeftijd <= 100 && spelend);
                        } else {
                          return false;
                        }
                        });
    var restHonderd = aantalLeden.filter(d => {var datum = d.geboortedatum; var spelend = d.isSpelend;
                        var datumGeb = datum;
                        var datumJuist = datumGeb.substring(6,10)+datumGeb.substring(2, 6)+datumGeb.substring(0, 2);
                        var gebdatum = new Date(datumJuist);
                        if (datum) {
                          var leeftijd = vandaag.getFullYear() - gebdatum.getFullYear()
                          return !(leeftijd >0 && leeftijd <= 100 && spelend);
                        } else {
                          return false;
                        }
                        });

    const dataLeeftijd = [
      { leeftijd: '60 - 100', aantal: UsersHonderd.length},
      { leeftijd: '51 - 60', aantal: UsersZestig.length},
      { leeftijd: '41 - 50', aantal: UsersVijftig.length},
      { leeftijd: '31 - 40', aantal: UsersVeertig.length},
      { leeftijd: '21 - 30', aantal: UsersDertig.length},
      { leeftijd: '11 - 20', aantal: UsersTwintig.length },
      { leeftijd: '0 - 10', aantal: UsersTien.length},
    ];

    var usersPloeg1 = users.filter(d => {var ploeg = d.ploeg;
                              return (ploeg === seizoen.ploeg1);
                            });
    var usersPloeg2 = users.filter(d => {var ploeg = d.ploeg;
                              return (ploeg === seizoen.ploeg2);
                            });
    var usersPloeg3 = users.filter(d => {var ploeg = d.ploeg;
                              return (ploeg === seizoen.ploeg3);
                            });
    var usersPloeg4 = users.filter(d => {var ploeg = d.ploeg;
                              return (ploeg === seizoen.ploeg4);
                            });
    var usersPloeg5 = users.filter(d => {var ploeg = d.ploeg;
                              return (ploeg === seizoen.ploeg5);
                            });
    var usersPloeg6 = users.filter(d => {var ploeg = d.ploeg;
                              return (ploeg === seizoen.ploeg6);
                            });
    var usersPloeg7 = users.filter(d => {var ploeg = d.ploeg;
                              return (ploeg === seizoen.ploeg7);
                            });
    var usersPloeg8 = users.filter(d => {var ploeg = d.ploeg;
                              return (ploeg === seizoen.ploeg8);
                            });
    var usersPloeg9 = users.filter(d => {var ploeg = d.ploeg;
                              return (ploeg === seizoen.ploeg9);
                            });
    var usersPloeg10 = users.filter(d => {var ploeg = d.ploeg;
                              return (ploeg === seizoen.ploeg10);
                            });
    var usersNietSpeler = users.filter(d => {var ploeg = d.ploeg;
                              return (ploeg === "Niet Speler");
                            });

    var foutPloegen = users.filter(d => {var ploeg = d.ploeg; var ouder = d.isOuder;
                              return (!ouder && ploeg !== seizoen.ploeg1 && ploeg !== seizoen.ploeg2 && ploeg !== seizoen.ploeg3 && ploeg !== seizoen.ploeg4 && ploeg !== seizoen.ploeg5 && ploeg !== seizoen.ploeg6 && ploeg !== seizoen.ploeg7 && ploeg !== seizoen.ploeg8 && ploeg !== seizoen.ploeg9 && ploeg !== seizoen.ploeg10 && ploeg!== seizoen.ploeg11 && ploeg !== seizoen.ploeg12 && ploeg !== "Niet Speler");
                            });


      var dataPloegen = [
        { Ploeg: `Niet Speler`, aantal: usersNietSpeler.length-1},
        { Ploeg: `${seizoen.ploeg10}`, aantal: usersPloeg10.length-1},
        { Ploeg: `${seizoen.ploeg9}`, aantal: usersPloeg9.length-1},
        { Ploeg: `${seizoen.ploeg8}`, aantal: usersPloeg8.length-1},
        { Ploeg: `${seizoen.ploeg7}`, aantal: usersPloeg7.length-1},
        { Ploeg: `${seizoen.ploeg6}`, aantal: usersPloeg6.length-1},
        { Ploeg: `${seizoen.ploeg5}`, aantal: usersPloeg5.length-1},
        { Ploeg: `${seizoen.ploeg4}`, aantal: usersPloeg4.length-1},
        { Ploeg: `${seizoen.ploeg3}`, aantal: usersPloeg3.length-1},
        { Ploeg: `${seizoen.ploeg2}`, aantal: usersPloeg2.length-1},
        { Ploeg: `${seizoen.ploeg1}`, aantal: usersPloeg1.length-1}
      ];

    return (
      <div id="adminpagina">
        <br />

      <h2>Statistieken</h2>
        <div className="grafiek-flex">
          <br />
          <Paper id="grafiek">
            <br />
            <h3>Aantal spelende leden: {aantalLeden.length}</h3>
            <h6>Doel: 120</h6>
            <br />
          </Paper>
          <br />
          <Paper id="grafiek">
              <Chart
                data={dataGeslacht}
              >
                <PieSeries
                  valueField="aantal"
                  argumentField="geslacht"
                />
                <Legend />
                <Title text="Leden per geslacht (excl niet spelende ouders)" />
                <Animation />
              </Chart>
          </Paper>
          {foutUsers.map(user => (
            <p>Foutmelding: {user.username}</p>
          ))}
          <Paper id="grafiek">
              <Chart
                data={dataPloegen}
                rotated
              >
                <BarSeries
                  valueField="aantal"
                  argumentField="Ploeg"
                />
                <ArgumentAxis/>
                <ValueAxis  position="top" showTicks="true" tickSize="1" showLine="false" showGrid="false"/>
                <Title text="Leden per ploeg" />
                <Animation />
              </Chart>
          </Paper>
          {foutPloegen.map(user => (
            <p>Foutmelding: {user.username}</p>
          ))}
          <Paper id="grafiek">
              <Chart
                data={dataLeeftijd}
                rotated
              >
                <BarSeries
                  valueField="aantal"
                  argumentField="leeftijd"
                />
                <ArgumentAxis/>
                <ValueAxis  position="top" showTicks="true" tickSize="1" showLine="false" showGrid="false"/>
                <Title text="Leden per leeftijd (excl niet spelende ouders)" />
                <Animation />
              </Chart>
          </Paper>
          {restHonderd.map(user => (
            <p>Foutmelding: {user.username}</p>
          ))}
        </div>
      </div>
    )
  }
}

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

  const Statistieken = withFirebase(StatistiekenBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase,
)(Statistieken);
