import React from 'react';
import { Ploeg } from './Ploeg';
import img from './img/ju13.jpg';
import { withFirebase } from '../Firebase';

export class JongensU17Base extends React.Component {
  constructor() {
    super()
    this.state = {
      ploeg: 4,
    }
  }

  render() {

    return (
      <div>
        <Ploeg
          ploeg={this.state.ploeg}
          img={img}
        />
      </div>
    )
  }
}

export const JongensU17 = withFirebase(JongensU17Base);
