import React from 'react';
import { Table } from 'react-bootstrap';
import { withFirebase } from '../Firebase';
import "./kalenders.css"
import {datumFirebaseDateNieuws} from '../../functions/DatumFirebase'

class KalenderBase extends React.Component {
  constructor() {
    super()
    this.state = {
      data: []
    }
  }

  componentDidMount() {
    this.props.firebase.algemeneagenda().on('value', snapshot => {
      const agendaObject = snapshot.val();
      if (agendaObject) {
        this.setState({
          data: agendaObject,
        });
      } else {
        this.setState({ data: null, loading: false });
      }

      })
  }

  render() {
    const { data } = this.state
    var algemeneAgendaShow = [];

    data.forEach((item, i) => {
      if(item.omschrijving !== "" && item.extern === true){
        algemeneAgendaShow.push(item)
      }
    });

    var vandaag = new Date()

    return (
      <div className="kalendertabel">
      <Table striped bordered hover size="sm" className="text-black" responsive>
        <thead>
          <tr>
            <th>Datum</th>
            <th>Omschrijving</th>
            <th>Startuur</th>
            <th>Plaats</th>
          </tr>
        </thead>
        <tbody>
        {
          algemeneAgendaShow.map(nieuws => {

            var display = "none"

            var datum = datumFirebaseDateNieuws(nieuws.datum)

            if (datum >= vandaag) {
              display = "tableCell"
            }

            return (
              <tr key={nieuws.omschrijving} style={{display: display}}>
                <td>{nieuws.datum}</td>
                <td>{nieuws.omschrijving}</td>
                <td>{nieuws.startuur}</td>
                <td>{nieuws.plaats}</td>
              </tr>
            )
          })
        }
        </tbody>
      </Table>
    </div>
    )
  }
}

export const Kalender = withFirebase(KalenderBase);
