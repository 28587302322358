import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Switch,
  Route,
  Link
} from "react-router-dom";
import Lottie from 'lottie-react-web'
import animation from '../Lottiefiles/loading.json';
import Nav from 'react-bootstrap/Nav';
import * as ROUTES from '../../constants/routes';

import Ledenpagina from './ledenpagina/Ledenpagina';
import AccountPage from './account/Account';
import Ledeninfo from './ledenInfo/Ledeninfo';
import Ledennieuws from './ledennieuws/Ledennieuws';
import Ledenagenda from './ledenagenda/Ledenagenda';
import Vraagstellen from './vraagstellen/Vraagstellen';
import Aanwezigheden from './aanwezigheden/Aanwezigheden';
import Order from './orders/Order';

import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification, AuthUserContext } from '../session';

const Ledenportaal = () => (
    <div>
       <AuthUserContext.Consumer>
         {authUser =>
            authUser && (
              <div>
                <LedenBase userId={authUser.uid} authUser={authUser}/>
              </div>
            )}
      </AuthUserContext.Consumer>
    </div>
)

class LedenPortaalBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      menuAan: true,
      displayMenu: "grid",
      menuWidth: "100",
      loading: true,
      agenda: [],
      ploegDienst: [],
      laatsteNieuws: [],
      ledennieuws: [],
      huidigSeizoen: ""
    };
  }


  componentDidMount() {
    window.scrollTo(0, 0);

    if(window.innerWidth < 700) {
      this.setState({
        menuAan: false,
        displayMenu: "none",
        menuWidth: "0",
        ledennieuws: [],
      });
    }

    window.addEventListener('resize', this.menuGSM);

    this.props.firebase.user(this.props.userId).on('value', snapshot => {
      const userObject = snapshot.val();
      if (userObject) {

        this.props.firebase.huidigSeizoen().on('value', snapshot => {
          var huidigSeizoen = snapshot.val();

          if(userObject.seizoenen !== undefined && userObject.seizoenen[huidigSeizoen] !== undefined && userObject.seizoenen[huidigSeizoen].ingeschreven === true) {
            this.setState({
              user: userObject,
              huidigSeizoen: huidigSeizoen
            });
          } else if (userObject.isOuder && !userObject.isSpelend && userObject.ingeschreven) {
            userObject.ingeschreven = true
            this.setState({
              user: userObject,
              huidigSeizoen: huidigSeizoen
            });
          } else {
            userObject.ingeschreven = false
            this.setState({
              user: userObject,
              huidigSeizoen: huidigSeizoen
            });
          }


        })

      } else {
        this.setState({ user: null, loading: false });
      }

      });


      this.props.firebase.algemeneagenda().on('value', snapshot => {
        const agendaObject = snapshot.val();

        try {
          const agendaList = Object.keys(agendaObject).map(key => ({
            ...agendaObject[key],
            uid: key,
          }));

          this.setState({
            agenda: agendaList,
            loading: false,
          });
        } catch(error) {
          var agenda = []

            this.setState({
              agenda: agenda,
              loading: false,
            })
          }
    })

    this.props.firebase.ploegDienst().on('value', snapshot => {
      const ploegDienstObject = snapshot.val();
      if (ploegDienstObject) {
        this.setState({
          ploegDienst: ploegDienstObject,
        });
      } else {
        this.setState({ ploegDienst: null, loading: false });
      }

      })

      this.props.firebase.ledennieuws().on('value', snapshot => {
        const usersObject = snapshot.val();

        try {
          const usersList = Object.keys(usersObject).map(key => ({
            ...usersObject[key],
            uid: key,
          }));

          usersList.sort(function(a, b) {
              var c = new Date(a.createdAt);
              var d = new Date(b.createdAt);
              return c-d;
          });

          usersList.reverse()
          var laatsteNieuws = usersList.slice(0,1)
          this.setState({
            ledennieuws: usersList,
            laatsteNieuws: laatsteNieuws,
            loading: false,
          });
        } catch(error) {
          var nieuws = []

            this.setState({
              ledennieuws: nieuws,
              loading: false,
              leeg: true,
            })
          }
        })

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.menuGSM);
  }

  menu() {
    if(this.state.menuAan && window.innerWidth < 700) {
      this.setState({
        menuAan: false,
        displayMenu: "none",
        menuWidth: "0",
      });
    } else {
      this.setState({
        menuAan: true,
        displayMenu: "grid",
        menuWidth: "100",
      });
    }

  }

  menuGSM = () => {
    if(window.innerWidth < 700) {
      this.setState({
        menuAan: false,
        displayMenu: "none",
        menuWidth: "0",
      });
    } else {
      this.setState({
        menuAan: true,
        displayMenu: "grid",
        menuWidth: "100",
      });
    }
  }

  render() {
    var { loading, menuWidth, displayMenu, user, agenda, ploegDienst, ledennieuws } = this.state

    return (
      <div>
        <div id="Ledenportaal">
          <div id="BestuurDashboard-menuIcon" onClick={()=> this.menu()}><i className="fas fa-bars"></i></div>
          <div id="BestuurDashboard-menu" style={{width:`${ menuWidth }%`, display:`${displayMenu}`, transition:"width 2s linear"}}>
            <h3>Leden<br/>portaal</h3>

            <Nav className="flex-column" >
              <Link to={ROUTES.LEDENPORTAAL} onClick={()=> this.menu()}>
                <div id="icon-menu">
                  <i className="fas fa-house-user"></i>
                  <Nav.Item><div className="nav-link">Dashboard</div></Nav.Item>
                </div>
              </Link>
              <Link to={ROUTES.ACCOUNT} onClick={()=> this.menu()}>
                <div id="icon-menu">
                  <i className="fas fa-user"></i>
                  <Nav.Item><div className="nav-link">Account</div></Nav.Item>
                </div>
              </Link>
              <Link to={ROUTES.LEDENINFO} onClick={()=> this.menu()}>
                <div id="icon-menu">
                  <i className="fas fa-info-circle"></i>
                  <Nav.Item><div className="nav-link">Clubinfo</div></Nav.Item>
                </div>
              </Link>
              <Link to={ROUTES.LEDENNIEUWS} onClick={()=> this.menu()}>
                <div id="icon-menu">
                  <i className="fas fa-newspaper"></i>
                  <Nav.Item><div className="nav-link">Clubnieuws</div></Nav.Item>
                </div>
              </Link>
              <Link to={ROUTES.LEDENAGENDA} onClick={()=> this.menu()}>
                <div id="icon-menu">
                  <i className="fas fa-calendar-alt"></i>
                  <Nav.Item><div className="nav-link">Clubagenda</div></Nav.Item>
                </div>
              </Link>
              <Link to={ROUTES.VRAAGSTELLEN} onClick={()=> this.menu()}>
                <div id="icon-menu">
                  <i className="fas fa-question-circle"></i>
                  <Nav.Item><div className="nav-link">Vraag stellen</div></Nav.Item>
                </div>
              </Link>
              <a href="https://vocschriek.shop4clubs.eu/" target="_blank" rel="noopener noreferrer" onClick={()=> this.menu()}>
                  <div id="icon-menu">
                    <i className="fas fa-shopping-cart"></i>
                    <Nav.Item><div className="nav-link">Webshop clubkledij</div></Nav.Item>
                  </div>
              </a>
            </Nav>

          </div>
          <div>
            {!!loading ? (
              <Lottie
                options={{
                  animationData: animation,
                }}
                width={"250px"}
                heigth={"250px"}
              />
            ) : (
              <Switch>
                <Route exact path={ROUTES.LEDENPORTAAL}>
                  <Ledenpagina user={user} huidigSeizoen={this.state.huidigSeizoen} laatsteNieuws={this.state.laatsteNieuws} agenda={agenda} ploegDienst={ploegDienst}/>
                </Route>
                <Route path={ROUTES.ACCOUNT}>
                  <div id="overflow-ledenpagina">
                    <AccountPage />
                  </div>
                </Route>
                <Route path={ROUTES.LEDENINFO}>
                  <div id="overflow-ledenpagina">
                    <Ledeninfo />
                  </div>
                </Route>
                <Route path={ROUTES.LEDENNIEUWS}>
                  <div id="overflow-ledenpagina">
                    <Ledennieuws ledennieuws={ledennieuws}/>
                  </div>
                </Route>
                <Route path={ROUTES.LEDENAGENDA}>
                  <div id="overflow-ledenpagina">
                    <Ledenagenda />
                  </div>
                </Route>
                <Route path={ROUTES.VRAAGSTELLEN}>
                  <Vraagstellen user={this.state.user}/>
                </Route>
                <Route path={ROUTES.AANWEZIGHEDEN}>
                  <Aanwezigheden user={this.state.user}/>
                </Route>
                <Route exact path={ROUTES.ORDERS_DETAILS}>
                  <Order />
                </Route>
              </Switch>
            )}
          </div>
        </div>
      </div>
    );
  }
  }

const condition = authUser =>
  authUser;

const LedenBase = withFirebase(LedenPortaalBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase,
)(Ledenportaal);
