import React from 'react';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import './style.css';

import Lottie from 'lottie-react-web'
import mail from '../Lottiefiles/mail-verification.json';

import {ButtonOne} from '../../constants/button';

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
    super(props);
    this.state = { isSent: false };
    }

    componentDidMount() {
      window.scrollTo(0, 0);
    }

    onSendEmailVerification = () => {
    this.props.firebase
      .doSendEmailVerification()
      .then(() => this.setState({ isSent: true }));
    };

    render() {
      return (
          <AuthUserContext.Consumer>
            {authUser =>
              needsEmailVerification(authUser) ? (
                <div id="verificatie">
                  <div>
                    {this.state.isSent ? (
                      <div>
                        <Lottie
                          options={{
                            animationData: mail,
                            loop:false,
                          }}
                          width={"80%"}
                          heigth={"auto"}
                        />
                      <br/><br/>
                        <h5>
                          Email voor verificatie werd opnieuw verstuurd: <br/>Kijk uw mailbox of spamfolder na voor een confirmatie email.<br/><br/>
                        </h5>
                      </div>
                    ) : (
                      <div>
                        <Lottie
                          options={{
                            animationData: mail,
                          }}
                          width={"80%"}
                          heigth={"auto"}
                        />
                      <br/><br/>
                        <h4>Je moet eerst je emailadres verifiëren.</h4><h5><br/> Kijk zeker uw mailbox of spamfolder na voor een confirmatie email.</h5>
                        <p><br />Of zend een nieuwe confirmatie email:</p>
                      </div>
                   )}

                    <ButtonOne
                      variant="primary"
                      type="button"
                      onClick={this.onSendEmailVerification}
                      disabled={this.state.isSent}
                      text="Stuur confirmatie email opnieuw"
                    >
                    </ButtonOne>
                    <br/><br/>
                     <p>Na confirmatie kan je deze pagina refreshen.</p>

                    <div>
                      <br />
                      <p>Blijf je problemen hebben?<br/>Stuur een mailtje naar onze webmaster: webmaster@vocschriek.be</p>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              ) : (
                <Component {...this.props} />
              )
            }
          </AuthUserContext.Consumer>
      );
    }
  }
  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
