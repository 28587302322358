import Button from 'react-bootstrap/Button';
import React from 'react';

import "./style.css"

export class ButtonOne extends React.Component {
  render() {
    var FontSize = "17px"
    if (!!this.props.small) {
      FontSize = "13px"
    }
    var backgroundColor = "#2c3e50";
    var color = "white";

    if (!!this.props.light) {
      backgroundColor = "#ecf0f1";
      color = "#2c3e50";
    }
    return<Button className="ButtonOne" onClick={this.props.onClick} style={{fontSize:FontSize, backgroundColor: backgroundColor, color: color}}>{this.props.text}</Button>
  }
}
