import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as ROUTES from '../../../constants/routes';
import axios from 'axios';
import { LinkContainer } from "react-router-bootstrap";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Lottie from 'lottie-react-web'
import animation from '../../Lottiefiles/loading.json';
import wakado from '../../afbeeldingen/wakado.jpg';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../session';

class InschrijvingVoltooid extends React.Component {
  render(){
    return(
      <div style={{display: this.props.voltooidDisplay}}>
        <div className="backgroundVoltooid"></div>
        <div className="componentVoltooid">
          <h3>Yes, {this.props.voornaam} is ingeschreven!</h3>
          <br/>
          <h6>{this.props.voornaam} is ingeschreven voor volgende ploeg:</h6>
          <h4>{this.props.ploeg}</h4>

          {this.props.eersteKeer && (
            <div>
              <br/>
              <h4>OPGELET: aangezien het hier gaat om een eerste inschrijving, vergeet {this.props.voornaam} ook niet in te schrijven bij Volley Vlaanderen (met een mooie foto)!</h4>
              <p>Dan komt de verzekering en licentie zo snel als mogelijk in orde.</p>
              <p>Link naar Volley Vlaanderen: <a href="https://www.volleyadmin2.be/external/membersubscription/11092/">https://www.volleyadmin2.be/external/membersubscription/11092/</a></p>
            </div>
          )}
          <br/>
          <h6>Er is een e-mail onderweg met de nodige informatie i.v.m. het lidgeld (bekijk zeker de SPAM folder!)</h6>
          <br/>
          <h6>Maar voor de zekerheid, hier al even de nodige info:</h6>
          <br/>
          <h6>Het lidgeld bedraagt € {this.props.lidgeld}</h6>
          <h6>Rekeningnummer: BE73 7332 3611 0760</h6>
          <h6>Mededeling: Lidmaatschap VOC Schriek {this.props.voornaam} {this.props.achternaam}</h6>
          <br/>
          <LinkContainer to={ROUTES.LEDENPORTAAL}>
            <Button variant="primary" size="lg" active>
              Komt in orde!
            </Button>
          </LinkContainer>
        </div>
      </div>
    )
  }
}

class HerinschrijvenGezinBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      aangepast: false,
      huidigSeizoen: "",
      user: "",
      userid: "",
      achternaam:"",
      geboortedatum:"",
      gemeente:"",
      ploeg:"",
      postcode:"",
      rijksregister:"",
      straatnr:"",
      telefoon:"",
      voornaam:"",
      roles:[],
      username:"",
      email:"",
      familie: [],
      gezinslid: "",
      mutualiteit:"",
      extragegevens:"",
      geslacht: "",
      activiteit1:"",
      activiteit2:"",
      activiteit3:"",
      activiteit4:"",
      activiteit5:"",
      activiteit6:"",
      activiteit7:"",
      datumActiviteit1:"",
      datumActiviteit2:"",
      datumActiviteit3:"",
      datumActiviteit4:"",
      datumActiviteit5:"",
      datumActiviteit6:"",
      datumActiviteit7:"",
      helpenActiviteit1:"",
      helpenActiviteit2:"",
      helpenActiviteit3:"",
      helpenActiviteit4:"",
      helpenActiviteit5:"",
      helpenActiviteit6:"",
      helpenActiviteit7:"",
      lidgeldNietSpeler: "",
      lidgeld: "",
      voltooidDisplay: "none",
      bancontact: false,
      overschrijving: false,
      loadingBC: false,
      ...props.location.state,
      foto: "",
      image1:"",
      progress1: 0,
      fotoEdit: false,
      liduid: ""
    };

  }
  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({ loading: true });
    var user = JSON.parse(localStorage.getItem('authUser'))
    this.setState({userid: user.uid})
    this.props.firebase.user(user.uid).on('value', snapshot => {
      const userObject = snapshot.val();
      if (userObject) {
        this.setState({
          user: userObject,
          familie: userObject.familie,
          loading: false
        });
      } else {
        this.setState({
          user: null,
          voornaam: null,
          achternaam: null,
          geboortedatum: null,
          rijksregister: null,
          telefoon: null,
          mutualiteit: null,
          straatnr: null,
          postcode: null,
          gemeente: null,
          extragegevens: null,
          ploeg: "",
          familie: [],
          geslacht: "",
          loading: false,
          ploeg1: "",
          ploeg2: "",
          ploeg3: "",
          ploeg4: "",
          ploeg5: "",
          ploeg6: "",
          ploeg7: "",
          ploeg8: "",
          ploeg9: "",
          ploeg10: "",
          ploeg11: "",
          ploeg12: "",
          lidgeldPloeg1: "",
          lidgeldPloeg2: "",
          lidgeldPloeg3: "",
          lidgeldPloeg4: "",
          lidgeldPloeg5: "",
          lidgeldPloeg6: "",
          lidgeldPloeg7: "",
          lidgeldPloeg8: "",
          lidgeldPloeg9: "",
          lidgeldPloeg10: "",
          lidgeldPloeg11: "",
          lidgeldPloeg12: "",
          lidgeldNietSpeler: "",
         });
      }
    });

    var huidigSeizoen = "2022-2023"
    this.props.firebase.huidigSeizoen().on('value', snapshot => {
      huidigSeizoen = snapshot.val()
      this.setState({
        huidigSeizoen
      })
    })

    this.props.firebase.seizoen(huidigSeizoen).on('value', snapshot => {
      const seizoenObject = snapshot.val();
      if (seizoenObject) {
        this.setState({
          activiteit1: seizoenObject.activiteit1,
          activiteit2: seizoenObject.activiteit2,
          activiteit3: seizoenObject.activiteit3,
          activiteit4: seizoenObject.activiteit4,
          activiteit5: seizoenObject.activiteit5,
          activiteit6: seizoenObject.activiteit6,
          activiteit7: seizoenObject.activiteit7,
          datumActiviteit1:seizoenObject.datumActiviteit1,
          datumActiviteit2:seizoenObject.datumActiviteit2,
          datumActiviteit3:seizoenObject.datumActiviteit3,
          datumActiviteit4:seizoenObject.datumActiviteit4,
          datumActiviteit5:seizoenObject.datumActiviteit5,
          datumActiviteit6:seizoenObject.datumActiviteit6,
          datumActiviteit7:seizoenObject.datumActiviteit7,
          ploeg1: seizoenObject.ploeg1,
          ploeg2: seizoenObject.ploeg2,
          ploeg3: seizoenObject.ploeg3,
          ploeg4: seizoenObject.ploeg4,
          ploeg5: seizoenObject.ploeg5,
          ploeg6: seizoenObject.ploeg6,
          ploeg7: seizoenObject.ploeg7,
          ploeg8: seizoenObject.ploeg8,
          ploeg9: seizoenObject.ploeg9,
          ploeg10: seizoenObject.ploeg10,
          ploeg11: seizoenObject.ploeg11,
          ploeg12: seizoenObject.ploeg12,
          lidgeldNietSpeler: seizoenObject.lidgeldNietSpeler,
          lidgeldPloeg1: seizoenObject.lidgeldPloeg1,
          lidgeldPloeg2: seizoenObject.lidgeldPloeg2,
          lidgeldPloeg3: seizoenObject.lidgeldPloeg3,
          lidgeldPloeg4: seizoenObject.lidgeldPloeg4,
          lidgeldPloeg5: seizoenObject.lidgeldPloeg5,
          lidgeldPloeg6: seizoenObject.lidgeldPloeg6,
          lidgeldPloeg7: seizoenObject.lidgeldPloeg7,
          lidgeldPloeg8: seizoenObject.lidgeldPloeg8,
          lidgeldPloeg9: seizoenObject.lidgeldPloeg9,
          lidgeldPloeg10: seizoenObject.lidgeldPloeg10,
          lidgeldPloeg11: seizoenObject.lidgeldPloeg11,
          lidgeldPloeg12: seizoenObject.lidgeldPloeg12,
          loading: false,
        })
    } else {
      this.setState({ inschrijvingOpen: null, loading: false });
    }
  })
  }


  onChangeVoornaam = event => {
    this.setState({ voornaam: event.target.value });
  };

  onChangeAchternaam = event => {
    this.setState({ achternaam: event.target.value });
  };

  onChangeStraatnr = event => {
    this.setState({ straatnr: event.target.value });
  };

  onChangePostcode = event => {
    this.setState({ postcode: event.target.value });
  };

  onChangeGemeente = event => {
    this.setState({ gemeente: event.target.value });
  };

  onChangeTelefoonnr = event => {
    this.setState({ telefoon: event.target.value });
  };

  onChangeEmail = event => {
    this.setState({ email: event.target.value });
  };

  onChangeRijksregister = event => {
    var nummer = event.target.value;
    nummer = nummer.replace(/[&/\\#,+()$~%.'":*?<>{}]/g,'')
    nummer = nummer.replace(/-/g, '');
    if(nummer.length > 8){
      nummer = nummer.substring(0,2)+"."+nummer.substring(2,4)+"."+nummer.substring(4,6)+"-"+nummer.substring(6,9)+"-"+nummer.substring(9,11)
    } else if (nummer.length > 5) {
      nummer = nummer.substring(0,2)+"."+nummer.substring(2,4)+"."+nummer.substring(4,6)+"-"+nummer.substring(6,9)
    } else if (nummer.length > 3) {
      nummer = nummer.substring(0,2)+"."+nummer.substring(2,4)+"."+nummer.substring(4,6)
    } else if (nummer.length > 1) {
      nummer = nummer.substring(0,2)+"."+nummer.substring(2,4)
    } else {
      nummer = nummer.substring(0,2)
    }
    this.setState({ rijksregister: nummer });
  };

  onChangeGeboortedatum = event => {
    this.setState({ geboortedatum: event.target.value });
  };

  onChangePloeg = event => {
    this.setState({ ploeg: event.target.value });
  };

  onChangeLid= event => {
    this.setState({ gezinslid: event.target.value });
  };

  onChangeMutualiteit = event => {
    this.setState({ mutualiteit: event.target.value });
  };

  onChangeExtragegevens = event => {
    this.setState({ extragegevens: event.target.value });
  };

  onChangeGeslacht = event => {
    this.setState({ geslacht: event.target.value });
  };

  onChangeCheckboxBancontact = event => {
    this.setState({
      overschrijving: false,
      bancontact: true
     });
  };

  onChangeCheckboxOverschrijving = event => {
    this.setState({
      overschrijving: true,
      bancontact: false
     });
  };

  handleChange1 = e => {
   if (e.target.files[0]) {
     const image1 = e.target.files[0];
     this.setState(() => ({ image1 }));
   }
  };

  onEditGegevens = (user, uid, voornaam, achternaam, geboortedatum, rijksregister, mutualiteit, extragegevens, ploeg, geslacht) => {

    if(
      voornaam === ""
      || achternaam === ""
      || geslacht === ""
      || geboortedatum === ""
      || rijksregister === ""
      || this.state.telefoon === ""
      || this.state.straatnr === ""
      || this.state.postcode === ""
      || this.state.gemeente === ""
      || ploeg === ""
      || (!this.state.overschrijving && !this.state.bancontact)
    ) {
      window.alert("Zo te zien ben je iets vergeten. Heb je alles ingevuld? Heb je een betaalwijze aangeduid?")
    } else {
      this.setState({
        loadingBC: true,
      })
      try {
        if(!!this.state.bancontact || !!this.state.overschrijving){
          var gezinshoofd = user.voornaam +" "+ user.achternaam
          var ouderuid = uid;
          var username = voornaam + " " + achternaam
          var liduid = this.props.firebase.users().push().getKey()
          var gemeente = user.gemeente;
          var datum = geboortedatum;
          var datumOmgekeerd = datum.substring(8,10)+datum.substring(4, 8)+datum.substring(0, 4)
          var bedrag = "";


          if(ouderuid === undefined) {
            ouderuid = this.state.user.uid
          }

          if(this.state.ploeg1 === ploeg) {
            bedrag = this.state.lidgeldPloeg1
          }
          if(this.state.ploeg2 === ploeg) {
            bedrag = this.state.lidgeldPloeg2
          }
          if(this.state.ploeg3 === ploeg) {
            bedrag = this.state.lidgeldPloeg3
          }
          if(this.state.ploeg4 === ploeg) {
            bedrag = this.state.lidgeldPloeg4
          }
          if(this.state.ploeg5 === ploeg) {
            bedrag = this.state.lidgeldPloeg5
          }
          if(this.state.ploeg6 === ploeg) {
            bedrag = this.state.lidgeldPloeg6
          }
          if(this.state.ploeg7 === ploeg) {
            bedrag = this.state.lidgeldPloeg7
          }
          if(this.state.ploeg8 === ploeg) {
            bedrag = this.state.lidgeldPloeg8
          }
          if(this.state.ploeg9 === ploeg) {
            bedrag = this.state.lidgeldPloeg9
          }
          if(this.state.ploeg10 === ploeg) {
            bedrag = this.state.lidgeldPloeg10
          }
          if(this.state.ploeg11 === ploeg) {
            bedrag = this.state.lidgeldPloeg11
          }
          if(this.state.ploeg12 === ploeg) {
            bedrag = this.state.lidgeldPloeg12
          }
          if(ploeg === "Niet Speler") {
            bedrag = this.state.lidgeldNietSpeler
          }

          bedrag.toString();
          var bedragMollie = bedrag
          bedrag = bedrag.replace(/\./g,',')

          var betaalmethode = ""
          if (this.state.overschrijving) {
            betaalmethode = "Overschrijving"
          } else if (this.state.bancontact) {
            betaalmethode = "Mollie"
          } else {
            betaalmethode = "Andere manier???"
          }

          var familie = user.familie
          if(user.familie === undefined) {
            familie = []
          }

          var seizoenen = []

          const ditSeizoen = this.state.huidigSeizoen

          const { ...userSnapshot } = user;

          var betaalwijze = ""
          if(this.state.overschrijving) {
            betaalwijze = "Overschrijving"
          }
          if(this.state.bancontact) {
            betaalwijze = "Bancontact"
          }



          let seizoenInschrijving = {
              "ploeg": ploeg,
              "voornaam": voornaam,
              "achternaam": achternaam,
              "ingeschreven": true,
              "isSpelend": true,
              "lidgeld": bedrag,
              "betaald": false,
              "betaaldwijze": betaalwijze,
              "email": this.state.email
            }

          seizoenen[ditSeizoen] = seizoenInschrijving


          var gezinslid = {voornaam: voornaam, achternaam: achternaam, ingeschreven: true, uid: liduid, ouderUid: ouderuid, ploeg: ploeg, betaalmethode, seizoenen}

          familie[liduid] = gezinslid

          this.props.firebase.user(uid).set({
            ...userSnapshot,
            familie,
          });

          this.props.firebase.users().child(liduid).set({
            achternaam,
            voornaam,
            gezinshoofd,
            ouderuid,
            geboortedatum: datumOmgekeerd,
            telefoon: this.state.telefoon,
            straatnr: this.state.straatnr,
            postcode: this.state.postcode,
            gemeente: gemeente,
            rijksregister,
            mutualiteit,
            extragegevens,
            ploeg,
            geslacht,
            isOuder: false,
            isSpelend: true,
            email: this.state.email,
            username,
            ingeschreven: true,
            seizoenen,
            uid: liduid
          });

          var key = this.props.firebase.bestellingen().push().getKey()
          var status = ""

          if(this.state.overschrijving) {
            status = "Over te schrijven"
          }

          if(this.state.bancontact) {
            status = "Niet betaald"
            this.setState({
              loadingBC: true,
            })
          }

          const bestelling = {
            naam: voornaam + " " + achternaam,
            userUid: liduid,
            ouderUid: uid,
            seizoen: this.state.huidigSeizoen,
            bedrag,
            omschrijving: "Lidgeld "+this.state.huidigSeizoen+" - "+voornaam+" "+achternaam+" - "+ploeg,
            betaalmethode,
            aanmaakdatum: this.props.firebase.serverValue.TIMESTAMP,
            status: status,
            betaaldOp: "",
            uid: key
          }

          this.props.firebase.userBestelling(uid, key).set(bestelling)

          this.props.firebase.bestelling(key).set(bestelling)

          if(this.state.overschrijving) {

            this.setState({
              lidgeld: bedrag,
              voltooidDisplay: "block",
            })
            axios.get('https://us-central1-ledenportaal-85bb5.cloudfunctions.net/emailNieuweSpeler',{params: {voornaam: voornaam, achternaam: achternaam, geslacht: geslacht, geboortedatum: datumOmgekeerd, rijksregisternr: rijksregister, telefoon: this.state.telefoon, straat: this.state.straatnr, postcode: this.state.postcode, gemeente: this.state.gemeente, mutualiteit: mutualiteit, email: user.email, ploeg: ploeg, extragegevens: extragegevens, foto: ""}})
            axios.get('https://us-central1-ledenportaal-85bb5.cloudfunctions.net/emailInschrijvingGezinslid', {params: {voornaam: voornaam,  naam: achternaam, voornaamOuder: user.voornaam, email: user.email, ploeg: ploeg, bedrag: bedrag}})
          } else if (this.state.bancontact) {
            (async()=> {
              try {
              const descriptionBetaling = achternaam+" "+voornaam+" lidgeld "+this.state.huidigSeizoen
              const redirectUrl = "http://www.vocschriek.be/Ledenportaal/orders/"+key
              axios.get('https://us-central1-ledenportaal-85bb5.cloudfunctions.net/emailNieuweSpeler',{params: {voornaam: voornaam, achternaam: achternaam, geslacht: geslacht, geboortedatum: datumOmgekeerd, rijksregisternr: rijksregister, telefoon: this.state.telefoon, straat: this.state.straatnr, postcode: this.state.postcode, gemeente: this.state.gemeente, mutualiteit: mutualiteit, email: user.email, ploeg: ploeg, extragegevens: extragegevens, foto: ""}})
              const response = await axios.get('https://us-central1-ledenportaal-85bb5.cloudfunctions.net/molliePayment', {params: {bedrag: bedragMollie, description: descriptionBetaling, redirectUrl: redirectUrl, key: key, userid: uid, seizoen: this.state.huidigSeizoen, gezinslid: true, gezinslidId: liduid}})
              window.location.replace(response.data)
            } catch(error){
              console.error("error" + error);
            }
          }
          )()
          }
        } else {
          alert("Oei, heb je wel een betaalwijze gekozen?")
        }

      }
      catch(err) {
        axios.get('https://us-central1-ledenportaal-85bb5.cloudfunctions.net/emailUitsturenViaPortaal',{params: {titel: "Error :"+user.username, message: err.message, emails: ["laurens.schilders@gmail.com"]}})
        alert("Error: de webmaster werd gecontacteerd en laat je zo snel mogelijk iets weten!")
      }

    }
  };
  render() {
    const { loadingBC } = this.state;

    var knopBlok = true;

    var watMist = ""

    if(this.state.voornaam === "") {
      watMist = watMist + "voornaam, "
    }
    if(this.state.achternaam === "") {
      watMist = watMist + "achternaam, "
    }
    if(this.state.geboortedatum === "") {
      watMist = watMist + "geboortedatum, "
    }
    if(this.state.geslacht === "") {
      watMist = watMist + "genderidentiteit, "
    }
    if(this.state.rijksregister === "") {
      watMist = watMist + "rijksregisternummer, "
    }
    if(this.state.telefoon === "") {
      watMist = watMist + "telefoonnummer, "
    }
    if(this.state.straatnr === "") {
      watMist = watMist + "straatnummer, "
    }
    if(this.state.postcode === "") {
      watMist = watMist + "postcode, "
    }
    if(this.state.gemeente === "") {
      watMist = watMist + "gemeente, "
    }
    if(this.state.ploeg === "") {
      watMist = watMist + "ploeg, "
    }

    if(!this.state.bancontact && !this.state.overschrijving) {
      watMist = watMist + "betaalwijze."
    }

    if(watMist.length === 0) {
      knopBlok = false
    }

    return (
      <div id="herinschrijven">
        <AuthUserContext.Consumer>
          {authUser => (
            <div>
              <InschrijvingVoltooid
                voltooidDisplay={this.state.voltooidDisplay}
                voornaam={this.state.voornaam}
                achternaam={this.state.achternaam}
                ploeg={this.state.ploeg}
                closeInschrijving={this.closeInschrijving}
                lidgeld={this.state.lidgeld}
                eersteKeer={true}
                />
            <div>
              <br />
              <h2>Gezinslid inschrijven</h2>
              <div className="onderlijn"></div>
              <div className="grid-herinschrijven">
              <Form>
              <h3>Persoonlijke gegevens</h3>
                <Row>
                  <Form.Group as={Col} controlId="formVoornaam">
                    <Form.Label>Naam: *</Form.Label>
                    <Form.Control type="text" placeholder="Voornaam"  value={this.state.voornaam} onChange={this.onChangeVoornaam}/>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Achternaam: *  </Form.Label>
                    <Form.Control type="text" placeholder="Achternaam" value={this.state.achternaam}  onChange={this.onChangeAchternaam}/>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col}  controlId="formDatum">
                    <Form.Label>Geboortedatum: *</Form.Label>
                    <Form.Control  type="date" placeholder="01-01-2020"  value={this.state.geboortedatum}  onChange={this.onChangeGeboortedatum}/>
                  </Form.Group>
                  
                  <Form.Group as={Col} controlId="formGeslacht">
                    <Form.Label>Genderidentiteit: *</Form.Label>
                    <Form.Control as="select" value={this.state.geslacht}  onChange={this.onChangeGeslacht}>
                      <option value="">Kies...</option>
                      <option value="Man">Man</option>
                      <option value="Vrouw">Vrouw</option>
                      <option value="Onzijdig">Anders</option>
                    </Form.Control>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col} controlId="formRijksnr">
                    <Form.Label>Rijksregisternummer: *</Form.Label>
                    <Form.Control type="text" placeholder="00.00.00-000-00" value={this.state.rijksregister}  onChange={this.onChangeRijksregister}/>
                  </Form.Group>

                    <Form.Group as={Col} controlId="formMut">
                      <Form.Label>Mutualiteit:</Form.Label>
                      <Form.Control type="text" placeholder="Mutualiteit" value={this.state.mutualiteit}  onChange={this.onChangeMutualiteit}/>
                    </Form.Group>
                </Row>

                <h3>Contactgegevens</h3>

                  <Row>
                    <Form.Group as={Col} controlId="formStraat">
                      <Form.Label>Straat + nr: *</Form.Label>
                      <Form.Control type="text" placeholder="Straat + nr" value={this.state.straatnr}  onChange={this.onChangeStraatnr}/>
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} controlId="formPostcode">
                      <Form.Label>Postcode: *</Form.Label>
                      <Form.Control type="number" placeholder="Postcode"  value={this.state.postcode}  onChange={this.onChangePostcode}/>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGemeente">
                      <Form.Label  sm="3">Gemeente: *</Form.Label>
                      <Form.Control type="text" placeholder="Gemeente"  value={this.state.gemeente}  onChange={this.onChangeGemeente}/>
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} controlId="formTelefoon">
                      <Form.Label>Telefoonnr: *</Form.Label>
                      <Form.Control type="text" placeholder="0499/99.99.99" value={this.state.telefoon}  onChange={this.onChangeTelefoonnr}/>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formEmail">
                      <Form.Label>Emailadres: *</Form.Label>
                      <Form.Control type="text" placeholder="jantje@vocschriek.be" value={this.state.email}  onChange={this.onChangeEmail}/>
                    </Form.Group>
                  </Row>

                  <h3>Inschrijving</h3>
                    <Form.Group as={Col} controlId="formPloeg">
                      <Form.Label>Ik schrijf in voor volgende ploeg: *</Form.Label>
                      <Form.Control as="select" value={this.state.ploeg} onChange={this.onChangePloeg}>
                        <option value="">Kies ploeg</option>
                        {!!this.state.ploeg1 && (
                          <option value={this.state.ploeg1}>{this.state.ploeg1} (€ {this.state.lidgeldPloeg1})</option>
                          )}
                        {!!this.state.ploeg2 && (
                          <option value={this.state.ploeg2}>{this.state.ploeg2} (€ {this.state.lidgeldPloeg2})</option>
                        )}
                        {!!this.state.ploeg3 && (
                          <option value={this.state.ploeg3}>{this.state.ploeg3} (€ {this.state.lidgeldPloeg3})</option>
                        )}
                        {!!this.state.ploeg4 && (
                          <option value={this.state.ploeg4}>{this.state.ploeg4} (€ {this.state.lidgeldPloeg4})</option>
                        )}
                        {!!this.state.ploeg5 && (
                          <option value={this.state.ploeg5}>{this.state.ploeg5} (€ {this.state.lidgeldPloeg5})</option>
                        )}
                        {!!this.state.ploeg6 && (
                          <option value={this.state.ploeg6}>{this.state.ploeg6} (€ {this.state.lidgeldPloeg6})</option>
                        )}
                        {!!this.state.ploeg7 && (
                          <option value={this.state.ploeg7}>{this.state.ploeg7} (€ {this.state.lidgeldPloeg7})</option>
                        )}
                        {!!this.state.ploeg8 && (
                          <option value={this.state.ploeg8}>{this.state.ploeg8} (€ {this.state.lidgeldPloeg8})</option>
                        )}
                        {!!this.state.ploeg9 && (
                          <option value={this.state.ploeg9}>{this.state.ploeg9} (€ {this.state.lidgeldPloeg9})</option>
                        )}
                        {!!this.state.ploeg10 && (
                          <option value={this.state.ploeg10}>{this.state.ploeg10} (€ {this.state.lidgeldPloeg10})</option>
                        )}
                        {!!this.state.ploeg11 && (
                          <option value={this.state.ploeg11}>{this.state.ploeg11} (€ {this.state.lidgeldPloeg11})</option>
                        )}
                        {!!this.state.ploeg12 && (
                          <option value={this.state.ploeg12}>{this.state.ploeg12} (€ {this.state.lidgeldPloeg12})</option>
                        )}
                          <option value="Niet Speler">Niet Speler</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGegevens">
                      <Form.Label>Extra gegevens:</Form.Label>
                      <Form.Control as="textarea" rows="4" placeholder="Extra gegevens" value={this.state.extragegevens}  onChange={this.onChangeExtragegevens}/>
                    </Form.Group>
                <br/>

                <div className="herinschrijven-activiteiten" id="betaalmethode">
                  <h3>Betaalwijze lidgeld</h3>
                  <h4>Ik ga betalen via:</h4>
                  <br/>
                  <Form.Row>
                    {!this.state.bancontact ? (
                      <div className="formGridCheckbox" onClick={()=>{this.onChangeCheckboxBancontact()}}>
                        <h4>Bancontact</h4>
                        <i className="far fa-circle"></i>
                      </div>
                    ) : (
                      <div className="formGridCheckbox" id="checked" onClick={()=>{this.onChangeCheckboxBancontact()}}>
                        <h4>Bancontact</h4>
                        <i className="fas fa-check-circle"></i>
                      </div>
                    )}
                    {!this.state.overschrijving ? (
                      <div className="formGridCheckbox"onClick={()=>{this.onChangeCheckboxOverschrijving()}}>
                        <h4>Overschrijving</h4>
                        <i className="far fa-circle"></i>
                      </div>
                    ) : (
                      <div className="formGridCheckbox" id="checked"  onClick={()=>{this.onChangeCheckboxOverschrijving()}}>
                        <h4>Overschrijving</h4>
                        <i className="fas fa-check-circle"></i>
                      </div>
                    )}
                  </Form.Row>
                  <br/>
                </div>
                </Form>
              </div>

              {watMist.length > 0 && (
                <p style={{color: "red"}}>Volgende gegevens moeten nog ingevuld worden: {watMist} <br/><br/></p>
              )}

              <div className="buttons-grid">
              <Button disabled={knopBlok} variant="primary" size="lg" active  onClick={() => {this.onEditGegevens(
                  this.state.user,
                  this.state.userid,
                  this.state.voornaam,
                  this.state.achternaam,
                  this.state.geboortedatum,
                  this.state.rijksregister,
                  this.state.mutualiteit,
                  this.state.extragegevens,
                  this.state.ploeg,
                  this.state.geslacht,
                )}}>
                <span>
              Inschrijven
              {!!loadingBC && (
                <Lottie
                  options={{
                    animationData: animation,
                  }}
                  width={"100px"}
                  heigth={"100px"}
                />
              )}
              </span>
            </Button>
                <LinkContainer to={ROUTES.LEDENPORTAAL}>
                  <Button variant="secondary" size="lg" active>
                    Ga terug
                  </Button>
              </LinkContainer>
            </div>
            </div>
            </div>
          )}
        </AuthUserContext.Consumer>
      </div>
    );
}}

const condition = authUser => authUser

const HerinschrijvenGezin = withFirebase(HerinschrijvenGezinBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase,
)(HerinschrijvenGezin);
