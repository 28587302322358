import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { compose } from 'recompose';
import ProgressBar from 'react-bootstrap/ProgressBar';

import Button from 'react-bootstrap/Button';


import { withFirebase } from '../../Firebase';
import { withAuthorization, withEmailVerification} from '../../session';

import { LinkContainer } from "react-router-bootstrap";

import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';

const Nieuwsredactie = () => (
    <div id="adminpagina">
      <br />
      <h2>Nieuwsredactie</h2>
      <div>
        <Switch>
          <Route exact path={ROUTES.NIEUWSREDACTIE} component={NieuwsLijst} />
          <Route exact path={ROUTES.NIEUWSREDACTIE_INTERN_DETAILS} component={NieuwsItemIntern} />
          <Route exact path={ROUTES.NIEUWSREDACTIE_EXTERN_DETAILS} component={NieuwsItemExtern} />
        </Switch>
      </div>
    </div>
)

class NieuwsredactieBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      ledennieuwsLeeg: false,
      nieuwsLeeg: false,
      text:"",
      titel: "",
      datum: "",
      ledennieuws: [],
      nieuws: [],
      intern: false,
      extern: false,
      image1: null,
      progress1: 0,
      urlfoto1: "",
      meerinfo: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({ loading: true });

      this.props.firebase.nieuws().orderByChild('datum').on('value', snapshot => {
        const usersObject = snapshot.val();

        try {
          const usersList = Object.keys(usersObject).map(key => ({
            ...usersObject[key],
            uid: key,
          }));

          usersList.reverse()

          this.setState({
            nieuws: usersList,
          });
        } catch(error) {

            var geenNieuws = []

            this.setState({
              nieuws: geenNieuws,
              loading: false,
              nieuwsLeeg: true,
            })
          }
      });

      this.props.firebase.ledennieuws().on('value', snapshot => {
        const usersObject = snapshot.val();

        try {
          const usersList = Object.keys(usersObject).map(key => ({
            ...usersObject[key],
            uid: key,
          }));

          usersList.reverse()

          this.setState({
            ledennieuws: usersList,
            loading: false,
          });
        } catch(error) {

          var geenNieuws = []

          this.setState({
            ledennieuws: geenNieuws,
            loading: false,
            ledennieuwsLeeg: true,
          })
      }});

  }

  componentWillUnmount() {
    this.props.firebase.nieuws().off();
    this.props.firebase.ledennieuws().off();
  }

  onChangeText = event => {
   this.setState({ text: event.target.value });
 };

 onChangeCheckbox = event => {
   this.setState({ [event.target.name]: event.target.checked });
 };

 onChangeTitel = event => {
   var titel = event.target.value
   var titelAangepast = titel.replace(/[&\\#,+()$~%.'":*?<>{}]/g,'');
  this.setState({ titel: titelAangepast });
};

  onChangeMeerInfo = event => {
   this.setState({ meerinfo: event.target.value });
  };

  onChangeDatum = event => {
   this.setState({ datum: event.target.value });
  };

 onCreateNieuws = event => {
   var datum = this.state.datum;
   var jaar = datum.substring(0, 4);
   var maand = datum.substring(6, 7);
   var dag = datum.substring (9, 10);
   if (Number(dag) < 10) {
     dag = "0"+dag
   }
   if (Number(maand) < 10) {
     maand = "0"+maand
   }
   var datumNieuw = dag+"-"+maand+"-"+jaar;
   var titel = this.state.titel;
   var url = this.state.urlfoto1;
   var naam = titel.replace(/ /g, "%20");
   var newUrl = url;

   if (this.state.intern && this.state.extern) {
     this.props.firebase.ledennieuws().push({
       titel: this.state.titel,
       text: this.state.text,
       datum: datumNieuw,
       urlfoto: newUrl,
       meerinfo: this.state.meerinfo,
       createdAt: this.props.firebase.serverValue.TIMESTAMP,
     });

     this.props.firebase.nieuws().push({
       titel: this.state.titel,
       text: this.state.text,
       datum: datumNieuw,
       urlfoto: newUrl,
       meerinfo: this.state.meerinfo,
       createdAt: this.props.firebase.serverValue.TIMESTAMP,
     });

     this.setState({ text: '', titel: '', intern: false, extern: false, urlfoto1: "", meerinfo: "", datum: "", progress1:0 });
     event.preventDefault();

   } else if (this.state.intern) {
     this.props.firebase.ledennieuws().push({
       titel: this.state.titel,
       text: this.state.text,
       datum: datumNieuw,
       urlfoto: newUrl,
       meerinfo: this.state.meerinfo,
       createdAt: this.props.firebase.serverValue.TIMESTAMP,
     });

     this.setState({ text: '', titel: '', intern: false, extern: false, urlfoto1: "", meerinfo: "", datum: "", progress1:0 });
     event.preventDefault();

   } else {
     this.props.firebase.nieuws().push({
       titel: this.state.titel,
       text: this.state.text,
       datum: datumNieuw,
       urlfoto: newUrl,
       meerinfo: this.state.meerinfo,
       createdAt: this.props.firebase.serverValue.TIMESTAMP,
     });

     this.setState({ text: '', titel: '', intern: false, extern: false, urlfoto1: "", meerinfo: "", datum: "", progress1:0 });

     event.preventDefault();
   }

   window.scrollTo(0, 0);

 };

 handleChange1 = e => {
  if (e.target.files[0]) {
    const image1 = e.target.files[0];
    this.setState(() => ({ image1 }));
  }
};

handleUpload1 = () => {
  const { image1 } = this.state;
  const imagename = this.state.titel.replace(/\\/g, '');
  const uploadTask = this.props.firebase.storage.ref(`nieuws/${imagename}`).put(image1);
  uploadTask.on(
    "state_changed",
    snapshot => {
      // progress function ...
      const progress1 = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      this.setState({ progress1 });
    },
    error => {
      // Error function ...
      console.log(error);
    },
    () => {
      // complete function ...
      this.props.firebase.storage
        .ref("nieuws")
        .child(imagename)
        .getDownloadURL()
        .then(url => {
          this.setState({ urlfoto1: url });
          console.log(url)
        });
    }
  );
};

onRemoveLedennieuws = (uid, titel) => {
  if (window.confirm("Ben je zeker dat je '"+titel+"' wilt verwijderen?")) {
    this.props.firebase.ledennieuwsitem(uid).remove();
  } else {
    console.log("niet gebeurd")
  }
 };

 onRemoveNieuws = (uid, titel) => {
   if (window.confirm("Ben je zeker dat je '"+titel+"' wilt verwijderen?")) {
     this.props.firebase.nieuwsitem(uid).remove();
   } else {
     console.log("niet gebeurd")
   }
  };


  render(){
    const { nieuws, ledennieuws, titel, text, datum, meerinfo, intern, extern, loading, ledennieuwsLeeg, nieuwsLeeg } = this.state;

  return (
    <div id="nieuwspagina">

      <form onSubmit={this.onCreateNieuws} id="formulier-nieuws">
        <div className="formulier-links">
          <h5>Titel Nieuwsitem:</h5>
          <input
            type="titel"
            value={titel}
            onChange={this.onChangeTitel}
            placeholder="titel nieuwsitem"
          />
        <h5>Tekst Nieuwsitem:</h5>
          <textarea
            type="text"
            value={text}
            onChange={this.onChangeText}
            placeholder="text nieuwsitem"
          />
        <h5>Meer info link: (met http://)</h5>
          <input
            type="titel"
            value={meerinfo}
            onChange={this.onChangeMeerInfo}
            placeholder="Meer info link"
          />
        <h5>Datum post:</h5>
          <input
            type="date"
            value = {datum}
            onChange={this.onChangeDatum}
            />
          <label>
            Intern clubnieuws:
            <input
              name="intern"
              type="checkbox"
              checked={intern}
              onChange={this.onChangeCheckbox}
            />
          </label>
          <label>
            Extern clubnieuws:
            <input
              name="extern"
              type="checkbox"
              checked={extern}
              onChange={this.onChangeCheckbox}
            />
          </label>
        </div>
        <div className="formulier-rechts">
          <h5>Foto toevoegen:</h5>
          <img
             src={this.state.urlfoto1 || "https://via.placeholder.com/400x300"}
             alt="Uploaded Images"
             height="300"
             width="400"
           />
             <br/><br />
            <input type="file" id="myfile" name="myfile" onChange={this.handleChange1} />
            <br />

            <div
              onClick={this.handleUpload1}
              className="uploadknop"
            >
              Upload foto
            </div>
            <div className="row">
              <ProgressBar now={this.state.progress1} label={`${this.state.progress1}%`} style={{width: "100%"}}/>
            </div>
            <br/>
          <Button variant="success" type="submit">Doorsturen</Button>
          </div>
          <br />
        </form>
        <div>
          <br />
          <h3>Intern Nieuws</h3>

          <table className="nieuwsredactie-lijst">
            <tr>
              <th>Datum gepost</th>
              <th>Titel</th>
              <th>Bewerken</th>
            </tr>
          {loading && <tr><td></td><td>Loading ...</td><td></td></tr>}
          {ledennieuwsLeeg && <tr><td></td><td>Er is momenteel geen nieuws.</td><td></td></tr>}
          {ledennieuws.map(nieuws => (
              <tr key={nieuws.uid}>
                <td>{nieuws.datum}</td>
                <td>{nieuws.titel}</td>
                <td>
                  <Link to={{ state: { nieuws, intern } , pathname: `${ROUTES.NIEUWSREDACTIE}/Intern/${nieuws.uid}` }}><i className="fas fa-edit"></i></Link>
                  <i className="fas fa-trash" onClick={() => this.onRemoveLedennieuws(nieuws.uid, nieuws.titel)}></i>
                </td>
              </tr>
          ))}
          <br />
          </table>
        </div>
        <div>
          <h3>Extern Nieuws (max 4)</h3>

            <table className="nieuwsredactie-lijst">
              <tr>
                <th>Datum gepost</th>
                <th>Titel</th>
                <th>Bewerken</th>
              </tr>
              {loading && <tr><td></td><td>Loading ...</td><td></td></tr>}
              {nieuwsLeeg && <tr><td></td><td>Er is momenteel geen nieuws.</td><td></td></tr>}
              {nieuws.map(nieuws => (
                  <tr key={nieuws.uid}>
                    <td>{nieuws.datum}</td>
                    <td>{nieuws.titel}</td>
                    <td>
                      <Link to={{ state: { nieuws }, pathname: `${ROUTES.NIEUWSREDACTIE}/Extern/${nieuws.uid}` }}><i className="fas fa-edit"></i></Link>
                      <i className="fas fa-trash" onClick={() => this.onRemoveNieuws(nieuws.uid, nieuws.titel)}></i>
                    </td>
                  </tr>
              ))}
              <br />
            </table>
        </div>
    </div>
  );
}}

class NieuwsItemBaseIntern extends React.Component {
constructor(props) {
  super(props);

  this.state = {
    loading: false,
    nieuws: null,
    titel: "",
    text: "",
    meerinfo:"",
    datum:"",
    urlfoto:"",
    fotoEdit: false,
    ...props.location.state,
  };
}

componentDidMount() {
  window.scrollTo(0, 0);

  this.setState({ loading: true });

  this.props.firebase
    .ledennieuwsitem(this.props.match.params.id)
    .on('value', snapshot => {
      const nieuws = snapshot.val()
      var datum = nieuws.datum;
      var datumJuist = datum.substring(6,10)+datum.substring(2, 6)+datum.substring(0, 2);

      this.setState({
        nieuws: nieuws,
        loading: false,
        progress1: 0,
        titel: nieuws.titel,
        text: nieuws.text,
        meerinfo: nieuws.meerinfo,
        datum: datumJuist,
        urlfoto: nieuws.urlfoto,
      });
    });
}

componentWillUnmount() {
  this.props.firebase.ledennieuwsitem(this.props.match.params.id).off();
}

onChangeTitel = event => {
this.setState({ titel: event.target.value });
};

onChangeText = event => {
 this.setState({ text: event.target.value });
};

onChangeMeerInfo = event => {
 this.setState({ meerinfo: event.target.value });
};

onChangeDatum = event => {
 this.setState({ datum: event.target.value });
};

handleChange1 = e => {
 if (e.target.files[0]) {
   const image1 = e.target.files[0];
   this.setState(() => ({ image1 }));
 }
};

handleUpload1 = () => {
 const { image1 } = this.state;
 const imagename = this.state.titel;
 const uploadTask = this.props.firebase.storage.ref(`nieuws/${imagename}`).put(image1);
 uploadTask.on(
   "state_changed",
   snapshot => {
     // progress function ...
     const progress1 = Math.round(
       (snapshot.bytesTransferred / snapshot.totalBytes) * 100
     );
     this.setState({ progress1 });
   },
   error => {
     // Error function ...
     console.log(error);
   },
   () => {
     // complete function ...
     this.props.firebase.storage
       .ref("nieuws")
       .child(imagename)
       .getDownloadURL()
       .then(url => {
         this.setState({ urlfoto: url });
       });
   }
 );
 this.setState({
   fotoEdit: true,
 })
};

onEditNieuws = event =>  {
    const { uid, ...nieuwsSnapshot } = this.state.nieuws;

    var datum = this.state.datum;
    var datumOmgekeerd = datum.substring(8,10)+datum.substring(4, 8)+datum.substring(0, 4)

    var url = this.state.urlfoto;
    var newUrl = "";
    if(this.state.fotoEdit === true) {
      newUrl = url
    } else {
      newUrl = url;
    }

    this.props.firebase.ledennieuwsitem(this.props.match.params.id).set({
      ...nieuwsSnapshot,
      titel: this.state.titel,
      text: this.state.text,
      meerinfo: this.state.meerinfo,
      datum: datumOmgekeerd,
      urlfoto: newUrl,
    });
  };

  render() {
    const { titel, text, meerinfo, datum, urlfoto } = this.state;

  return (
    <div className="App" id="nieuwspagina">
    <LinkContainer to={ROUTES.NIEUWSREDACTIE}><i id="backAdmin" className="fas fa-arrow-left"></i></LinkContainer>
      <br />
      <h4>Nieuwsitem aanpassen</h4>
        <form onSubmit={this.onEditNieuws} id="formulier-nieuws">
          <div className="formulier-links">
            <h5>Titel Nieuwsitem:</h5>
            <input
              type="titel"
              value={titel}
              onChange={this.onChangeTitel}
              placeholder="titel nieuwsitem"
            />
          <h5>Tekst Nieuwsitem:</h5>
            <textarea
              type="text"
              value={text}
              onChange={this.onChangeText}
              placeholder="text nieuwsitem"
            />
          <h5>Meer info link: (met http://)</h5>
            <input
              type="titel"
              value={meerinfo}
              onChange={this.onChangeMeerInfo}
              placeholder="Meer info link"
            />
          <h5>Datum post:</h5>
            <input
              type="date"
              value = {datum}
              onChange={this.onChangeDatum}
              />
          </div>
          <div className="formulier-rechts">
            <h5>Foto toevoegen:</h5>
            <img
               src={urlfoto || "https://via.placeholder.com/400x300"}
               alt="Uploaded Images"
               height="200"
               width="auto"
             />
               <br/>
              <input type="file" id="myfile" name="myfile" onChange={this.handleChange1} />
              <br />

              <div
                onClick={this.handleUpload1}
                className="uploadknop"
              >
                Upload foto
              </div>
              <div className="row">
                <ProgressBar now={this.state.progress1} label={`${this.state.progress1}%`} max="100" style={{width: "100%"}}/>
              </div>
            <button type="submit">Doorsturen</button>
            </div>
            <br />
          </form>
    </div>
  );
    }
  }

  class NieuwsItemBaseExtern extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      nieuws: null,
      titel: "",
      text: "",
      meerinfo:"",
      datum:"",
      urlfoto:"",
      fotoEdit: false,
      ...props.location.state,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({ loading: true });

    this.props.firebase
      .nieuwsitem(this.props.match.params.id)
      .on('value', snapshot => {
        const nieuws = snapshot.val()
        var datum = nieuws.datum;
        var datumJuist = datum.substring(6,10)+datum.substring(2, 6)+datum.substring(0, 2);
        this.setState({
          nieuws: nieuws,
          loading: false,
          progress1: 0,
          titel: nieuws.titel,
          text: nieuws.text,
          meerinfo: nieuws.meerinfo,
          datum: datumJuist,
          urlfoto: nieuws.urlfoto,
        });
      });
  }

  componentWillUnmount() {
    this.props.firebase.nieuwsitem(this.props.match.params.id).off();
  }

  onChangeTitel = event => {
  this.setState({ titel: event.target.value });
  };

  onChangeText = event => {
   this.setState({ text: event.target.value });
  };

  onChangeMeerInfo = event => {
   this.setState({ meerinfo: event.target.value });
  };

  onChangeDatum = event => {
   this.setState({ datum: event.target.value });
  };

  handleChange1 = e => {
   if (e.target.files[0]) {
     const image1 = e.target.files[0];
     this.setState(() => ({ image1 }));
   }
  };

  handleUpload1 = () => {
   const { image1 } = this.state;
   const imagename = this.state.titel;
   const uploadTask = this.props.firebase.storage.ref(`nieuws/${imagename}`).put(image1)
   uploadTask.on(
     "state_changed",
     snapshot => {
       // progress function ...
       const progress1 = Math.round(
         (snapshot.bytesTransferred / snapshot.totalBytes) * 100
       );
       this.setState({ progress1 });
     },
     error => {
       // Error function ...
       console.log(error);
     },
     () => {
       // complete function ...
       this.props.firebase.storage
         .ref("nieuws")
         .child(imagename)
         .getDownloadURL()
         .then(url => {
           this.setState({ urlfoto: url });
         });
     }
   );
   this.setState({
     fotoEdit: true,
   })
  };

  onEditNieuws = event =>  {
      const { uid, ...nieuwsSnapshot } = this.state.nieuws;

      var datum = this.state.datum;
      var datumOmgekeerd = datum.substring(8,10)+datum.substring(4, 8)+datum.substring(0, 4)

      var titel = this.state.titel;
      var url = this.state.urlfoto;
      var naam = titel.replace(/ /g, "%20");
      var newNaam = naam
      var newUrl = "";
      if(this.state.fotoEdit === true) {
        newUrl = url.replace(naam,newNaam);
      } else {
        newUrl = url;
      }

      this.props.firebase.nieuwsitem(this.props.match.params.id).set({
        ...nieuwsSnapshot,
        titel: this.state.titel,
        text: this.state.text,
        meerinfo: this.state.meerinfo,
        datum: datumOmgekeerd,
        urlfoto: newUrl,
      });
    };

    render() {
      const { titel, text, meerinfo, datum, urlfoto } = this.state;
      console.log(this.state.fotoEdit)
    return (
      <div className="App" id="nieuwspagina">
      <LinkContainer to={ROUTES.NIEUWSREDACTIE}><i id="backAdmin" className="fas fa-arrow-left"></i></LinkContainer>
        <br />
        <h4>Nieuwsitem aanpassen</h4>
          <form onSubmit={this.onEditNieuws} id="formulier-nieuws">
            <div className="formulier-links">
              <h5>Titel Nieuwsitem:</h5>
              <input
                type="titel"
                value={titel}
                onChange={this.onChangeTitel}
                placeholder="titel nieuwsitem"
              />
            <h5>Tekst Nieuwsitem:</h5>
              <textarea
                type="text"
                value={text}
                onChange={this.onChangeText}
                placeholder="text nieuwsitem"
              />
            <h5>Meer info link: (met http://)</h5>
              <input
                type="titel"
                value={meerinfo}
                onChange={this.onChangeMeerInfo}
                placeholder="Meer info link"
              />
            <h5>Datum post:</h5>
              <input
                type="date"
                value = {datum}
                onChange={this.onChangeDatum}
                />
            </div>
            <div className="formulier-rechts">
              <h5>Foto toevoegen:</h5>
              <img
                 src={urlfoto || "https://via.placeholder.com/400x300"}
                 alt="Uploaded Images"
                 height="300"
                 width="400"
               />
                 <br/>
                <input type="file" id="myfile" name="myfile" onChange={this.handleChange1} />
                <br />

                <div
                  onClick={this.handleUpload1}
                  className="uploadknop"
                >
                  Upload foto
                </div>
                <div className="row">
                  <ProgressBar now={this.state.progress1} label={`${this.state.progress1}%`} max="100" style={{width: "100%"}}/>
                </div>
              <button type="submit">Doorsturen</button>
              </div>
              <br />
            </form>
      </div>
    );
      }
    }

const condition = authUser =>
authUser && !!authUser.roles[ROLES.ADMIN];

const NieuwsLijst = withFirebase(NieuwsredactieBase);
const NieuwsItemIntern = withFirebase(NieuwsItemBaseIntern);
const NieuwsItemExtern = withFirebase(NieuwsItemBaseExtern);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(Nieuwsredactie);
