import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import * as ROUTES from '../../../constants/routes';

import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withAuthorization, withEmailVerification } from '../../session';

class Helpers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      huidigSeizoen: "",
      activiteit1: "",
      activiteit2: "",
      activiteit3: "",
      activiteit4: "",
      activiteit5: "",
      activiteit6: "",
      activiteit7: "",
      users: [],
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    var huidigSeizoen = "2024-2025"
    this.props.firebase.huidigSeizoen().on('value', snapshot => {
      huidigSeizoen = snapshot.val()
      this.setState({
        huidigSeizoen
      })
      this.props.firebase.seizoen(huidigSeizoen).on('value', snapshot => {
        var seizoenObject = snapshot.val();
        if (seizoenObject) {
          this.setState({
            activiteit1: seizoenObject.activiteit1,
            activiteit2: seizoenObject.activiteit2,
            activiteit3: seizoenObject.activiteit3,
            activiteit4: seizoenObject.activiteit4,
            activiteit5: seizoenObject.activiteit5,
            activiteit6: seizoenObject.activiteit6,
            activiteit7: seizoenObject.activiteit7,
            datumActiviteit1:seizoenObject.datumActiviteit1,
            datumActiviteit2:seizoenObject.datumActiviteit2,
            datumActiviteit3:seizoenObject.datumActiviteit3,
            datumActiviteit4:seizoenObject.datumActiviteit4,
            datumActiviteit5:seizoenObject.datumActiviteit5,
            datumActiviteit6:seizoenObject.datumActiviteit6,
            datumActiviteit7:seizoenObject.datumActiviteit7,
            loading: false,
          })
      } else {
        this.setState({ inschrijvingOpen: null, loading: false });
      }
    })
      this.props.firebase.activiteitenAanwezigheid().on('value', snapshot => {
        const usersObject = snapshot.val();

        const usersList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));

        this.setState({
          users: usersList,
          loading: false,
        });
      });
    })

  }
  componentWillUnmount() {

  }

  render() {
    var { huidigSeizoen, activiteit1, activiteit2, activiteit3, activiteit4, activiteit5, activiteit6, activiteit7, users } = this.state
    console.log(this.state.users)
    var usersActiviteit1 = users.filter(d => {var helpen = d.activiteit1;
                             return (helpen === true);
                            });

    var usersActiviteit2 = users.filter(d => {var helpen = d.activiteit2;
                             return (helpen === true);
                            });

    var usersActiviteit3 = users.filter(d => {var helpen = d.activiteit3;
                             return (helpen === true);
                            });

    var usersActiviteit4 = users.filter(d => {var helpen = d.activiteit4;
                             return (helpen === true);
                            });

    var usersActiviteit5 = users.filter(d => {var helpen = d.activiteit5;
                             return (helpen === true);
                            });

    var usersActiviteit6 = users.filter(d => {var helpen = d.activiteit6;
                             return (helpen === true);
                            });

    var usersActiviteit7 = users.filter(d => {var helpen = d.activiteit7;
                             return (helpen === true);
                            });

    var emailUsersActiviteit1Array = [];
    for (var key in usersActiviteit1) {
      emailUsersActiviteit1Array.push(usersActiviteit1[key].email)
    }
    var emailUsersActiviteit1 = "mailto: ?bcc="+emailUsersActiviteit1Array.join()

    var emailUsersActiviteit2Array = [];
    // eslint-disable-next-line
    for (var key in usersActiviteit2) {
      emailUsersActiviteit2Array.push(usersActiviteit2[key].email)
    }
    var emailUsersActiviteit2 = "mailto: ?bcc="+emailUsersActiviteit2Array.join()

    var emailUsersActiviteit3Array = [];
    // eslint-disable-next-line
    for (var key in usersActiviteit3) {
      emailUsersActiviteit3Array.push(usersActiviteit3[key].email)
    }
    var emailUsersActiviteit3 = "mailto: ?bcc="+emailUsersActiviteit3Array.join()

    var emailUsersActiviteit4Array = [];
    // eslint-disable-next-line
    for (var key in usersActiviteit4) {
      emailUsersActiviteit4Array.push(usersActiviteit4[key].email)
    }
    var emailUsersActiviteit4 = "mailto: ?bcc="+emailUsersActiviteit4Array.join()

    var emailUsersActiviteit5Array = [];
    // eslint-disable-next-line
    for (var key in usersActiviteit5) {
      emailUsersActiviteit5Array.push(usersActiviteit5[key].email)
    }
    var emailUsersActiviteit5 = "mailto: ?bcc="+emailUsersActiviteit5Array.join()

    var emailUsersActiviteit6Array = [];
    // eslint-disable-next-line
    for (var key in usersActiviteit6) {
      emailUsersActiviteit6Array.push(usersActiviteit6[key].email)
    }
    var emailUsersActiviteit6 = "mailto: ?bcc="+emailUsersActiviteit6Array.join()

    var emailUsersActiviteit7Array = [];
    // eslint-disable-next-line
    for (var key in usersActiviteit7) {
      emailUsersActiviteit7Array.push(usersActiviteit7[key].email)
    }
    var emailUsersActiviteit7 = "mailto: ?bcc="+emailUsersActiviteit7Array.join()

    return (
      <div style={{color: "#2c3e50"}}>
        <br/>
        <h2>Helpers Activiteiten (seizoen {huidigSeizoen})</h2>

        <div id="flex-helperslijsten">
        {!!activiteit1 && (
          <div>
            <br />
            <Table className="tableUsers" responsive>
              <thead>
                <tr>
                  <td>
                    <h3>{activiteit1} ({usersActiviteit1.length})</h3>
                  </td>
                  <td>
                    <Button variant="success" size="sm" active href={emailUsersActiviteit1}>
                      Helpers Contacteren
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th>Naam</th>
                  <th>Ploeg</th>
                </tr>
              </thead>
              <tbody>
                {usersActiviteit1.map(user => {
                  var ploeg = user.ploeg
                  var eersteKind = ""
                  if(user.ploeg === ""|| user.ploeg === "Ouder"|| user.ploeg === "Ouder") {
                    if(user.familie !== undefined){
                      eersteKind = user.familie[0].voornaam + " " + user.familie[0].achternaam
                    }
                    ploeg = `Ouder van ${eersteKind}`
                  }
                  return(
                  <tr key={user.achternaam+user.voornaam+ploeg+activiteit1}>
                   <td>{user.achternaam} {user.voornaam}</td>
                   <td>{ploeg}</td>
                  </tr>
                )})}
              </tbody>
            </Table>
          </div>
        )}
        {!!activiteit2 && (
          <div>
            <br />
            <Table className="tableUsers" responsive>
              <thead>
                <tr>
                  <td>
                    <h3>{activiteit2} ({usersActiviteit2.length})</h3>
                  </td>
                  <td>
                    <Button variant="success" size="sm" active href={emailUsersActiviteit2}>
                      Helpers Contacteren
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th>Naam</th>
                  <th>Ploeg</th>
                </tr>
              </thead>
              <tbody>
                {usersActiviteit2.map(user => {
                  var ploeg = user.ploeg
                  var eersteKind = ""
                  if(user.ploeg === ""|| user.ploeg === "Ouder") {
                    if(user.familie !== undefined){
                      eersteKind = user.familie[0].voornaam + " " + user.familie[0].achternaam
                    }
                    ploeg = `Ouder van ${eersteKind}`
                  }
                  return(
                  <tr key={user.achternaam+user.voornaam+ploeg+activiteit2}>
                   <td>{user.achternaam} {user.voornaam}</td>
                   <td>{ploeg}</td>
                  </tr>
                )})}
              </tbody>
            </Table>
          </div>
        )}
        {!!activiteit3 && (
          <div>
            <br />
            <Table className="tableUsers" responsive>
              <thead>
                <tr>
                  <td>
                    <h3>{activiteit3} ({usersActiviteit3.length})</h3>
                  </td>
                  <td>
                    <Button variant="success" size="sm" active href={emailUsersActiviteit3}>
                      Helpers Contacteren
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th>Naam</th>
                  <th>Ploeg</th>
                </tr>
              </thead>
              <tbody>
                {usersActiviteit3.map(user => {
                  var ploeg = user.ploeg
                  var eersteKind = ""
                  if(user.ploeg === ""|| user.ploeg === "Ouder") {
                    if(user.familie !== undefined){
                      eersteKind = user.familie[0].voornaam + " " + user.familie[0].achternaam
                    }
                    ploeg = `Ouder van ${eersteKind}`
                  }
                  return(
                  <tr key={user.achternaam+user.voornaam+ploeg+activiteit3}>
                   <td>{user.achternaam} {user.voornaam}</td>
                   <td>{ploeg}</td>
                  </tr>
                )})}
              </tbody>
            </Table>
          </div>
        )}
        {!!activiteit4 && (
          <div>
            <br />
            <Table className="tableUsers" responsive>
              <thead>
                <tr>
                  <td>
                    <h3>{activiteit4} ({usersActiviteit4.length})</h3>
                  </td>
                  <td>
                    <Button variant="success" size="sm" active href={emailUsersActiviteit4}>
                      Helpers Contacteren
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th>Naam</th>
                  <th>Ploeg</th>
                </tr>
              </thead>
              <tbody>
                {usersActiviteit4.map(user => {
                  var ploeg = user.ploeg
                  var eersteKind = ""
                  if(user.ploeg === ""|| user.ploeg === "Ouder") {
                    if(user.familie !== undefined){
                      eersteKind = user.familie[0].voornaam + " " + user.familie[0].achternaam
                    }
                    ploeg = `Ouder van ${eersteKind}`
                  }
                  return(
                  <tr key={user.achternaam+user.voornaam+ploeg+activiteit4}>
                   <td>{user.achternaam} {user.voornaam}</td>
                   <td>{ploeg}</td>
                  </tr>
                )})}
              </tbody>
            </Table>
          </div>
        )}
        {!!activiteit5 && (
          <div>
            <br />
            <Table className="tableUsers" responsive>
              <thead>
                <tr>
                  <td>
                    <h3>{activiteit5} ({usersActiviteit5.length})</h3>
                  </td>
                  <td>
                    <Button variant="success" size="sm" active href={emailUsersActiviteit5}>
                      Helpers Contacteren
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th>Naam</th>
                  <th>Ploeg</th>
                </tr>
              </thead>
              <tbody>
                {usersActiviteit5.map(user => {
                  var ploeg = user.ploeg
                  var eersteKind = ""
                  if(user.ploeg === ""|| user.ploeg === "Ouder") {
                    if(user.familie !== undefined){
                      eersteKind = user.familie[0].voornaam + " " + user.familie[0].achternaam
                    }
                    ploeg = `Ouder van ${eersteKind}`
                  }
                  return(
                  <tr key={user.achternaam+user.voornaamploeg+activiteit5}>
                   <td>{user.achternaam} {user.voornaam}</td>
                   <td>{ploeg}</td>
                  </tr>
                )})}
              </tbody>
            </Table>
          </div>
        )}
        {!!activiteit6 && (
          <div>
            <br />
            <Table className="tableUsers" responsive>
              <thead>
                <tr>
                  <td>
                    <h3>{activiteit6} ({usersActiviteit6.length})</h3>
                  </td>
                  <td>
                    <Button variant="success" size="sm" active href={emailUsersActiviteit6}>
                      Helpers Contacteren
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th>Naam</th>
                  <th>Ploeg</th>
                </tr>
              </thead>
              <tbody>
                {usersActiviteit6.map(user => {
                  var ploeg = user.ploeg
                  var eersteKind = ""
                  if(user.ploeg === ""|| user.ploeg === "Ouder") {
                    if(user.familie !== undefined){
                      eersteKind = user.familie[0].voornaam + " " + user.familie[0].achternaam
                    }
                    ploeg = `Ouder van ${eersteKind}`
                  }
                  return(
                  <tr key={user.achternaam+user.voornaam+ploeg+activiteit6}>
                   <td>{user.achternaam} {user.voornaam}</td>
                   <td>{ploeg}</td>
                  </tr>
                )})}
              </tbody>
              <tr>
              </tr>
            </Table>
          </div>
        )}
        {!!activiteit7 && (
          <div>
            <br />
            <Table className="tableUsers" responsive>
              <thead>
                <tr>
                  <td>
                    <h3>{activiteit7} ({usersActiviteit7.length})</h3>
                  </td>
                  <td>
                    <Button variant="success" size="sm" active href={emailUsersActiviteit7}>
                      Helpers Contacteren
                    </Button>
                  </td>
                </tr>
                <tr>
                  <th>Naam</th>
                  <th>Ploeg</th>
                </tr>
              </thead>
              <tbody>
                {usersActiviteit7.map(user => {
                  var ploeg = user.ploeg
                  var eersteKind = ""
                  if(user.ploeg === ""|| user.ploeg === "Ouder") {
                    if(user.familie !== undefined){
                      eersteKind = user.familie[0].voornaam + " " + user.familie[0].achternaam
                    }
                    ploeg = `Ouder van ${eersteKind}`
                  }
                  return(
                  <tr key={user.achternaam+user.voornaam+ploeg+activiteit7}>
                   <td>{user.achternaam} {user.voornaam}</td>
                   <td>{ploeg}</td>
                  </tr>
                )})}
              </tbody>
            </Table>
          </div>
        )}
        </div>
      </div>
    );
  }
  }

const condition = authUser =>
  authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase,
)(Helpers);
