import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';

var config = {
   apiKey: "AIzaSyDE80Z4yyCnG2yLdPfU5i8CgpASRR3n24M",
   authDomain: "ledenportaal-85bb5.firebaseapp.com",
   databaseURL: "https://ledenportaal-85bb5.firebaseio.com",
   projectId: "ledenportaal-85bb5",
   storageBucket: "ledenportaal-85bb5.appspot.com",
   messagingSenderId: "555753902947",
   appId: "1:555753902947:web:eafb031d173997edbd7956",
   measurementId: "G-64DD2MEVJD"
 };

 class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();
    this.functions = app.functions();
    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
     this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  doEmailUpdate = email =>
    this.auth.currentUser.updateEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: "http://www.vocschriek.be/Ledenportaal",
    });

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();
            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }
            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: true,
              providerData: authUser.providerData,
              ...dbUser,
            };
            next(authUser);
          });
      } else {
        fallback();
      }
    });

    // *** User API ***
  userBestellingSeizoen = (uid , seizoen) => this.db.ref(`users/${uid}/seizoenen/${seizoen}`);
  userBestelling = (uid , bestelling) => this.db.ref(`users/${uid}/bestellingen/${bestelling}`);
  user = uid => this.db.ref(`users/${uid}`);
  users = () => this.db.ref('users');

// *** Bestellingen API ***
  bestelling = uid => this.db.ref(`bestellingen/${uid}`);
  bestellingen = () => this.db.ref('bestellingen');

  // *** huidigSeizoen API ***
  huidigSeizoen = () => this.db.ref('huidigSeizoen');

  // *** Message API ***
  message = uid => this.db.ref(`messages/${uid}`);
  messages = () => this.db.ref('messages');

  // *** Activiteiten API ***
  activiteit = uid => this.db.ref(`activiteiten/${uid}`);
  activiteiten = () => this.db.ref('activiteiten');

  activiteitenAanwezigheidPerUser = uid => this.db.ref(`activiteitenHelpen/${uid}`)
  activiteitenAanwezigheid = () => this.db.ref(`activiteitenHelpen`)

  // *** Seizoenen API ***
  seizoen = uid => this.db.ref(`seizoenen/${uid}`);
  seizoenen = () => this.db.ref('seizoenen');

  // *** Helpers API ***
  helper = uid => this.db.ref(`helpers/${uid}`);
  helpers = () => this.db.ref('helpers');

  // *** Ledennieuws API ***
  ledennieuwsitem = uid => this.db.ref(`ledennieuws/${uid}`);
  ledennieuws = () => this.db.ref('ledennieuws');

  // *** Nieuws API ***
  nieuwsitem = uid => this.db.ref(`nieuws/${uid}`);
  nieuws = () => this.db.ref('nieuws');

  // *** Algemene Agenda API ***
  algemeneagendaitem = uid => this.db.ref(`algemeneAgenda/${uid}`);
  algemeneagenda = () => this.db.ref('algemeneAgenda');

  // *** Ploeg van Dienst Agenda API ***
  ploegDienstItem = uid => this.db.ref(`ploegDienst/${uid}`);
  ploegDienst = () => this.db.ref('ploegDienst');

  // *** Clubchat API ***
  clubchatItem = uid => this.db.ref(`clubchat/${uid}`);
  clubchat = () => this.db.ref('clubchat');

  // *** Algemene Agenda API ***
  vragenBeantwoord = () => this.db.ref('vragenLedenBeantwoord');
  vragenLedenItem = uid => this.db.ref(`vragenLeden/${uid}`);
  vragenLeden = () => this.db.ref('vragenLeden');

  // *** Todo API ***
  todoGedaanItem = uid => this.db.ref(`todoBestuurGedaan/${uid}`);
  todoGedaan = () => this.db.ref('todoBestuurGedaan');
  todoItem = uid => this.db.ref(`todoBestuur/${uid}`);
  todo = () => this.db.ref('todoBestuur');

  wedstrijden = () => this.db.ref('Wedstrijden');

  volleyscoresPloegRangschikking = uid => this.db.ref(`volleyscores/tabellen/${uid}`);
  volleyscoresPloegWedstrijden = uid => this.db.ref(`volleyscores/wedstrijden/${uid}`);
  volleyscoresWedstrijden = () => this.db.ref('volleyscores/wedstrijden');
  volleyscores = () => this.db.ref('volleyscores');

  kwbheren = () => this.db.ref('kwbmatchen/heren');
  kwbdames = () => this.db.ref('kwbmatchen/dames');
  kwbKalender = () => this.db.ref('kwbmatchenKalender');
  kwbherenKalender = () => this.db.ref('kwbmatchenKalender/heren');
  kwbdamesKalender = () => this.db.ref('kwbmatchenKalender/dames');
  kwbwedstrijden = () => this.db.ref('kwbmatchen');
}

export default Firebase;
