import React from 'react';
import { Table } from 'react-bootstrap';
import "./Lidworden.css";

import { withFirebase } from '../../Firebase';

class OnzePloegenBase extends React.Component {
  constructor() {
    super()
    this.state = {
      huidigSeizoen: "",
      ploeg1: "",
      ploeg2: "",
      ploeg3: "",
      ploeg4: "",
      ploeg5: "",
      ploeg6: "",
      ploeg7: "",
      ploeg8: "",
      ploeg9: "",
      ploeg10: "",
      ploeg11: "",
      ploeg12: "",
      leeftijdPloeg1: "",
      leeftijdPloeg2: "",
      leeftijdPloeg3: "",
      leeftijdPloeg4: "",
      leeftijdPloeg5: "",
      leeftijdPloeg6: "",
      leeftijdPloeg7: "",
      leeftijdPloeg8: "",
      leeftijdPloeg9: "",
      leeftijdPloeg10: "",
      leeftijdPloeg11: "",
      leeftijdPloeg12: "",
    }
  }

  componentDidMount() {
    var huidigSeizoen = "2021-2022"
    this.props.firebase.huidigSeizoen().on('value', snapshot => {
      huidigSeizoen = snapshot.val()
      this.setState({
        huidigSeizoen
      })
    })

      this.props.firebase.seizoen(huidigSeizoen).on('value', snapshot => {
        var seizoenObject = snapshot.val();
        if (seizoenObject) {
          this.setState({
            ploeg1: seizoenObject.ploeg1,
            ploeg2: seizoenObject.ploeg2,
            ploeg3: seizoenObject.ploeg3,
            ploeg4: seizoenObject.ploeg4,
            ploeg5: seizoenObject.ploeg5,
            ploeg6: seizoenObject.ploeg6,
            ploeg7: seizoenObject.ploeg7,
            ploeg8: seizoenObject.ploeg8,
            ploeg9: seizoenObject.ploeg9,
            ploeg10: seizoenObject.ploeg10,
            ploeg11: seizoenObject.ploeg11,
            ploeg12: seizoenObject.ploeg12,
            leeftijdPloeg1: seizoenObject.leeftijdPloeg1,
            leeftijdPloeg2: seizoenObject.leeftijdPloeg2,
            leeftijdPloeg3: seizoenObject.leeftijdPloeg3,
            leeftijdPloeg4: seizoenObject.leeftijdPloeg4,
            leeftijdPloeg5: seizoenObject.leeftijdPloeg5,
            leeftijdPloeg6: seizoenObject.leeftijdPloeg6,
            leeftijdPloeg7: seizoenObject.leeftijdPloeg7,
            leeftijdPloeg8: seizoenObject.leeftijdPloeg8,
            leeftijdPloeg9: seizoenObject.leeftijdPloeg9,
            leeftijdPloeg10: seizoenObject.leeftijdPloeg10,
            leeftijdPloeg11: seizoenObject.leeftijdPloeg11,
            leeftijdPloeg12: seizoenObject.leeftijdPloeg12,
            loading: false,
          })
      } else {
        this.setState({ inschrijvingOpen: null, loading: false });
      }
    })
  }

  render() {

    return (
      <div className="infoTabel">
        <Table striped bordered hover size="sm" className="text-black">
          <thead>
            <tr>
              <th>Ploeg</th>
              <th>Leeftijd</th>
            </tr>
          </thead>
          <tbody>
            {!!this.state.ploeg1 && (
              <tr key={this.state.ploeg1}>
                <td>{this.state.ploeg1}</td>
                <td>{this.state.leeftijdPloeg1}</td>
              </tr>
            )}
            {!!this.state.ploeg2 && (
              <tr key={this.state.ploeg2}>
                <td>{this.state.ploeg2}</td>
                <td>{this.state.leeftijdPloeg2}</td>
              </tr>
            )}
            {!!this.state.ploeg3 && (
              <tr key={this.state.ploeg3}>
                <td>{this.state.ploeg3}</td>
                <td>{this.state.leeftijdPloeg3}</td>
              </tr>
            )}
            {!!this.state.ploeg4 && (
              <tr key={this.state.ploeg4}>
                <td>{this.state.ploeg4}</td>
                <td>{this.state.leeftijdPloeg4}</td>
              </tr>
            )}
            {!!this.state.ploeg5 && (
              <tr key={this.state.ploeg5}>
                <td>{this.state.ploeg5}</td>
                <td>{this.state.leeftijdPloeg5}</td>
              </tr>
            )}
            {!!this.state.ploeg6 && (
              <tr key={this.state.ploeg6}>
                <td>{this.state.ploeg6}</td>
                <td>{this.state.leeftijdPloeg6}</td>
              </tr>
            )}
            {!!this.state.ploeg7 && (
              <tr key={this.state.ploeg7}>
                <td>{this.state.ploeg7}</td>
                <td>{this.state.leeftijdPloeg7}</td>
              </tr>
            )}
            {!!this.state.ploeg8 && (
              <tr key={this.state.ploeg8}>
                <td>{this.state.ploeg8}</td>
                <td>{this.state.leeftijdPloeg8}</td>
              </tr>
            )}
            {!!this.state.ploeg9 && (
              <tr key={this.state.ploeg9}>
                <td>{this.state.ploeg9}</td>
                <td>{this.state.leeftijdPloeg9}</td>
              </tr>
            )}
            {!!this.state.ploeg10 && (
              <tr key={this.state.ploeg10}>
                <td>{this.state.ploeg10}</td>
                <td>{this.state.leeftijdPloeg10}</td>
              </tr>
            )}
            {!!this.state.ploeg11 && (
              <tr key={this.state.ploeg11}>
                <td>{this.state.ploeg11}</td>
                <td>{this.state.leeftijdPloeg11}</td>
              </tr>
            )}
            {!!this.state.ploeg12 && (
              <tr key={this.state.ploeg12}>
                <td>{this.state.ploeg12}</td>
                <td>{this.state.leeftijdPloeg12}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    )
  }
}

export const OnzePloegen = withFirebase(OnzePloegenBase);
