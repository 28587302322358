//Algemeen
export const HOME = '/';
export const SPORTHAL = '/info/Sporthal';
export const BESTUUR = '/info/Bestuur';
export const CONTACT = '/info/Contact';
export const APIS = '/info/API';
export const WEBSHOP = '/info/WEBSHOP';
export const JEUGD = '/jeugd';
export const LIDWORDEN = '/info/Lidworden';
export const HEREN1 = '/ploegen/Heren1';
export const DAMES1 = '/ploegen/Dames1';
export const HEREN2 = '/ploegen/Heren2';
export const DAMES2 = '/ploegen/Dames2';
export const MEISJESU17 = '/ploegen/MeisjesU17';
export const JONGENSU17 = '/ploegen/JongensU17';
export const MEISJESU15 = '/ploegen/MeisjesU15';
export const JONGENSU15 = '/ploegen/JongensU15';
export const MEISJESU13 = '/ploegen/MeisjesU13';
export const JONGENSU13 = '/ploegen/JongensU13';
export const U11GEMENGD = '/ploegen/U11Gemengd';
export const BEWEGINGSSCHOOL = '/ploegen/Bewegingsschool';
export const KWBHEREN = '/ploegen/KWBHeren';
export const KWBDAMES = '/ploegen/KWBDames';
export const JEUGDWEEKEND = '/jeugdweekend';
export const SPAGHETTIDAG = '/pastatakeaway';
export const QUIZ = '/Quiz';
export const BRUNCH = '/BrunchEnBloemen';
export const SPONSERS = '/Sponsors';
export const INSCHRIJVEN = '/Inschrijven';
export const LIDAANMAKEN = '/lidaanmaken';
export const LOGIN = '/Login';
export const PASSWORD_VERGETEN = '/pw-vergeten';
export const PRIVACY = '/Privacy';

//LedenPortaal
export const LEDENPORTAAL = '/Ledenportaal';
export const ACCOUNT = '/Ledenportaal/Account';
export const LEDENINFO = '/Ledenportaal/Ledeninfo';
export const LEDENNIEUWS = '/Ledenportaal/Ledennieuws';
export const LEDENNIEUWS_DETAILS = '/Ledenportaal/Ledennieuws/:id';
export const LEDENAGENDA = '/Ledenportaal/Ledenagenda';
export const GEGEVENSAANPASSEN = '/Ledenportaal/Gegevens';
export const GEGEVENSAANPASSENGEZIN = '/Ledenportaal/Gegevens/:id';
export const HERINSCHRIJVEN = '/Ledenportaal/Herinschrijven';
export const HERINSCHRIJVENFAMILIELID = '/Ledenportaal/Nieuwfamilielid';
export const HERINSCHRIJVENFAMILIELIDOPNIEUW = '/Ledenportaal/Herinschrijvenfamilielid';
export const VRAAGSTELLEN = '/Ledenportaal/VraagStellen';
export const AANWEZIGHEDEN = '/Ledenportaal/AanwezighedenTraining';
export const ORDERS_DETAILS = '/Ledenportaal/orders/:id';
export const LEDENPORTAALINONDERHOUD = 'LedenportaalInOnderhoud';

//BestuurDashboard
export const BESTUURDASHBOARD = '/Bestuurportaal';
export const LEDENLIJST = '/Bestuurportaal/ledenlijst';
export const LEDENLIJST_DETAILS = '/Bestuurportaal/ledenlijst/:id';
export const LEDENLIJST_INSCHRIJVEN = '/Bestuurportaal/iemandInschrijven';
export const LEDENLIJST_NIET = '/Bestuurportaal/nietingeschreven';
export const LEDENLIJST_STAT = '/Bestuurportaal/Statistieken';
export const LEDENLIJST_VERJAARDAGEN = '/Bestuurportaal/Verjaardagen';
export const EMAILSTUREN = '/Bestuurportaal/Emailsturen';
export const FINANCIEEL = '/Bestuurportaal/VOCFinancien';
export const HELPERS = '/Bestuurportaal/HelpersActiviteiten';
export const NIEUWSREDACTIE = '/Bestuurportaal/Nieuwsredactie';
export const NIEUWSREDACTIE_INTERN_DETAILS = '/Bestuurportaal/Nieuwsredactie/Intern/:id';
export const NIEUWSREDACTIE_EXTERN_DETAILS = '/Bestuurportaal/Nieuwsredactie/Extern/:id';
export const WEBSITE = '/Bestuurportaal/WebsiteAanpassen';
export const AGENDA = '/Bestuurportaal/Agenda';
export const DIENST = '/Bestuurportaal/PloegenVanDienst';
export const OVERZICHTENAANWEZIGHEDEN = '/Bestuurportaal/OverzichtAanwezigheden';
export const SEIZOENINSTELLINGEN = '/Bestuurportaal/SeizoenInstellingen';
export const KALENDERKWB = '/Bestuurportaal/KalenderKWB';
