import React from 'react';
import "./style.css";

import { Table } from 'react-bootstrap';

import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withEmailVerification } from '../../session';

import VraagLezen from './VraagLezen';

class VragenLeden extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vragen: this.props.vragen,
      displayAdd: "none",
      vraag: "",
    };
  }

  open(vraag) {
    this.setState({
      vraag: vraag,
      displayAdd: "grid"
    });
  }

  close = () => {
    this.setState({
      vraag:"",
      displayAdd: "none"
    });
  }

  todoDone(vraag) {
    var gedaan = window.confirm(`Ben je zeker dat deze vraag is beantwoord? (${vraag.vraag})`)
    if (gedaan) {
      this.props.firebase.vragenBeantwoord().push({
        onderwerp: vraag.onderwerp,
        vraag: vraag.vraag,
        user: vraag.user,
        datum: vraag.datum,
        gedaanOp: this.props.firebase.serverValue.TIMESTAMP,
      });
      this.props.firebase.vragenLedenItem(vraag.uid).remove();
      alert("In orde!")
    }
  }

  render() {
    var {displayAdd} = this.state;
    var vragen = this.props.vragen
    if (vragen === undefined) {
      vragen = [];
    }
    var geenVragenDisplay = false;
    if (vragen.length === 0) {
      geenVragenDisplay = true;
    }
    return (
      <div>
        <Table responsive id="todoTable">
          <thead>
            <tr>
              <th>Datum</th>
              <th>Onderwerp</th>
              <th>Vraag</th>
              <th>Naam</th>
            </tr>
          </thead>
          {!!geenVragenDisplay ? (
            <tbody>
                <tr>
                  <td colSpan="4" style={{textAlign:"center", padding:"50px 0"}}>Momenteel geen vragen. Goed bezig!</td>
                </tr>
            </tbody>
          ) : (
            <tbody>
              {vragen.map(item => {
                var datum = new Date(item.datum)
                var dag = datum.getDate()
                var maand = datum.getMonth()
                var jaar = datum.getFullYear()
                var uur = datum.getHours()
                var minuten = datum.getMinutes()
                if(dag < 10) {
                  dag = "0"+dag
                }
                if(maand < 10) {
                  maand = "0"+maand
                }
                if(minuten < 10) {
                  minuten = "0"+minuten
                }
                var datumString = `${dag}/${maand}/${jaar} - ${uur}:${minuten}`
                return(
                <tr key={item.onderwerp} className="vraagLid" onClick={()=>this.open(item)}>
                  <td>{datumString}</td>
                  <td>{item.onderwerp}</td>
                  <td>{item.vraag}</td>
                  <td>{item.user}</td>
                </tr>
              )})}
            </tbody>
          )}

        </Table>
        <VraagLezen displayAdd={displayAdd} close={() => this.close()} vragen={vragen} vraag={this.state.vraag} />
      </div>
    );
  }
}


export default compose(
  withEmailVerification,
  withFirebase,
)(VragenLeden);
