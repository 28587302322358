import React from 'react';
import "./style.css"
import { Button } from 'react-bootstrap';

export class Api extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <div className="backgroundContact"></div>
        <div className="white-bg"></div>
        <h1 className="activiteit-titel">Aanspreekpunt integriteit (API)</h1>
        <div className="onderlijn"></div>
        <div className="d-flex justify-content-center flex-wrap mb-5 tekstInfoAPI">
          <h3>Wat is een API?</h3>
            <p>Een onafhankelijk aanspreekpunt integriteit (API) is een laagdrempelig aanspreekpunt voor sporters, ouders, trainers of andere betrokkenen met een vraag, vermoeden of klacht rond mogelijk grensoverschrijdend gedrag. Deze persoon draagt er mee zorg voor dat het beleid voldoende aandacht geeft aan preventie van en omgaan met grensoverschrijdend gedrag.
            <br/><br/>
            Heb je te maken met ongewenst gedrag van anderen?
            Voel je je mentaal of fysiek misbruikt?
            Merk je tijdens je sportactiviteiten dat er handelingen gebeuren die niet aanvaardbaar zijn?
            PRAAT ER OVER!
            <br/><br/>
            </p>
            <a href="https://volleyvlaanderen.be/Gezondheid-en-ethiek/Federatie-API" target='_blank' rel="noopener noreferrer"><Button>Meer info</Button></a>
        </div>

      </div>
    )
  }
}
