import React from 'react';
import { Ploeg } from './Ploeg';
import img from './img/Dames2.png';

import { withFirebase } from '../Firebase';

export class Dames2Base extends React.Component {
  constructor() {
    super()
    this.state = {
      ploeg: 2,
    }
  }

  render() {

    return (
      <div>
        <Ploeg
          ploeg={this.state.ploeg}
          img={img}
        />
      </div>
    )
  }
}

export const Dames2 = withFirebase(Dames2Base);
