import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import axios from 'axios';
import { LinkContainer } from "react-router-bootstrap";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import {  withAuthorization, withEmailVerification } from '../session';

const INITIAL_STATE = {
  achternaam: '',
  voornaam: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  isOuder: false,
  error: null,
};

class LidaanmakenBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      aangepast: false,
      achternaam:"",
      geboortedatum:"",
      gemeente:"",
      ploeg:"",
      postcode:"",
      rijksregister:"",
      straatnr:"",
      telefoon:"",
      voornaam:"",
      email: "",
      roles:[],
      username:"",
      geslacht:"",
      mutualiteit:"",
      extragegevens:"",
      isOuder: false,
      isSpelend: false,
      activiteit1:"",
      activiteit2:"",
      activiteit3:"",
      activiteit4:"",
      activiteit5:"",
      activiteit6:"",
      activiteit7:"",
      datumActiviteit1:"",
      datumActiviteit2:"",
      datumActiviteit3:"",
      datumActiviteit4:"",
      datumActiviteit5:"",
      datumActiviteit6:"",
      datumActiviteit7:"",
      helpenActiviteit1:"",
      helpenActiviteit2:"",
      helpenActiviteit3:"",
      helpenActiviteit4:"",
      helpenActiviteit5:"",
      helpenActiviteit6:"",
      helpenActiviteit7:"",
      lidgeldNietSpeler: "",
    };

  }
  componentDidMount() {

    }


  componentWillUnmount() {

  }

  onChangeVoornaam = event => {
    this.setState({ voornaam: event.target.value });
  };

  onChangeAchternaam = event => {
    this.setState({ achternaam: event.target.value });
  };

  onChangeStraatnr = event => {
    this.setState({ straatnr: event.target.value });
  };

  onChangePostcode = event => {
    this.setState({ postcode: event.target.value });
  };

  onChangeGemeente = event => {
    this.setState({ gemeente: event.target.value });
  };

  onChangeTelefoonnr = event => {
    this.setState({ telefoon: event.target.value });
  };

  onChangeRijksregister = event => {
    this.setState({ rijksregister: event.target.value });
  };

  onChangeGeboortedatum = event => {
    this.setState({ geboortedatum: event.target.value });
  };

  onChangePloeg = event => {
    this.setState({ ploeg: event.target.value });
  };

  onChangeMutualiteit = event => {
    this.setState({ mutualiteit: event.target.value });
  };

  onChangeExtragegevens = event => {
    this.setState({ extragegevens: event.target.value });
  };

  onChangeGeslacht = event => {
    this.setState({ geslacht: event.target.value });
  };

  onChangeEmail = event => {
    this.setState({ email: event.target.value });
  };


  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };


  onSubmit = event => {

      const { voornaam, achternaam, email, geslacht, geboortedatum, rijksregister, telefoon, straatnr, postcode, gemeente, isOuder } = this.state;
      const roles = {};
      const ploeg = "";
      const mutualiteit = "";
      const extragegevens = "";
      const ingeschreven = false;
      const welkom = true;
      const username = achternaam + " " + voornaam
      var isSpelend = true;
      if (isOuder) {
       isSpelend = false
      }

      var random = Math.floor(Math.random() * 100)
      const passwordOne = voornaam+random

      this.props.firebase
        .doCreateUserWithEmailAndPassword(email, passwordOne)
        .then(authUser => {
          return this.props.firebase
            .user(authUser.user.uid)
            .set({
              username,
              email,
              roles,
              voornaam,
              achternaam,
              geboortedatum,
              gemeente,
              ploeg,
              postcode,
              rijksregister,
              straatnr,
              telefoon,
              mutualiteit,
              extragegevens,
              ingeschreven,
              isOuder,
              isSpelend,
              geslacht,
              welkom,
            });
        })
        .then(authUser => {
          this.setState({ ...INITIAL_STATE });
        })
        .catch(error => {
          this.setState({ error });
        });
      event.preventDefault();

      axios.get('https://us-central1-ledenportaal-85bb5.cloudfunctions.net/emailWelkom', {params: {voornaam: voornaam,  email: email, wachtwoord: passwordOne}})

      alert('Je hebt '+ voornaam + ' uitgenodigd voor het portaal!')

    }
  render(){

    const {
     error,
   } = this.state;

    return (
      <div id="adminpagina">
            <div className="grid-herinschrijven">
              <br />
              <h2>(werkt nog niet perfect)Lid aanmaken en uitnodigen voor het portaal</h2>

              <br />
                <Form onSubmit={this.onSubmit}>
                  <Form.Row>
                    <Form.Label column lg={4}>
                      Voornaam:
                    </Form.Label>
                    <Col>
                      <Form.Control type="text" placeholder="Voornaam"  value={this.state.voornaam} onChange={this.onChangeVoornaam}/>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Form.Label column lg={4}>
                      Achternaam:
                    </Form.Label>
                    <Col>
                      <Form.Control type="text" placeholder="Achternaam" value={this.state.achternaam}  onChange={this.onChangeAchternaam}/>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Form.Label column lg={4}>
                      Emailadres:
                    </Form.Label>
                    <Col>
                      <Form.Control type="text" placeholder="Emailadres" value={this.state.email}  onChange={this.onChangeEmail}/>
                    </Col>
                  </Form.Row>
                    <div>
                      <Form.Row>
                        <Form.Label column lg={4} >Geslacht: </Form.Label>
                        <Col>
                          <Form.Control as="select" value={this.state.geslacht}  onChange={this.onChangeGeslacht}>
                            <option value="">Kies...</option>
                            <option value="Man">Man</option>
                            <option value="Vrouw">Vrouw</option>
                            <option value="Onzijdig">Onzijdig</option>
                          </Form.Control>
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Form.Label column lg={4}>
                          Geboortedatum:
                        </Form.Label>
                        <Col>
                          <Form.Control type="date" placeholder="01-01-2020"  value={this.state.geboortedatum}  onChange={this.onChangeGeboortedatum}/>
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Form.Label column lg={4}>
                          Rijksregisternr:
                        </Form.Label>
                        <Col>
                          <Form.Control type="number" placeholder="00000000000" value={this.state.rijksregister}  onChange={this.onChangeRijksregister}/>
                        </Col>
                      </Form.Row>
                    </div>
                  <Form.Row>
                    <Form.Label column lg={4}>
                      Telefoonnr:
                    </Form.Label>
                    <Col>
                      <Form.Control type="number" placeholder="0499999999" value={this.state.telefoon}  onChange={this.onChangeTelefoonnr}/>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Form.Label column lg={4}>
                      Straat + nr:
                    </Form.Label>
                    <Col>
                      <Form.Control type="text" placeholder="Straat + nr" value={this.state.straatnr}  onChange={this.onChangeStraatnr}/>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Form.Label column lg={4}>
                      Postcode:
                    </Form.Label>
                    <Col>
                      <Form.Control type="number" placeholder="Postcode"  value={this.state.postcode}  onChange={this.onChangePostcode}/>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Form.Label column lg={4}>
                      Gemeente:
                    </Form.Label>
                    <Col>
                      <Form.Control type="text" placeholder="Gemeente"  value={this.state.gemeente}  onChange={this.onChangeGemeente}/>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Form.Label column lg={4}>
                      Ouder-account:
                    </Form.Label>
                    <Col>
                      <Form.Check
                        aria-label="option 1"
                        name="isOuder"
                        checked={this.state.isOuder}
                        onChange={this.onChangeCheckbox}
                      />
                    </Col>
                  </Form.Row>
                  <br />
                <Button variant="primary" size="lg" active  type="submit">
                  Inschrijven en uitnodigen
                </Button>{' '}
                <LinkContainer to={ROUTES.LEDENLIJST}>
                  <Button variant="secondary" size="lg" active>
                    Ga terug
                  </Button>
                </LinkContainer>
                {error && <p>{error.message}</p>}
              </Form>
            </div>
          </div>
    )
  }
}

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

  const Lidaanmaken = withFirebase(LidaanmakenBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase,
)(Lidaanmaken);
