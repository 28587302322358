import React from 'react';
import "./style.css";
import Button from 'react-bootstrap/Button';

import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withEmailVerification } from '../../session';


class VraagLezen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }


  onGedaan = (vraag, uid, datum) => {
        this.props.firebase.vragenBeantwoord().push({
        datum: datum,
        beantwoord: this.props.firebase.serverValue.TIMESTAMP,
        email: vraag[1],
        onderwerp: vraag[2],
        uid: vraag[5],
        user: vraag[3],
        vraag: vraag[4],
      });
      this.props.firebase.vragenLedenItem(uid).remove();
      this.props.close()

  }

  SendEmail(adres) {
    window.location = "mailto:" + adres
  }

  render() {
    var vraag = this.props.vraag;
    var vraagArray = Object.values(vraag)

    var datum = new Date(vraagArray[0])
    var dag = datum.getDate()
    var maand = datum.getMonth()
    var jaar = datum.getFullYear()
    var uur = datum.getHours()
    var minuten = datum.getMinutes()
    if(dag < 10) {
      dag = "0"+dag
    }
    if(maand < 10) {
      maand = "0"+maand
    }
    if(minuten < 10) {
      minuten = "0"+minuten
    }
    var datumString = `${dag}/${maand}/${jaar} - ${uur}:${minuten}`
    return (
      <div id="AddTodoSection" style={{display:`${this.props.displayAdd}`}}>
        <div id="AddTodoSection-background">
        </div>
        <div id="AddTodoSection-front">
          <div>
            <h2>Vraag van {vraagArray[3]}</h2>
            <br/>
            <h4>Onderwerp: <br/>{vraagArray[2]} <br/>({datumString})</h4>
            <br/>
            <p style={{whiteSpace: "pre-line"}}>{vraagArray[4]}</p>
            <Button variant="primary" onClick={() => this.SendEmail(vraagArray[1])}>Email sturen</Button>
            <Button variant="success" onClick={() => this.onGedaan(vraagArray, vraagArray[5], datumString)}>Vraag is beantwoord</Button>
            <Button variant="danger" onClick={this.props.close}>Annuleren</Button>
            <br/><br/>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withEmailVerification,
  withFirebase,
)(VraagLezen);
