import React from 'react';
import { Ploeg } from './Ploeg';
import img from './img/U11.JPG';

import { withFirebase } from '../Firebase';

export class U11GemengdBase extends React.Component {
  constructor() {
    super()
    this.state = {
      ploeg: 7,
    }
  }


  render() {

    return (
      <div>
        <Ploeg
          ploeg={this.state.ploeg}
          img={img}
        />
      </div>
    )
  }
}

export const U11Gemengd = withFirebase(U11GemengdBase);
