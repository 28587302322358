import React from 'react';
import './Ploeg.css';
import { Table } from 'react-bootstrap';
import { withFirebase } from '../Firebase';

class PloegBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      huidigSeizoen: "",
      seizoen: [],
      ploeg: {},
      klassement: [],
      klassementTwee: [],
      wedstrijden: [],
      wedstrijdenTwee: [],
      fotoDisplay: "none"
    };
  }
  componentDidMount() {

    this.setState({ loading: true });

    const huidigSeizoen = this.props.firebase.huidigSeizoen().once('value')
    var seizoen = "2023-2024"
    huidigSeizoen.then(snapshot => {
      seizoen = snapshot.val()
      this.setState({
        huidigSeizoen: seizoen
      })

      this.props.firebase.seizoen(seizoen).on('value', snapshot => {

        var ploegCode = ""
        var ploegCodeTwee = ""

        if(snapshot.val() !== undefined) {
          if(snapshot.val().ploegen !== undefined) {
            if(snapshot.val().ploegen[this.props.ploeg].code.length > 1) {
              ploegCode = snapshot.val().ploegen[this.props.ploeg].code
              ploegCodeTwee = snapshot.val().ploegen[this.props.ploeg].codeTwee
            }
          }
        }

          this.setState({
            seizoen: snapshot.val(),
            ploeg: snapshot.val().ploegen[this.props.ploeg],
            loading: false,
          });

          if(this.props.kwb !== "kwbheren" && this.props.kwb !== "kwbdames"){

            this.props.firebase.volleyscoresPloegRangschikking(ploegCode).on('value', snapshot => {
              const object = snapshot.val();

              if(object !== null && object !== undefined) {
                if (object.tabel !== undefined && object.tabel !== null) {
                  const klassement = Object.keys(object.tabel).map(key => ({
                    ...object.tabel[key],
                    uid: key,
                  }));

                  console.log(klassement)

                  this.setState({
                    klassement: klassement,
                    loading: false,
                  });
                }
              }
            });

            if(ploegCodeTwee !== "") {
              this.props.firebase.volleyscoresPloegRangschikking(ploegCodeTwee).on('value', snapshot => {
                if(snapshot.val() !== null) {
                  const object = snapshot.val().tabel;

                  if(object !== undefined) {
                    const klassement = Object.keys(object).map(key => ({
                      ...object[key],
                      uid: key,
                    }));

                    this.setState({
                      klassementTwee: klassement,
                      loading: false,
                    });
                  }
                }
              });
            }

            this.props.firebase.volleyscoresPloegWedstrijden(ploegCode).on('value', snapshot => {
              if(snapshot.val() !== null && snapshot.val() !== undefined){
                const object = snapshot.val().tabel;

                if(object !== undefined) {
                  const wedstrijden = Object.keys(object).map(key => ({
                    ...object[key],
                    uid: key,
                  }));

                  this.setState({
                    wedstrijden: wedstrijden,
                    loading: false,
                  });
                }
              }
            });

            this.props.firebase.volleyscoresPloegWedstrijden(ploegCodeTwee).on('value', snapshot => {
              if(snapshot.val() !== null && snapshot.val() !== undefined){
                const object = snapshot.val().tabel;

                if(object !== undefined) {
                  const wedstrijden = Object.keys(object).map(key => ({
                    ...object[key],
                    uid: key,
                  }));

                  this.setState({
                    wedstrijdenTwee: wedstrijden,
                    loading: false,
                  });
                }
              }
            });

          } else {

            if(this.props.kwb === "kwbheren") {
              this.props.firebase.kwbherenKalender().on('value', snapshot => {
                const usersObject = snapshot.val();
                  this.setState({
                    wedstrijden: usersObject,
                    loading: false,
                  });
              })
            } else {
              this.props.firebase.kwbdamesKalender().on('value', snapshot => {
                const usersObject = snapshot.val();
                  this.setState({
                    wedstrijden: usersObject,
                    loading: false,
                  });
              })
            }


          }

      });

    })
  }

  componentWillUnmount() {
    this.props.firebase.huidigSeizoen().off()
    this.props.firebase.seizoen().off()
    this.props.firebase.volleyscoresPloegRangschikking().off()
    this.props.firebase.volleyscoresPloegWedstrijden().off()
  }

  fotoOpen() {
    this.setState({
      fotoDisplay: "block"
    })
  }

  fotoClose() {
    window.location.reload()
  }


  render() {
    var {loading, ploeg, klassement, klassementTwee, wedstrijden, wedstrijdenTwee} = this.state

    var website = "https://www.volleyscores.be/direct/AH-1736/"+ploeg.code
    var websitenaam = "Volleyscores"
    var displayKlassement = true
    var disable = false
    if (this.props.kwb === "kwbheren" || this.props.kwb === "kwbdames"){
      website = "http://www.falos.be/?p=volley_index"
      websitenaam = "Falos-Sport"
      displayKlassement = false
      if(klassement.length === 0) {
        klassement = [
          {
            stand: "",
            ploeg:"",
            ptn: "",
            aantalwed: "",
            gew3set:"",
            gew5set: "",
            verl3set:"",
            verl5set:"",
            gewsets:"",
            verlsets:"",
            forfaits: ""
          }
        ]
      }
      disable = false
      wedstrijden = []
    }

    if(klassement.length > 0 && wedstrijden.length > 0) {
      disable = true
    }

    var wedstrijdenSamengevoegd = wedstrijden.concat(wedstrijdenTwee)

    var alleWedstrijdenGefiltert = wedstrijdenSamengevoegd.sort(function (a, b) {
         var datumEen = new Date(a.datum.substring(6,10), a.datum.substring(3,5)-1, a.datum.substring(0,2), a.uur.substring(0,2))
         var datumTwee = new Date(b.datum.substring(6,10), b.datum.substring(3,5)-1, b.datum.substring(0,2), b.uur.substring(0,2))
         return new Date(datumEen) - new Date(datumTwee)
     })

    return (
      <div className="ploeg-section">
        <div id="ploegfoto-display" style={{display: this.state.fotoDisplay}}  onClick={() => this.fotoClose()}>
          <div id="ploegfoto-display-bg" ></div>
          <img id="ploegfoto-display-img" src={this.props.img} alt={ploeg.naam}></img>
        </div>
        <div className="backgroundPloeg"></div>
        <div className="white-bg"></div>
        {!!loading && <div>Loading ...</div>}
        {!loading && (
        <div>
          <h1 className="ploeg-titel">{ploeg.naam}</h1>
          <div className="onderlijn"></div>
          <div>
            <div className="ploegsection">

            <img src={this.props.img} alt={ploeg.naam} className="ploegfoto" onClick={() => this.fotoOpen()}></img>

            <div className="ploegsection-sub">
              <div>
                <h3>Training</h3>
                <div className="divider2 div-transparent2"></div>
                <p>{ploeg.training}</p>
              </div>
            </div>
            </div>
            <div>
              {disable &&
                <div>
                  <h1>Klassement  {ploeg.code}</h1>
                  <div className="onderlijn"></div>
                  <div id="section2">
                    {!!displayKlassement && (
                    <div className="kalendertabel">
                      <Table responsive striped bordered hover size="sm" className="ploegkalender text-black" >
                        <thead>
                          <tr>
                            <th></th>
                            <th>Ploeg</th>
                            <th>Ptn</th>
                            <th>#Wed</th>
                            <th>Gew. 3-0/3-1</th>
                            <th>Gew. 3-2</th>
                            <th>Verl. 3-0/3-1</th>
                            <th>Verl. 3-2</th>
                            <th>Gew.sets</th>
                            <th>Verl.sets</th>
                            <th>Forfaits</th>
                          </tr>
                        </thead>
                        <tbody>
                          {klassement.map(ploeg => {
                            var background = ""
                            var color = ""
                            if(ploeg.ploeg.toLowerCase().includes("schriek")) {
                              background = "#2c3e50"
                              color= "white"
                            }
                            return(
                              <tr key={ploeg.ploeg} style={{backgroundColor: background, color: color}}>
                                <td>{ploeg.stand}</td>
                                <td>{ploeg.ploeg}</td>
                                <td>{ploeg.ptn}</td>
                                <td>{ploeg.aantalwed}</td>
                                <td>{ploeg.gew3set}</td>
                                <td>{ploeg.gew5set}</td>
                                <td>{ploeg.verl3set}</td>
                                <td>{ploeg.verl5set}</td>
                                <td>{ploeg.gewsets}</td>
                                <td>{ploeg.verlsets}</td>
                                <td>{ploeg.forfaits}</td>
                              </tr>
                            )
                          })}

                        </tbody>
                      </Table>
                      Meer info: <a href={website} target="_blank" rel="noopener noreferrer">{websitenaam}</a>

                    </div>
                    )}

                  <br/><br/>
                  </div>

                  {!!klassementTwee.length > 0 &&
                    <div>
                      <h1>Klassement  {ploeg.codeTwee}</h1>
                      <div className="onderlijn"></div>
                      <div id="section2">
                        {!!displayKlassement && (
                        <div className="kalendertabel">
                          <Table responsive striped bordered hover size="sm" className="ploegkalender text-black" >
                            <thead>
                              <tr>
                                <th></th>
                                <th>Ploeg</th>
                                <th>Ptn</th>
                                <th>#Wed</th>
                                <th>Gew. 3-0/3-1</th>
                                <th>Gew. 3-2</th>
                                <th>Verl. 3-0/3-1</th>
                                <th>Verl. 3-2</th>
                                <th>Gew.sets</th>
                                <th>Verl.sets</th>
                                <th>Forfaits</th>
                              </tr>
                            </thead>
                            <tbody>
                              {klassementTwee.map(ploeg => {
                                var background = ""
                                var color = ""
                                if(ploeg.ploeg.toLowerCase().includes("schriek")) {
                                  background = "#2c3e50"
                                  color= "white"
                                }
                                return(
                                  <tr key={ploeg.ploeg} style={{backgroundColor: background, color: color}}>
                                    <td>{ploeg.stand}</td>
                                    <td>{ploeg.ploeg}</td>
                                    <td>{ploeg.ptn}</td>
                                    <td>{ploeg.aantalwed}</td>
                                    <td>{ploeg.gew3set}</td>
                                    <td>{ploeg.gew5set}</td>
                                    <td>{ploeg.verl3set}</td>
                                    <td>{ploeg.verl5set}</td>
                                    <td>{ploeg.gewsets}</td>
                                    <td>{ploeg.verlsets}</td>
                                    <td>{ploeg.forfaits}</td>
                                  </tr>
                                )
                              })}

                            </tbody>
                          </Table>
                          Meer info: <a href={website} target="_blank" rel="noopener noreferrer">{websitenaam}</a>
                        </div>
                        )}

                      <br/><br/>
                      </div>
                    </div>
                  }





                  <h1>Kalender</h1>
                  <div className="onderlijn"></div>
                  <div id="section2">
                    <div className="kalendertabel">
                      <Table responsive striped bordered hover size="sm" className="ploegkalender text-black" >
                        <thead>
                          <tr>
                            <th>Dag</th>
                            <td>Datum</td>
                            <th>Uur</th>
                            <th>Thuis</th>
                            <th>Bezoekers</th>
                            <th>Sporthal</th>
                            <th>Uitslag</th>
                          </tr>
                        </thead>
                        <tbody>
                        {alleWedstrijdenGefiltert.map(item => {

                          var schriek = "schriek"
                          var thuisSchriek = item.thuis.toLowerCase().includes(schriek);
                          var uitSchriek = item.bezoeker.toLowerCase().includes(schriek);
                          var thuisSchriekFont = "normal"
                          var uitSchriekFont = "normal"
                          var kleurUitslag = ""
                          var color = ""
                          var uitslag = item.uitslag.split(" - ");
                          var thuis = parseInt(uitslag[0], 10);
                          var uit = parseInt(uitslag[1], 10);


                          if(thuisSchriek) {
                            thuisSchriekFont = "bold"
                            if (thuis > uit) {
                              kleurUitslag="#1abc9c"
                              color = "white"
                            }
                            if (thuis < uit) {
                              kleurUitslag="#e74c3c"
                              color = "white"
                            }
                          }
                          if(uitSchriek) {
                            uitSchriekFont = "bold"
                            if (thuis < uit) {
                              kleurUitslag="#1abc9c"
                              color = "white"
                            }
                            if (thuis > uit) {
                              kleurUitslag="#e74c3c"
                              color = "white"
                            }
                          }
                          var uitslagVeld = ""
                          if(item.uitslag !== undefined && item.uitslag.length > 0) {
                            uitslagVeld = item.uitslag
                          }

                          var dag = item.dag

                          if(dag === "" || dag === undefined) {

                            const dagDatum = item.datum.substr(0,2)
                            const maand = Number(item.datum.substr(3, 2))
                            const jaar = item.datum.substr(6, 4)

                            if(new Date(jaar, maand-1,dagDatum).getDay() === 6) {
                              dag = "Za"
                            } else if(new Date(jaar, maand-1,dagDatum).getDay() === 0) {
                              dag = "Zo"
                            } else {
                              dag = "Vr"
                            }
                          }



                          return(
                            <tr key={item.code}>
                              <td>{dag}</td>
                              <td>{item.datum}</td>
                              <td>{item.uur}</td>
                              <td style={{fontWeight: thuisSchriekFont}}>{item.thuis}</td>
                              <td style={{fontWeight: uitSchriekFont}}>{item.bezoeker}</td>
                              <td>{item.plaats}</td>
                              <td style={{backgroundColor: kleurUitslag, color: color, textAlign: "center"}}>{uitslagVeld}</td>
                            </tr>
                          )
                        })}
                        {loading &&
                          <tr>
                            <td colSpan="7"><p>--- Gegevens ophalen bij Volleyscores ---</p></td>
                          </tr>
                          }
                        </tbody>
                      </Table>
                    </div>
                  </div>
              </div>
            }
            </div>
          </div>
        </div>
      )}
      </div>
    )
  }
}

export const Ploeg = withFirebase(PloegBase);
