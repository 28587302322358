import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {compose} from 'recompose';
import {withAuthorization, withEmailVerification} from '../../session';
import Welkom from '../welkom/Welkom';
import wakado from '../../afbeeldingen/wakado.jpg'

import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'

import {LinkContainer} from "react-router-bootstrap";
import * as ROUTES from '../../../constants/routes';

class Ledenpagina extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      huidigSeizoen: ""
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    var huidigSeizoen = ""
    this.props.firebase.huidigSeizoen().on('value', snapshot => {
      huidigSeizoen = snapshot.val();
      this.setState({
        huidigSeizoen: snapshot.val(),
      })
    })
  }

  render() {
    var {
      user,
      ledennieuws,
      agenda,
      ploegDienst
    } = this.props;

    var {huidigSeizoen} = this.state

    if (ledennieuws === undefined) {
      ledennieuws = []
    }
    if (agenda === undefined) {
      agenda = []
    }
    if (ploegDienst === undefined) {
      ploegDienst = []
    }

    var date = new Date();
    var uur = date.getHours();
    var begroeting = ""

    if (uur < 6) {
      begroeting = "Goedenacht"
    } else if (uur < 12) {
      begroeting = "Goedemorgen"
    } else if (uur < 18) {
      begroeting = "Goedemiddag"
    } else {
      begroeting = "Goedenavond"
    }

    var userfoto = 'url("' + user.foto + '")'

    if (user.foto === undefined || user.foto === "") {
      userfoto = 'url("' + wakado + '")'
    }
    var inschrijvingHuidigSeizoen = []
    if (!!user.isOuder && !user.isSpelend && !!user.ingeschreven) {
      inschrijvingHuidigSeizoen.inschrijvingInOrde = true
      inschrijvingHuidigSeizoen.ploeg = "Ouder"
    } else if(!!user.isSpelend && user.seizoenen !== undefined && user.seizoenen[huidigSeizoen] !== undefined && !!user.seizoenen[huidigSeizoen].ingeschreven) {
      inschrijvingHuidigSeizoen = user.seizoenen[huidigSeizoen]
      inschrijvingHuidigSeizoen.inschrijvingInOrde = true
    } else {
      inschrijvingHuidigSeizoen.inschrijvingInOrde = false
      inschrijvingHuidigSeizoen.ploeg = ""
    }

    var familieAanwezig = false
    var familie = []
    if(user.familie !== undefined) {
      familieAanwezig = true

      familie = Object.values(user.familie);
    }

    const vandaag = new Date()
    var agendaGefilterd = agenda.filter(a => {var datum = a.datum; var omschrijving = a.omschrijving
      var arrayDatum = datum.split("/");

      const dagAgenda = arrayDatum[0]
      const maandAgenda = Number(arrayDatum[1])
      var jaarAgenda = arrayDatum[2]
      if(jaarAgenda < 2000) {
        jaarAgenda = "20"+String(jaarAgenda)
      }
  
      const dateString = jaarAgenda+"-"+maandAgenda+"-"+dagAgenda
  
      return (new Date(dateString) >= vandaag);
     });

     agendaGefilterd = agendaGefilterd.slice(0,3)

    return (
      <div>
        <Welkom/>
          <div id="ledenpagina">

            {/* Begroeting */}

            <div className="ledenpagina-begroeting">
              <LinkContainer to={ROUTES.ACCOUNT}>
                <div>
                  <div id="accountFotoLedenpagina" style={{
                      backgroundImage: userfoto
                    }}></div>
                </div>
              </LinkContainer>
              <h3 id="begroeting">{begroeting}<br/> {user.voornaam}!</h3>
            </div>

              {/* Inschrijven */}

              <div className="moduleLedenportaal" id="inschrijvingen">
                <h3>Inschrijvingen (seizoen {huidigSeizoen})</h3>

                  <div style={{padding: "10px 20px"}}>
                    <h6>Jouw inschrijvingen voor dit seizoen:</h6>
                    <Table>
                      <thead>
                        <tr>
                          <th style={{width: "40%"}}>Naam</th>
                          <th style={{width: "20%"}}>Type account</th>
                          <th style={{width: "20%"}}>Status</th>
                          <th style={{width: "20%"}}></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{user.voornaam} {user.achternaam} </td>
                          <td>{user.isOuder && !user.isSpelend ? ("Ouder account") : ("Speler account")}</td>
                          {inschrijvingHuidigSeizoen.inschrijvingInOrde ? (
                            <td><div className="statusInschrijvingBalon" style={{backgroundColor: "#16a085"}}>In orde!</div></td>
                          ) : (
                            <td><div className="statusInschrijvingBalon" style={{backgroundColor: "#f39c12"}}>Nog in te schrijven</div></td>
                          ) }
                          {inschrijvingHuidigSeizoen.inschrijvingInOrde ? (
                            <td><i className="fa-solid fa-check" style={{color: "green"}}></i></td>
                          ) : (
                            <td>
                              <LinkContainer to={ROUTES.HERINSCHRIJVEN}>
                                <Button>Inschrijven</Button>
                              </LinkContainer>
                            </td>
                          ) }
                        </tr>
                        {familie.map(lid => {

                          var inschrijvingGezinslidInOrde = true

                          if(lid.seizoenen !== undefined && lid.seizoenen[huidigSeizoen] !== undefined) {
                            inschrijvingGezinslidInOrde = lid.seizoenen[huidigSeizoen].ingeschreven
                          } else {
                            inschrijvingGezinslidInOrde = false
                          }

                          return(
                            <tr>
                              <td>{lid.voornaam}</td>
                              <td>Speler account</td>
                              {inschrijvingGezinslidInOrde ? (
                                <td><div className="statusInschrijvingBalon" style={{backgroundColor: "#16a085"}}>In orde!</div></td>
                              ) : (
                                <td><div className="statusInschrijvingBalon" style={{backgroundColor: "#f39c12"}}>Nog in te schrijven</div></td>
                              ) }
                              {inschrijvingGezinslidInOrde ? (
                                <td><i className="fa-solid fa-check" style={{color: "green"}}></i></td>
                              ) : (
                                <td>
                                  <LinkContainer to={{ state: { lid }, pathname: `${ROUTES.HERINSCHRIJVENFAMILIELIDOPNIEUW}/${lid.uid}`}}>
                                    <Button>Inschrijven</Button>
                                  </LinkContainer>
                                </td>
                              ) }
                            </tr>
                          )
                          })}
                      </tbody>
                    </Table>

                </div>
                <LinkContainer to={ROUTES.HERINSCHRIJVENFAMILIELID}>
                    <Button id="buttonNieuwGezinslid">Nieuw gezinslid inschrijven</Button>
                  </LinkContainer>
              </div>

              <div className='sidebar-ledenportaal moduleLedenportaal'>
                <h3>Volgende activiteiten</h3>
                <div className="activiteiten-grid">
                  {agendaGefilterd.map(agendapunt => {
                    return(
                    <div className="agendaPuntBlokje">
                      <div className="agendaPuntBlokje-preblok"></div>
                      <div className="agendaPuntBlokje-tekst">
                        <div>
                          <div style={{fontWeight: "600", fontSize: "1.1rem"}}>{agendapunt.omschrijving}</div>
                          <div>{agendapunt.datum} - {agendapunt.plaats}</div>
                        </div>
                      </div>
                      <div className="agendaPuntBlokje-uur">
                        {agendapunt.startuur !== "" && agendapunt.startuur !== undefined ? (
                        <div>
                          <span><i className="fa-solid fa-clock"></i><br/>
                          {agendapunt.startuur}</span>
                        </div>
                        ) : (
                          <div>
                          <span><i className="fa-solid fa-clock"></i><br/>
                          OOuOO</span>
                        </div>
                        )}
                      </div>
                    </div>
                    )
                  })}
                </div>
                <div>

                </div>
              </div>



            </div>
    </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(withEmailVerification, withAuthorization(condition),)(Ledenpagina);
