import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import "./style.css"

class Privacy extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {

    return(
      <div id="privacyDisplay">
        <div className="privacyText">
          <br/>
          <h1>Privacy Verklaring</h1>
          <br/>
              <p>In deze privacyverklaring wordt informatie gegeven over welke gegevens verzameld worden en het hiermee omgegaan wordt.</p>
              <p>VOC Schriek houdt zich in alle gevallen aan de toepasselijke wet- en regelgeving, waaronder de Algemene Verordening Gegevensbescherming (AVG), elders ook GDPR genoemd.</p>
              <p>Dit betekent dat wij:</p>
              <ul>
              <li>persoonsgegevens verwerken in overeenstemming met het doel waarvoor deze zijn verstrekt, deze doelen en type persoonsgegevens zijn beschreven in deze Privacyverklaring;</li>
              <li>verwerking van persoonsgegevens beperken tot enkel die gegevens die minimaal nodig zijn voor de doeleinden waarvoor ze worden verwerkt;</li>
              <li>vragen om uw uitdrukkelijke toestemming wanneer deze nodig is;</li>
              <li>passende technische en organisatorische maatregelen nemen om persoonsgegevens te beveiligen;</li>
              <li>geen persoonsgegevens doorgeven aan andere partijen, tenzij dit nodig is voor uitvoering van de doeleinden waarvoor ze zijn verstrekt;</li>
              <li>de rechten omtrent persoonsgegevens respecteren.</li>
              </ul>
              <p>Bij vragen over deze privacyverklaring kan steeds contact opgenomen via onderstaande contactgegevens:</p>
              <p>VOC Schriek</p>
              <p>Vaes-Oogstlaan 15</p>
              <p>2220 Heist-op-den-Berg</p>
              <a href="mailto:volleybalclubschriek@gmail.com">volleybalclubschriek@gmail.com</a>
              <br/>
              <h3>Waarom verwerken wij persoonsgegevens?</h3>
              <br/>
              <p>Persoonsgegevens worden door VOC Schriek verwerkt ten behoeve van de volgende doeleinden en rechtsgronden:</p>
              <ul>
              <li>om de gevraagde dienstverlening te kunnen verstrekken</li>
              <li>om te kunnen deelnemen aan de activiteiten van VOC Schriek</li>
              <li>Het versturen van nieuwsbrieven en uitnodigingen (toestemming betrokkene). Deze toestemming kan te allen tijde terug ingetrokken worden</li>
              </ul>
              <p>We gebruiken de verzamelde gegevens alleen voor de doeleinden waarvoor we de gegevens hebben verkregen.</p>
              <br/>
              <h3>Verstrekking aan derden</h3>
              <br/>
              <p>Persoonsgegevens die VOC Schriek verwerkt, worden enkel gedeeld met derden indien dit noodzakelijk is voor het verstrekken van de dienst waarvoor zij ingezameld werden of indien er een wettelijke basis of verplichting voor geldt.</p>
              <p>Met deze partijen (verwerkers) maken wij de nodige afspraken om de beveiliging van de persoonsgegevens te waarborgen.</p>
              <br/>
              <h3>Minderjarigen</h3>
              <br/>
              <p>Wij verwerken enkel en alleen persoonsgegevens van minderjarigen indien daarvoor schriftelijke toestemming is gegeven door de ouder of wettelijke vertegenwoordiger.</p>
              <br/>
              <h3>Bewaartermijn</h3>
              <br/>
              <p>VOC Schriek bewaart persoonsgegevens niet langer dan noodzakelijk voor het doel waarvoor deze zijn verstrekt dan wel op grond van de wet is vereist.</p>
              <br/>
              <h3>Beveiliging van de gegevens</h3>
              <br/>
              <p>Wij hebben passende technische en organisatorische maatregelen genomen om persoonsgegevens te beschermen.</p>
              <br/>
              <h3>Uw rechten omtrent uw gegevens</h3>
              <br/>
              <p>U heeft recht op inzage en recht op correctie of verwijdering van de persoonsgegeven welke wij van u ontvangen hebben. Bovenaan deze privacyverklaring staat hoe je contact met ons kan opnemen.</p>
              <p>Tevens kan bezwaar <em>(verzet)</em> gemaakt worden tegen de verwerking van de persoonsgegevens (of een deel hiervan) door ons of door één van onze verwerkers. Dit heeft impact op het al dan niet verlenen van de dienst.</p>
              <p>Ook hebt u het recht om de door u verstrekte gegevens door ons te laten overdragen aan uzelf of in opdracht van u direct aan een andere partij. Wij kunnen u vragen om u te legitimeren voordat wij gehoor kunnen geven aan voornoemde verzoeken.</p>
              <p>Er geldt een uitzonderingsgrond op het verstrekken van informatie&nbsp; indien dit onevenredig veel moeite kost.</p>
              <br/>
              <h3>Klachten</h3>
              <br/>
              <p>Mocht u een klacht hebben over de verwerking van uw persoonsgegevens dan vragen wij u hierover direct contact met ons op te nemen. Als VOC Schriek geen gehoor geeft aan uw klacht binnen de 30 dagen nadat de klacht ontvangen werd, kan de klacht ingediend worden bij de toezichthoudende autoriteit op het gebied van privacy bescherming.</p>
              <br/>
              <h3>Archivering</h3>
              <br/>
              <p>Er kan een verdere verwerking gebeuren met het oog op archivering in het algemeen belang, wetenschappelijk of historisch onderzoek of statistische doeleinden. Dit wordt niet beschouwd als onverenigbaar met de aanvankelijke doeleinden. In dit kader mogen persoonsgegevens langer bewaard worden.</p>
              <br/>
              <h3>Wijziging privacyverklaring</h3>
              <p>VOC Schriek kan zijn privacyverklaring wijzigen. De wetgeving is in volle ontwikkeling waardoor deze een impact kan hebben op deze privacyverklaring. De laatste wijziging gebeurde op 1 juni 2018.</p>
              <br/>
              <h3>Contactinformatie CBPL</h3>
              <p>GBA is de GegevensBeschermingsAutoriteit. Meer informatie over privacy en GDPR kan je vinden op <a href="https://www.gegevensbeschermingsautoriteit.be">https://www.gegevensbeschermingsautoriteit.be</a></p>
              <br/>
              <h3>Verklaring van afwijzing van verantwoordelijkheid</h3>
              <br/>
              <p>VOC Schriek beoogt met deze website de toegang van het publiek tot informatie over haar initiatieven te verbeteren. Het is de bedoeling dat deze informatie bijgewerkt en juist is. Indien wij opmerkzaam worden gemaakt op onjuistheden, zullen wij trachten deze te verbeteren.</p>
              <p>VOC Schriek kan evenwel geen enkele verantwoordelijkheid of aansprakelijkheid voor de op deze site verstrekte gegevens aanvaarden.
              Deze gegevens:</p>
              <ul>
              <li>omvatten uitsluitend informatie van algemene aard die niet op de specifieke omstandigheden van enige persoon of entiteit gericht is;</li>
              <li>zijn niet noodzakelijk allesomvattend, volledig, nauwkeurig of bijgewerkt;</li>
              <li>zijn soms gekoppeld aan externe sites waarover VOC Schriek geen zeggenschap heeft en waarvoor de VOC Schriek geen verantwoordelijkheid draagt;</li>
              <li>vormen geen professioneel of rechtskundig advies (voor specifiek advies dient u steeds een daarvoor geëigende deskundige te raadplegen).</li>
              </ul>
              <p>Er zij op gewezen dat niet kan worden gewaarborgd dat een document dat on-line beschikbaar is, een exacte weergave van een officieel goedgekeurde tekst vormt.</p>
              <p>Sommige gegevens of informatie op onze site kunnen evenwel in files of formats zijn gecreëerd of gestructureerd die niet foutloos zijn;
              Wij streven ernaar onderbrekingen door technische fouten zo veel mogelijk te voorkomen. Wij kunnen echter niet garanderen dat onze dienst geen onderbreking zal kennen of door andere problemen kan worden beïnvloed.
              VOC Schriek aanvaardt geen aansprakelijkheid voor problemen die zich als gevolg van het gebruik van deze site of van andere daarmee verbonden externe sites mochten voordoen.</p>
        </div>
      </div>
    )
}};


export default Privacy
