import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { PasswordForgetLink } from '../paswoordvergeten/password';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import "./style.css";
import Button from 'react-bootstrap/Button';
import { LinkContainer } from "react-router-bootstrap";


const SignInPage = () => (
  <div id="login">
    <div  id="loginform" >
      <div>
        <h1>Inloggen</h1>
        <br/>
        <SignInForm />
        <br/>
        <PasswordForgetLink />
        <br/>
        <p>Wil je graag lid worden? <LinkContainer to={ROUTES.LIDWORDEN}><div>Klik dan <strong style={{cursor: "pointer"}}>hier</strong>!</div></LinkContainer></p>
      </div>
    </div>
  </div>
);
const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};
class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  onSubmit = event => {
    const { email, password } = this.state;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.LEDENPORTAAL);
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    var { email, password, error } = this.state;
    const isInvalid = password === '' || email === '';

    email = email.replace(/ /g,'')

    return (
      <div>
        <form className="inloggegevensform" onSubmit={this.onSubmit}>
          <input
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="E-mailadres"
          />
          <input
            name="password"
            value={password}
            onChange={this.onChange}
            type="password"
            placeholder="Wachtwoord"
          />
        <br />
          <Button variant="primary" disabled={isInvalid} type="submit" style={{width: "70%", margin: "auto", marginWidth: "200px"}}>
            Inloggen
          </Button>
          {error && <p>{error.message}</p>}
        </form>
      </div>
    );
  }
}
const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

export default SignInPage;
export { SignInForm };
