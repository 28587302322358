import React from 'react';
import "./Lidworden.css";
import { OnzePloegen } from './OnzePloegen';
import { Lidgeld } from './Lidgeld';
import axios from 'axios';

import {ButtonOne} from '../../../constants/button';
import { LinkContainer } from "react-router-bootstrap";
import Form from 'react-bootstrap/Form'
import * as ROUTES from '../../../constants/routes';

export class Lidworden extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      naam: "",
      geboortedatum: "",
      email: "",
      gsm: "",
      opmerking: "",
      ingediend: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onChangeText = event => {
   this.setState({ [event.target.name]: event.target.value });
  };

  indienen() {
  if(this.state.naam === "" || this.state.geboortedatum === "" || this.state.email === "" || this.state.gsm === "") {
    alert("Gelieve alle velden in te vullen")
  } else {

    axios.get('https://us-central1-ledenportaal-85bb5.cloudfunctions.net/emailproeftraining', {params: {naam: this.state.naam, geboortedatum: this.state.geboortedatum, email: this.state.email, gsm: this.state.gsm, opmerking: this.state.opmerking}})

    this.setState({
      ingediend: true,
      naam: "",
      geboortedatum: "",
      email: "",
      gsm: "",
      opmerking: "",
    })

  }
}

  render() {

    var {ingediend} = this.state

    return (
      <div>
        <div className="backgroundLid"></div>
        <div className="white-bg"></div>
        <div className="activiteit-text">
            <h1 className="activiteit-titel">Lid worden?</h1>
            <div className="onderlijn"></div>
            <div className="d-block">
              <p>Klein of groot, jong of oud, ervaren of een groentje, voor ons maakt het allemaal niet uit.</p>
              <p>Als jij wilt volleyballen, willen wij je daar graag bij helpen.</p>
              <br></br>
              <p>Ben je nog niet zeker of volleybal wel iets voor jou is?</p>
              <p>Bij ons mag je 2X gratis komen meetrainen, om eens te proeven van het Schriekse volleybal.</p>
              <p><strong>Vraag hiernaar onderaan deze pagina!</strong></p>

            <h1 className="subtitel">Onze ploegen</h1>
            <div className="onderlijn"></div>
            <OnzePloegen/>
            <br></br>
            <p>Als je niet goed weet bij welke groep je hoort, <br/>neem dan zeker even contact met ons op (volleybalclubschriek@gmail.com)</p>
            <h1 className="subtitel">Lidgeld</h1>
            <div className="onderlijn"></div>
            <Lidgeld />
            <h1 className="subtitel">Proeftrainingen aanvragen</h1>
            <div className="onderlijn"></div>
            {!ingediend ? (
              <Form className="infoTabel">
                <Form.Group className="mb-3" controlId="formGridPloegnaam">
                  <Form.Label style={{display: "flex"}}>Voor- en achternaam<div style={{color: "red"}}>*</div></Form.Label>
                  <Form.Control placeholder="Voor- en achternaam" name="naam" value={this.state.naam} onChange={this.onChangeText}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGridPloegnaam">
                  <Form.Label style={{display: "flex"}}>Geboortedatum<div style={{color: "red"}}>*</div></Form.Label>
                  <Form.Control type="date" placeholder="2020-01-01" name="geboortedatum" value={this.state.geboortedatum} onChange={this.onChangeText}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label style={{display: "flex"}}>Email<div style={{color: "red"}}>*</div></Form.Label>
                  <Form.Control type="email" placeholder="naam@voorbeeld.be" name="email" value={this.state.email} onChange={this.onChangeText}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label style={{display: "flex"}}>GSM nummer<div style={{color: "red"}}>*</div></Form.Label>
                  <Form.Control placeholder="0479/99.99.99" name="gsm" value={this.state.gsm} onChange={this.onChangeText}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label style={{display: "flex"}}>Bericht:</Form.Label>
                  <Form.Control as="textarea" rows={3} name="opmerking" value={this.state.opmerking} onChange={this.onChangeText}/>
                </Form.Group>
                <ButtonOne text="Proeftrainingen aanvragen" onClick={() => this.indienen()} />
              </Form>
            ) : (
              <div style={{height: "250px"}}>
                <h3>Je aanvraag werd doorgestuurd!</h3>
                <h6>Binnenkort nemen we contact met je op!</h6>
              </div>
            )}
            <br/>
            <h1 className="subtitel">Inschrijven?</h1>
            <div className="onderlijn"></div>
            <p>Wil je graag lid worden van onze toffe club? <br/>
            Klik op onderstaande knop en schrijf je in via Volley Vlaanderen.
            <br/>Nadat je inschrijving is verwerkt, nodigen we je uit op ons ledenportaal en krijg je de nodige info over het betalen van het lidgeld.
            </p>
            <LinkContainer to={ROUTES.INSCHRIJVEN}><div><ButtonOne text="Inschrijven bij VOC Schriek" /></div></LinkContainer>
            <br/><br/>
            <p>Heb je toch nog wat vragen? <br/>Stuur ons dan zeker een mailtje (<a href="mailto:volleybalclubschriek@gmail.com?Subject=Ik%20heb%20een%20vraag">volleybalclubschriek@gmail.com</a>)</p>
            <br>
            </br>
            <br></br>
          </div>
        </div>
      </div>
    )
  }
}
