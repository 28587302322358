import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification} from '../../session';
import { withFirebase } from '../../Firebase';

import * as ROLES from '../../../constants/roles';

class OverzichtAanwezighedenBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      huidigSeizoen: "0000-0000",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.firebase.huidigSeizoen().on('value', snapshot => {
      this.setState({
        huidigSeizoen: snapshot.val(),
      })
    })

  };

  componentWillUnmount() {

  }

  onChangeText = event => {
   this.setState({ [event.target.name]: event.target.value });
  };

  render(){
    const {huidigSeizoen} = this.state
  return (
    <div id="vraagstellen">
      <div>
        <div>
          <h1>Overzicht aanwezigheden trainingen </h1>
          <h4>(Seizoen: {huidigSeizoen})</h4>
        </div>
        <div>
          <h3>Ingevulde trainingen</h3>
        </div>
        <div>
          <h3>Niet ingevulde trainingen</h3>
        </div>
        <div>
          <h3>Overzichten vorige seizoenen</h3>
          <p>2020-2021</p>
        </div>
      </div>
    </div>
  );
}}

const condition = authUser =>
  authUser && (!!authUser.roles[ROLES.ADMIN] || !!authUser.roles[ROLES.TRAINER]);

const OverzichtAanwezigheden = withFirebase(OverzichtAanwezighedenBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(OverzichtAanwezigheden);
