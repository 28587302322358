import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as ROUTES from '../../../constants/routes';
import { LinkContainer } from "react-router-bootstrap";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../../session';

class GegevensaanpassenGezin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      ...props.location.state,
      aangepast: false,
      user: "",
      uid: "",
      achternaam:"",
      geboortedatum:"",
      gemeente:"",
      ploeg:"",
      postcode:"",
      rijksregister:"",
      straatnr:"",
      telefoon:"",
      voornaam:"",
      roles:[],
      username:"",
      email:"",
      geslacht:"",
      mutualiteit:"",
      extragegevens:"",
    };

  }
  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({ loading: true });
    console.log(this.props.match.params.uid)
    this.props.firebase.user(this.props.match.params.id).on('value', snapshot => {
      const userObject = snapshot.val();
      if (userObject) {
        this.setState({
          user: userObject,
          voornaam: userObject.voornaam,
          achternaam: userObject.achternaam,
          geboortedatum: userObject.geboortedatum,
          rijksregister: userObject.rijksregister,
          telefoon: userObject.telefoon,
          mutualiteit: userObject.mutualiteit,
          straatnr: userObject.straatnr,
          postcode: userObject.postcode,
          gemeente: userObject.gemeente,
          extragegevens: userObject.extragegevens,
          geslacht: userObject.geslacht,
          ploeg: userObject.ploeg,
          loading: false,
          uid: this.props.match.params.id,
        });
      } else {
        this.setState({
          user: null,
          voornaam: null,
          achternaam: null,
          geboortedatum: null,
          rijksregister: null,
          telefoon: null,
          mutualiteit: null,
          straatnr: null,
          postcode: null,
          gemeente: null,
          extragegevens: null,
          ploeg: null,
          geslacht: null,
          loading: false });
      }
    });
  }

  componentWillUnmount() {
    this.props.firebase.user().off();
  }

  onChangeVoornaam = event => {
    this.setState({ voornaam: event.target.value });
  };

  onChangeAchternaam = event => {
    this.setState({ achternaam: event.target.value });
  };

  onChangeStraatnr = event => {
    this.setState({ straatnr: event.target.value });
  };

  onChangePostcode = event => {
    this.setState({ postcode: event.target.value });
  };

  onChangeGemeente = event => {
    this.setState({ gemeente: event.target.value });
  };

  onChangeTelefoonnr = event => {
    this.setState({ telefoon: event.target.value });
  };

  onChangeRijksregister = event => {
    this.setState({ rijksregister: event.target.value });
  };

  onChangeGeboortedatum = event => {
    this.setState({ geboortedatum: event.target.value });
  };

  onChangePloeg = event => {
    this.setState({ ploeg: event.target.value });
  };

  onChangeGeslacht = event => {
    this.setState({ geslacht: event.target.value });
  };

  onChangeMutualiteit = event => {
    this.setState({ mutualiteit: event.target.value });
  };

  onChangeExtragegevens = event => {
    this.setState({ extragegevens: event.target.value });
  };

  onEditGegevens = (user, uid, voornaam, achternaam, mutualiteit, extragegevens, geslacht) => {
    const { ...userSnapshot } = user;
    this.props.firebase.user(uid).set({
      ...userSnapshot,
      voornaam,
      achternaam,
      mutualiteit,
      extragegevens,
      geslacht,
    });
    alert('Gegevens opgeslagen!')
    window.location.replace("./Ledenportaal/Account")
  };

  render() {
    var { uid, voornaam, achternaam, geslacht } = this.state;
    console.log(uid)
    var isInvalid = voornaam === '' || achternaam === '' || geslacht === "";
    return(
    <div id="herinschrijven">
      <div>
      <br />
      <AuthUserContext.Consumer>
        {authUser => (
          <div id="grid-herinschrijven-inschrijvenGezin">
            <h2>Gegevens gezinslid aanpassen </h2>
            <br />
            <div className="grid-container">
              <Form.Row>
                <Form.Label column lg={4}>
                  Voornaam: *
                </Form.Label>
                <Col>
                  <Form.Control type="text" placeholder="Voornaam"  value={this.state.voornaam} onChange={this.onChangeVoornaam}/>
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={4}>
                  Achternaam: *
                </Form.Label>
                <Col>
                  <Form.Control type="text" placeholder="Achternaam" value={this.state.achternaam}  onChange={this.onChangeAchternaam}/>
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={4} >Geslacht: *</Form.Label>
                <Col>
                  <Form.Control as="select" value={this.state.geslacht}  onChange={this.onChangeGeslacht}>
                    <option value="">Kies...</option>
                    <option value="Man">Man</option>
                    <option value="Vrouw">Vrouw</option>
                    <option value="Onzijdig">Genderneutraal</option>
                  </Form.Control>
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={4}>
                  Mutualiteit:
                </Form.Label>
                <Col>
                  <Form.Control type="text" placeholder="Mutualiteit" value={this.state.mutualiteit}  onChange={this.onChangeMutualiteit}/>
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Label column lg={4}>
                  Extra gegevens:
                </Form.Label>
                <Col>
                  <Form.Control as="textarea" rows="5" placeholder="Extra gegevens" value={this.state.extragegevens}  onChange={this.onChangeExtragegevens}/>
                </Col>
              </Form.Row>
            </div>
            <br />
            <br />
            <Button variant="primary" size="lg" active disabled={isInvalid} onClick={() => {this.onEditGegevens(this.state.user, this.state.uid, this.state.voornaam, this.state.achternaam, this.state.mutualiteit, this.state.extragegevens, this.state.geslacht)}}>Gegevens aanpassen</Button>{' '}
            <LinkContainer to={ROUTES.ACCOUNT}>
              <Button variant="secondary" size="lg" active>
                Ga terug
              </Button>
            </LinkContainer>
            <br />
        </div>
        )}
      </AuthUserContext.Consumer>
      </div>
    </div>
    )
  }
}

const condition = authUser => authUser ;

const Gegevens = withFirebase(GegevensaanpassenGezin);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase,
)(Gegevens);
