import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification} from '../../session';
import { withFirebase } from '../../Firebase';

import foto from './vocfinancien.png';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Mollie from './Mollie'
import {DatumFromFirebaseServerToString} from '../../../functions/DatumFirebase'
import * as ROLES from '../../../constants/roles';

class FinancieelBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      huidigSeizoen: "0000-0000",
      bestellingen: [],
      search: "",
      nummer: 0
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.firebase.huidigSeizoen().on('value', snapshot => {
      this.setState({
        huidigSeizoen: snapshot.val(),
      })
    })

    this.props.firebase.bestellingen().on('value', snapshot => {
      const usersObject = snapshot.val();

      if (usersObject !== undefined && usersObject !== null) {

        const usersList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));

        this.setState({
          bestellingen: usersList,
          loading: false,
        });
      } else {
        this.setState({
          bestellingen: [],
          loading: false,
        });
      }
    });



  };


  onChangeText = event => {
   this.setState({ [event.target.name]: event.target.value });
  };

  onChangeSearch = event => {
    this.setState({ search: event.target.value });
  };

  vorigeTien() {
    var cijfer = this.state.nummer

    if(cijfer >= 10) {
      var nieuwCijfer = cijfer - 10
      this.setState({
        nummer: nieuwCijfer
      })
    }
  }

  volgendeTien(betalingen) {
    var cijfer = this.state.nummer
    var length = betalingen.length

    if(cijfer < length - 10) {
      var nieuwCijfer = cijfer + 10
      this.setState({
        nummer: nieuwCijfer
      })
    }
  }

  overgeschreven(bestelling) {
    // eslint-disable-next-line
    const bevestiging = window.confirm("Ben je zeker dat "+bestelling.naam+" ("+bestelling.omschrijving+")"+ " betaald heeft via overschrijving?")
    const vandaag = new Date()
    if (bevestiging) {
      this.props.firebase.bestelling(bestelling.uid).on('value', snapshot => {
        const { ...userSnapshot } = snapshot.val();

        this.props.firebase.bestelling(bestelling.uid).set({
          ...userSnapshot,
          status: "Betaald",
          betaaldOp: DatumFromFirebaseServerToString(vandaag)
        })

      })
      if(bestelling.ouderUid === undefined || bestelling.ouderUid === null) {

        this.props.firebase.userBestelling(bestelling.userUid, bestelling.uid).on('value', snapshot => {
          const { ...userSnapshot } = snapshot.val();

          this.props.firebase.userBestelling(bestelling.userUid, bestelling.uid).set({
            ...userSnapshot,
            status: "Betaald",
            betaaldOp: DatumFromFirebaseServerToString(vandaag)
          })
        })

      } else {

        this.props.firebase.userBestelling(bestelling.ouderUid, bestelling.uid).on('value', snapshot => {
          const { ...userSnapshot } = snapshot.val();

          this.props.firebase.userBestelling(bestelling.ouderUid, bestelling.uid).set({
            ...userSnapshot,
            status: "Betaald",
            betaaldOp: DatumFromFirebaseServerToString(vandaag)
          })
        })

      }

      this.props.firebase.userBestellingSeizoen(bestelling.userUid, bestelling.seizoen).on('value', snapshot => {
        const { ...userSnapshot } = snapshot.val();

        this.props.firebase.userBestellingSeizoen(bestelling.userUid, bestelling.seizoen).set({
          ...userSnapshot,
          status: "Betaald",
          betaaldOp: DatumFromFirebaseServerToString(vandaag),
          betaald: true,
          ingeschreven: true,
          foutmeldingBetaling: "",
          emailBetaling: true,
        })
      })

    }
  }

  render(){
    var { bestellingen, huidigSeizoen, nummer } = this.state

    bestellingen.sort(function(a, b) {

        return new Date(b.aanmaakdatum) - new Date(a.aanmaakdatum);
    });

    var betalingsFilter = [];
    var searchFilter = this.state.search;
    searchFilter = searchFilter.toLowerCase();

    if (searchFilter === "") {
      betalingsFilter = bestellingen;
    } else {
      betalingsFilter = bestellingen.filter(d => {var naam = d.naam; var omschrijving = d.omschrijving

                               return (naam.toLowerCase().includes(searchFilter) || omschrijving.toLowerCase().includes(searchFilter));
                              });
    }

    const tienBestellingen = betalingsFilter.slice(nummer, nummer+10);

  return (
    <div id="FinancieelView">
        <div>
          <img id="FinancieelFoto" src={foto} alt="haha"/>
          <h3>Betalingen Lidgeld {huidigSeizoen}</h3>
          <div>
            <Form.Control style={{width: "40%", minWidth: "300px", margin: "15px auto" }} placeholder="betaling van lid zoeken" value={this.state.search} onChange={this.onChangeSearch} />
          </div>
          <Table striped bordered hover size="sm" responsive>
            <thead>
              <tr>
                <th>id</th>
                <th>Datum</th>
                <th>Naam</th>
                <th>Omschrijving</th>
                <th>Bedrag</th>
                <th>Betaalwijze</th>
                <th>Status</th>
                <th>Betaald op</th>
                <th>Betaald?</th>
              </tr>
            </thead>
            <tbody>
              {tienBestellingen.map(bestelling => {
                var datum = DatumFromFirebaseServerToString(new Date(bestelling.aanmaakdatum))

                var overTeSchrijven = true;
                // eslint-disable-next-line
                if(bestelling.betaalmethode === "Overschrijving" && bestelling.status !== "Over te schrijven" || bestelling.betaalmethode === "Mollie") {
                  overTeSchrijven = false;
                }

                return(
                <tr key={bestelling.uid}>
                  <td>{bestelling.uid}</td>
                    <td>{datum}</td>
                    <td>{bestelling.naam}</td>
                    <td>{bestelling.omschrijving}</td>
                    <td>{bestelling.bedrag}</td>
                    <td>{bestelling.betaalmethode}</td>
                    <td>{bestelling.status}</td>
                    <td>{bestelling.betaaldOp}</td>
                    {!!overTeSchrijven && (
                      <td onClick={() => {this.overgeschreven(bestelling)}}><i className="far fa-thumbs-up"></i></td>
                    )}
                </tr>
              )})}
            </tbody>
          </Table>
          <br/>
          <div style={{display: "flex", width: "100%", justifyContent: "center"}}>
            {nummer !== 0 ? (
              <Button onClick={() => this.vorigeTien()}>Vorige 10 </Button>
            ) : (
              <Button disabled>Vorige 10 </Button>
            )}
            <p style={{margin: "0 10px"}}> - {nummer} tot {nummer+10} - </p>
            {nummer < bestellingen.length - 10 ? (
              <Button onClick={() => this.volgendeTien(bestellingen)}>Volgende 10</Button>
            ) : (
              <Button disabled>Volgende 10</Button>
            )}
          </div>
          <br/>
        </div>
        <br/>
        <Mollie />
    </div>
  );
}}

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN]

const Financieel = withFirebase(FinancieelBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(Financieel);
