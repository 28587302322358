import React from 'react';
import "./style.css";
import * as ROUTES from '../../../constants/routes';
import * as ROLES from '../../../constants/roles';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withEmailVerification } from '../../session';

class Berichtsturen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message:"",
      titel:"",
      users: this.props.users,
      ontvanger:"",
      ploeg1: "",
      ploeg2: "",
      ploeg3: "",
      ploeg4: "",
      ploeg5: "",
      ploeg6: "",
      ploeg7: "",
      ploeg8: "",
      ploeg9: "",
      ploeg10: "",
      ploeg11: "",
      ploeg12: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

      if(this.state.users.length === 0) {
        window.location.replace(`${ROUTES.BESTUURDASHBOARD}`)
      }

      var huidigSeizoen = ""
      this.props.firebase.huidigSeizoen().on('value', snapshot => {
        huidigSeizoen = snapshot.val();
        this.setState({
          huidigSeizoen: snapshot.val(),
        })
      })

      this.props.firebase.seizoen(huidigSeizoen).on('value', snapshot => {
        const seizoen = snapshot.val();
        this.setState({
          ploeg1: seizoen.ploeg1,
          ploeg2: seizoen.ploeg2,
          ploeg3: seizoen.ploeg3,
          ploeg4: seizoen.ploeg4,
          ploeg5: seizoen.ploeg5,
          ploeg6: seizoen.ploeg6,
          ploeg7: seizoen.ploeg7,
          ploeg8: seizoen.ploeg8,
          ploeg9: seizoen.ploeg9,
          ploeg10: seizoen.ploeg10,
          ploeg11: seizoen.ploeg11,
          ploeg12: seizoen.ploeg12,
        })
      })
    }

  handleEditorChange = (content, editor) => {
     this.setState({
       message: content
     })
   }

   onChange = event => {
     this.setState({ [event.target.name]: event.target.value });
   };

   sendEmail(emails) {
     if(this.state.ontvanger !== "" && this.state.titel !== "" && this.state.message !== "<p></p>") {
       var bevestiging = window.confirm("Ben je zeker dat het verstuurd mag worden?")
       if(bevestiging){
         const titel = this.state.titel
         const message = this.state.message
         axios.get('https://us-central1-ledenportaal-85bb5.cloudfunctions.net/emailUitsturenViaPortaal', {params: {titel: titel,  message: message, emails: emails}})
         alert("verstuurd!")
         this.setState({
           titel: "",
           message: "<p></p>",
           ontvanger: "",
         })
       }
     } else {
       alert("Je hebt nog niets alles ingevuld")
     }

   }

  render() {
    var { titel, users } = this.state;

    var opties = [
      {naam: "Iedereen"},
      {naam: "Trainers"},
      {naam: "Ouders"},
      {naam: "Bestuur"}
    ];

    if(this.state.ploeg1 !== "") {
      opties.push({naam: this.state.ploeg1 + " + trainer"})
    }

    if(this.state.ploeg2 !== "") {
      opties.push({naam: this.state.ploeg2 + " + trainer"})
    }

    if(this.state.ploeg3 !== "") {
      opties.push({naam: this.state.ploeg3 + " + trainer"})
    }

    if(this.state.ploeg4 !== "") {
      opties.push({naam: this.state.ploeg4 + " + trainer"})
    }

    if(this.state.ploeg5 !== "") {
      opties.push({naam: this.state.ploeg5 + " + trainer"})
    }

    if(this.state.ploeg6 !== "") {
      opties.push({naam: this.state.ploeg6 + " + trainer"})
    }

    if(this.state.ploeg7 !== "") {
      opties.push({naam: this.state.ploeg7 + " + trainer"})
    }

    if(this.state.ploeg8 !== "") {
      opties.push({naam: this.state.ploeg8 + " + trainer"})
    }

    if(this.state.ploeg9 !== "") {
      opties.push({naam: this.state.ploeg9 + " + trainer"})
    }

    if(this.state.ploeg10 !== "") {
      opties.push({naam: this.state.ploeg10 + " + trainer"})
    }

    if(this.state.ploeg11 !== "") {
      opties.push({naam: this.state.ploeg11 + " + trainer"})
    }

    if(this.state.ploeg12 !== "") {
      opties.push({naam: this.state.ploeg12 + " + trainer"})
    }

    var emailsOntvangers = []
    if(this.state.ontvanger === "Iedereen") {
      // eslint-disable-next-line
      {users.forEach((user) => {
        if(user.email !== undefined){
          emailsOntvangers.push(user.email)
        }
      });
      }
    }
    if(this.state.ontvanger === "Trainers") {
      // eslint-disable-next-line
      {users.forEach((user, i) => {
        if(user.roles !== undefined){
          if(user.roles[ROLES.TRAINER]) {
            emailsOntvangers.push(user.email)
          }
        }
      });
      }
    }
    if(this.state.ontvanger === "Ouders") {
      // eslint-disable-next-line
      {users.forEach((user, i) => {
        if(user.roles !== undefined){
          if(user.roles[ROLES.OUDER]) {
            emailsOntvangers.push(user.email)
          }
        }
      });
      }
    }

    if(this.state.ontvanger === "Bestuur") {
      // eslint-disable-next-line
      {users.forEach((user, i) => {
        if(user.roles !== undefined){
          if(user.roles[ROLES.ADMIN]) {
            emailsOntvangers.push(user.email)
          }
        }
      });
      }
    }

    if(this.state.ontvanger === this.state.ploeg1 + " + trainer") {
      // eslint-disable-next-line
      {users.forEach((user, i) => {
        if(user.ploeg === this.state.ploeg1) {
          emailsOntvangers.push(user.email)
        }
      });
      }
    }

    if(this.state.ontvanger === this.state.ploeg2 + " + trainer") {
      // eslint-disable-next-line
      {users.forEach((user, i) => {
        if(user.ploeg === this.state.ploeg2) {
          emailsOntvangers.push(user.email)
        }
      });
      }
    }

    if(this.state.ontvanger === this.state.ploeg3 + " + trainer") {
      // eslint-disable-next-line
      {users.forEach((user, i) => {
        if(user.ploeg === this.state.ploeg3) {
          emailsOntvangers.push(user.email)
        }
      });
      }
    }

    if(this.state.ontvanger === this.state.ploeg4 + " + trainer") {
      // eslint-disable-next-line
      {users.forEach((user, i) => {
        if(user.ploeg === this.state.ploeg4) {
          emailsOntvangers.push(user.email)
        }
      });
      }
    }

    if(this.state.ontvanger === this.state.ploeg5 + " + trainer") {
      // eslint-disable-next-line
      {users.forEach((user, i) => {
        if(user.ploeg === this.state.ploeg5) {
          emailsOntvangers.push(user.email)
        }
      });
      }
    }

    if(this.state.ontvanger === this.state.ploeg6 + " + trainer") {
      // eslint-disable-next-line
      {users.forEach((user, i) => {
        if(user.ploeg === this.state.ploeg6) {
          emailsOntvangers.push(user.email)
        }
      });
      }
    }

    if(this.state.ontvanger === this.state.ploeg7 + " + trainer") {
      // eslint-disable-next-line
      {users.forEach((user, i) => {
        if(user.ploeg === this.state.ploeg7) {
          emailsOntvangers.push(user.email)
        }
      });
      }
    }

    if(this.state.ontvanger === this.state.ploeg8 + " + trainer") {
      // eslint-disable-next-line
      {users.forEach((user, i) => {
        if(user.ploeg === this.state.ploeg8) {
          emailsOntvangers.push(user.email)
        }
      });
      }
    }

    if(this.state.ontvanger === this.state.ploeg9 + " + trainer") {
      // eslint-disable-next-line
      {users.forEach((user, i) => {
        if(user.ploeg === this.state.ploeg9) {
          emailsOntvangers.push(user.email)
        }
      });
      }
    }

    if(this.state.ontvanger === this.state.ploeg10 + " + trainer") {
      // eslint-disable-next-line
      {users.forEach((user, i) => {
        if(user.ploeg === this.state.ploeg10) {
          emailsOntvangers.push(user.email)
        }
      });
      }
    }
    if(this.state.ontvanger === this.state.ploeg11 + " + trainer") {
      // eslint-disable-next-line
      {users.forEach((user, i) => {
        if(user.ploeg === this.state.ploeg11) {
          emailsOntvangers.push(user.email)
        }
      });
      }
    }
    if(this.state.ontvanger === this.state.ploeg12 + " + trainer") {
      // eslint-disable-next-line
      {users.forEach((user, i) => {
        if(user.ploeg === this.state.ploeg12) {
          emailsOntvangers.push(user.email)
        }
      });
      }
    }

    return (
      <div id="moduleEmailsturen">
        <h2>Email Sturen</h2>
        <div>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Email sturen naar:</Form.Label>
            <Form.Control
              as="select"
              value={this.state.ontvanger}
              onChange={this.onChange}
              name="ontvanger"
              >
              <option></option>
              {opties.map(item => (
                <option key={item.naam}>{item.naam}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Titel email:</Form.Label>
            <Form.Control
              placeholder="titel"
              value={titel}
              onChange={this.onChange}
              name="titel"
              type="text"
              />
          </Form.Group>
          <Editor
             initialValue="<p></p>"
             init={{
               height: 500,
               menubar: false,
               plugins: [
                 'advlist autolink lists link image charmap print preview anchor',
                 'searchreplace visualblocks code fullscreen',
                 'insertdatetime media table paste code help wordcount'
               ],
               toolbar:'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
             }}
             onEditorChange={this.handleEditorChange}
             apiKey="j5l84rkk4tyq8kkhrub03ifz72qmwi2z6o7ffxbgxgqx5o22"
           />
         <br/>
         <Button onClick={() => this.sendEmail(emailsOntvangers)}>
           Email sturen
         </Button>
        </div>
      </div>
    );
  }
}


export default compose(
  withEmailVerification,
  withFirebase,
)(Berichtsturen);
