import React from 'react';
import { Table } from 'react-bootstrap';
import "./Lidworden.css";

import { withFirebase } from '../../Firebase';

class LidgeldBase extends React.Component {
  constructor() {
    super()
    this.state = {
      huidigSeizoen: "",
      ploeg1: "",
      ploeg2: "",
      ploeg3: "",
      ploeg4: "",
      ploeg5: "",
      ploeg6: "",
      ploeg7: "",
      ploeg8: "",
      ploeg9: "",
      ploeg10: "",
      ploeg11: "",
      ploeg12: "",
      lidgeldPloeg1: "",
      lidgeldPloeg2: "",
      lidgeldPloeg3: "",
      lidgeldPloeg4: "",
      lidgeldPloeg5: "",
      lidgeldPloeg6: "",
      lidgeldPloeg7: "",
      lidgeldPloeg8: "",
      lidgeldPloeg9: "",
      lidgeldPloeg10: "",
      lidgeldPloeg11: "",
      lidgeldPloeg12: "",
    }
  }

  componentDidMount() {
    var huidigSeizoen = "2023-2024"
    this.props.firebase.huidigSeizoen().on('value', snapshot => {
      huidigSeizoen = snapshot.val()
      this.setState({
        huidigSeizoen
      })
    })

      this.props.firebase.seizoen(huidigSeizoen).on('value', snapshot => {
        var seizoenObject = snapshot.val();
        if (seizoenObject) {
          this.setState({
            ploeg1: seizoenObject.ploeg1,
            ploeg2: seizoenObject.ploeg2,
            ploeg3: seizoenObject.ploeg3,
            ploeg4: seizoenObject.ploeg4,
            ploeg5: seizoenObject.ploeg5,
            ploeg6: seizoenObject.ploeg6,
            ploeg7: seizoenObject.ploeg7,
            ploeg8: seizoenObject.ploeg8,
            ploeg9: seizoenObject.ploeg9,
            ploeg10: seizoenObject.ploeg10,
            ploeg11: seizoenObject.ploeg11,
            ploeg12: seizoenObject.ploeg12,
            lidgeldPloeg1: Number(seizoenObject.lidgeldPloeg1).toFixed(2),
            lidgeldPloeg2: Number(seizoenObject.lidgeldPloeg2).toFixed(2),
            lidgeldPloeg3: Number(seizoenObject.lidgeldPloeg3).toFixed(2),
            lidgeldPloeg4: Number(seizoenObject.lidgeldPloeg4).toFixed(2),
            lidgeldPloeg5: Number(seizoenObject.lidgeldPloeg5).toFixed(2),
            lidgeldPloeg6: Number(seizoenObject.lidgeldPloeg6).toFixed(2),
            lidgeldPloeg7: Number(seizoenObject.lidgeldPloeg7).toFixed(2),
            lidgeldPloeg8: Number(seizoenObject.lidgeldPloeg8).toFixed(2),
            lidgeldPloeg9: Number(seizoenObject.lidgeldPloeg9).toFixed(2),
            lidgeldPloeg10: Number(seizoenObject.lidgeldPloeg10).toFixed(2),
            lidgeldPloeg11: Number(seizoenObject.lidgeldPloeg11).toFixed(2),
            lidgeldPloeg12: Number(seizoenObject.lidgeldPloeg12).toFixed(2),
            loading: false,
          })
      } else {
        this.setState({ inschrijvingOpen: null, loading: false });
      }
    })
  }

  render() {

    return (
      <div className="infoTabel">
        <Table striped bordered hover size="sm" className="text-black infoTabel">
          <thead>
            <tr>
              <th>Ploeg</th>
              <th>EUR</th>
            </tr>
          </thead>
          <tbody>
            {!!this.state.ploeg1 && (
              <tr key={this.state.ploeg1}>
                <td>{this.state.ploeg1}</td>
                <td>€ {this.state.lidgeldPloeg1}</td>
              </tr>
            )}
            {!!this.state.ploeg2 && (
              <tr key={this.state.ploeg2}>
                <td>{this.state.ploeg2}</td>
                <td>€ {this.state.lidgeldPloeg2}</td>
              </tr>
            )}
            {!!this.state.ploeg3 && (
              <tr key={this.state.ploeg3}>
                <td>{this.state.ploeg3}</td>
                <td>€ {this.state.lidgeldPloeg3}</td>
              </tr>
            )}
            {!!this.state.ploeg4 && (
              <tr key={this.state.ploeg4}>
                <td>{this.state.ploeg4}</td>
                <td>€ {this.state.lidgeldPloeg4}</td>
              </tr>
            )}
            {!!this.state.ploeg5 && (
              <tr key={this.state.ploeg5}>
                <td>{this.state.ploeg5}</td>
                <td>€ {this.state.lidgeldPloeg5}</td>
              </tr>
            )}
            {!!this.state.ploeg6 && (
              <tr key={this.state.ploeg6}>
                <td>{this.state.ploeg6}</td>
                <td>€ {this.state.lidgeldPloeg6}</td>
              </tr>
            )}
            {!!this.state.ploeg7 && (
              <tr key={this.state.ploeg7}>
                <td>{this.state.ploeg7}</td>
                <td>€ {this.state.lidgeldPloeg7}</td>
              </tr>
            )}
            {!!this.state.ploeg8 && (
              <tr key={this.state.ploeg8}>
                <td>{this.state.ploeg8}</td>
                <td>€ {this.state.lidgeldPloeg8}</td>
              </tr>
            )}
            {!!this.state.ploeg9 && (
              <tr key={this.state.ploeg9}>
                <td>{this.state.ploeg9}</td>
                <td>€ {this.state.lidgeldPloeg9}</td>
              </tr>
            )}
            {!!this.state.ploeg10 && (
              <tr key={this.state.ploeg10}>
                <td>{this.state.ploeg10}</td>
                <td>€ {this.state.lidgeldPloeg10}</td>
              </tr>
            )}
            {!!this.state.ploeg11 && (
              <tr key={this.state.ploeg11}>
                <td>{this.state.ploeg11}</td>
                <td>€ {this.state.lidgeldPloeg11}</td>
              </tr>
            )}
            {!!this.state.ploeg12 && (
              <tr key={this.state.ploeg12}>
                <td>{this.state.ploeg12}</td>
                <td>€ {this.state.lidgeldPloeg12}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    )
  }
}

export const Lidgeld = withFirebase(LidgeldBase);
