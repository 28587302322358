import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {SeizoenInstellingen} from './seizoeninstellingen/SeizoenInstellingen';
import {Agenda} from './agenda.js';
import Dienst from './dienst.js';
import {ActiviteitDrie} from './activiteit3.js';


import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withAuthorization, withEmailVerification } from '../../session';
import * as ROLES from '../../../constants/roles';

class Website extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      select: "",
      activiteiten: [],
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.firebase.activiteiten().on('value', snapshot => {
      const activiteitObject = snapshot.val();
      if (activiteitObject) {
        const activiteitList = Object.keys(activiteitObject).map(key => ({
          ...activiteitObject[key],
          uid: key,
        }));
        this.setState({
          activiteiten: activiteitList,
          loading: false
        });
      } else {
        this.setState({ activiteit: null, loading: false });
      }
    });
  }

  componentWillUnmount() {
    this.props.firebase.activiteiten().off();
  }

    onChangeSelect= event => {
      this.setState({ select: event.target.value });
    };

render() {
  const { select } = this.state;
  var section1 = false
  var section2 = false
  var section3 = false
  var section4 = false
  var section5 = false
  var section6 = false
  var section7 = false
  var section8 = false
  var section9 = false

  if (select === "") {
    section1 = false
    section2 = false
    section3 = false
    section4 = false
    section5 = false
    section6 = false
    section7 = false
    section8 = false
    section9 = false
  } else if (select === "seizoen") {
    section1 = true
    section2 = false
    section3 = false
    section4 = false
    section5 = false
    section6 = false
    section7 = false
    section8 = false
    section9 = false
  } else if (select === "agenda") {
    section1 = false
    section2 = true
    section3 = false
    section4 = false
    section5 = false
    section6 = false
    section7 = false
    section8 = false
    section9 = false
  } else if (select === "dienst") {
    section1 = false
    section2 = false
    section3 = true
    section4 = false
    section5 = false
    section6 = false
    section7 = false
    section8 = false
    section9 = false
  } else if (select === "activiteit1") {
    section1 = false
    section2 = false
    section3 = false
    section4 = true
    section5 = false
    section6 = false
    section7 = false
    section8 = false
    section9 = false
  } else if (select === "activiteit2") {
    section1 = false
    section2 = false
    section3 = false
    section4 = false
    section5 = true
    section6 = false
    section7 = false
    section8 = false
    section9 = false
  } else if (select === "activiteit3") {
    section1 = false
    section2 = false
    section3 = false
    section4 = false
    section5 = false
    section6 = true
    section7 = false
    section8 = false
    section9 = false
  } else if (select === "activiteit4") {
    section1 = false
    section2 = false
    section3 = false
    section4 = false
    section5 = false
    section6 = false
    section7 = true
    section8 = false
    section9 = false
  } else if (select === "bestuur") {
    section1 = false
    section2 = false
    section3 = false
    section4 = false
    section5 = false
    section6 = false
    section7 = false
    section8 = true
    section9 = false
  } else if (select === "sponsors") {
    section1 = false
    section2 = false
    section3 = false
    section4 = false
    section5 = false
    section6 = false
    section7 = false
    section8 = false
    section9 = true
  }


  return (
    <div className="App" id="websiteAanpassenpagina">
        <br />
        <h2>Website aanpassen <br/>(Under construction)</h2>
          <p>
            Wat wil je aanpassen?
          </p>
          <select onChange={this.onChangeSelect}>
            <option value="">--- Kies sectie ---</option>
            <option value="seizoen">Seizoen Instellingen</option>
            <option value="agenda">Agenda</option>
            <option value="dienst">Ploegen van dienst</option>
            <option value="activiteit1">Activiteit1</option>
            <option value="activiteit2">Activiteit2</option>
            <option value="activiteit3">Activiteit3</option>
            <option value="activiteit4">Activiteit4</option>
            <option value="bestuur">Het bestuur</option>
            <option value="sponsors">Sponsors</option>
          </select>
          <div className="divider2 div-transparent2"></div>
          {section1 ? (
            <div>
              <SeizoenInstellingen />
              <div className="divider2 div-transparent2"></div>
            </div>
          ) : (
            <div></div>
          )}
          {section2 ? (
            <div>
              <Agenda />
              <div className="divider2 div-transparent2"></div>
            </div>
          ) : (
            <div></div>
          )}
          {section3 ? (
            <div>
              <Dienst />
              <div className="divider2 div-transparent2"></div>
            </div>
          ) : (
            <div></div>
          )}
          {section4 ? (
            <div>
              <div className="divider2 div-transparent2"></div>
            </div>
          ) : (
            <div></div>
          )}
          {section5 ? (
            <div>
              <div className="divider2 div-transparent2"></div>
            </div>
          ) : (
            <div></div>
          )}
          {section6 ? (
            <div>
              <ActiviteitDrie />
              <div className="divider2 div-transparent2"></div>
            </div>
          ) : (
            <div></div>
          )}
          {section7 ? (
            <div>
              <div className="divider2 div-transparent2"></div>
            </div>
          ) : (
            <div></div>
          )}
          {section8 ? (
            <div>
              <div className="divider2 div-transparent2"></div>
            </div>
          ) : (
            <div></div>
          )}
          {section9 ? (
            <div>
              <div className="divider2 div-transparent2"></div>
            </div>
          ) : (
            <div></div>
          )}
      </div>
  )
}
}

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN] && !!authUser.roles[ROLES.WEBSITE];

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase,
)(Website);
