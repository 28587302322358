import React from 'react';
import { BrowserRouter} from 'react-router-dom';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Firebase, { FirebaseContext } from './components/Firebase';

ReactDOM.render(<FirebaseContext.Provider value={new Firebase()}><BrowserRouter><App /></BrowserRouter></FirebaseContext.Provider>, document.getElementById('root'));

serviceWorker.unregister();
