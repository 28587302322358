import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import { Table } from 'react-bootstrap';

import { withFirebase } from '../../../Firebase';


class KalenderKWBBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      status: "",
      huidigSeizoen: "0000-0000",
      kwbheren: [],
      kwbdames: []
    };
  }
  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.huidigSeizoen().on('value', snapshot => {

      this.setState({
        huidigSeizoen: snapshot.val(),
      })
    })

    this.props.firebase.kwbherenKalender().on('value', snapshot => {
      const objectValue = snapshot.val();
      if(objectValue) {
        const wedstrijdList = Object.keys(objectValue).map(key => ({
          ...objectValue[key],
          uid: key,
        }));

        wedstrijdList.forEach((wed, i) => {
          var datumArray = wed.datum.split("/")
          wed.datum = datumArray[2]+"-"+datumArray[1]+"-"+datumArray[0]
        });

        this.setState({
          kwbheren: wedstrijdList
        })
      }
    })

    this.props.firebase.kwbdamesKalender().on('value', snapshot => {
      const objectValue = snapshot.val();
      if(objectValue) {
        const wedstrijdList = Object.keys(objectValue).map(key => ({
          ...objectValue[key],
          uid: key,
        }));

        wedstrijdList.forEach((wed, i) => {
          var datumArray = wed.datum.split("/")
          wed.datum = datumArray[2]+"-"+datumArray[1]+"-"+datumArray[0]
        });

        this.setState({
          kwbdames: wedstrijdList
        })
      }
    })

  }

  onChangeDataKWBHeren = (event, key) => {
    var lijst = this.state.kwbheren
    lijst[key][event.target.name] = event.target.value
    this.setState({
      kwbheren: lijst,
    })
  };

  wed = {
    bezoeker: "",
    code: "",
    dag: "",
    datum: "",
    plaats: "",
    sets: "",
    thuis: "",
    uitslag: "",
    uur: ""
  }

  onChangeDataKWBDames = (event, key) => {
    var lijst = this.state.kwbdames
    lijst[key][event.target.name] = event.target.value
    this.setState({
      kwbdames: lijst,
    })
  };

  addLijnKWBHeren(){
    var lijst = this.state.kwbheren

    lijst.push({
      bezoeker: "",
      code: "",
      dag: "",
      datum: "",
      plaats: "",
      sets: "",
      thuis: "",
      uitslag: "",
      uur: ""
    })

    this.setState({
      kwbheren: lijst,
    })
  }

  addLijnKWBDames(){
    var lijst = this.state.kwbdames

    lijst.push({
      bezoeker: "",
      code: "",
      dag: "",
      datum: "",
      plaats: "",
      sets: "",
      thuis: "",
      uitslag: "",
      uur: ""
    })

    this.setState({
      kwbdames: lijst,
    })
  }

  deleteLijnKWBHeren(key) {
    var lijst = this.state.kwbheren

    lijst.splice(key, 1);

    this.setState({
      kwbheren: lijst,
    })
  }

  deleteLijnKWBDames(key) {
    var lijst = this.state.kwbdames

    lijst.splice(key, 1);

    this.setState({
      kwbdames: lijst,
    })
  }

  gegevensOpslagen() {
    var kwbheren = this.state.kwbheren
    var kwbdames = this.state.kwbdames

    kwbheren.forEach((wed, i) => {
      const datum = new Date(wed.datum)
      var dag = datum.getDate()
      if(Number(dag) < 10) {
        dag = "0" + dag
      }

      var maand = datum.getMonth()+1
      if(Number(maand) < 10) {
        maand = "0" + maand
      }

      wed.datum = dag+"/"+maand+"/"+datum.getFullYear()

      if(wed.thuis.toLowerCase().includes("schriek")){
        wed.thuis = "Schriek VOC (H)"
      }

      if(wed.bezoeker.toLowerCase().includes("schriek")){
        wed.bezoeker = "Schriek VOC (H)"
      }

    });

    kwbdames.forEach((wed, i) => {
      const datum = new Date(wed.datum)
      var dag = datum.getDate()
      if(Number(dag) < 10) {
        dag = "0" + dag
      }

      var maand = datum.getMonth()+1
      if(Number(maand) < 10) {
        maand = "0" + maand
      }

      wed.datum = dag+"/"+maand+"/"+datum.getFullYear()

      if(wed.thuis.toLowerCase().includes("schriek")){
        wed.thuis = "Schriek VOC (D)"
      }

      if(wed.bezoeker.toLowerCase().includes("schriek")){
        wed.bezoeker = "Schriek VOC (D)"
      }

    });

    this.props.firebase.kwbKalender().set({
      heren: kwbheren,
      dames: kwbdames
    })
    alert('Gegevens opgeslagen!')
  }

  render() {

    var {kwbheren, kwbdames} = this.state;

    if(kwbheren.length < 1){
      kwbheren.push({
        bezoeker: "",
        code: "",
        dag: "",
        datum: "",
        plaats: "",
        sets: "",
        thuis: "",
        uitslag: "",
        uur: ""
      })
    }

    if(kwbdames.length < 1){
      kwbdames.push({
        bezoeker: "",
        code: "",
        dag: "",
        datum: "",
        plaats: "",
        sets: "",
        thuis: "",
        uitslag: "",
        uur: ""
      })
    }

    return (
      <div className="sectionSeizoensinstellingen">
        <h1>Kalenders KWB ingeven</h1>

      <div>
        <h3>KWB Heren</h3>
        <Table responsive>
          <thead>
            <tr>
              <th></th>
              <th>Datum</th>
              <th>Uur</th>
              <th>Thuis</th>
              <th>Bezoekers</th>
              <th>Sporthal</th>
              <th>Uitslag</th>
              <th>Verwijderen</th>
            </tr>
          </thead>
          <tbody>
          {kwbheren.map((wed, key) => {
            return(
              <tr>
                <td>{key+1}.</td>
                <td>
                  <input value={wed.datum} type="date" onChange={(e)=>this.onChangeDataKWBHeren(e, key)} name="datum"></input>
                </td>
                <td>
                  <input value={wed.uur} type="time" onChange={(e)=>this.onChangeDataKWBHeren(e, key)} name="uur"></input>
                </td>
                <td>
                  <input value={wed.thuis} onChange={(e)=>this.onChangeDataKWBHeren(e, key)} name="thuis"></input>
                </td>
                <td>
                  <input value={wed.bezoeker} onChange={(e)=>this.onChangeDataKWBHeren(e, key)} name="bezoeker"></input>
                </td>
                <td>
                  <input value={wed.plaats} onChange={(e)=>this.onChangeDataKWBHeren(e, key)} name="plaats"></input>
                </td>
                <td>
                  <input value={wed.uitslag} onChange={(e)=>this.onChangeDataKWBHeren(e, key)} name="uitslag"></input>
                </td>
                {key !== 0 && (
                  <td style={{color: "red"}} onClick={()=>this.deleteLijnKWBHeren(key)}>
                    <i className="fas fa-times"></i>
                  </td>
                )}

              </tr>
            )
          })}
        </tbody>
        </Table>
        <Button variant="primary" onClick={()=> this.addLijnKWBHeren()}>Extra datum toevoegen</Button>
      </div>
      <div>
        <h3>KWB Dames</h3>
          <Table responsive>
            <thead>
              <tr>
                <th></th>
                <th>Datum</th>
                <th>Uur</th>
                <th>Thuis</th>
                <th>Bezoekers</th>
                <th>Sporthal</th>
                <th>Verwijderen</th>
              </tr>
            </thead>
            <tbody>
            {kwbdames.map((wed, key) => {
              return(
                <tr>
                  <td>{key+1}.</td>
                  <td>
                    <input value={wed.datum} type="date" onChange={(e)=>this.onChangeDataKWBDames(e, key)} name="datum"></input>
                  </td>
                  <td>
                    <input value={wed.uur} type="time" onChange={(e)=>this.onChangeDataKWBDames(e, key)} name="uur"></input>
                  </td>
                  <td>
                    <input value={wed.thuis} onChange={(e)=>this.onChangeDataKWBDames(e, key)} name="thuis"></input>
                  </td>
                  <td>
                    <input value={wed.bezoeker} onChange={(e)=>this.onChangeDataKWBDames(e, key)} name="bezoeker"></input>
                  </td>
                  <td>
                    <input value={wed.plaats} onChange={(e)=>this.onChangeDataKWBDames(e, key)} name="plaats"></input>
                  </td>
                  <td>
                    <input value={wed.uitslag} onChange={(e)=>this.onChangeDataKWBDames(e, key)} name="uitslag"></input>
                  </td>
                  {key !== 0 && (
                    <td style={{color: "red"}} onClick={()=>this.deleteLijnKWBDames(key)}>
                      <i className="fas fa-times"></i>
                    </td>
                  )}

                </tr>
              )
            })}
          </tbody>
          </Table>
          <Button variant="primary" onClick={()=> this.addLijnKWBDames()}>Extra datum toevoegen</Button>
      </div>
        <Button variant="primary" onClick={() => this.gegevensOpslagen()}>Gegevens opslagen</Button>
      </div>
    );
  }
}

export const KalenderKWB = withFirebase(KalenderKWBBase);
