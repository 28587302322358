import React from 'react';
import { Ploeg } from './Ploeg';
import img from './img/KWBheren.jpg';

import { withFirebase } from '../Firebase';


export class KWBHerenBase extends React.Component {
  constructor() {
    super()
    this.state = {
      ploeg: 9,
      kwb: "kwbheren"
    }
  }


  render() {

    return (
      <div>
        <Ploeg
          ploeg={this.state.ploeg}
          img={img}
          kwb={this.state.kwb}
        />
      </div>
    )
  }
}

export const KWBHeren = withFirebase(KWBHerenBase);
