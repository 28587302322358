import React from 'react';
import './Nieuws.css';
import { LinkContainer } from "react-router-bootstrap";
import {datumFirebaseString} from "../../functions/DatumFirebase"
import {ButtonOne} from '../../constants/button';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

export class Nieuws extends React.Component {
  render() {

    var nieuwsimage = "url("+this.props.img+")"

    var link = this.props.link
    var interneLink = false
    if(link.includes("www.vocschriek.be")) {
      link = link.split("vocschriek.be")[1];
      interneLink = true
    }

    var textSplit = this.props.tekst.split(" ")
    const maxWoorden = 17
    var textKlein = ""
    textSplit.forEach((item, i) => {
      if(i <= maxWoorden) {
        textKlein = textKlein + item + " "
      }
    });

    textKlein += "(...)"
    return (
        <div className="nieuws-card">
          <div className="nieuws-card-img" style={{backgroundImage: nieuwsimage}} />
          <div>
            <br/>
            <h4>{this.props.titel}</h4>
            <p className="nieuws-card-datum"><i className="far fa-calendar-alt"></i>{datumFirebaseString(this.props.datum)}</p>
            <p>{textKlein}</p>
            <br/>
          </div>
          <Popup
            trigger={<ButtonOne text="Meer info"> </ButtonOne>}
            modal
            nested
            >
              {close => (
                <div className="popupNieuws">
                  <h2>{this.props.titel}</h2>
                  <p className="nieuws-card-datum"><i className="far fa-calendar-alt"></i>{datumFirebaseString(this.props.datum)}</p>
                  <br/>
                  <p>{this.props.tekst}</p>
                  <br/>
                  {!!interneLink ? (
                    <div>
                      {!!this.props.link && (
                        <LinkContainer to={link} style={{marginBottom: "20px"}}>
                          <ButtonOne text="Meer info"> </ButtonOne>
                        </LinkContainer>
                      )}
                    </div>
                  ) : (
                    <div>
                      {!!this.props.link && (
                        <a href={link}>
                          <ButtonOne text="Meer info"> </ButtonOne>
                        </a>
                      )}
                    </div>
                  )}
                  <ButtonOne
                    text="Sluiten"
                    onClick={() => {
                      close();
                    }}
                    />
                </div>
              )}
          </Popup>
        </div>

    )
  }
}
