import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import './style.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const PasswordForgetPage = () => (
  <div id="login">
    <div id="loginform">
      <div>
        <h2>Wachtwoord vergeten</h2>
        <br/>
        <PasswordForgetForm />
      </div>
    </div>
  </div>
);
const INITIAL_STATE = {
  email: '',
  error: null,
};
class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  onSubmit = event => {
    const { email } = this.state;
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { email, error } = this.state;
    const isInvalid = email === '';
    return (
        <form className="inloggegevensform" onSubmit={this.onSubmit}>
          <Form.Control
            name="email"
            value={this.state.email}
            onChange={this.onChange}
            type="text"
            placeholder="E-mailadres"
          />
          <Button variant="primary" disabled={isInvalid} type="submit">
            Reset mijn wachtwoord
          </Button>
          {error && <p>{error.message}</p>}
        </form>
    );
  }
}
const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_VERGETEN}><Button variant="secondary">Wachtwoord vergeten?</Button></Link>
  </p>
);
export default PasswordForgetPage;
const PasswordForgetForm = withFirebase(PasswordForgetFormBase);
export { PasswordForgetForm, PasswordForgetLink };
