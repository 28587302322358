import React from 'react';
import { Ploeg } from './Ploeg';
import img from './img/Heren1.JPG';
import { withFirebase } from '../Firebase';

class Heren1Base extends React.Component {
  constructor() {
    super()
    this.state = {
      ploeg: 0,
    }
  }


  render() {

    return (
      <div>
        <Ploeg
          ploeg={this.state.ploeg}
          img={img}
        />
      </div>
    )
  }
}

export const Heren1 = withFirebase(Heren1Base);
