import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';

import { withFirebase } from '../../../Firebase';


class SeizoenInstellingenBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      status: "",
      huidigSeizoen: "0000-0000",
      seizoen: "",
      inschrijvingOpen: "",
      ploeg1: "",
      ploeg2: "",
      ploeg3: "",
      ploeg4: "",
      ploeg5: "",
      ploeg6: "",
      ploeg7: "",
      ploeg8: "",
      ploeg9: "",
      ploeg10: "",
      ploeg11: "",
      ploeg12: "",
      lidgeldPloeg1: "",
      lidgeldPloeg2: "",
      lidgeldPloeg3: "",
      lidgeldPloeg4: "",
      lidgeldPloeg5: "",
      lidgeldPloeg6: "",
      lidgeldPloeg7: "",
      lidgeldPloeg8: "",
      lidgeldPloeg9: "",
      lidgeldPloeg10: "",
      lidgeldPloeg11: "",
      lidgeldPloeg12: "",
      lidgeldNietSpeler: "",
      volleyscoresPloeg1eerste:"",
      volleyscoresPloeg2eerste:"",
      volleyscoresPloeg3eerste:"",
      volleyscoresPloeg4eerste:"",
      volleyscoresPloeg5eerste:"",
      volleyscoresPloeg6eerste:"",
      volleyscoresPloeg7eerste:"",
      volleyscoresPloeg8eerste:"",
      volleyscoresPloeg9eerste:"",
      volleyscoresPloeg10eerste:"",
      volleyscoresPloeg11eerste:"",
      volleyscoresPloeg12eerste:"",
      volleyscoresPloeg1tweede:"",
      volleyscoresPloeg2tweede:"",
      volleyscoresPloeg3tweede:"",
      volleyscoresPloeg4tweede:"",
      volleyscoresPloeg5tweede:"",
      volleyscoresPloeg6tweede:"",
      volleyscoresPloeg7tweede:"",
      volleyscoresPloeg8tweede:"",
      volleyscoresPloeg9tweede:"",
      volleyscoresPloeg10tweede:"",
      volleyscoresPloeg11tweede:"",
      volleyscoresPloeg12tweede:"",
      activiteit1: "",
      datumActiviteit1: "",
      activiteit2: "",
      datumActiviteit2: "",
      activiteit3: "",
      datumActiviteit3: "",
      activiteit4: "",
      datumActiviteit4: "",
      activiteit5: "",
      datumActiviteit5: "",
      activiteit6: "",
      datumActiviteit6: "",
      activiteit7: "",
      datumActiviteit7: "",
      ploeg1Traingin: "",
      ploeg2Traingin: "",
      ploeg3Traingin: "",
      ploeg4Traingin: "",
      ploeg5Traingin: "",
      ploeg6Traingin: "",
      ploeg7Traingin: "",
      ploeg8Traingin: "",
      ploeg9Traingin: "",
      ploeg10Traingin: "",
      ploeg11Traingin: "",
      ploeg12Traingin: "",
      leeftijdPloeg1: "",
      leeftijdPloeg2: "",
      leeftijdPloeg3: "",
      leeftijdPloeg4: "",
      leeftijdPloeg5: "",
      leeftijdPloeg6: "",
      leeftijdPloeg7: "",
      leeftijdPloeg8: "",
      leeftijdPloeg9: "",
      leeftijdPloeg10: "",
      leeftijdPloeg11: "",
      leeftijdPloeg12: "",
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    var huidigSeizoen = ""
    this.props.firebase.huidigSeizoen().on('value', snapshot => {
      huidigSeizoen = snapshot.val();
      this.setState({
        huidigSeizoen: snapshot.val(),
      })
    })

    this.props.firebase.seizoen(huidigSeizoen).on('value', snapshot => {
      const seizoenObject = snapshot.val();
      if (seizoenObject) {

        var inschrijvingOpen = "false"
        if(!!seizoenObject.inschrijvingOpen){
          inschrijvingOpen = "true"
        }
        this.setState({
          seizoen: seizoenObject,
          inschrijvingOpen: inschrijvingOpen,
          ploeg1: seizoenObject.ploeg1,
          ploeg2: seizoenObject.ploeg2,
          ploeg3: seizoenObject.ploeg3,
          ploeg4: seizoenObject.ploeg4,
          ploeg5: seizoenObject.ploeg5,
          ploeg6: seizoenObject.ploeg6,
          ploeg7: seizoenObject.ploeg7,
          ploeg8: seizoenObject.ploeg8,
          ploeg9: seizoenObject.ploeg9,
          ploeg10: seizoenObject.ploeg10,
          ploeg11: seizoenObject.ploeg11,
          ploeg12: seizoenObject.ploeg12,
          lidgeldPloeg1: seizoenObject.lidgeldPloeg1,
          lidgeldPloeg2: seizoenObject.lidgeldPloeg2,
          lidgeldPloeg3: seizoenObject.lidgeldPloeg3,
          lidgeldPloeg4: seizoenObject.lidgeldPloeg4,
          lidgeldPloeg5: seizoenObject.lidgeldPloeg5,
          lidgeldPloeg6: seizoenObject.lidgeldPloeg6,
          lidgeldPloeg7: seizoenObject.lidgeldPloeg7,
          lidgeldPloeg8: seizoenObject.lidgeldPloeg8,
          lidgeldPloeg9: seizoenObject.lidgeldPloeg9,
          lidgeldPloeg10: seizoenObject.lidgeldPloeg10,
          lidgeldPloeg11: seizoenObject.lidgeldPloeg11,
          lidgeldPloeg12: seizoenObject.lidgeldPloeg12,
          lidgeldNietSpeler: seizoenObject.lidgeldNietSpeler,
          volleyscoresPloeg1eerste: seizoenObject.volleyscoresPloeg1,
          volleyscoresPloeg2eerste: seizoenObject.volleyscoresPloeg2,
          volleyscoresPloeg3eerste: seizoenObject.volleyscoresPloeg3,
          volleyscoresPloeg4eerste: seizoenObject.volleyscoresPloeg4,
          volleyscoresPloeg5eerste: seizoenObject.volleyscoresPloeg5,
          volleyscoresPloeg6eerste: seizoenObject.volleyscoresPloeg6,
          volleyscoresPloeg7eerste: seizoenObject.volleyscoresPloeg7,
          volleyscoresPloeg8eerste: seizoenObject.volleyscoresPloeg8,
          volleyscoresPloeg9eerste: seizoenObject.volleyscoresPloeg9,
          volleyscoresPloeg10eerste: seizoenObject.volleyscoresPloeg10,
          volleyscoresPloeg11eerste: seizoenObject.volleyscoresPloeg11,
          volleyscoresPloeg12eerste: seizoenObject.volleyscoresPloeg12,
          volleyscoresPloeg1tweede: seizoenObject.volleyscoresPloeg1tweede,
          volleyscoresPloeg2tweede: seizoenObject.volleyscoresPloeg2tweede,
          volleyscoresPloeg3tweede: seizoenObject.volleyscoresPloeg3tweede,
          volleyscoresPloeg4tweede: seizoenObject.volleyscoresPloeg4tweede,
          volleyscoresPloeg5tweede: seizoenObject.volleyscoresPloeg5tweede,
          volleyscoresPloeg6tweede: seizoenObject.volleyscoresPloeg6tweede,
          volleyscoresPloeg7tweede: seizoenObject.volleyscoresPloeg7tweede,
          volleyscoresPloeg8tweede: seizoenObject.volleyscoresPloeg8tweede,
          volleyscoresPloeg9tweede: seizoenObject.volleyscoresPloeg9tweede,
          volleyscoresPloeg10tweede: seizoenObject.volleyscoresPloeg10tweede,
          volleyscoresPloeg11tweede: seizoenObject.volleyscoresPloeg11tweede,
          volleyscoresPloeg12tweede: seizoenObject.volleyscoresPloeg12tweede,
          activiteit1: seizoenObject.activiteit1,
          datumActiviteit1: seizoenObject.datumActiviteit1,
          activiteit2: seizoenObject.activiteit2,
          datumActiviteit2: seizoenObject.datumActiviteit2,
          activiteit3: seizoenObject.activiteit3,
          datumActiviteit3: seizoenObject.datumActiviteit3,
          activiteit4: seizoenObject.activiteit4,
          datumActiviteit4: seizoenObject.datumActiviteit4,
          activiteit5: seizoenObject.activiteit5,
          datumActiviteit5: seizoenObject.datumActiviteit5,
          activiteit6: seizoenObject.activiteit6,
          datumActiviteit6: seizoenObject.datumActiviteit6,
          activiteit7: seizoenObject.activiteit7,
          datumActiviteit7: seizoenObject.datumActiviteit7,
          ploeg1Training: seizoenObject.ploeg1Training,
          ploeg2Training: seizoenObject.ploeg2Training,
          ploeg3Training: seizoenObject.ploeg3Training,
          ploeg4Training: seizoenObject.ploeg4Training,
          ploeg5Training: seizoenObject.ploeg5Training,
          ploeg6Training: seizoenObject.ploeg6Training,
          ploeg7Training: seizoenObject.ploeg7Training,
          ploeg8Training: seizoenObject.ploeg8Training,
          ploeg9Training: seizoenObject.ploeg9Training,
          ploeg10Training: seizoenObject.ploeg10Training,
          ploeg11Training: seizoenObject.ploeg11Training,
          ploeg12Training: seizoenObject.ploeg12Training,
          leeftijdPloeg1: seizoenObject.leeftijdPloeg1,
          leeftijdPloeg2: seizoenObject.leeftijdPloeg2,
          leeftijdPloeg3: seizoenObject.leeftijdPloeg3,
          leeftijdPloeg4: seizoenObject.leeftijdPloeg4,
          leeftijdPloeg5: seizoenObject.leeftijdPloeg5,
          leeftijdPloeg6: seizoenObject.leeftijdPloeg6,
          leeftijdPloeg7: seizoenObject.leeftijdPloeg7,
          leeftijdPloeg8: seizoenObject.leeftijdPloeg8,
          leeftijdPloeg9: seizoenObject.leeftijdPloeg9,
          leeftijdPloeg10: seizoenObject.leeftijdPloeg10,
          leeftijdPloeg11: seizoenObject.leeftijdPloeg11,
          leeftijdPloeg12: seizoenObject.leeftijdPloeg12,
          loading: false
        });
      } else {
        this.setState({
          seizoen: null,
          inschrijvingOpen: null,
          ploeg1: null,
          ploeg2: null,
          ploeg3: null,
          ploeg4: null,
          ploeg5: null,
          ploeg6: null,
          ploeg7: null,
          ploeg8: null,
          ploeg9: null,
          ploeg10: null,
          ploeg11: null,
          ploeg12: null,
          lidgeldPloeg1: null,
          lidgeldPloeg2: null,
          lidgeldPloeg3: null,
          lidgeldPloeg4: null,
          lidgeldPloeg5: null,
          lidgeldPloeg6: null,
          lidgeldPloeg7: null,
          lidgeldPloeg8: null,
          lidgeldPloeg9: null,
          lidgeldPloeg10: null,
          lidgeldPloeg11: null,
          lidgeldPloeg12: null,
          lidgeldNietSpeler: null,
          activiteit1: null,
          datumActiviteit1: null,
          activiteit2: null,
          datumActiviteit2: null,
          activiteit3: null,
          datumActiviteit3: null,
          activiteit4: null,
          datumActiviteit4: null,
          activiteit5: null,
          datumActiviteit5: null,
          activiteit6: null,
          datumActiviteit6: null,
          activiteit7: null,
          datumActiviteit7: null,
          loading: false });
      }
    });
  }

  componentWillUnmount() {
    this.props.firebase.activiteiten().off();
  }

  onChangeData = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onEditGegevens = (inschrijvingOpen, seizoen, ploeg1, ploeg2, ploeg3, ploeg4, ploeg5, ploeg6, ploeg7, ploeg8, ploeg9,ploeg10,ploeg11,ploeg12, lidgeldPloeg1, lidgeldPloeg2, lidgeldPloeg3, lidgeldPloeg4, lidgeldPloeg5, lidgeldPloeg6, lidgeldPloeg7, lidgeldPloeg8, lidgeldPloeg9,lidgeldPloeg10,lidgeldPloeg11,lidgeldPloeg12, lidgeldNietSpeler) => {
    const { uid, ...seizoenSnapshot } = seizoen;
    var huidigSeizoen = this.state.huidigSeizoen;

    const volleyscoresCodes = [[this.state.volleyscoresPloeg1eerste, this.state.volleyscoresPloeg1tweede],
    [this.state.volleyscoresPloeg2eerste, this.state.volleyscoresPloeg2tweede],
    [this.state.volleyscoresPloeg3eerste, this.state.volleyscoresPloeg3tweede],
    [this.state.volleyscoresPloeg4eerste, this.state.volleyscoresPloeg4tweede],
    [this.state.volleyscoresPloeg5eerste, this.state.volleyscoresPloeg5tweede],
    [this.state.volleyscoresPloeg6eerste, this.state.volleyscoresPloeg6tweede],
    [this.state.volleyscoresPloeg7eerste, this.state.volleyscoresPloeg7tweede],
    [this.state.volleyscoresPloeg8eerste, this.state.volleyscoresPloeg8tweede],
    [this.state.volleyscoresPloeg9eerste, this.state.volleyscoresPloeg9tweede],
    [this.state.volleyscoresPloeg10eerste, this.state.volleyscoresPloeg10tweede],
    [this.state.volleyscoresPloeg11eerste, this.state.volleyscoresPloeg11tweede],
    [this.state.volleyscoresPloeg12eerste, this.state.volleyscoresPloeg12tweede]]

    var ploegen = [];

    if(this.state.ploeg1 !=="") {
      const ploeg = {
        naam: this.state.ploeg1,
        lidgeld: this.state.lidgeldPloeg1,
        code: this.state.volleyscoresPloeg1eerste,
        codeTwee: this.state.volleyscoresPloeg1tweede,
        training: this.state.ploeg1Training,
        leeftijd: this.state.leeftijdPloeg1
      }
      ploegen.push(ploeg)
    }
    if(this.state.ploeg2 !=="") {
      const ploeg = {
        naam: this.state.ploeg2,
        lidgeld: this.state.lidgeldPloeg2,
        code: this.state.volleyscoresPloeg2eerste,
        codeTwee: this.state.volleyscoresPloeg2tweede,
        training: this.state.ploeg2Training,
        leeftijd: this.state.leeftijdPloeg2
      }
      ploegen.push(ploeg)
    }
    if(this.state.ploeg3 !=="") {
      const ploeg = {
        naam: this.state.ploeg3,
        lidgeld: this.state.lidgeldPloeg3,
        code: this.state.volleyscoresPloeg3eerste,
        codeTwee: this.state.volleyscoresPloeg3tweede,
        training: this.state.ploeg3Training,
        leeftijd: this.state.leeftijdPloeg3
      }
      ploegen.push(ploeg)
    }
    if(this.state.ploeg4 !=="") {
      const ploeg = {
        naam: this.state.ploeg4,
        lidgeld: this.state.lidgeldPloeg4,
        code: this.state.volleyscoresPloeg4eerste,
        codeTwee: this.state.volleyscoresPloeg4tweede,
        training: this.state.ploeg4Training,
        leeftijd: this.state.leeftijdPloeg4
      }
      ploegen.push(ploeg)
    }
    if(this.state.ploeg5 !=="") {
      const ploeg = {
        naam: this.state.ploeg5,
        lidgeld: this.state.lidgeldPloeg5,
        code: this.state.volleyscoresPloeg5eerste,
        codeTwee: this.state.volleyscoresPloeg5tweede,
        training: this.state.ploeg5Training,
        leeftijd: this.state.leeftijdPloeg5
      }
      ploegen.push(ploeg)
    }
    if(this.state.ploeg6 !=="") {
      const ploeg = {
        naam: this.state.ploeg6,
        lidgeld: this.state.lidgeldPloeg6,
        code: this.state.volleyscoresPloeg6eerste,
        codeTwee: this.state.volleyscoresPloeg6tweede,
        training: this.state.ploeg6Training,
        leeftijd: this.state.leeftijdPloeg6
      }
      ploegen.push(ploeg)
    }
    if(this.state.ploeg7 !=="") {
      const ploeg = {
        naam: this.state.ploeg7,
        lidgeld: this.state.lidgeldPloeg7,
        code: this.state.volleyscoresPloeg7eerste,
        codeTwee: this.state.volleyscoresPloeg7tweede,
        training: this.state.ploeg7Training,
        leeftijd: this.state.leeftijdPloeg7
      }
      ploegen.push(ploeg)
    }
    if(this.state.ploeg8 !=="") {
      const ploeg = {
        naam: this.state.ploeg8,
        lidgeld: this.state.lidgeldPloeg8,
        code: this.state.volleyscoresPloeg8eerste,
        codeTwee: this.state.volleyscoresPloeg8tweede,
        training: this.state.ploeg8Training,
        leeftijd: this.state.leeftijdPloeg8
      }
      ploegen.push(ploeg)
    }
    if(this.state.ploeg9 !=="") {
      const ploeg = {
        naam: this.state.ploeg9,
        lidgeld: this.state.lidgeldPloeg9,
        code: this.state.volleyscoresPloeg9eerste,
        codeTwee: this.state.volleyscoresPloeg9tweede,
        training: this.state.ploeg9Training,
        leeftijd: this.state.leeftijdPloeg9
      }
      ploegen.push(ploeg)
    }
    if(this.state.ploeg10 !=="") {
      const ploeg = {
        naam: this.state.ploeg10,
        lidgeld: this.state.lidgeldPloeg10,
        code: this.state.volleyscoresPloeg10eerste,
        codeTwee: this.state.volleyscoresPloeg10tweede,
        training: this.state.ploeg10Training,
        leeftijd: this.state.leeftijdPloeg10
      }
      ploegen.push(ploeg)
    }
    if(this.state.ploeg11 !=="") {
      const ploeg = {
        naam: this.state.ploeg11,
        lidgeld: this.state.lidgeldPloeg11,
        code: this.state.volleyscoresPloeg11eerste,
        codeTwee: this.state.volleyscoresPloeg11tweede,
        training: this.state.ploeg11Training,
        leeftijd: this.state.leeftijdPloeg11
      }
      ploegen.push(ploeg)
    }
    if(this.state.ploeg12 !=="") {
      const ploeg = {
        naam: this.state.ploeg12,
        lidgeld: this.state.lidgeldPloeg12,
        code: this.state.volleyscoresPloeg12eerste,
        codeTwee: this.state.volleyscoresPloeg12tweede,
        training: this.state.ploeg12Training,
        leeftijd: this.state.leeftijdPloeg12
      }
      ploegen.push(ploeg)
    }

    if(inschrijvingOpen === "true") {
      inschrijvingOpen = true
    } else {
      inschrijvingOpen = false
    }

    try {
      this.props.firebase.seizoen(huidigSeizoen).set({
        ...seizoenSnapshot,
        inschrijvingOpen,
        ploegen,
        ploeg1,
        ploeg2,
        ploeg3,
        ploeg4,
        ploeg5,
        ploeg6,
        ploeg7,
        ploeg8,
        ploeg9,
        ploeg10,
        ploeg11,
        ploeg12,
        lidgeldPloeg1,
        lidgeldPloeg2,
        lidgeldPloeg3,
        lidgeldPloeg4,
        lidgeldPloeg5,
        lidgeldPloeg6,
        lidgeldPloeg7,
        lidgeldPloeg8,
        lidgeldPloeg9,
        lidgeldPloeg10,
        lidgeldPloeg11,
        lidgeldPloeg12,
        lidgeldNietSpeler,
        volleyscoresPloeg1: this.state.volleyscoresPloeg1eerste,
        volleyscoresPloeg2: this.state.volleyscoresPloeg2eerste,
        volleyscoresPloeg3: this.state.volleyscoresPloeg3eerste,
        volleyscoresPloeg4: this.state.volleyscoresPloeg4eerste,
        volleyscoresPloeg5: this.state.volleyscoresPloeg5eerste,
        volleyscoresPloeg6: this.state.volleyscoresPloeg6eerste,
        volleyscoresPloeg7: this.state.volleyscoresPloeg7eerste,
        volleyscoresPloeg8: this.state.volleyscoresPloeg8eerste,
        volleyscoresPloeg9: this.state.volleyscoresPloeg9eerste,
        volleyscoresPloeg10: this.state.volleyscoresPloeg10eerste,
        volleyscoresPloeg11: this.state.volleyscoresPloeg11eerste,
        volleyscoresPloeg12: this.state.volleyscoresPloeg12eerste,
        volleyscoresPloeg1tweede: this.state.volleyscoresPloeg1tweede,
        volleyscoresPloeg2tweede: this.state.volleyscoresPloeg2tweede,
        volleyscoresPloeg3tweede: this.state.volleyscoresPloeg3tweede,
        volleyscoresPloeg4tweede: this.state.volleyscoresPloeg4tweede,
        volleyscoresPloeg5tweede: this.state.volleyscoresPloeg5tweede,
        volleyscoresPloeg6tweede: this.state.volleyscoresPloeg6tweede,
        volleyscoresPloeg7tweede: this.state.volleyscoresPloeg7tweede,
        volleyscoresPloeg8tweede: this.state.volleyscoresPloeg8tweede,
        volleyscoresPloeg9tweede: this.state.volleyscoresPloeg9tweede,
        volleyscoresPloeg10tweede: this.state.volleyscoresPloeg10tweede,
        volleyscoresPloeg11tweede: this.state.volleyscoresPloeg11tweede,
        volleyscoresPloeg12tweede: this.state.volleyscoresPloeg12tweede,
        activiteit1: this.state.activiteit1,
        datumActiviteit1: this.state.datumActiviteit1,
        activiteit2: this.state.activiteit2,
        datumActiviteit2: this.state.datumActiviteit2,
        activiteit3: this.state.activiteit3,
        datumActiviteit3: this.state.datumActiviteit3,
        activiteit4: this.state.activiteit4,
        datumActiviteit4: this.state.datumActiviteit4,
        activiteit5: this.state.activiteit5,
        datumActiviteit5: this.state.datumActiviteit5,
        activiteit6: this.state.activiteit6,
        datumActiviteit6: this.state.datumActiviteit6,
        activiteit7: this.state.activiteit7,
        datumActiviteit7: this.state.datumActiviteit7,
        ploeg1Training: this.state.ploeg1Training,
        ploeg2Training: this.state.ploeg2Training,
        ploeg3Training: this.state.ploeg3Training,
        ploeg4Training: this.state.ploeg4Training,
        ploeg5Training: this.state.ploeg5Training,
        ploeg6Training: this.state.ploeg6Training,
        ploeg7Training: this.state.ploeg7Training,
        ploeg8Training: this.state.ploeg8Training,
        ploeg9Training: this.state.ploeg9Training,
        ploeg10Training: this.state.ploeg10Training,
        ploeg11Training: this.state.ploeg11Training,
        ploeg12Training: this.state.ploeg12Training,
        leeftijdPloeg1: this.state.leeftijdPloeg1,
        leeftijdPloeg2: this.state.leeftijdPloeg2,
        leeftijdPloeg3: this.state.leeftijdPloeg3,
        leeftijdPloeg4: this.state.leeftijdPloeg4,
        leeftijdPloeg5: this.state.leeftijdPloeg5,
        leeftijdPloeg6: this.state.leeftijdPloeg6,
        leeftijdPloeg7: this.state.leeftijdPloeg7,
        leeftijdPloeg8: this.state.leeftijdPloeg8,
        leeftijdPloeg9: this.state.leeftijdPloeg9,
        leeftijdPloeg10: this.state.leeftijdPloeg10,
        leeftijdPloeg11: this.state.leeftijdPloeg11,
        leeftijdPloeg12: this.state.leeftijdPloeg12,
        volleyscoresCodes
      });
    } catch(error) {
      alert(error)
    }

    this.props.firebase.huidigSeizoen().set(huidigSeizoen)
    alert('Gegevens opgeslagen!')
  };

  getInfoVorigJaar = () => {

    var huidigSeizoen = this.state.huidigSeizoen.split("-")

    const vorigSeizoen = String(Number(huidigSeizoen[0])-1)+"-"+String(Number(huidigSeizoen[1])-1)

    this.props.firebase.seizoen(vorigSeizoen).on('value', snapshot => {
      const seizoenObject = snapshot.val();
      if (seizoenObject) {

        var inschrijvingOpen = "false"
        if(!!seizoenObject.inschrijvingOpen){
          inschrijvingOpen = "true"
        }
        this.setState({
          seizoen: seizoenObject,
          inschrijvingOpen: inschrijvingOpen,
          ploeg1: seizoenObject.ploeg1,
          ploeg2: seizoenObject.ploeg2,
          ploeg3: seizoenObject.ploeg3,
          ploeg4: seizoenObject.ploeg4,
          ploeg5: seizoenObject.ploeg5,
          ploeg6: seizoenObject.ploeg6,
          ploeg7: seizoenObject.ploeg7,
          ploeg8: seizoenObject.ploeg8,
          ploeg9: seizoenObject.ploeg9,
          ploeg10: seizoenObject.ploeg10,
          ploeg11: seizoenObject.ploeg11,
          ploeg12: seizoenObject.ploeg12,
          lidgeldPloeg1: seizoenObject.lidgeldPloeg1,
          lidgeldPloeg2: seizoenObject.lidgeldPloeg2,
          lidgeldPloeg3: seizoenObject.lidgeldPloeg3,
          lidgeldPloeg4: seizoenObject.lidgeldPloeg4,
          lidgeldPloeg5: seizoenObject.lidgeldPloeg5,
          lidgeldPloeg6: seizoenObject.lidgeldPloeg6,
          lidgeldPloeg7: seizoenObject.lidgeldPloeg7,
          lidgeldPloeg8: seizoenObject.lidgeldPloeg8,
          lidgeldPloeg9: seizoenObject.lidgeldPloeg9,
          lidgeldPloeg10: seizoenObject.lidgeldPloeg10,
          lidgeldPloeg11: seizoenObject.lidgeldPloeg11,
          lidgeldPloeg12: seizoenObject.lidgeldPloeg12,
          lidgeldNietSpeler: seizoenObject.lidgeldNietSpeler,
          volleyscoresPloeg1eerste: seizoenObject.volleyscoresPloeg1,
          volleyscoresPloeg2eerste: seizoenObject.volleyscoresPloeg2,
          volleyscoresPloeg3eerste: seizoenObject.volleyscoresPloeg3,
          volleyscoresPloeg4eerste: seizoenObject.volleyscoresPloeg4,
          volleyscoresPloeg5eerste: seizoenObject.volleyscoresPloeg5,
          volleyscoresPloeg6eerste: seizoenObject.volleyscoresPloeg6,
          volleyscoresPloeg7eerste: seizoenObject.volleyscoresPloeg7,
          volleyscoresPloeg8eerste: seizoenObject.volleyscoresPloeg8,
          volleyscoresPloeg9eerste: seizoenObject.volleyscoresPloeg9,
          volleyscoresPloeg10eerste: seizoenObject.volleyscoresPloeg10,
          volleyscoresPloeg11eerste: seizoenObject.volleyscoresPloeg11,
          volleyscoresPloeg12eerste: seizoenObject.volleyscoresPloeg12,
          volleyscoresPloeg1tweede: seizoenObject.volleyscoresPloeg1tweede,
          volleyscoresPloeg2tweede: seizoenObject.volleyscoresPloeg2tweede,
          volleyscoresPloeg3tweede: seizoenObject.volleyscoresPloeg3tweede,
          volleyscoresPloeg4tweede: seizoenObject.volleyscoresPloeg4tweede,
          volleyscoresPloeg5tweede: seizoenObject.volleyscoresPloeg5tweede,
          volleyscoresPloeg6tweede: seizoenObject.volleyscoresPloeg6tweede,
          volleyscoresPloeg7tweede: seizoenObject.volleyscoresPloeg7tweede,
          volleyscoresPloeg8tweede: seizoenObject.volleyscoresPloeg8tweede,
          volleyscoresPloeg9tweede: seizoenObject.volleyscoresPloeg9tweede,
          volleyscoresPloeg10tweede: seizoenObject.volleyscoresPloeg10tweede,
          volleyscoresPloeg11tweede: seizoenObject.volleyscoresPloeg11tweede,
          volleyscoresPloeg12tweede: seizoenObject.volleyscoresPloeg12tweede,
          activiteit1: seizoenObject.activiteit1,
          datumActiviteit1: seizoenObject.datumActiviteit1,
          activiteit2: seizoenObject.activiteit2,
          datumActiviteit2: seizoenObject.datumActiviteit2,
          activiteit3: seizoenObject.activiteit3,
          datumActiviteit3: seizoenObject.datumActiviteit3,
          activiteit4: seizoenObject.activiteit4,
          datumActiviteit4: seizoenObject.datumActiviteit4,
          activiteit5: seizoenObject.activiteit5,
          datumActiviteit5: seizoenObject.datumActiviteit5,
          activiteit6: seizoenObject.activiteit6,
          datumActiviteit6: seizoenObject.datumActiviteit6,
          activiteit7: seizoenObject.activiteit7,
          datumActiviteit7: seizoenObject.datumActiviteit7,
          ploeg1Training: seizoenObject.ploeg1Training,
          ploeg2Training: seizoenObject.ploeg2Training,
          ploeg3Training: seizoenObject.ploeg3Training,
          ploeg4Training: seizoenObject.ploeg4Training,
          ploeg5Training: seizoenObject.ploeg5Training,
          ploeg6Training: seizoenObject.ploeg6Training,
          ploeg7Training: seizoenObject.ploeg7Training,
          ploeg8Training: seizoenObject.ploeg8Training,
          ploeg9Training: seizoenObject.ploeg9Training,
          ploeg10Training: seizoenObject.ploeg10Training,
          ploeg11Training: seizoenObject.ploeg11Training,
          ploeg12Training: seizoenObject.ploeg12Training,
          leeftijdPloeg1: seizoenObject.leeftijdPloeg1,
          leeftijdPloeg2: seizoenObject.leeftijdPloeg2,
          leeftijdPloeg3: seizoenObject.leeftijdPloeg3,
          leeftijdPloeg4: seizoenObject.leeftijdPloeg4,
          leeftijdPloeg5: seizoenObject.leeftijdPloeg5,
          leeftijdPloeg6: seizoenObject.leeftijdPloeg6,
          leeftijdPloeg7: seizoenObject.leeftijdPloeg7,
          leeftijdPloeg8: seizoenObject.leeftijdPloeg8,
          leeftijdPloeg9: seizoenObject.leeftijdPloeg9,
          leeftijdPloeg10: seizoenObject.leeftijdPloeg10,
          leeftijdPloeg11: seizoenObject.leeftijdPloeg11,
          leeftijdPloeg12: seizoenObject.leeftijdPloeg12,
          loading: false
        });
      } else {
        this.setState({
          seizoen: null,
          inschrijvingOpen: null,
          ploeg1: null,
          ploeg2: null,
          ploeg3: null,
          ploeg4: null,
          ploeg5: null,
          ploeg6: null,
          ploeg7: null,
          ploeg8: null,
          ploeg9: null,
          ploeg10: null,
          ploeg11: null,
          ploeg12: null,
          lidgeldPloeg1: null,
          lidgeldPloeg2: null,
          lidgeldPloeg3: null,
          lidgeldPloeg4: null,
          lidgeldPloeg5: null,
          lidgeldPloeg6: null,
          lidgeldPloeg7: null,
          lidgeldPloeg8: null,
          lidgeldPloeg9: null,
          lidgeldPloeg10: null,
          lidgeldPloeg11: null,
          lidgeldPloeg12: null,
          lidgeldNietSpeler: null,
          activiteit1: null,
          datumActiviteit1: null,
          activiteit2: null,
          datumActiviteit2: null,
          activiteit3: null,
          datumActiviteit3: null,
          activiteit4: null,
          datumActiviteit4: null,
          activiteit5: null,
          datumActiviteit5: null,
          activiteit6: null,
          datumActiviteit6: null,
          activiteit7: null,
          datumActiviteit7: null,
          loading: false });
      }
    });
  }

  render() {

    return (
      <div className="sectionSeizoensinstellingen">
        <br/>
        <h2>Seizoen instellingen</h2>
        <br/>
        <h4>Huidig seizoen: <input type="text" value={this.state.huidigSeizoen} placeholder="2020-2021" onChange={this.onChangeData} name="huidigSeizoen"></input></h4>
        <p>Seizoenvoorbeeld: 2020-2021 (streepje niet vergeten)</p>
        <p>Opgelet! Dit aanpassen start een nieuw seizoen!</p>
        {this.state.seizoen === null && (
        <Button onClick={() => this.getInfoVorigJaar()}>Info van vorig jaar hieronder kopiëren</Button>
        )}
          <div className="inschrijvingen-activiteitenlijst">
            <p>Status:</p>
            <select id="herinschrijvingSelect" value={this.state.inschrijvingOpen} onChange={this.onChangeData} name="inschrijvingOpen">
              <option value="false">Inschrijvingen Gesloten</option>
              <option value="true">Inschrijvingen Open</option>
            </select>
          </div>
        <br/><br/>
        <div className="inschrijvingen-ploegenlijst">
          <h5>Ploegen:</h5>
          <div className="flex-herinschrijven">
            <input type="text" value={this.state.ploeg1} placeholder="Ploeg 1" onChange={this.onChangeData} name="ploeg1"></input>
            <input type="number" value={this.state.lidgeldPloeg1} placeholder="Lidgeld Ploeg 1" onChange={this.onChangeData} name="lidgeldPloeg1"></input>
            <input type="text" value={this.state.volleyscoresPloeg1eerste} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg1eerste"></input>
            <input type="text" value={this.state.volleyscoresPloeg1tweede} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg1tweede"></input>
            <input type="text" value={this.state.ploeg1Training} placeholder="Training" onChange={this.onChangeData} name="ploeg1Training"></input>
            <input type="text" value={this.state.leeftijdPloeg1} placeholder="Leeftijd" onChange={this.onChangeData} name="leeftijdPloeg1"></input>
          </div>
          <div className="flex-herinschrijven">
            <input type="text" value={this.state.ploeg2} placeholder="Ploeg 2" onChange={this.onChangeData} name="ploeg2"></input>
            <input type="number" value={this.state.lidgeldPloeg2} placeholder="Lidgeld Ploeg 2" onChange={this.onChangeData}  name="lidgeldPloeg2"></input>
            <input type="text" value={this.state.volleyscoresPloeg2eerste} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg2eerste"></input>
            <input type="text" value={this.state.volleyscoresPloeg2tweede} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg2tweede"></input>
            <input type="text" value={this.state.ploeg2Training} placeholder="Training" onChange={this.onChangeData} name="ploeg2Training"></input>
            <input type="text" value={this.state.leeftijdPloeg2} placeholder="Leeftijd" onChange={this.onChangeData} name="leeftijdPloeg2"></input>
          </div>
          <div className="flex-herinschrijven">
            <input type="text" value={this.state.ploeg3} placeholder="Ploeg 3"  onChange={this.onChangeData} name="ploeg3"></input>
            <input type="number" value={this.state.lidgeldPloeg3} placeholder="Lidgeld Ploeg 3" onChange={this.onChangeData} name="lidgeldPloeg3"></input>
            <input type="text" value={this.state.volleyscoresPloeg3eerste} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg3eerste"></input>
            <input type="text" value={this.state.volleyscoresPloeg3tweede} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg3tweede"></input>
            <input type="text" value={this.state.ploeg3Training} placeholder="Training" onChange={this.onChangeData} name="ploeg3Training"></input>
            <input type="text" value={this.state.leeftijdPloeg3} placeholder="Leeftijd" onChange={this.onChangeData} name="leeftijdPloeg3"></input>
          </div>
          <div className="flex-herinschrijven">
            <input type="text" value={this.state.ploeg4} placeholder="Ploeg 4"  onChange={this.onChangeData} name="ploeg4"></input>
            <input type="number" value={this.state.lidgeldPloeg4} placeholder="Lidgeld Ploeg 4" onChange={this.onChangeData} name="lidgeldPloeg4"></input>
            <input type="text" value={this.state.volleyscoresPloeg4eerste} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg4eerste"></input>
            <input type="text" value={this.state.volleyscoresPloeg4tweede} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg4tweede"></input>
            <input type="text" value={this.state.ploeg4Training} placeholder="Training" onChange={this.onChangeData} name="ploeg4Training"></input>
            <input type="text" value={this.state.leeftijdPloeg4} placeholder="Leeftijd" onChange={this.onChangeData} name="leeftijdPloeg4"></input>
          </div>
          <div className="flex-herinschrijven">
            <input type="text" value={this.state.ploeg5} placeholder="Ploeg 5"  onChange={this.onChangeData} name="ploeg5"></input>
            <input type="number" value={this.state.lidgeldPloeg5} placeholder="Lidgeld Ploeg 5" onChange={this.onChangeData} name="lidgeldPloeg5"></input>
            <input type="text" value={this.state.volleyscoresPloeg5eerste} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg5eerste"></input>
            <input type="text" value={this.state.volleyscoresPloeg5tweede} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg5tweede"></input>
            <input type="text" value={this.state.ploeg5Training} placeholder="Training" onChange={this.onChangeData} name="ploeg5Training"></input>
            <input type="text" value={this.state.leeftijdPloeg5} placeholder="Leeftijd" onChange={this.onChangeData} name="leeftijdPloeg5"></input>
          </div>
          <div className="flex-herinschrijven">
            <input type="text" value={this.state.ploeg6} placeholder="Ploeg 6"  onChange={this.onChangeData} name="ploeg6"></input>
            <input type="number" value={this.state.lidgeldPloeg6} placeholder="Lidgeld Ploeg 6" onChange={this.onChangeData} name="lidgeldPloeg6"></input>
            <input type="text" value={this.state.volleyscoresPloeg6eerste} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg6eerste"></input>
            <input type="text" value={this.state.volleyscoresPloeg6tweede} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg6tweede"></input>
            <input type="text" value={this.state.ploeg6Training} placeholder="Training" onChange={this.onChangeData} name="ploeg6Training"></input>
            <input type="text" value={this.state.leeftijdPloeg6} placeholder="Leeftijd" onChange={this.onChangeData} name="leeftijdPloeg6"></input>
          </div>
          <div className="flex-herinschrijven">
            <input type="text" value={this.state.ploeg7} placeholder="Ploeg 7"  onChange={this.onChangeData} name="ploeg7"></input>
            <input type="number" value={this.state.lidgeldPloeg7} placeholder="Lidgeld Ploeg 7" onChange={this.onChangeData} name="lidgeldPloeg7"></input>
            <input type="text" value={this.state.volleyscoresPloeg7eerste} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg7eerste"></input>
            <input type="text" value={this.state.volleyscoresPloeg7tweede} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg7tweede"></input>
            <input type="text" value={this.state.ploeg7Training} placeholder="Training" onChange={this.onChangeData} name="ploeg7Training"></input>
            <input type="text" value={this.state.leeftijdPloeg7} placeholder="Leeftijd" onChange={this.onChangeData} name="leeftijdPloeg7"></input>
          </div>
          <div className="flex-herinschrijven">
            <input type="text" value={this.state.ploeg8} placeholder="Ploeg 8"  onChange={this.onChangeData} name="ploeg8"></input>
            <input type="number" value={this.state.lidgeldPloeg8} placeholder="Lidgeld Ploeg 8" onChange={this.onChangeData} name="lidgeldPloeg8"></input>
            <input type="text" value={this.state.volleyscoresPloeg8eerste} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg8eerste"></input>
            <input type="text" value={this.state.volleyscoresPloeg8tweede} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg8tweede"></input>
            <input type="text" value={this.state.ploeg8Training} placeholder="Training" onChange={this.onChangeData} name="ploeg8Training"></input>
            <input type="text" value={this.state.leeftijdPloeg8} placeholder="Leeftijd" onChange={this.onChangeData} name="leeftijdPloeg8"></input>
          </div>
          <div className="flex-herinschrijven">
            <input type="text" value={this.state.ploeg9} placeholder="Ploeg 9"  onChange={this.onChangeData} name="ploeg9"></input>
            <input type="number" value={this.state.lidgeldPloeg9} placeholder="Lidgeld Ploeg 9" onChange={this.onChangeData} name="lidgeldPloeg9"></input>
            <input type="text" value={this.state.volleyscoresPloeg9eerste} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg9eerste"></input>
            <input type="text" value={this.state.volleyscoresPloeg9tweede} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg9tweede"></input>
            <input type="text" value={this.state.ploeg9Training} placeholder="Training" onChange={this.onChangeData} name="ploeg9Training"></input>
            <input type="text" value={this.state.leeftijdPloeg9} placeholder="Leeftijd" onChange={this.onChangeData} name="leeftijdPloeg9"></input>
          </div>
          <div className="flex-herinschrijven">
            <input type="text" value={this.state.ploeg10} placeholder="Ploeg 10"  onChange={this.onChangeData} name="ploeg10"></input>
            <input type="number" value={this.state.lidgeldPloeg10} placeholder="Lidgeld Ploeg 10" onChange={this.onChangeData} name="lidgeldPloeg10"></input>
            <input type="text" value={this.state.volleyscoresPloeg10eerste} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg10eerste"></input>
            <input type="text" value={this.state.volleyscoresPloeg10tweede} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg10tweede"></input>
            <input type="text" value={this.state.ploeg10Training} placeholder="Training" onChange={this.onChangeData} name="ploeg10Training"></input>
            <input type="text" value={this.state.leeftijdPloeg10} placeholder="Leeftijd" onChange={this.onChangeData} name="leeftijdPloeg10"></input>
          </div>
          <div className="flex-herinschrijven">
            <input type="text" value={this.state.ploeg11} placeholder="Ploeg 11"  onChange={this.onChangeData} name="ploeg11"></input>
            <input type="number" value={this.state.lidgeldPloeg11} placeholder="Lidgeld Ploeg 11" onChange={this.onChangeData} name="lidgeldPloeg11"></input>
            <input type="text" value={this.state.volleyscoresPloeg11eerste} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg11eerste"></input>
            <input type="text" value={this.state.volleyscoresPloeg11tweede} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg11tweede"></input>
            <input type="text" value={this.state.ploeg11Training} placeholder="Training" onChange={this.onChangeData} name="ploeg11Training"></input>
            <input type="text" value={this.state.leeftijdPloeg11} placeholder="Leeftijd" onChange={this.onChangeData} name="leeftijdPloeg11"></input>
          </div>
          <div className="flex-herinschrijven">
            <input type="text" value={this.state.ploeg12} placeholder="Ploeg 12"  onChange={this.onChangeData} name="ploeg12"></input>
            <input type="number" value={this.state.lidgeldPloeg12} placeholder="Lidgeld Ploeg 12" onChange={this.onChangeData} name="lidgeldPloeg12"></input>
            <input type="text" value={this.state.volleyscoresPloeg12eerste} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg12eerste"></input>
            <input type="text" value={this.state.volleyscoresPloeg12tweede} placeholder="Code volleyscores" onChange={this.onChangeData} name="volleyscoresPloeg12tweede"></input>
            <input type="text" value={this.state.ploeg12Training} placeholder="Training" onChange={this.onChangeData} name="ploeg12Training"></input>
            <input type="text" value={this.state.leeftijdPloeg12} placeholder="Leeftijd" onChange={this.onChangeData} name="leeftijdPloeg12"></input>
          </div>
          <div className="flex-herinschrijven">
            <input type="text" value="Niet Speler"></input>
            <input type="number" value={this.state.lidgeldNietSpeler} placeholder="Lidgeld Niet Speler" onChange={this.onChangeData} name="lidgeldNietSpeler"></input>
          </div>
        </div>
        <div className="inschrijvingen-activiteitenlijst">
          <h5>Activiteiten:</h5>
          <div className="flex-herinschrijven">
            <input type="text" value={this.state.activiteit1} placeholder="Activiteit 1" onChange={this.onChangeData} name="activiteit1"></input>
            <input type="date" value={this.state.datumActiviteit1} placeholder="Datum activiteit 1" onChange={this.onChangeData} name="datumActiviteit1"></input>
          </div>
          <div className="flex-herinschrijven">
            <input type="text" value={this.state.activiteit2} placeholder="Activiteit 2" onChange={this.onChangeData} name="activiteit2"></input>
            <input type="date" value={this.state.datumActiviteit2} placeholder="Datum activiteit 2" onChange={this.onChangeData} name="datumActiviteit2"></input>
          </div>
          <div className="flex-herinschrijven">
            <input type="text" value={this.state.activiteit3} placeholder="Activiteit 3" onChange={this.onChangeData} name="activiteit3"></input>
            <input type="date" value={this.state.datumActiviteit3} placeholder="Datum activiteit 3" onChange={this.onChangeData} name="datumActiviteit3"></input>
          </div>
          <div className="flex-herinschrijven">
            <input type="text" value={this.state.activiteit4} placeholder="Activiteit 4" onChange={this.onChangeData} name="activiteit4"></input>
            <input type="date" value={this.state.datumActiviteit4} placeholder="Datum activiteit 4" onChange={this.onChangeData} name="datumActiviteit4"></input>
          </div>
          <div className="flex-herinschrijven">
            <input type="text" value={this.state.activiteit5} placeholder="Activiteit 5" onChange={this.onChangeData} name="activiteit5"></input>
            <input type="date" value={this.state.datumActiviteit5} placeholder="Datum activiteit 5" onChange={this.onChangeData} name="datumActiviteit5"></input>
          </div>
          <div className="flex-herinschrijven">
            <input type="text" value={this.state.activiteit6} placeholder="Activiteit 6" onChange={this.onChangeData} name="activiteit6"></input>
            <input type="date" value={this.state.datumActiviteit6} placeholder="Datum activiteit 6" onChange={this.onChangeData} name="datumActiviteit6"></input>
          </div>
          <div className="flex-herinschrijven">
            <input type="text" value={this.state.activiteit7} placeholder="Activiteit 7" onChange={this.onChangeData} name="activiteit7"></input>
            <input type="date" value={this.state.datumActiviteit7} placeholder="Datum activiteit 7" onChange={this.onChangeData} name="datumActiviteit7"></input>
          </div>
        </div>
        <Button variant="primary" onClick={() => {this.onEditGegevens(
            this.state.inschrijvingOpen,
            this.state.seizoen,
             this.state.ploeg1,
              this.state.ploeg2,
               this.state.ploeg3,
                this.state.ploeg4,
                 this.state.ploeg5,
                  this.state.ploeg6,
                   this.state.ploeg7,
                    this.state.ploeg8,
                     this.state.ploeg9,
                      this.state.ploeg10,
                       this.state.ploeg11,
                        this.state.ploeg12,
                         this.state.lidgeldPloeg1,
                          this.state.lidgeldPloeg2,
                           this.state.lidgeldPloeg3,
                            this.state.lidgeldPloeg4,
                             this.state.lidgeldPloeg5,
                              this.state.lidgeldPloeg6,
                               this.state.lidgeldPloeg7,
                                this.state.lidgeldPloeg8,
                                 this.state.lidgeldPloeg9,
                                  this.state.lidgeldPloeg10,
                                   this.state.lidgeldPloeg11,
                                    this.state.lidgeldPloeg12,
                                    this.state.lidgeldNietSpeler,
                                  )}}>Gegevens ploegen opslagen</Button>
      </div>
    );
  }
}

export const SeizoenInstellingen = withFirebase(SeizoenInstellingenBase);
