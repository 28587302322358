import React from 'react';
import { Ploeg } from './Ploeg';
import img from './img/MU15.jpeg';

import { withFirebase } from '../Firebase';

export class MeisjesU15Base extends React.Component {
  constructor() {
    super()
    this.state = {
      ploeg: 5,
    }
  }


  render() {

    return (
      <div>
        <Ploeg
          ploeg={this.state.ploeg}
          img={img}
        />
      </div>
    )
  }
}

export const MeisjesU15 = withFirebase(MeisjesU15Base);
