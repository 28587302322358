export function datumFirebaseDate(datum) {
  if(datum !== ""){
    var datumZonder = datum.replace(/[&/\-#,+()$~%.'":*?<>{}]/g,'')
    var jaar = datumZonder.substr(6,4);
    var maand = datumZonder.substr(2,2);
    var dag = datumZonder.substr(0,2);
    if (jaar.length < 4) {
      jaar = "20"+jaar
    }
    return new Date(maand+"/"+dag+"/"+jaar)
  }
}

export function datumFirebaseDateNieuws(datum) {
  if(datum !== ""){
    var datumZonder = datum.replace(/[&/\-#,+()$~%.'":*?<>{}]/g,'')
    var jaar = datumZonder.substr(4,2);
    var maand = datumZonder.substr(2,2);
    var dag = datumZonder.substr(0,2);
    if (jaar.length < 4) {
      jaar = "20"+jaar
    }
    return new Date(maand+"/"+dag+"/"+jaar)
  }
}

export function datumFirebaseString(datum) {
  if(datum !== ""){
    var datumZonder = datum.replace(/[&/\-#,+()$~%.'":*?<>{}]/g,'')
    var jaar = datumZonder.substr(6,2);
    var maand = datumZonder.substr(2,2);
    var dag = datumZonder.substr(0,2);
    if (jaar.length < 4) {
      jaar = "20"+jaar
    }

    return dag+"/"+maand+"/"+jaar
  }
}

export function datumFirebaseOmgekeerdeString(datum) {
  if(datum !== ""){
    var datumZonder = datum.replace(/[&/\-#,+()$~%.'":*?<>{}]/g,'')
    var jaar = datumZonder.substr(6,2);
    var maand = datumZonder.substr(2,2);
    var dag = datumZonder.substr(0,2);
    if (jaar.length < 4) {
      jaar = "20"+jaar
    }

    return jaar+"-"+maand+"-"+dag
  }
}

export function DatumFromFirebaseServerToString(datum) {
  if(datum !== ""){
    var date = new Date(datum)
    var dag = date.getDate()
    var maand = date.getMonth()
    var jaar = date.getFullYear()
    maand = maand + 1

    if (dag < 10) {
      dag = "0" + dag
    }
    if (maand < 10) {
      maand = "0" + maand
    }

    var uur = date.getHours()
    var minuten = date.getMinutes()

    if (uur < 10) {
      uur = "0" + uur
    }

    if (minuten < 10) {
      minuten = "0" + minuten
    }


    return dag+"/"+maand+"/"+jaar + " - " +uur+":"+minuten
  }
}
