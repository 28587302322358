import React from 'react';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification} from '../../session';
import { LinkContainer } from "react-router-bootstrap";
import * as ROUTES from '../../../constants/routes';
import EthiasPDF from './Ethias-Aangifteformulier.pdf';
import EthiasIMG from './Ethias.png';
import FalosPDF from './Falos-Aangifteformulier.pdf';
import FalosIMG from './Falos.png';
import whatsappAlgemeen from './whatsapp_algemeen.svg';
import whatsappJeugd from './whatsapp_jeugd.svg';

class Ledeninfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);


    };

    componentWillUnmount() {

    }

  render(){

  return (
    <div id="infopagina">
      <br />
      <div><h1>Info over onze club</h1></div>
      <div className="onderlijn"></div>
      <br/>
      <h3>Whatsapp groep</h3>
      <br/>
      <p>Scan of upload onderstaande QR-code met de whatsapp-camera om lid te worden van deze groep</p>
      <p>Bezoek je deze pagina met je smartphone en heb je Whatsapp geinstalleerd, dan kan je gewoon op de QR-code klikken!</p>
      <div className="verzekeringspapieren-flex">
        <div>
          <br/>
          <a href="https://chat.whatsapp.com/B891MVU2IQrFlVdBr8B1Y7"><img src={whatsappAlgemeen} className="whatsappIMG" alt="whatssapp_algemeen"></img></a>
        </div>
      </div>
      <br/>
      <br/>
      <h3>Verzekeringspapieren</h3>
      <br/>
      <p>Heb je een blessure opgelopen tijdens een training of wedstrijd?<br/>
      Bezorg onderstaande papieren dan binnen de 8 dagen aan </p><LinkContainer to={ROUTES.BESTUUR}><div className="contactpersoon"><p>Trees Peeters</p></div></LinkContainer>
      <div className="verzekeringspapieren-flex">
        <a href={EthiasPDF} download>
          <br/>
          <h5>Verzekeringspapieren Volley Vlaanderen</h5>
          <br/>
          <img src={EthiasIMG} className="verzekeringIMG" alt="ethias"></img>
        </a>
        <a href={FalosPDF} download>
          <br/>
          <h5>Verzekeringspapieren KWB Falos</h5>
          <br/>
          <img src={FalosIMG} className="verzekeringIMG" alt="falos"></img>
        </a>
      </div>
      <br/>
      <br/>
      <h3>Wat is dat: van dienst zijn?</h3>
      <br/>
        <p>Van dienst zijn wilt zeggen dat je met jouw ploeg 1 of meerdere wedstrijden zorgt dat alles in orde is.</p>
        <br/>
        <p>Wat moet je allemaal doen wanneer je van dienst bent?</p>
        <br/>
        <ul style={{margin: "auto", width: "420px", textAlign:"left"}}>
          <li>Je bent <strong>1 uur (!!!)</strong> voor de eerste match aanwezig om alle terreinen op te stellen</li>
          <br/>
          <li>Wat stel je op:<br/>
          - De terreinen (uiteraard)<br/>
          - Schermen tussen de terreinen<br/>
          - De tafels in het ballenkot voor invullen papieren<br/>
          - Sponsor banners<br/>
          - Water voorzien op elk terrein<br/>
          </li>
          <br/>
          <li>Per match is er verplicht 1 terreinverantwoordelijke en 1 puntenzetter (ten minste een half uur voor de match aanwezig op het plein)</li>
          <br/>
          <li>Voor de match nakijken of er voldoende water aanwezig is</li>
          <br/>
          <li>Na de laatste match: alles terug afbreken en op de juiste plaats leggen</li>
          <br/>
        </ul>
      <br/>
      <br/>
      <h3>Markeren</h3>
      <br />
      <p>Markeren is niet zo moeilijk zoals vele mensen denken</p>
      <p>Via onderstaande links kan je lezen over hoe je moet markeren (op papier en op tabblad) en je zal zien dat dit allemaal wel meevalt!</p>
      <br/>
      <a href="https://www.volleyvlaanderen.be/wp-content/uploads/cursusmarkeren.pdf" target="_black">Handleiding Markeren (op papier)</a>
      <br/><br/>
      <a href="https://www.volleyvlaanderen.be/wp-content/uploads/VolleySpike-NL.pdf" target="_black">Handleiding VolleySpike (Markeren op tabblad)</a>
    </div>
  );
}}

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(Ledeninfo);
