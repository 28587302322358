import React from 'react';
import "./Contact.css"

export class Contact extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <div className="backgroundContact"></div>
        <div className="white-bg"></div>
        <h1 className="activiteit-titel">Contact</h1>
        <div className="onderlijn"></div>
        <div className="d-flex justify-content-center flex-wrap mb-5">
          <div className="d-block mx-5">
            <p>Heb je vragen?<br/>
            Wil je ons iets vertellen?</p>
            <br></br>
            <p>Dan kan je ons altijd een email sturen op:</p>
            <a href="mailto:volleybalclubschriek@gmail.com?Subject=Ik%20heb%20een%20vraag">volleybalclubschriek@gmail.com</a>
          </div>
        </div>
      </div>
    )
  }
}
