import React from 'react';
import "./style.css";
import foto1 from './img/jeugd1.jpeg';
import foto2 from './img/jeugd2.jpeg';
import foto3 from './img/jeugd3.jpeg';
import foto4 from './img/jeugd4.jpeg';
import foto5 from './img/jeugd5.jpeg';
import {ButtonOne} from '../../constants/button';
import Pdf from './Jeugdbeleidsplan_seizoen_2021-2022.pdf';
import { LinkContainer } from "react-router-bootstrap";
import * as ROUTES from '../../constants/routes';

export class Jeugd extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <div className="backgroundJeugd"></div>
        <div className="white-bg"></div>
        <div className="activiteit-text" id="text-jeugd">
          <h1 className="activiteit-titel">Jeugdwerking VOC Schriek</h1>
          <div className="onderlijn"></div>
          <div className="pagina-algemeen" id="pagina-jeugd-text-rechts">
            <h2>Wij zetten in op onze jeugd!</h2>
            <img className="jeugdfoto" id="jeugdfoto1" src={foto1} alt="foto1"></img>
            <div>
              <p>Aangezien de jeugd de toekomst is, zetten we als club volop in op onze jeugdwerking.
                <br/><br/>
              VOC Schriek doet zo bijvoorbeeld elk jaar mee met het Jeugdsportfonds om de jeugdwerking te analyseren en verbeteren.
              <br/>Met onze deelname aan het Jeugdsportfonds kunnen we een kwalitatieve jeugdwerking garanderen.
                <br/><br/>
              Daarnaast organiseren we per seizoen minstens één vriendjesdag waarop jeugdleden hun vriendjes kunnen meebrengen om deze op een speelse manier kennis te laten maken met volleybal.
                <br/><br/>
              </p>
            </div>
            <div id="pagina-jeugd-text-links">
              <p>
              Ook zetten we elk jaar onze schouders onder de sportdag van basisschool De Groeituin te Schriek/Grootlo en brengen we soms een bezoek aan enkele scholen in naburige gemeentes. <br/>We passeren ook jaarlijks bij de plaatselijke jeugdbewegingen om een toffe volleybalnamiddag te organiseren. <br/>Zo laten we de kinderen kennis maken met deze toffe balsport en vinden ze makkelijker de weg naar onze club.
                <br/><br/>
              </p>
            </div>
            <img className="jeugdfoto" id="jeugdfoto4" src={foto4} alt="foto4"></img>
            <img className="jeugdfoto" id="jeugdfoto5" src={foto5} alt="foto5"></img>
            <p>
              Per seizoen organiseren we elk jaar een toffe activiteit voor onze jeugdspelers, het mag natuurlijk af en toe ook eens iets anders zijn dan volleybal.
              <br/><br/>
              Wij willen als club ook toegankelijk blijven. Ons lidgeld voor jeugdspelers bedraagt daarom slechts 140 euro voor een gans volleybalseizoen. <br/>Voor de jongste leeftijdscategorie (6-8 jaar, Kids Volley) bedraagt dit zelfs maar 100 euro.
            </p>
          </div>
          <div className="pagina-algemeen andereKleur" id="pagina-jeugd-text-links">
            <h2>Kids Volley</h2>
            <div>
              <p>Voor alle jongens en meisjes die graag met andere kinderen willen sporten, en kiezen voor een bal- en teamsport.
                <br/><br/>
              In deze groep leer je vooral de fysieke basisvaardigheden als lenigheid en coördinatie ontwikkelen door middel van spelvormen als 1-met-1 en 1-tegen-1. De kinderen ontdekken de vreugde van het samenspelen in opdrachten en wedstrijdjes 2-tegen-2.
              <br/><br/>
              De kinderen maken op een speelse en toffe manier een eerste keer kennis met de verschillende baltechnieken. Volleybal komt hier pas op de tweede plaats. Tijdens de lessenreeks evolueren de kinderen langzaam van individueel handelen naar aangepaste competitievormen met kindgerichte spelregels.
              <br/><br/>
              Van zodra het kind klaar is voor volleybal, gaat hij/zij naar de groep ‘U11 Gemengd’. <br/>Vanaf dan krijgen de kinderen specifieke volleybaltraining en kunnen zij meedoen aan de competitie. Het moment van overstap is voor elk kind verschillend en wordt door de trainers beoordeeld.
              </p>
            </div>
            <img className="jeugdfoto" id="jeugdfoto2" src={foto2} alt="foto2"></img>
          </div>
          <div className="pagina-algemeen" id="pagina-jeugd-text-rechts">
            <h2>Start2Volley</h2>
            <img className="jeugdfoto" id="jeugdfoto3" src={foto3} alt="foto3"></img>
            <div>
              <p>Regelmatig organiseren wij <strong>gratis</strong> start2volley trainingen voor alle jongens en meisjes van 6 t.e.m. 12 jaar.
                <br/><br/>Dit zal doorgaan in DE Sporthal van Heist o/d berg <br/>(Lostraat 48A - 2220 Heist o/d Berg).
                <br/><br/>Inschrijven is niet verplicht maar wel aangeraden. (Je kan ons bereiken via ons e-mailadres: volleybalclubschriek@gmail.com)
              </p>
            </div>
          </div>
          <div className="pagina-algemeen andereKleur">
            <div></div>
            <div></div>
            <div>
              <h3>Jeugdbeleidsplan en Jeugdcharter</h3>
              <br/><br/>
              <div>
                <a href={Pdf} target="_blank"rel="noopener noreferrer"><div><ButtonOne variant="primary" light text="Jeugdbeleidsplan en Jeugdcharter VOC Schriek"/></div></a>
                <br />
                <br />
              </div>
            </div>
            <div>
              <h3>Heb je toch nog wat vragen?</h3><br /><p>Stuur ons dan zeker een mailtje: <br/><strong>volleybalclubschriek@gmail.com</strong> <br/>of <br />contacteer onze jeugdcoördinator: <br/><strong>jeugdcoordinator@vocschriek.be</strong></p>
            </div>
            <div className="overtuigd">
              <h3>Helemaal overtuigd?</h3> <h4>Super!</h4><br/> <p>Ga dan naar onze "Lid Worden" pagina voor de laatste info <br/>en toegang tot ons online inschrijfsysteem</p>
              <br/>
              <LinkContainer to={ROUTES.LIDWORDEN}><div><ButtonOne variant="primary" text="Lid worden" light/></div></LinkContainer>
            </div>
          </div>
        </div>

      </div>
    )
  }
}
